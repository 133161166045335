<template>
    <div class="form-field__checkbox">
        <input
            v-if="field.type != 'disclaimer'"
            :id="`${field.name}-${uid}`"
            type="checkbox"
            :checked="field.value == 't'"
            :class="{ 'is-invalid': field.error }"
            :required="( field.rules || [] ).includes('required')"
            @change="emits"
            >
        <label
            class="form__checkbox-label"
            :for="`${field.name}-${uid}`"
            v-html="field.label"
            />
    </div>
</template>

<script>
import UniqueID from '@helpers/unique-id'
export default {
    props: {
        field: { type: Object, required: true }
    },
    data() {
        return {
            uid: UniqueID.generate()
        }
    },
    methods: {
        emits(e) {
            this.$emit('change', this.field)
            this.$emit('checked', e.target.checked)
        }
    }
}
</script>
