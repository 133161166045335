<template>
    <b-modal
        v-model="outOfStock"
        :title="$ll('productBasketModalAvailabilityNotifyTitle')"
        :ok-title="$ll('basketModalAvailabilityNotifySubmit')"
        :cancel-title="$ll('basketBackToShoppingButton')"
        centered
        :ok-disabled="sent"
        @ok="e => { e.preventDefault(); $refs.form.submit(); }"
        @hidden="$emit('hidden')"
        >
        <!-- <div class="text-left" v-if="!login.logged"> -->
        <div class="text-left">
            <custom-form
                ref="form"
                target="availabilityNotice"
                :action="`/ajax/availabilityNotice/${variants()[0].variant_id}`"
                @submitted="sent = true"
                />
        </div>
        <!-- <div class="text-left" v-else>
            Inaczej?
        </div> -->
    </b-modal>
</template>

<script>
import CustomForm from '@components/custom-form'
import { mapGetters } from 'vuex'
export default {

    components: {
        CustomForm
    },

    props: {
        open: { type: Boolean, default: false },
        variant: { type: Object, required: true }
    },

    data() {
        return {
            sent: false
        }
    },

    computed: {
        // ...mapState('Setup', ['login']),
        ...mapGetters({
            variants: 'ProductCard/variants'
        }),
        outOfStock: {
            get() {
                return this.$store.state.ProductCard.outOfStock
            },
            set(payload) {
                this.$store.commit('ProductCard/outOfStock', payload, { root: true })
            }
        }
    },
    watch: {
        outOfStock(v) {
            if (v) {
                this.sent = false
                this.$store.commit('CustomForm/unhideForm', 'availabilityNotice')
                this.$store.commit('CustomForm/setFormAction', {
                    target: 'availabilityNotice',
                    action: `/ajax/availabilityNotice/${this.variants()[0].variant_id}`
                })
            }
        }
    }
}
</script>
