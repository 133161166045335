<template>
    <div
        v-if="deliveryId && loadDelivery"
        class="form-group"
        >
        <label
            class="text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black payments-label"
            >
            {{ $ll('basketSelectPaymentMethod') }}
        </label>
        <input
            type="hidden"
            :value="payment"
            required
            class="form-control"
            :class="{ 'is-invalid': !payment && submited }"
            >
        <div class="invalid-feedback">
            {{ $ll('basketPaymentMethodNotSet') }}
        </div>
        <div class="row">
            <div
                v-for="(item, i) in deliveries[deliveryId].items"
                :key="i"
                class="col-6 col-sm-4 col-md-3 col-lg-4 btn-group"
                >
                <div
                    class="btn-outline-primary p-2 text-center"
                    :class="{ active: payment == i }"
                    @click.prevent="choosePayment(i, item)"
                    >
                    <div>
                        <div
                            class="mb-1"
                            v-text="item.name"
                            />
                        <span v-if="!free_delivery || !(item.cost - deliveries[deliveryId].min)">
                            <span
                                v-if="item.cost - deliveries[deliveryId].min > 0"
                                v-text="'+'"
                                />
                            <price
                                v-if="item.cost - deliveries[deliveryId].min > 0"
                                :value="item.cost - deliveries[deliveryId].min"
                                />
                        </span>
                    </div>
                </div>
            </div>
            <payment-wall v-if="payment && payment_methods[paymentId]" />
        </div>
    </div>
</template>

<script>
import Price from '@components/price'
import { mapGetters, mapState, mapMutations } from 'vuex'
const PaymentWall = () => import(/* webpackChunkName: 'payment-wall' */ '@/components/basket/full/step2/delivery/payment-wall.vue')

export default {

    components: {
        Price,
        PaymentWall
    },

    computed: {
        ...mapState('Basket', [
            'submited', 'deliveryId', 'payment', 'free_delivery', 'loadDelivery', 'payment_methods', 'paymentId'
        ]),
        ...mapGetters({
            deliveries: 'Basket/getDeliveries'
        })
    },

    methods: {
        ...mapMutations({
            setPayment: 'Basket/setPayment',
            setPaymentId: 'Basket/setPaymentId',
            setPaymentBehaviour: 'Basket/setPaymentBehaviour'
        }),
        choosePayment(i, item) {
            this.setPayment(i)
            this.setPaymentId(item.paymentId)
            this.setPaymentBehaviour(item.behaviour)
        }

    }
}
</script>
