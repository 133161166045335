import '@/vanilla'
import Affix from 'vue-affix'
import VueScrollTo from 'vue-scrollto'
import VueLazyload from 'vue-lazyload'
import Vue from 'vue'

import { VBToggle, VBTooltip, BPagination, BPaginationNav, BCollapse, BAlert, BModal, BBadge, BProgress, BInputGroup, BFormInput, BInputGroupAppend, BButton, BPopover } from 'bootstrap-vue'

import LangLine from '@plugins/lang'
import photoMiniature from '@plugins/photominiature'
import RouteTranslate from '@plugins/rt'
import VuePhotoSwipe from '@plugins/photoswipe'
import QuantityNumbers from '@plugins/quantity-numbers'

import Basket from '@components/basket/mini'
import BasketButton from '@components/basket/mini/top-button'
import CategoryFilter from '@components/category-filter'
import CategoryFiltersContainer from '@components/category/container'
import CategoryFiltersPriceInput from '@components/category-filter-price-input'
import CategoryFiltersToggle from '@components/category/toggle'
import CategoryFiltersToggleGrid from '@components/category/toggle-grid'
import CategoryPerPage from '@components/category-per-page'
import CategorySortBy from '@components/category-sort-by'

import CupboardButton from '@components/product/cupboard/top-button'
import CupboardList from '@components/cupboard-list'
import CurrencyList from '@components/currency-list'
import CustomForm from '@components/custom-form'
import CustomSelect from '@components/custom-form/fields/select'
import LanguageList from '@components/language-list'
import LastBrick from '@components/category/last-brick'
import Loader from '@components/loader'
import Newsletter from '@components/newsletter'
import Notifications from '@components/notifications'
import NotificationsModal from '@components/notifications-modal'
import OrderPayment from '@components/order'
import ProductAddBasket from '@components/product-add-basket'
import ProductAddBasketModal from '@components/product/add-basket-modals/product-main'
import ProductAddCupboard from '@components/product/cupboard/button'
import ProductAttr from '@components/product-attr'
import ProductCardPrice from '@components/product-card-price'
import ProductCarousel from '@components/product/carousel'
import ProductCode from '@components/product/code'
import ProductSet from '@components/product/sets'
import ProductInformation from '@components/product/information'
import ProductList from '@components/product-list'
import ProductListPagination from '@components/product-list-pagination'
import ProductPhotos from '@components/product-photos'
import ProductPhotosCarousel from '@components/product-photos-carousel'
import ProductPreview from '@components/product/preview'
import ProductShortPreview from '@components/product/short-preview'
import ProductTwigPreview from '@components/product/twig-preview'
import ProductSizeTable from '@components/product/size-table'
import LowestMonthPrice from '@components/product/lowest-month-price'
import ProductSizeTableTrigger from '@components/product/size-table/trigger'
import SearchForm from '@components/search/form'
import SearchTop from '@components/search'
import StarsPreview from '@components/my-account/stars-preview'
import StarsBars from '@components/product/stars-bars'
import TotalOpinions from '@components/product/total-opinions'
import StationaryShops from '@components/stationary-shops'
import clubInfoBanner from '@components/club-info-banner'
import OpinionsCarousel from '@components/opinions-carousel'
import { Carousel, Slide } from 'vue-carousel'
import Picture from '@components/picture'
import Banderolle from '@components/banderolle'
import router from '@/router'
import store from '@/store'

import '@scss/main.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@plugins/gmap'
import '@components/global'

import '@node/@mdi/font/scss/materialdesignicons.scss'
import '@src/icons-icomoon/style.css'
import '@src/icons-icomoon/fonts/icons.ttf'
import '@node/@mdi/font/fonts/materialdesignicons-webfont.woff2'

const CookiesPolicy = () => import(/* webpackChunkName: 'cookies-policy' */ '@components/cookies-policy')
const Cookieconsentstables = () => import(/* webpackChunkName: 'cookie-consents-tables' */ '@components/cookies-policy/cookie-consents-tables.vue')

Vue.use(Affix)
Vue.use(LangLine)
Vue.use(photoMiniature)
Vue.use(RouteTranslate)
Vue.use(VuePhotoSwipe)
Vue.use(VueScrollTo)
Vue.use(QuantityNumbers)

Vue.use(VueLazyload, {
    preLoad: 1,
    lazyComponent: true,
})

Vue.prototype.$langUrl = function (url) {
    const defaultLang = store.state.Setup.default_language_iso

    return `${defaultLang === store.getters['Setup/language'] ? '' : `/${store.getters['Setup/language']}`}${url}`
}

Vue.mixin({
    directives: {
        'b-toggle': VBToggle,
        'b-tooltip': VBTooltip,
    },
    components: {
        Picture,
        BPagination,
        BPaginationNav,
        BCollapse,
        BAlert,
        BModal,
        BBadge,
        BProgress,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BPopover,
    },
})

const vue = new Vue({
    el: '#app',
    components: {
        Basket,
        BasketButton,
        Carousel,
        LowestMonthPrice,
        CategoryFilter,
        CategoryFiltersContainer,
        CategoryFiltersPriceInput,
        CategoryFiltersToggle,
        CategoryFiltersToggleGrid,
        CategoryPerPage,
        CategorySortBy,
        CookiesPolicy,
        CupboardButton,
        CupboardList,
        CurrencyList,
        CustomForm,
        CustomSelect,
        LanguageList,
        LastBrick,
        Loader,
        Newsletter,
        Notifications,
        NotificationsModal,
        OrderPayment,
        ProductAddBasket,
        ProductAddBasketModal,
        ProductAddCupboard,
        ProductAttr,
        ProductCardPrice,
        ProductCarousel,
        ProductInformation,
        ProductList,
        ProductListPagination,
        ProductPhotos,
        ProductPhotosCarousel,
        ProductPreview,
        ProductShortPreview,
        ProductTwigPreview,
        ProductSizeTable,
        ProductSizeTableTrigger,
        SearchForm,
        SearchTop,
        Slide,
        StarsPreview,
        StationaryShops,
        clubInfoBanner,
        ProductSet,
        StarsBars,
        TotalOpinions,
        ProductCode,
        OpinionsCarousel,
        Banderolle,
        Cookieconsentstables,
    },
    router,
    store,
})

const filter = function (text, length, clamp) {
    const newClamp = clamp || '...'
    const node = document.createElement('div')

    node.innerHTML = text
    const content = node.textContent

    return content.length > length ? content.slice(0, length) + newClamp : content
}

Vue.filter('truncate', filter)

