<template>
    <div class="banderolle__outer">
        <div
            v-for="item in items"
            :key="item.id"
            class="banderolle"
            :style="{backgroundColor: item.variables.backgroundColor}"
            >
            <div :class="{ container: !fluid, 'container-fluid': fluid }">
                <div
                    class="banderolle__content"
                    v-html="item.variables.content"
                    />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import throttle from 'lodash.throttle'

export default {
    name: 'Banderolle',
    props: {
        type: {
            type: String,
            required: true
        },
        fluid: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            setBodyPaddingThrottled: throttle(() => {
                this.setBodyPadding()
            }, 100)
        }
    },
    computed: {
        ...mapGetters('Notifications', ['getByType']),
        items() {
            return this.getByType(this.type)
        }
    },
    mounted() {
        window.addEventListener('resize', this.setBodyPaddingThrottled)
        this.setBodyPaddingThrottled()
        setTimeout(this.setBodyPaddingThrottled, 500)
    },
    methods: {
        setBodyPadding() {
            let headerHeight = document.querySelector('.header-wrapper').getBoundingClientRect().height
            const scrolledDown = document.querySelector('.scrolled-down')

            if (scrolledDown) headerHeight += 20 // add hidden .header-content-top element height and shrinked header elements to prevent layout jump

            if (headerHeight + 'px' !== document.body.style.paddingTop) {
                document.body.style.paddingTop = headerHeight + 'px'
            }
        }
    }
}
</script>