var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "basket-gift-pack" }, [
    _c(
      "div",
      {
        staticClass:
          "form-field__checkbox form-field__checkbox--alternate form-field--alternate form-field__checkbox--image",
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.vValue,
              expression: "vValue",
            },
          ],
          attrs: { id: "gift-pack", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.vValue)
              ? _vm._i(_vm.vValue, null) > -1
              : _vm.vValue,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.vValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.vValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.vValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.vValue = $$c
              }
            },
          },
        }),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "form__checkbox-label", attrs: { for: "gift-pack" } },
          [
            _c("span", [_vm._v(_vm._s(_vm.$ll("giftPackingLabel")))]),
            _vm._v(" "),
            _c("i", { staticClass: "i-gift" }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }