var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "py-5 my-5 row mx-0": _vm.alone } },
    [
      _c("div", { class: { "col-12 col-md-8 col-lg-6 m-auto": _vm.alone } }, [
        _c(
          "h3",
          {
            staticClass:
              "mb-4 pb-3 text-center text-uppercase bottom-line light",
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.$ll("loginTitle")) + "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.login({
                  email: _vm.email,
                  psw: _vm.psw,
                  basket: !_vm.alone,
                })
              },
            },
          },
          [
            _c("div", { staticClass: "form-field" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                staticClass: "form-control form-field__input",
                attrs: {
                  id: "InputEmail",
                  type: "email",
                  placeholder: _vm.$ll("loginEmail"),
                  disabled: _vm.busy,
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("label", {
                staticClass: "form-field__placeholder",
                domProps: { textContent: _vm._s(_vm.$ll("loginEmail")) },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-field" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.psw,
                    expression: "psw",
                  },
                ],
                staticClass: "form-control form-field__input",
                attrs: {
                  id: "InputPsw",
                  type: "password",
                  placeholder: _vm.$ll("loginPassword"),
                  disabled: _vm.busy,
                },
                domProps: { value: _vm.psw },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.psw = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("label", {
                staticClass: "form-field__placeholder",
                domProps: { textContent: _vm._s(_vm.$ll("loginPassword")) },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center mb-4" },
              [
                _c("btn-submit", {
                  staticClass: "btn btn-primary mb-3 mt-3 w-100",
                  class: { "w-100": !_vm.alone },
                  attrs: { disabled: _vm.busy, value: _vm.$ll("loginButton") },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "d-inline-block plain-link px-4",
                    attrs: { href: _vm.$rt("resetPassword") },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$ll("loginPasswordForgotten")) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("a", { attrs: { href: "/access/registration_step1" } }, [
          _c("button", { staticClass: "btn btn-outline-secondary w-100" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$ll("topRegister")) +
                "\n            "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("modal-message"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }