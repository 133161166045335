<template>
    <span class="product-item__badges">
        <div class="form-field">
            <div class="form-field__checkbox">
                <input
                    :id="`c-${item.cupboard_id}-${item.variant.variant}`"
                    type="checkbox"
                    @change="setChecked"
                    >
                <label
                    :for="`c-${item.cupboard_id}-${item.variant.variant}`"
                    class="form__checkbox-label form__checkbox-label-cupboard"
                    />
            </div>
        </div>
    </span>
</template>

<script>
export default {
    props: ['item'],
    methods: {
        setChecked(ev) {
            this.$store.commit('ProductCupboard/setSelect', {
                item: this.item,
                set: ev.target.checked
            })
        }
    }
}
</script>
