var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline-block mb-1 stars-container" },
    [
      _c("star-rating", {
        attrs: {
          rating: _vm.value,
          increment: 1,
          "read-only": true,
          "star-size": _vm.starSizeComputed,
          "show-rating": false,
          "inactive-color": _vm.color,
          "active-color": _vm.activeColor,
          "border-width": _vm.borderWidth,
          "border-color": _vm.activeColor,
          "rounded-corners": !_vm.isSharpNoBorders,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }