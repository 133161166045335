var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-3" }, [
    _c("div", { staticClass: "col-12 col-sm-6 mb-3" }, [
      _vm._v("\n        " + _vm._s(_vm.$ll("basketDeliveryInfo")) + ":"),
      _c("br"),
      _c("strong", { staticClass: "text-black" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.getDeliveries[_vm.deliveryId].name) +
            "\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 col-sm-6 mb-3" }, [
      _vm._v("\n        " + _vm._s(_vm.$ll("basketPaymentInfo")) + ":"),
      _c("br"),
      _c("strong", { staticClass: "text-black" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.getDeliveries[_vm.deliveryId].items[_vm.payment].name) +
            "\n        "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }