var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { "mb-4": _vm.fieldOpen } }, [
    _c("div", { staticClass: "row no-gutters" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "basket-discount-input-row" }, [
          _c("div", { staticClass: "form-field form-field--alternate mb-2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value",
                },
              ],
              staticClass: "form-control form-field__input",
              class: { "form-field__input--invalid": _vm.voucherError },
              attrs: {
                disabled: _vm.quantityChangePending,
                placeholder: _vm.$ll("basketVoucherPlaceholder"),
              },
              domProps: { value: _vm.value },
              on: {
                keyup: function ($event) {
                  return _vm.setVoucherInput($event, _vm.value)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.value = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("label", {
              staticClass: "form-field__placeholder",
              domProps: {
                textContent: _vm._s(_vm.$ll("basketVoucherPlaceholder")),
              },
            }),
            _vm._v(" "),
            _vm.voucherError
              ? _c("div", {
                  staticClass: "form-field__hint form-field__hint--invalid",
                  domProps: { textContent: _vm._s(_vm.voucherError) },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("button", {
              staticClass:
                "btn btn-secondary btn-secondary-slimed px-2 mr-1 w-100",
              class: { disabled: _vm.quantityChangePending },
              attrs: { disabled: !_vm.value },
              domProps: { textContent: _vm._s(_vm.$ll("basketVoucherSubmit")) },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.setVoucher(_vm.value)
                  _vm.value = ""
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _vm.voucher.code
          ? _c("div", { staticClass: "clearer" }, [
              _c("small", [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.voucher.name) +
                    ": -" +
                    _vm._s(_vm.voucher.value) +
                    "\n                    "
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn-link-inline no-hover d-inline-block ml-2",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.clearVoucher.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._m(0)]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [_c("i", { staticClass: "i-close font-weight-bolder" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }