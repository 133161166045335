var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "basket__container" },
    [_vm.loginFrom.includes("/basket") ? _c("login-basket") : _c("login-form")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }