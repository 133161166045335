<template>
    <a
        href="#"
        class="no-hover"
        @click.prevent="close"
        >
        <i class="i-close" />
    </a>
</template>

<script>
import { mapMutations } from 'vuex'

export default {

    methods: {
        ...mapMutations({
            close: 'CategoryFilter/toggle'
        })
    }
}
</script>
