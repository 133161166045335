var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row no-gutters" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        Object.keys(_vm.images).length > 1
          ? _c(
              "carousel",
              {
                staticClass: "VueProductCarousel product-carousel",
                attrs: {
                  "per-page": 1,
                  loop: false,
                  "pagination-enabled": false,
                  "navigation-enabled": true,
                  "navigation-next-label": "",
                  "navigation-prev-label": "",
                },
              },
              _vm._l(_vm.images, function (product, id) {
                return _c("slide", { key: id }, [
                  _c(
                    "a",
                    { attrs: { href: _vm.url } },
                    [
                      _c("Picture", {
                        attrs: {
                          src: _vm.$photoUrl(product.path, 380),
                          width: product.w,
                          height: product.h,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              1
            )
          : _c(
              "div",
              { staticClass: "row mt-3" },
              _vm._l(_vm.images, function (product, id) {
                return _c("div", { key: id, staticClass: "col-12 mb-3 pr-5" }, [
                  _c(
                    "a",
                    { attrs: { href: _vm.url } },
                    [
                      _c("Picture", {
                        attrs: {
                          src: _vm.$photoUrl(product.path, 380),
                          width: product.w,
                          height: product.h,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }