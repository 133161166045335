<template>
    <div>
        <div
            v-for="item in filterAttributes"
            :key="item.id"
            class="pt-2 product-description-item"
            :class="{'params-warning': paramsWarn && !chosen[item.id]}"
        >
            <div class="text-uppercase text-primary">
                {{ item.name }}: <span
                    v-if="chosenColorName && item.color "
                    v-text="chosenColorName"
                />
            </div>
            <ul class="nav nav-pills prod-attr mt-2">
                <li
                    v-for="value in item.values"
                    :key="value.id"
                    class="nav-item"
                >
                    <a
                        class="nav-link cursor-pointer"
                        :class="{active: chosen[item.id] == value.id, disabled: value.disabled, 'not-available': value.notifyIco}"
                        @click.prevent="select({id: item.id, value: value.id})"
                    >
                        <div
                            v-if="value.photo"
                            class="position-relative"
                        >
                            <link
                                rel="preload"
                                as="image"
                                :href="$photoUrl(value.photo.full)"
                            >
                            <link
                                rel="preload"
                                as="image"
                                :href="$photoUrl(value.photo.full).replace(/.(jpg|png)$/, '.webp')"
                            >
                            <span
                                :id="`col-${item.id}-${value.id}`"
                                class="product-color position-relative prod-attr__popover-trigger"
                            >
                                <Picture
                                    :src="$photoUrl(value.photo.thumbnail)"
                                    class-img="img-cover position-absolute pos-reset"
                                />
                            </span>

                            <div class="d-flex flex-column prod-attr__popover-content popover bs-popover-top">
                                <div class="arrow" />
                                <Picture
                                    :src="$photoUrl(value.photo.full)"
                                    style="max-width: 100px"
                                    width="100"
                                    height="100"
                                    style-img="max-width: unset !important"
                                />
                                <span
                                    class="text-uppercase mt-2"
                                    v-text="value.value"
                                />
                            </div>
                        </div>
                        <span
                            v-else-if="item.type == 'attributeList'"
                            v-text="value.value"
                        />
                        <span
                            v-else-if="item.type == 'attributeColor'"
                            class="product-color"
                            :style="{'background': value.color}"
                        />
                        <span
                            v-if="item.type == 'attributeColor'"
                            class="d-none color-name"
                            v-text="value.value"
                        />
                    </a>
                </li>
            </ul>
            <div
                v-if="paramsWarn && !chosen[item.id]"
                class="not-set"
            >
                {{ $ll('productCardAttributeNotSet') }}: {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {

    computed: {
        ...mapState('ProductCard', [
            'attributes',
            'paramsWarn',
            'visibility',
            'modelGroups',
        ]),
        ...mapGetters({
            chosen: 'ProductCard/chosen',
        }),
        filterAttributes() {
            /**
             * Dodanie filtra do atrybutow wylaczajacy kolor z listy jezeli modelGrup
             */
            return this.attributes.filter((attr) => !this.modelGroups || !attr.color)
        },
        chosenColorName() {
            /* Find color attributes with its values */
            const attrColor = this.attributes.find((attribute) => attribute.color)

            if (attrColor) {
                /* Array with color values objects */
                const colors = attrColor.values
                const chosenColor = colors && colors.find((color) => color.id === +this.chosen[attrColor.id])

                return chosenColor && chosenColor.value
            }
            return null
        },
    },

    mounted() {
        if (this.visibility) {
            this.setDisabledNotify()
            this.setAvailableAttr()
        }
    },

    methods: {
        ...mapActions({
            setAttr: 'ProductCard/setAttr',
            setAvailableAttr: 'ProductCard/setAvailableAttr',
            setDisabledNotify: 'ProductCard/setDisabledNotify',
        }),
        select(payload) {
            if (this.visibility) {
                this.setAttr(payload)
            }
        },
    },
}
</script>

<style lang="scss">
.params-warning {
    .not-set {
        border-top: 1px solid red;
        color: red;
    }
}

.prod-attr {
    &__popover {
        &-content {
            transition: .5s;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(calc(-100% - 14px));
            padding: 0.5rem 0.75rem;
        }
        &-trigger:not(:hover) + &-content {
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
        }
    }
}
</style>
