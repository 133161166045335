<template>
    <div>
        <div class="container mb-2 my-account-menu">
            <h1
                class=" mt-4 mb-5 text-center text-uppercase"
                v-text="$ll('myCupboardsTitle')"
                />
            <ul
                v-if="list.length > 1"
                class="nav justify-content-center"
                >
                <!-- <li
                    class="nav-item"
                    :class="{ active: !cupboardId }"
                    >
                    <a
                        class="nav-link cursor-pointer"
                        @click="$router.push($langUrl('/cupboard'))"
                        >
                        {{ $ll('myCupboardsItemAll') }} ({{ allItemsCount }})
                    </a>
                </li> -->
                <li
                    v-for="item in list"
                    :key="item.cupboardId"
                    class="nav-item"
                    :class="{ active: cupboardId == item.cupboardId }"
                    >
                    <a
                        class="nav-link cursor-pointer"
                        @click="$router.push($langUrl(`/cupboard${item.cupboardId && `/${item.cupboardId}` || ''}`))"
                        >
                        {{ item.cupboardName }} ({{ item.products.length }})
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('ProductCupboard', [
            'list', 'cupboardId'
        ])
        // allItemsCount() {
        //     return this.list.reduce((r, i) => {
        //         r += i.products.length
        //         return r
        //     }, 0)
        // }
    }
}
</script>
