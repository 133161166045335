<template>
    <div class="row no-gutters pb-2">
        <div class="col-12 col-md-8 col-lg-6 m-auto">
            <form
                ref="form"
                class="m-auto footer-nl"
                :action="action"
                @submit.prevent="dispatch"
                >
                <div class="row no-gutters">
                    <div class="col-10 col-lg-8 m-auto">
                        <div class="form-field form-field--alternate">
                            <input
                                v-model="fields.email.value"
                                class="form-control form-field__input"
                                required
                                type="email"
                                name="email"
                                autocomplete="off"
                                :disabled="data.disabled"
                                placeholder="email"
                                @focus="focused = true"
                                >

                            <label
                                class="form-field__placeholder "
                                v-text="$ll('newsletter-label')"
                                />

                            <div
                                v-if="!!fields.email.error"
                                class="form-field__hint form-field__hint--invalid"
                                v-text="fields.email.error"
                                />
                            <button class="btn btn--submit-over">
                                {{ $ll('newsletterSubscribeNewsletter') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-10 col-lg-8 m-auto">
                        <transition name="fade">
                            <form-items
                                v-show="focused"
                                target="newsletter"
                                :action="action"
                                />
                        </transition>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import FormMixin from '@mixins/custom-form'
import FormItems from '@components/custom-form/items'

export default {

    components: {
        FormItems
    },

    mixins: [FormMixin],

    data() {
        return {
            focused: false
        }
    }
}
</script>