//dodawanie klasy do inputa z zawartością
document.addEventListener('DOMContentLoaded', () => {
    const input = document.querySelectorAll('input[type="email"], input[type="text"], textarea')
    const selects = document.querySelectorAll('.v-select')

    const miniLabel = function(el) {
        if (el.value !== "") {
            el.classList.add('not-empty')
        }
        else {
            el.classList.remove('not-empty')
        }
    }

    for (let i = 0; i < input.length; i++) {
        miniLabel(input[i])
        input[i].addEventListener("keyup", () => {
            miniLabel(input[i])
        })
    }
    if (selects) {
        for (let i = 0; i < selects.length; i++) {
            selects[i].addEventListener("click", () => {
                if (selects[i].querySelector('.selected-tag')) {
                    selects[i].classList.add('not-empty')
                }
            })
        }
    }
    if (document.getElementById('consent-rodo')) {
        const text = document.getElementById("consent-rodo").innerText
        const register_rodo = document.getElementById("consent-rodo")
        const register_more_btn = document.getElementById("consent-more")
        const register_collapse = document.getElementById("consent-collapse")

        const truncateConsent = () => {
            const truncated = text.substring(0, 89) + "..."

            register_rodo.innerHTML = truncated
        }

        const unTruncateConsent = () => {
            register_rodo.innerHTML = text
        }

        if (text.length > 89) {
            truncateConsent()
            register_more_btn.classList.add("d-block")
            register_more_btn.classList.remove("d-none")
            register_more_btn.addEventListener('click', () => {
                unTruncateConsent()
                register_rodo.classList.add("active")
                register_collapse.classList.add("d-block")
                register_collapse.classList.remove("d-none")
                register_more_btn.classList.add("d-none")
                register_more_btn.classList.remove("d-block")
            })
            register_collapse.addEventListener('click', () => {
                truncateConsent()
                register_rodo.classList.remove("active")
                register_collapse.classList.add("d-none")
                register_collapse.classList.remove("d-block")
                register_more_btn.classList.add("d-block")
                register_more_btn.classList.remove("d-none")
            })
        }
    }
})