<template>
    <div>
        <div
            v-for="(field,id) in fields"
            :key="id"
            class="form-field"
            :class="{ 'd-none': field.hidden}"
            >
            <input
                v-if="field.fieldType == 'input'"
                v-model="field.value"
                class="form-control form-field__input"
                :class="{ 'form-field__input--invalid': field.error }"
                :type="field.hidden ? 'hidden' : field.type"
                :name="field.name"
                :required="( field.rules || [] ).includes('required')"
                :minlength="( field.rules || [] ).map(i => i.match(/^min_length\[[0-9]{1,}/) && i.match(/\d+/).shift()).filter(Boolean).join('') || false"
                :maxlength="( field.rules || [] ).map(i => i.match(/^max_length\[[0-9]{1,}/) && i.match(/\d+/).shift()).filter(Boolean).join('') || false"
                :placeholder="field.label"
                :disabled="data.disabled || field.disabled"
                >

            <input
                v-if="field.fieldType == 'file'"
                class="form-control form-field__input"
                :class="{ 'form-field__input--invalid': field.error }"
                type="file"
                :name="field.name"
                :required="( field.rules || [] ).includes('required')"
                :accept="( field.rules || []).map(i => i.match(/^fileExtensionCheck/))"
                :placeholder="field.label"
                :disabled="data.disabled || field.disabled"
                @change="changeFile"
                >

            <div v-if="field.fieldType == 'textarea'">
                <textarea
                    v-model="field.value"
                    class="form-control form-field__textarea"
                    :class="{ 'form-field__textarea--invalid': field.error }"
                    :name="field.name"
                    :required="( field.rules || [] ).includes('required')"
                    :placeholder="field.label"
                    :disabled="data.disabled || field.disabled"
                    :maxlength="( field.rules || [] ).map(i => i.match(/^max_length\[[0-9]{1,}/) && i.match(/\d+/).shift()).filter(Boolean).join('') || false"
                    />

                <div
                    v-if="!!( field.rules || [] ).map(i => i.match(/^max_length\[[0-9]{1,}/) && i.match(/\d+/).shift()).filter(Boolean).join('') || false"
                    >
                    {{ $ll('form-textarea-chars-remaining') }}:
                    {{ ( field.rules || [] ).map(i => i.match(/^max_length\[[0-9]{1,}/) && i.match(/\d+/).shift()).filter(Boolean).join('') - (field.value || '').length }}
                </div>
            </div>

            <div
                v-if="field.fieldType == 'list1'"
                :class="{ 'form-field__select--invalid': field.error }"
                >
                <custom-select
                    :options="field.data"
                    :name="field.name"
                    :disabled="data.disabled || field.disabled"
                    @change="changeSelect(field,$event)"
                    />
            </div>

            <custom-list
                v-if="field.fieldType == 'list'"
                :class="{ 'form-field__select--invalid': field.error }"
                :field="field"
                :data="data"
                :target="target"
                />

            <label
                v-if="['input', 'textarea', 'file'].includes(field.fieldType)"
                class="form-field__placeholder"
                :class="{ 'd-none': field.listType =='radioSquares' || field.listType == 'radioImages'}"
                >
                {{ field.label }}
                <span
                    v-if="( field.rules || [] ).includes('required')"
                    v-text="'*'"
                    />
            </label>

            <custom-checkbox
                v-if="field.fieldType == 'bool'"
                :field="field"
                @change="toggleCheckbox(field)"
                />

            <template v-if="field.fieldType == 'boolHidding'">
                <div class="form-field__checkbox">
                    <input
                        :id="`${field.name}-${uid}`"
                        type="checkbox"
                        :checked="field.value == 't'"
                        :class="{ 'form-field__checkbox--invalid': field.error }"
                        :required="( field.rules || [] ).includes('required')"
                        @change="toogleBoolHidding(field)"
                        >
                    <label
                        class="form__checkbox-label"
                        :for="`${field.name}-${uid}`"
                        v-text="field.label"
                        />
                </div>
            </template>

            <template v-if="field.fieldType == 'button'">
                <div
                    class="text-right"
                    :class="{'vouchers-button mt-4 mb-5': target == 'vouchers'}"
                    >
                    <btn-submit
                        :class="{'w-100': field.name == 'send'}"
                        :disabled="data.disabled || field.disabled"
                        :value="field.label"
                        />
                </div>
            </template>

            <div
                v-if="field.fieldType == 'htmlSection'"
                class="text-uppercase"
                :class="{'d-none' : field.label == 'vouchers_discountsFormTitle', 'pt-4' : field.label == 'vouchers_paymentsFormTitle'} "
                v-html="field.html"
                />

            <div
                v-if="field.error"
                class="form-field__hint form-field__hint--invalid"
                v-text="field.error"
                />
        </div>
    </div>
</template>

<script>
import FormMixin from '@mixins/custom-form'
import CustomCheckbox from '@components/custom-form/fields/checkbox'
import CustomList from '@components/custom-form/fields/list'
import CustomSelect from '@components/custom-form/fields/select'

export default {
    components: {
        CustomCheckbox,
        CustomList,
        CustomSelect
    },
    mixins: [FormMixin],
    props: {
        target: { type: String, required: true }
    },
    methods: {
        changeFile(v) {
            this.$store.commit('CustomForm/addFile', {
                target: this.target,
                name: v.target.name,
                files: v.target.files
            })
        }
    }
}
</script>
