<template>
    <filters-collapse toggle-id="filter-producers">
        <span
            slot="collapseTitle"
            v-ll="'categorySelectProducers'"
            />
        <ul
            slot="body"
            class="list-unstyled row"
            >
            <li
                v-for="producer in producers"
                :key="producer.id"
                class="col-12 col-md-6"
                >
                <filter-checkbox
                    :filter="{id:0}"
                    :option="producer"
                    :check="check"
                    @toggle="toggle"
                    />
            </li>
        </ul>
    </filters-collapse>
</template>

<script>
import { mapState } from "vuex"

import FilterCheckbox from "@components/category/slot/checkbox"
import FiltersCollapse from '@components/category/filters-collapse'

export default {
    components: {
        FilterCheckbox,
        FiltersCollapse
    },
    data() {
        return {
            check: []
        }
    },
    computed: {
        ...mapState("CategoryFilter", [
            "producers_checked", 'producers'
        ])
    },
    watch: {
        producers_checked: {
            handler() {
                this.check = JSON.parse(JSON.stringify(this.producers_checked))
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        get() {
            return this.check
        },
        toggle(id, set) {
            set ? this.check.push(id) : this.check = this.check.filter(i => i != id)
        }
    }
}

</script>

