<template>
    <GmapMap
        ref="gmap"
        style="width: 100%; height: 50vh"
        :center="center"
        :zoom="5"
        @bounds_changed="start = true"
        >
        <GmapInfoWindow
            v-if="infoWindow.opened"
            :options="infoWindow.options"
            :position="infoWindow.position"
            :opened="!!infoWindow.opened"
            @closeclick="infoWindow.opened=false"
            >
            <h6 class="text-primary">
                {{ infoWindow.shopName }}
            </h6>
            <p
                v-if="infoWindow.shopAddress"
                class="mb-0"
                >
                {{ infoWindow.shopAddress }}
            </p>
            <p
                v-if="infoWindow.shopCity"
                class="mb-2"
                >
                {{ infoWindow.shopCity }}
            </p>
            <p
                v-if="infoWindow.shopPhone"
                class="mb-0"
                >
                {{ $ll('stationaryShopsTelLabel') }}: {{ infoWindow.shopPhone }}
            </p>
            <p
                v-if="infoWindow.shopFax"
                class="mb-0"
                >
                {{ $ll('stationaryShopsFaxLabel') }}: {{ infoWindow.shopFax }}
            </p>
            <p
                v-if="infoWindow.shopEmail"
                class="mb-0"
                >
                {{ infoWindow.shopEmail }}
            </p>

            <h6
                v-if="infoWindow.shopWorkHours"
                class="mt-3"
                >
                {{ $ll('stationaryShopsWorkingHours') }}:
            </h6>
            <p
                v-if="infoWindow.shopWorkHours"
                class="mb-0"
                v-html="infoWindow.shopWorkHours"
                />

            <h6
                v-if="infoWindow.shopAdditionalInfo"
                class="mt-3"
                >
                {{ $ll('stationaryShopsAdditionalInfo') }}:
            </h6>
            <p
                class="mb-0"
                v-html="infoWindow.shopAdditionalInfo"
                />
        </GmapInfoWindow>
    </GmapMap>
</template>

<script>
/* global google */
import json from '@db'
import MarkerClusterer from 'marker-clusterer-plus'

export default {
    data() {
        return {
            start: false,
            center: {lat: 1, lng: 1},
            infoWindow: {
                opened: false, //otwarte info na mapie
                shopName: '', //nazwa wybranego sklepu
                shopAddress: '', //adres wybranego sklepu,
                shopCity: '',
                shopPhone: '',
                shopFax: '',
                shopEmail: '',
                shopWorkHours: '',
                shopAdditionalInfo: '',
                position: {}, //lat lng
                options: { //ustawienie infoWindow NAD punktem
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                }
            }
        }
    },
    computed: {
        //odfiltrowanie sklepow ktore nie maja ustawionej pozycji gmap
        shops: _ => json.shops
            .filter(shop => !!shop.location)
            .map(shop => { //@todo - jakby php poprawili JSON - do usuniecia
                shop.location = JSON.parse(shop.location)
                return shop
            })
    },
    //po wczytaniu mapy 'this.start' zmienia sie na true
    watch: {
        start: 'ready'
    },

    methods: {
        //po wczytaniu mapy ustawiamy boundsa dla zooma
        ready() {
            const bounds = new google.maps.LatLngBounds()

            this.shops.forEach(shop => bounds.extend(shop.location.geometry.location))

            this.$refs.gmap.$mapObject.fitBounds(bounds)
            this.$nextTick(() => {
                const markers = this.shops.map(p => {
                    const marker = new google.maps.Marker({
                        position: p.location.geometry.location,
                        icon: `/static/www/map/3/point.png`
                    })

                    marker.addListener('click', _ => this.setMarker(p.stationary_shop_id))
                    return marker
                })

                const mc = new MarkerClusterer(this.$refs.gmap.$mapObject, markers, {
                    imagePath: `/static/www/map/3/m`
                })

                mc.setStyles(mc.getStyles().map(style => {
                    style.textColor = '#fff'
                    return style
                }))
            })
        },
        //wybrany punkt - pokaz informacje
        setMarker(id) {
            this.infoWindow.opened = true

            this.shops.map(p => {
                if (p.stationary_shop_id === id) {
                    this.infoWindow.shopName = p.name
                    this.infoWindow.shopAddress = `${p.street} ${p.building_number} ${p.flat_number && '/' + p.flat_number}`
                    this.infoWindow.shopCity = `${p.postcode} ${p.city}`
                    this.infoWindow.shopPhone = p.phone
                    this.infoWindow.shopFax = p.fax
                    this.infoWindow.shopEmail = p.email
                    this.infoWindow.shopWorkHours = p.work_hours
                    this.infoWindow.shopAdditionalInfo = p.additional_info
                    this.infoWindow.position = p.location.geometry.location
                }
            })
        }
    }
}
</script>
