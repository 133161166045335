import json from '@db'
import debounce from 'debounce'
import axios from 'axios'

const translations = []
const request = debounce(() => {
    if (process.env.NODE_ENV !== 'development') return
    axios.post('/ajax/registerTranslations', { translations })
        .then(() => translations.length = 0)
}, 3000)

export default {
    install (Vue) {
        Vue.prototype.$ll = key => {
            const id = `js_${key}`

            if (json.ll[id]) {
                // console.log('LL:', id, json.ll[id])
                return json.ll[id]
            }

            translations.push(id)
            request()

            return `{${key}}`
        }

        Vue.directive('ll', {
            bind (el, binding) {
                // some logic ...
                const id = `js_${binding.value}`

                if (json.ll[id]) {
                    el.textContent = json.ll[id]
                    return
                }

                translations.push(id)
                request()

                el.textContent = `{${binding.value}}`
            }
        })
    }
}
