var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _vm._v("\n        " + _vm._s(_vm.$ll("basketDeliveryPoint")) + ":"),
      _c("br"),
      _vm._v(" "),
      _c("strong", { staticClass: "text-black" }, [
        _vm._v("\n            " + _vm._s(_vm.details.i) + "\n        "),
      ]),
    ]),
    _vm._v(" "),
    _vm.details.address
      ? _c("p", [
          _c("strong", { staticClass: "text-black" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.details.address.street) +
                " " +
                _vm._s(_vm.details.address.building_number) +
                _vm._s(
                  _vm.details.address.flat_number &&
                    `/${_vm.details.address.flat_number}`
                )
            ),
            _c("br"),
            _vm._v(
              "\n            " +
                _vm._s(_vm.details.address.post_code) +
                " " +
                _vm._s(_vm.details.address.city) +
                "\n        "
            ),
          ]),
          _c("br"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "text-left mt-3" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("setPoint", _vm.details)
            },
          },
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$ll("basketDeliveryPointCommit")) +
              "\n        "
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-3" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.$ll("basketDeliveryPointOpenHours")) +
          ":\n    "
      ),
    ]),
    _vm._v(" "),
    _c("p", { domProps: { innerHTML: _vm._s(_vm.details.operating_hours) } }),
    _vm._v(" "),
    _vm.details.location_description
      ? _c("div", { staticClass: "mt-3" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$ll("basketDeliveryPointAdditionalInfo")) +
              ":\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("p", {
      domProps: { innerHTML: _vm._s(_vm.details.location_description) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }