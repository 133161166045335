<template>
    <div>
        <a
            :class="css"
            class="cursor-pointer"
            @click="modalShow = true"
            v-text="title"
            />
        <b-modal
            v-model="modalShow"
            :title="modalTitle"
            :ok-title="modalOkTitle"
            :cancel-title="modalCancelTitle"
            centered
            hide-header-close	
            no-close-on-backdrop
            no-close-on-esc
            @ok="ok"
            >
            {{ modalBody }}
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        css: {
            type: String,
            required: true
        },
        href: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        modalTitle: {
            type: String,
            default: 'No title'
        },
        modalBody: {
            type: String,
            default: 'No body'
        },
        modalOkTitle: {
            type: String,
            default: 'OK'
        },
        modalCancelTitle: {
            type: String,
            default: 'Cancel'
        },
    },
    data () {
        return {
            modalShow: false
        }
    },
    methods: {
        ok() {
            location.href = this.href
        }
    }
}
</script>
