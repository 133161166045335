<template>
    <div class="product-brick">
        <a
            class="product-item"
            :href="product.url"
            >
            <span class="product-item__body">
                <slot name="badges">
                    <span class="product-item__badges">
                        <!-- <span class="product-item__badges-item product-item__badges-item--text"
                            v-for="(item, key) in product.flags"
                            :key="key"
                            v-text="item"
                            /> -->
                        <Picture
                            v-for="(item, key) in product.fiches"
                            :key="key"
                            :src="$photoUrl(item.path)"
                            :alt="item.name"
                            class="product-item__badges-item"
                            />
                    </span>
                </slot>
                <span
                    v-if="product.thumbnail"
                    :href="product.url"
                    class="product-item__image position-relative"
                    >
                    <Picture
                        :src="$photoUrl(product.thumbnail, 520)"
                        :alt="product.name"
                        class-img="img-cover position-absolute pos-reset"
                        :width="product.thumbnail_width"
                        :height="product.thumbnail_height"
                        />
                    <Picture
                        v-if="product.hover"
                        :src="`${product.hover ? $photoUrl(product.hover, 520) : $photoUrl(product.thumbnail, 520)}`"
                        :alt="product.name"
                        class-img="product-item__img product-item__img--hidden"
                        />
                </span>
                <span
                    v-else
                    class="img-placeholder"
                    />
                <slot
                    name="body"
                    />
            </span>

            <span class="product-item__footer">
                <h4
                    v-if="product.producer"
                    class="product-item__producer-name"
                    v-text="product.producer.name"
                    />
                <span
                    class="product-item__product-name"
                    v-text="product.name"
                    />
                <slot name="attributes" />
                <span class="product-item__price">
                    <slot name="price">
                        <price :price="product.prices" />
                    </slot>
                </span>
                <slot name="footer" />
            </span>
        </a>
    </div>
</template>

<script>
import Price from "@components/product/price"

export default {
    components: {
        Price
    },

    props: {
        product: { type: Object, required: true }
    }
}
</script>
