

document.addEventListener('DOMContentLoaded', () => {
    let fileFields = document.querySelectorAll('.form-field__file-file')

    for (let i = 0; i < fileFields.length; i++) {
        fileFields[i].addEventListener("change", function () {
            fileFields[i].parentNode.parentNode.querySelectorAll('.form-field__file-name')[0].value = fileFields[i].value.replace(/C:\\fakepath\\/i, '')
        })
    }

})