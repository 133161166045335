var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-content-between" },
    [
      _c(
        "div",
        { key: _vm.quickFiltersKey, staticClass: "d-none d-md-block" },
        [_vm.showQuickFilters ? _c("quick-filters") : _vm._e()],
        1
      ),
      _vm._v(" "),
      _c("category-filters-toggle"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }