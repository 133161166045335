<template>
    <span v-text="maxOpinions"/>
</template>

<script>
import {  mapState } from 'vuex'
export default {
    computed: {
        ...mapState('ProductCard', ['maxOpinions'])
    }
}
</script>
