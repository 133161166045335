var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "product-item__badges" }, [
    _c("div", { staticClass: "form-field" }, [
      _c("div", { staticClass: "form-field__checkbox" }, [
        _c("input", {
          attrs: {
            id: `c-${_vm.item.cupboard_id}-${_vm.item.variant.variant}`,
            type: "checkbox",
          },
          on: { change: _vm.setChecked },
        }),
        _vm._v(" "),
        _c("label", {
          staticClass: "form__checkbox-label form__checkbox-label-cupboard",
          attrs: {
            for: `c-${_vm.item.cupboard_id}-${_vm.item.variant.variant}`,
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }