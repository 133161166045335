var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-field" },
    [
      _c("input", {
        ref: "input",
        staticClass: "form-control form-field__input",
        attrs: { placeholder: _vm.$ll("searchLabel") },
        domProps: { value: _vm.search },
        on: {
          input: _vm.setInput,
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.go.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("label", {
        staticClass: "form-field__placeholder",
        domProps: { textContent: _vm._s(_vm.$ll("searchLabel")) },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "form-field__append" }, [
        _c(
          "button",
          {
            staticClass: "btn-icon",
            attrs: { disabled: _vm.search.length === 0 },
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.search = ""
              },
            },
          },
          [
            _c("i", { staticClass: "i-close" }),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$ll("searchClearButton")) +
                  "\n            "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn-icon",
            attrs: { disabled: _vm.search.length < 3 },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.go.apply(null, arguments)
              },
            },
          },
          [
            _c("i", { staticClass: "i-loupe" }),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$ll("searchSubmitButton")) +
                  "\n            "
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("hints-list", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hintsVisible,
            expression: "hintsVisible",
          },
        ],
        attrs: { hints: _vm.hints },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }