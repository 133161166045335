var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    _vm._l(_vm.fiches.v, function (fiche) {
      return _c(
        "span",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: { hover: true },
            },
          ],
          key: fiche.id,
          staticClass: "my-2 py-1 px-2 mr-2 remove-filter d-none text-black",
          class: {
            "d-inline-block": _vm.checkedFichesID.indexOf(fiche.id) !== -1,
          },
          attrs: { title: _vm.$ll("categoryRemoveFilters") },
          on: {
            click: function ($event) {
              return _vm.$emit("removeFiches", fiche.id)
            },
          },
        },
        [
          _vm._v("\n        " + _vm._s(fiche.v) + "\n        "),
          _c("i", { staticClass: "i-close" }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }