import json from '@db'

export default {
    namespaced: true,
    state: {
        ...json.ll,
    },
    getters: {

        get( state ) {
            return key => state[ key ]
        }
    }
}