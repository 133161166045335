<template>
    <div>
        <type-images
            v-if="field.listType == 'radioImages'"
            :field="field"
            :data="data"
            :target="target"
            />
        <type-squares
            v-else-if="field.listType == 'radioSquares'"
            :field="field"
            :data="data"
            :target="target"
            />
        <star-rating
            v-else-if="field.listType == 'ratingStars'"
            :max-rating="field.ratingStarsSetting.max"
            :increment="field.ratingStarsSetting.increment"
            inactive-color="#ffffff"
            active-color="#d40d1b"
            border-color="#d40d1b"
            :star-size="34"
            :border-width="3"
            :rounded-corners="true"
            @rating-selected="field.value = $event"
            />
        <type-default
            v-else
            :field="field"
            :data="data"
            :target="target"
            />
    </div>
</template>

<script>
import TypeDefault from '@components/custom-form/fields/list/types/default'
import TypeImages from '@components/custom-form/fields/list/types/images'
import TypeSquares from '@components/custom-form/fields/list/types/squares'
import StarRating from 'vue-star-rating'

export default {
    components: {
        TypeDefault,
        TypeImages,
        TypeSquares,
        StarRating
    },
    props: [
        'field', 'data', 'target'
    ]
}
</script>
