var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "buttons-container" }, [
    _c(
      "div",
      { staticClass: "basket__buttons", class: { fixed: _vm.buttonFixed } },
      [
        _c("div", { class: { container: _vm.buttonFixed } }, [
          _c("div", { staticClass: "row no-gutters justify-content-between" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.buttonFixed,
                    expression: "buttonFixed",
                  },
                ],
                staticClass: "col-12 mb-3",
              },
              [
                _c("label", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$ll("basketSummaryFull")) + ":"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "strong",
                  [
                    _c("price-single", {
                      staticClass: "float-right",
                      attrs: { value: _vm.shipment.final },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 order-sm-last" },
              [
                _vm.productErrors
                  ? _c("div", {
                      directives: [
                        {
                          name: "ll",
                          rawName: "v-ll",
                          value: "basketWarningFormContainsErrors",
                          expression: "'basketWarningFormContainsErrors'",
                        },
                      ],
                      staticClass: "text-danger text-center",
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-black w-100 px-3 mb-3",
                    class: { disabled: _vm.quantityChangePending },
                    attrs: {
                      to: _vm.$langUrl(
                        _vm.productErrors ? "/basket" : "/basket/delivery"
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$ll("basketButtonStep1")) +
                        "\n                    "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.buttonFixed,
                    expression: "!buttonFixed",
                  },
                ],
                staticClass: "col-12 mb-2",
              },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "ll",
                        rawName: "v-ll",
                        value: "basketButtonReturnToShopping",
                        expression: "'basketButtonReturnToShopping'",
                      },
                    ],
                    staticClass: "btn btn-secondary w-100 px-3",
                    class: { disabled: _vm.quantityChangePending },
                    attrs: { href: _vm.urlReferer },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$ll("basketButtonBack")) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }