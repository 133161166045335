/* eslint-disable no-inner-declarations */
import { scroll } from './scroll-body-class'
//pokazywanie, ukrywanie menu
document.addEventListener('DOMContentLoaded', () => {
    const button = document.querySelector('.mobile-menu-button')

    if (!button) return

    const menu = document.querySelector('.header-nav .nav')
    const arrowsNext = document.querySelectorAll('.menu-arrow-next')
    const arrowsPrev = document.querySelectorAll('.menu-arrow-prev')
    const mobileMenu = function() {
        const menus = document.querySelectorAll('.nav .show')

        for (let i = 0; i < menus.length; i++) {
            menus[i].classList.remove('show')
        }
        const isShow = menu.classList.contains('show')

        menu.classList.toggle('show', !isShow)
        document.body.classList.toggle('menu-show', !isShow)
    }
    const subMenuOpen = function(el) {
        el.parentElement.classList.add('show')
    }
    const subMenuClose = function(el) {
        el.parentElement.parentElement.classList.remove('show')
    }

    button.addEventListener("click", mobileMenu)
    document.querySelector('.menu-overlay').addEventListener("click", mobileMenu)
    document.querySelector('.menu-close').addEventListener("click", mobileMenu)

    for (let i = 0; i < arrowsNext.length; i++) {
        arrowsNext[i].addEventListener("click", () => {
            subMenuOpen(arrowsNext[i])
        })
    }
    for (let i = 0; i < arrowsPrev.length; i++) {
        arrowsPrev[i].addEventListener("click", () => {
            subMenuClose(arrowsPrev[i])
        })
    }
    const buttonLog = document.querySelectorAll('.log-menu .nav-link')

    for (let i = 0; i < buttonLog.length; i++) {
        buttonLog[i].addEventListener("click", () => {
            mobileMenu()
        })
    }

    const menuAdvanced = document.querySelector('.header-nav:not(.header-nav--basic)')

    if (menuAdvanced) {
        const menuTriggerLinks = Array.from(document.querySelectorAll('.header-nav .nav:not(.log-menu) > .nav-item:not(.direct)'))
        const isTouch = 'ontouchstart' in document.documentElement

        if (isTouch) {
            document.addEventListener('scroll', ()=> {
                Array.from(document.querySelectorAll('.submenu-open')).forEach(el => el.classList.remove('submenu-open'))
            })
            document.querySelectorAll('.list-nav-item--for-touch').forEach(el=>el.classList.add('visible'))
        }

        menuTriggerLinks.forEach((trigger) => {
            if (isTouch) {
                handleMenuOpenTouch(trigger)
            }
            else {
                handleMenuOpenDesktop(trigger)
            }
        })

        function handleMenuOpenTouch(trigger) {
            trigger.querySelector('.nav-link').addEventListener('click', (e) => {
                const {matches} = window.matchMedia('(max-width: 991px)')

                if (matches) return
                e.preventDefault()
                defineFixedMenuTop(trigger)
                if (trigger.classList.contains('submenu-open')) {
                    trigger.classList.remove('submenu-open')
                }
                else if (document.querySelector('.submenu-open')) {
                    Array.from(document.querySelectorAll('.submenu-open')).forEach(el => el.classList.remove('submenu-open'))
                    trigger.classList.add('submenu-open')
                }
                else {
                    trigger.classList.add('submenu-open')
                }
            })
        }

        function handleMenuOpenDesktop(trigger) {
            trigger.addEventListener('mouseleave', () => {
                const {matches} = window.matchMedia('(max-width: 991px)')

                if (matches) return
                menuTriggerLinks.forEach((item) => item.classList.remove('submenu-open'))
                unsetFixedMenuTop(trigger)
                trigger.classList.remove('submenu-open')
                // fire scroll function to update scroll body class
                scroll()
            })
            trigger.addEventListener('mouseenter', () => {
                const {matches} = window.matchMedia('(max-width: 991px)')

                if (matches) return
                defineFixedMenuTop(trigger)
                trigger.classList.add('submenu-open')
            })
        }

        function defineFixedMenuTop(trigger) {
            const {matches} = window.matchMedia('(max-width: 991px)')

            if (matches) return
            const subMenu = trigger.querySelector('.sub-menu')

            if (subMenu) subMenu.style.top = trigger.getBoundingClientRect().bottom + 'px'
        }

        function unsetFixedMenuTop(trigger) {
            const {matches} = window.matchMedia('(max-width: 991px)')

            if (matches) return
            const subMenu = trigger.querySelector('.sub-menu')

            if (subMenu) subMenu.style.top = null
        }
    }

    const menuBasic = document.querySelector('.header-nav.header-nav--basic')

    if (menuBasic) {
        const menuItemsNoChildren = document.querySelectorAll('.nav-item--no-children')

        if (menuItemsNoChildren.length > 0) {
            menuItemsNoChildren.forEach((item) => {
                item.addEventListener('mouseenter', addNotOverlayClass)
                item.addEventListener('mouseleave', removeNotOverlayClass)
            })
        }

        function addNotOverlayClass() {
            const overlay = document.querySelector('.header-nav .nav')

            overlay.classList.add('no-overlay')
        }

        function removeNotOverlayClass() {
            const overlay = document.querySelector('.header-nav .nav')

            overlay.classList.remove('no-overlay')
        }
    }
})

