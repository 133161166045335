<template>
    <div class="row">
        <div
            v-for="(img, id) in list"
            :key="id"
            class="col-12 col-lg-6 pb-3 position-relative img-container"
            >
            <Picture
                v-if="img"
                :src="$photoUrl(img.path, 520)"
                class="img-fluid loupe"
                :alt="img.name || img.defaultAlt"
                :width="img.w"
                :height="img.h"
                @click="photoswipe(id)"
                />
            <div
                v-else
                class="img-placeholder"
                />
            <span
                v-if="!id && fiches"
                class="position-absolute"
                style="top: 5px; left: 15px"
                >
                <Picture
                    v-for="(item, key) in fiches"
                    :key="key"
                    :src="$photoUrl(item.path)"
                    :alt="item.name"
                    class="product-item__badges-item"
                    />
            </span>
            <span
                v-if="!id && variantFiches"
                class="position-absolute"
                style="top: 0; left: 0"
                >
                <Picture
                    v-for="(item, key) in variantFiches"
                    :key="key"
                    :src="$photoUrl(item.path)"
                    :alt="item.name"
                    class="product-item__badges-item"
                    />
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {

    computed: {
        ...mapGetters({
            list: "ProductPhotos/list",
            photoswipeList: "ProductPhotos/photoswipe",
            promo: "ProductCard/isPromo"

        }),
        ...mapState('ProductCard', ['variantFiches']),
        ...mapState('ProductPreview', ['fiches'])
    },

    methods: {

        photoswipe(id) {
            this.$photoswipe.open(
                id,
                this.photoswipeList,
                {
                    closeOnScroll: false,
                    addCaptionHTMLFn: _ => true
                }
            )
        }
    }
}

</script>

<style lang="scss">
.loupe {
    cursor: url('~@src/assets/icons/loupe.svg'), auto;
}
</style>
