var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("filters-collapse", { attrs: { "toggle-id": _vm.filter.id } }, [
    _c("span", { attrs: { slot: "collapseTitle" }, slot: "collapseTitle" }, [
      _vm._v("\n        " + _vm._s(_vm.filter.n) + "\n    "),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "list-unstyled row",
        attrs: { slot: "body" },
        slot: "body",
      },
      _vm._l(_vm.filter.v, function (option) {
        return _c(
          "li",
          { key: option.id, staticClass: "col-12 col-md-6" },
          [
            _vm.filter.b === "attributeColor"
              ? _c("filter-color", {
                  attrs: {
                    filter: _vm.filter,
                    option: option,
                    check: _vm.check,
                  },
                  on: { toggle: _vm.toggle },
                })
              : _c("filter-checkbox", {
                  attrs: {
                    filter: _vm.filter,
                    option: option,
                    check: _vm.check,
                  },
                  on: { toggle: _vm.toggle },
                }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }