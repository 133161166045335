var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-category-preview" },
    [
      _c(
        "b-modal",
        {
          class: { "wishlist-modal": _vm.cupboard },
          attrs: {
            title: _vm.cupboard
              ? _vm.$ll("previewWishlistTitle")
              : _vm.product.name,
            "hide-footer": !_vm.loaded,
            centered: "",
            "cancel-title": _vm.$ll("basketBackToShoppingButton"),
            "ok-title": _vm.cupboard
              ? _vm.$ll("cupboardAddButton")
              : (_vm.availability.variants > 1 || _vm.availability.stock) &&
                _vm.availability.available
              ? _vm.$ll("productCardBasketAdd")
              : _vm.$ll("productCardAvailabilityNotify"),
          },
          on: { ok: _vm.ok },
          model: {
            value: _vm.modal,
            callback: function ($$v) {
              _vm.modal = $$v
            },
            expression: "modal",
          },
        },
        [
          _vm.loaded
            ? _c(
                "div",
                {
                  staticClass: "container product-card",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _vm.cupboard
                    ? _c("product-add-cupboard", { attrs: { button: false } })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("b-alert", {
                    attrs: { show: _vm.outOfStock, variant: "danger" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$ll("productBasketModalOutOfStockTitle")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-alert", {
                    attrs: { show: _vm.basketAdd, variant: "success" },
                    domProps: {
                      textContent: _vm._s(_vm.$ll("productBasketModalTitle")),
                    },
                  }),
                  _vm._v(" "),
                  _c("b-alert", {
                    attrs: {
                      show: !!_vm.response.message,
                      variant: _vm.response.status ? "success" : "danger",
                    },
                    domProps: { textContent: _vm._s(_vm.response.message) },
                  }),
                  _vm._v(" "),
                  _vm.cupboard
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("h4", {
                            staticClass: "text-sm-left mt-2 mb-4",
                            domProps: { textContent: _vm._s(_vm.product.name) },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row product-preview" }, [
                    _c("div", { staticClass: "col-12 col-sm-4 pl-sm-3 px-0" }, [
                      _c("div", { staticClass: "img-container" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy:background-image",
                                value: `/${_vm.$photoUrl(_vm.photo, 520)}`,
                                expression: "`/${$photoUrl(photo, 520)}`",
                                arg: "background-image",
                              },
                            ],
                            staticClass:
                              "product-item__image position-relative",
                          },
                          [
                            _c("Picture", {
                              attrs: {
                                src: `/${_vm.$photoUrl(_vm.photo, 520)}`,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-8 px-0" },
                      [
                        _c("product-card-price"),
                        _vm._v(" "),
                        _c("product-attr"),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _c("spin-loader", { attrs: { p: 5 } }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded && (!_vm.availability.stock || !_vm.availability.available)
        ? _c("basket-availability-notify")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }