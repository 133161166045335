<template>
    <div>
        <div
            v-for="item in attr"
            :key="item.id"
            class="pt-2 product-description-item"
            :class="{ 'params-warning': paramsWarn && !chosen[item.id] }"
            >
            <div>{{ item.name }}:</div>
            <ul class="nav nav-pills prod-attr mt-2">
                <li
                    v-for="value in item.values"
                    :key="value.id"
                    class="nav-item"
                    >
                    <a
                        class="nav-link cursor-pointer"
                        :class="{ active: chosen[item.id] == value.id, disabled: value.disabled, 'not-available': value.notifyIco }"
                        @click.prevent="select({ index, id: item.id, value: value.id })"
                        >
                        <template v-if="item.type == 'attributeList' && value.photo">
                            <span
                                :id="`setCol-${index}-${value.id}`"
                                class="product-color"
                                :style="{ 'backgroundImage': `url('${$photoUrl(value.photo.thumbnail)}')`}"
                                />
                            <b-popover
                                :target="`setCol-${index}-${value.id}`"
                                triggers="hover focus"
                                :boundary-padding="0"
                                boundary="window"
                                placement="top"
                                >
                                <div class="d-flex flex-column">
                                    <Picture
                                        :src="$photoUrl(value.photo.full)"
                                        style-img="max-width: 200px"
                                        />
                                    <span
                                        class="text-uppercase mt-2"
                                        v-text="value.value"
                                        />
                                </div>
                            </b-popover>
                        </template>
                        <span
                            v-else-if="item.type == 'attributeList'"
                            v-text="value.value"
                            />
                        <span
                            v-else
                            >
                            <span
                                class="product-color"
                                :class="{ 'color-white': value.color == '#ffffff'}"
                                :style="{ 'background': value.color, 'border-color': value.color }"
                                />
                            <span
                                class="d-none color-name"
                                v-text="value.value"
                                />
                        </span>
                    </a>
                </li>
            </ul>
            <div
                v-if="paramsWarn && !chosen[item.id]"
                class="not-set"
                >
                {{ $ll('productCardAttributeNotSet') }}: {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: {
        index: { type: Number, required: true }
    },
    computed: {
        ...mapGetters({
            attrList: 'ProductSets/getAttr',
            chosenList: 'ProductSets/getChosen',
            paramsWarnList: 'ProductSets/getParamsWarn'
        }),
        attr() {
            return this.attrList(this.index)
        },
        chosen() {
            return this.chosenList(this.index)
        },
        paramsWarn() {
            return this.paramsWarnList(this.index)
        }
    },
    mounted() {
        this.setDisabledNotify({index: this.index})
    },
    methods: {
        ...mapActions({
            select: 'ProductSets/setAttr',
            setDisabledNotify: 'ProductSets/setDisabledNotify'
        })
    }
}
</script>

<style lang="scss">
.params-warning {
    .not-set {
        border-top: 1px solid red;
        color: red;
    }
}
</style>
