<template>
    <span>
        <span
            v-for="fiche in fiches.v"
            :key="fiche.id"
            v-b-tooltip.hover
            class="my-2 py-1 px-2 mr-2 remove-filter d-none text-black"
            :class="{'d-inline-block': checkedFichesID.indexOf(fiche.id) !== -1}"
            :title="$ll('categoryRemoveFilters')"
            @click="$emit('removeFiches', fiche.id)"
            >
            {{ fiche.v }}
            <i class="i-close" />
        </span>
    </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

    props: [
        'id', 'filter'
    ],

    computed: {
        ...mapGetters({
            checkedFichesID: 'CategoryFilter/checkedFiches',
            fiches: 'CategoryFilter/getFiches'
        })
    }
}
</script>
