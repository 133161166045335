//ustawienia dla js poza Vue

import '@/vanilla/src/css'
import '@/vanilla/src/history-popstate'
import '@/vanilla/src/category-hover'
import '@/vanilla/src/input-labels'
import '@/vanilla/src/left-menu'
import '@/vanilla/src/mobile-menu'
import '@/vanilla/src/scroll-body-class'
import '@/vanilla/src/_cookies'
import '@/vanilla/src/field-file'
import '@/vanilla/src/opinions'
import '@/vanilla/src/home-video'
// import '@/vanilla/src/scroll-product-class'
import '@/vanilla/src/sticky'
import '@/vanilla/src/product-description'
import '@/vanilla/src/webp-setclasses'
import '@/vanilla/src/object-fit-polyfill'
import '@/vanilla/src/footer-collapse'

