<template>
    <div class="cupboard">
        <cupboard-header />
        <cupboard-product-list />
        <cupboard-bottom />
    </div>
</template>

<script>
// import CategoryToggle from '@components/category/toggle'
import CupboardHeader from '@components/cupboard-list/header'
import CupboardProductList from '@components/cupboard-list/list'
import CupboardBottom from '@components/cupboard-list/bottom'
import { mapActions } from 'vuex'

export default {
    components: {
        // CategoryToggle,
        CupboardHeader,
        CupboardProductList,
        CupboardBottom
    },
    props: {
        id: { type: Number, default: 0 }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.setCupboard(to.params.id || 0)
            }
        }
    },
    methods: {
        ...mapActions({
            setCupboard: 'ProductCupboard/setCupboard'
        })
    }
}
</script>
