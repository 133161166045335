<template>
    <span
        class="prices"
        itemprop="offers"
        itemscope
        itemtype="http://schema.org/Offer"
        >
        <!-- aktualny currency znajduje sie w Setup - mozna dodac getter do setup/currency/currencies/[ID]/iso -->
        <meta
            itemprop="priceCurrency"
            content="PLN"
            >
        <meta
            itemprop="price"
            :content="price.current"
            >
        <!-- nie wiem czy jakis LL potrzebny? -->
        <span
            v-if="!price.set"
            v-text="'od'"
            />
        <strong :class="{'text-primary':price.current != price.old}">
            <price :value="price.current" />
        </strong>
        <s v-if="price.current != price.old">
            (<price :value="price.old" />)
        </s>
    </span>
</template>

<script>
import { mapGetters } from 'vuex'
import Price from '@components/price'

export default {
    components: {
        Price
    },
    props: {
        index: { type: Number, required: true }
    },
    computed: {
        ...mapGetters({
            variantsList: 'ProductSets/variants',
            getPrice: 'ProductSets/getPrice'
        }),
        price() {
            return this.getPrice(this.index)
        }
    }
}
</script>