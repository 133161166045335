var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.fields, function (field, id) {
      return _c(
        "div",
        {
          key: id,
          staticClass: "form-field",
          class: { "d-none": field.hidden },
        },
        [
          (field.hidden ? "hidden" : field.type) === "checkbox" &&
          field.fieldType == "input"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: field.value,
                    expression: "field.value",
                  },
                ],
                staticClass: "form-control form-field__input",
                class: { "form-field__input--invalid": field.error },
                attrs: {
                  name: field.name,
                  required: (field.rules || []).includes("required"),
                  minlength:
                    (field.rules || [])
                      .map(
                        (i) =>
                          i.match(/^min_length\[[0-9]{1,}/) &&
                          i.match(/\d+/).shift()
                      )
                      .filter(Boolean)
                      .join("") || false,
                  maxlength:
                    (field.rules || [])
                      .map(
                        (i) =>
                          i.match(/^max_length\[[0-9]{1,}/) &&
                          i.match(/\d+/).shift()
                      )
                      .filter(Boolean)
                      .join("") || false,
                  placeholder: field.label,
                  disabled: _vm.data.disabled || field.disabled,
                  type: "checkbox",
                },
                domProps: {
                  checked: Array.isArray(field.value)
                    ? _vm._i(field.value, null) > -1
                    : field.value,
                },
                on: {
                  change: function ($event) {
                    var $$a = field.value,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(field, "value", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            field,
                            "value",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(field, "value", $$c)
                    }
                  },
                },
              })
            : (field.hidden ? "hidden" : field.type) === "radio" &&
              field.fieldType == "input"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: field.value,
                    expression: "field.value",
                  },
                ],
                staticClass: "form-control form-field__input",
                class: { "form-field__input--invalid": field.error },
                attrs: {
                  name: field.name,
                  required: (field.rules || []).includes("required"),
                  minlength:
                    (field.rules || [])
                      .map(
                        (i) =>
                          i.match(/^min_length\[[0-9]{1,}/) &&
                          i.match(/\d+/).shift()
                      )
                      .filter(Boolean)
                      .join("") || false,
                  maxlength:
                    (field.rules || [])
                      .map(
                        (i) =>
                          i.match(/^max_length\[[0-9]{1,}/) &&
                          i.match(/\d+/).shift()
                      )
                      .filter(Boolean)
                      .join("") || false,
                  placeholder: field.label,
                  disabled: _vm.data.disabled || field.disabled,
                  type: "radio",
                },
                domProps: { checked: _vm._q(field.value, null) },
                on: {
                  change: function ($event) {
                    return _vm.$set(field, "value", null)
                  },
                },
              })
            : field.fieldType == "input"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: field.value,
                    expression: "field.value",
                  },
                ],
                staticClass: "form-control form-field__input",
                class: { "form-field__input--invalid": field.error },
                attrs: {
                  name: field.name,
                  required: (field.rules || []).includes("required"),
                  minlength:
                    (field.rules || [])
                      .map(
                        (i) =>
                          i.match(/^min_length\[[0-9]{1,}/) &&
                          i.match(/\d+/).shift()
                      )
                      .filter(Boolean)
                      .join("") || false,
                  maxlength:
                    (field.rules || [])
                      .map(
                        (i) =>
                          i.match(/^max_length\[[0-9]{1,}/) &&
                          i.match(/\d+/).shift()
                      )
                      .filter(Boolean)
                      .join("") || false,
                  placeholder: field.label,
                  disabled: _vm.data.disabled || field.disabled,
                  type: field.hidden ? "hidden" : field.type,
                },
                domProps: { value: field.value },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(field, "value", $event.target.value)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          field.fieldType == "file"
            ? _c("input", {
                staticClass: "form-control form-field__input",
                class: { "form-field__input--invalid": field.error },
                attrs: {
                  type: "file",
                  name: field.name,
                  required: (field.rules || []).includes("required"),
                  accept: (field.rules || []).map((i) =>
                    i.match(/^fileExtensionCheck/)
                  ),
                  placeholder: field.label,
                  disabled: _vm.data.disabled || field.disabled,
                },
                on: { change: _vm.changeFile },
              })
            : _vm._e(),
          _vm._v(" "),
          field.fieldType == "textarea"
            ? _c("div", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: field.value,
                      expression: "field.value",
                    },
                  ],
                  staticClass: "form-control form-field__textarea",
                  class: { "form-field__textarea--invalid": field.error },
                  attrs: {
                    name: field.name,
                    required: (field.rules || []).includes("required"),
                    placeholder: field.label,
                    disabled: _vm.data.disabled || field.disabled,
                    maxlength:
                      (field.rules || [])
                        .map(
                          (i) =>
                            i.match(/^max_length\[[0-9]{1,}/) &&
                            i.match(/\d+/).shift()
                        )
                        .filter(Boolean)
                        .join("") || false,
                  },
                  domProps: { value: field.value },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(field, "value", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                !!(field.rules || [])
                  .map(
                    (i) =>
                      i.match(/^max_length\[[0-9]{1,}/) &&
                      i.match(/\d+/).shift()
                  )
                  .filter(Boolean)
                  .join("") || false
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$ll("form-textarea-chars-remaining")) +
                          ":\n                " +
                          _vm._s(
                            (field.rules || [])
                              .map(
                                (i) =>
                                  i.match(/^max_length\[[0-9]{1,}/) &&
                                  i.match(/\d+/).shift()
                              )
                              .filter(Boolean)
                              .join("") - (field.value || "").length
                          ) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          field.fieldType == "list1"
            ? _c(
                "div",
                { class: { "form-field__select--invalid": field.error } },
                [
                  _c("custom-select", {
                    attrs: {
                      options: field.data,
                      name: field.name,
                      disabled: _vm.data.disabled || field.disabled,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeSelect(field, $event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          field.fieldType == "list"
            ? _c("custom-list", {
                class: { "form-field__select--invalid": field.error },
                attrs: { field: field, data: _vm.data, target: _vm.target },
              })
            : _vm._e(),
          _vm._v(" "),
          ["input", "textarea", "file"].includes(field.fieldType)
            ? _c(
                "label",
                {
                  staticClass: "form-field__placeholder",
                  class: {
                    "d-none":
                      field.listType == "radioSquares" ||
                      field.listType == "radioImages",
                  },
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(field.label) + "\n            "
                  ),
                  (field.rules || []).includes("required")
                    ? _c("span", { domProps: { textContent: _vm._s("*") } })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          field.fieldType == "bool"
            ? _c("custom-checkbox", {
                attrs: { field: field },
                on: {
                  change: function ($event) {
                    return _vm.toggleCheckbox(field)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          field.fieldType == "boolHidding"
            ? [
                _c("div", { staticClass: "form-field__checkbox" }, [
                  _c("input", {
                    class: { "form-field__checkbox--invalid": field.error },
                    attrs: {
                      id: `${field.name}-${_vm.uid}`,
                      type: "checkbox",
                      required: (field.rules || []).includes("required"),
                    },
                    domProps: { checked: field.value == "t" },
                    on: {
                      change: function ($event) {
                        return _vm.toogleBoolHidding(field)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", {
                    staticClass: "form__checkbox-label",
                    attrs: { for: `${field.name}-${_vm.uid}` },
                    domProps: { textContent: _vm._s(field.label) },
                  }),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          field.fieldType == "button"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "text-right",
                    class: {
                      "vouchers-button mt-4 mb-5": _vm.target == "vouchers",
                    },
                  },
                  [
                    _c("btn-submit", {
                      class: { "w-100": field.name == "send" },
                      attrs: {
                        disabled: _vm.data.disabled || field.disabled,
                        value: field.label,
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          field.fieldType == "htmlSection"
            ? _c("div", {
                staticClass: "text-uppercase",
                class: {
                  "d-none": field.label == "vouchers_discountsFormTitle",
                  "pt-4": field.label == "vouchers_paymentsFormTitle",
                },
                domProps: { innerHTML: _vm._s(field.html) },
              })
            : _vm._e(),
          _vm._v(" "),
          field.error
            ? _c("div", {
                staticClass: "form-field__hint form-field__hint--invalid",
                domProps: { textContent: _vm._s(field.error) },
              })
            : _vm._e(),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }