<template>
    <div
        v-if="!!payment && showForm && addresses[id].length && !showMap"
        class="form-group adresses-form-group"
        :class="{ 'is-invalid': addressIndex[id] === null && submited }"
        >
        <input
            type="hidden"
            :value="addressIndex[id]"
            required
            class="form-control"
            :class="{ 'is-invalid': addressIndex[id] === null && submited }"
            >
        <div class="invalid-feedback">
            {{ $ll(`basketLogged${lang}NotSet`) }}
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    props: {
        id: { type: String, required: true }
    },
    computed: {
        ...mapState('Basket', ['payment', 'addresses', 'addressIndex', 'submited']),
        ...mapGetters({
            showForm: 'Basket/showForm',
            showMap: 'Basket/showMap',
        }),
        lang() {
            return this.id.charAt(0).toUpperCase() + this.id.slice(1)
        }
    }
}
</script>
