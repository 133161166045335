<template>
    <nav>
        <ul
            v-if="pageCount > 1"
            role="menubar"
            class="pagination b-pagination pagination-md"
            >
            <!-- poprzednia -->
            <li
                role="none presentation"
                class="page-item"
                :class="{ disabled: current == 1 }"
                >
                <a
                    v-if="current > 1"
                    role="menuitem"
                    tabindex="-1"
                    aria-label="Go to previous page"
                    target="_self"
                    :href="url(current-1)"
                    class="page-link"
                    rel="prev"
                    @click.prevent="current = current - 1"
                    >
                    <i class="i-arrow-left-small" />
                </a>
                <span
                    v-else
                    role="menuitem"
                    class="page-link"
                    >
                    <i class="i-arrow-left-small" />
                </span>
            </li>
            <!-- lista -->
            <li class="page-item">
                <span
                    role="menuitemradio"
                    :aria-label="`Go to page ${current}`"
                    :aria-posinset="current"
                    tabindex="-1"
                    target="_self"
                    class="page-link"
                    >
                    {{ current }}
                </span>
            </li>
            <span class="pagination-separator">
                z
            </span>
            <!-- ostatni -->
            <li

                role="none presentation"
                class="page-item"
                >
                <a
                    role="menuitemradio"
                    :aria-label="`Go to page ${pageCount}`"
                    aria-checked="false"
                    :aria-posinset="pageCount"
                    aria-setsize="65"
                    tabindex="-1"
                    target="_self"
                    :href="url(pageCount)"
                    class="page-link"
                    @click.prevent="current = pageCount"
                    >
                    {{ pageCount }}
                </a>
            </li>

            <!-- nastepna -->
            <li
                role="none presentation"
                class="page-item"
                :class="{ disabled: current == pageCount }"
                >
                <a
                    v-if="current != pageCount"
                    role="menuitem"
                    tabindex="-1"
                    aria-label="Go to next page"
                    target="_self"
                    :href="url(current+1)"
                    class="page-link"
                    rel="next"
                    @click.prevent="current = current + 1"
                    >
                    <i class="i-arrow-right-small" />
                </a>
                <span
                    v-else
                    role="menuitem"
                    class="page-link"
                    >
                    <i class="i-arrow-right-small" />
                </span>
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import pages from '@helpers/pagination-array'

export default {

    computed: {
        ...mapGetters({
            pageCount: 'CategoryFilter/getPageCount',
            url: 'ProductList/getUrl'
        }),
        pages() {
            return pages(this.current, this.pageCount)
        },
        current: {
            get() {
                return this.$store.getters['CategoryFilter/getPage']
            },
            set(p) {
                if (p !== this.$store.getters['CategoryFilter/getPage']) {
                    this.$scrollTo('#twig-container', 500)
                    this.$store.dispatch('CategoryFilter/goToPage', p)
                }
            }
        }
    }
}
</script>
