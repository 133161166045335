<template>
    <div>
        <div
            v-for="alert in alerts.slice().reverse()"
            :key="alert.id"
            :class="{ 'container': !alert.variables.width, 'container-fluid': alert.variables.width }"
            >
            <b-alert
                :variant="alert.variables.color || 'dark'"
                :dismissible="alert.closeable"
                show
                @dismissed="$emit('dismissed', alert.id)"
                >
                <span v-html="alert.variables.content" />
                <b v-if="alert.counter>1">
                    ({{ alert.counter }})
                </b>
            </b-alert>
        </div>
    </div>
</template>

<script>
export default {
    props: ['alerts']
}
</script>
