var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row no-gutters pb-2" }, [
    _c("div", { staticClass: "col-12 col-md-8 col-lg-6 m-auto" }, [
      _c(
        "form",
        {
          ref: "form",
          staticClass: "m-auto footer-nl",
          attrs: { action: _vm.action },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.dispatch.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "row no-gutters" }, [
            _c("div", { staticClass: "col-10 col-lg-8 m-auto" }, [
              _c("div", { staticClass: "form-field form-field--alternate" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.fields.email.value,
                      expression: "fields.email.value",
                    },
                  ],
                  staticClass: "form-control form-field__input",
                  attrs: {
                    required: "",
                    type: "email",
                    name: "email",
                    autocomplete: "off",
                    disabled: _vm.data.disabled,
                    placeholder: "email",
                  },
                  domProps: { value: _vm.fields.email.value },
                  on: {
                    focus: function ($event) {
                      _vm.focused = true
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.fields.email, "value", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", {
                  staticClass: "form-field__placeholder",
                  domProps: {
                    textContent: _vm._s(_vm.$ll("newsletter-label")),
                  },
                }),
                _vm._v(" "),
                !!_vm.fields.email.error
                  ? _c("div", {
                      staticClass: "form-field__hint form-field__hint--invalid",
                      domProps: { textContent: _vm._s(_vm.fields.email.error) },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("button", { staticClass: "btn btn--submit-over" }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.$ll("newsletterSubscribeNewsletter")) +
                      "\n                        "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row no-gutters" }, [
            _c(
              "div",
              { staticClass: "col-10 col-lg-8 m-auto" },
              [
                _c(
                  "transition",
                  { attrs: { name: "fade" } },
                  [
                    _c("form-items", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.focused,
                          expression: "focused",
                        },
                      ],
                      attrs: { target: "newsletter", action: _vm.action },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }