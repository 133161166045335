var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-field" }, [
    _c(
      "div",
      {
        staticClass: "form-field__checkbox form-field__checkbox--color pl-2",
        class: { "color-white": _vm.ColorOption.color == "#ffffff" },
      },
      [
        _c("i", {
          staticClass: "form_checkbox-color",
          style: { background: _vm.ColorOption.color },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: {
            id: "col-" + _vm.filter.id + "-" + _vm.option.id,
            type: "checkbox",
          },
          domProps: { checked: _vm.check.indexOf(_vm.option.id) >= 0 },
          on: {
            change: function ($event) {
              return _vm.$emit("toggle", _vm.option.id, $event.target.checked)
            },
          },
        }),
        _vm._v(" "),
        _c("label", {
          staticClass: "form__checkbox-label",
          attrs: { for: "col-" + _vm.filter.id + "-" + _vm.option.id },
          domProps: { textContent: _vm._s(_vm.option.v) },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }