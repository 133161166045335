var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "form-field__select--invalid": _vm.error } },
    [
      _c("v-select", {
        attrs: {
          label: "label",
          options: _vm.values,
          disabled: _vm.disabled,
          name: _vm.name,
          value: _vm.selected,
        },
        on: { input: _vm.setSorted },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }