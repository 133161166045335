<template>
    <div class="row">
        <div class="col-12">
            <carousel
                v-if="Object.keys(products).length > 4"
                :per-page-custom="[[100,1],[480,2],[768,3],[992,4]]"
                class="VueProductCarousel product-carousel"
                :loop="true"
                :pagination-enabled="false"
                :navigation-enabled="true"
                :scroll-per-page="false"
                :navigation-next-label="''"
                :navigation-prev-label="''"
                center-mode
            >
                <slide
                    v-for="(product, id) in products"
                    :key="id"
                >
                    <product-brick :product="product" />
                </slide>
            </carousel>

            <div
                v-else-if="Object.keys(products).length === 4"
                class="row mt-3"
            >
                <div
                    v-for="(product, id) in products"
                    :key="id"
                    class="col-12 col-sm-4 col-lg-3 mb-3 px-0 px-xl-2 d-none d-lg-block"
                >
                    <product-brick :product="product" />
                </div>
                <div class="d-lg-none col-12 px-0">
                    <carousel
                        :per-page-custom="[[100,1],[480,2],[768,3]]"
                        class="VueProductCarousel"
                        :loop="true"
                        :pagination-enabled="false"
                        :navigation-enabled="true"
                        :scroll-per-page="false"
                        :navigation-next-label="''"
                        :navigation-prev-label="''"
                    >
                        <slide
                            v-for="(product, id) in products"
                            :key="id"
                        >
                            <product-brick :product="product" />
                        </slide>
                    </carousel>
                </div>
            </div>
            <div
                v-else-if="Object.keys(products).length === 3"
                class="row mt-3"
            >
                <div
                    v-for="(product, id) in products"
                    :key="id"
                    class="col-12 col-sm-4 col-lg-3 mb-3 px-2 d-none d-md-block"
                >
                    <product-brick :product="product" />
                </div>
                <div class="d-md-none col-12 px-0">
                    <carousel
                        class="VueProductCarousel"
                        :loop="true"
                        :pagination-enabled="false"
                        :navigation-enabled="true"
                        :scroll-per-page="false"
                        :navigation-next-label="''"
                        :navigation-prev-label="''"
                    >
                        <slide
                            v-for="(product, id) in products"
                            :key="id"
                        >
                            <product-brick :product="product" />
                        </slide>
                    </carousel>
                </div>
            </div>
            <div
                v-else
                class="row mt-3"
            >
                <div
                    v-for="(product, id) in products"
                    :key="id"
                    class="col-6 col-md-4 col-lg-3 mb-3 px-2"
                >
                    <product-brick :product="product" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import ProductBrick from '@components/product/brick'

export default {

    components: {
        Carousel,
        Slide,
        ProductBrick,
    },

    props: {
        list: { type: String },
        data: { type: Object },
    },

    data: () => ({
        products: {},
    }),

    mounted() {
        if (this.list) {
            this.products = JSON.parse(this.list)
        }
        if (this.data) {
            this.products = this.data
        }
    },
}
</script>

<style lang="scss">
.VueProductCarousel {
    margin-left: -10px;
    margin-right: -10px;
//   .VueCarousel-wrapper{
//     padding-top: 15px;
//       @media(max-width: 480px){
//           margin: -15px 30px !important;
//       }
//   }
}
</style>
