var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "basket", class: { show: _vm.show } }, [
    _c(
      "div",
      { staticClass: "basket-content pb-5" },
      [
        _c("div", { staticClass: "text-right" }, [
          _c("a", { staticClass: "btn btn-link", on: { click: _vm.toggle } }, [
            _c("i", { staticClass: "i-close" }),
          ]),
        ]),
        _vm._v(" "),
        _c("h5", { staticClass: "text-center pt-2 mb-5" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$ll("basketTitle")) +
              " (" +
              _vm._s(_vm.products.length) +
              ")\n        "
          ),
        ]),
        _vm._v(" "),
        _c("basket-view"),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "basket-overlay overlay-color",
      on: { click: _vm.toggle },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }