var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("basket-modal", {
        attrs: {
          open: _vm.basketModal !== false,
          variant: _vm.variantBasket[0],
          "photo-path": _vm.products[_vm.basketModal || 0].thumbnail,
        },
        on: {
          hidden: function ($event) {
            _vm.basketModal = false
          },
        },
      }),
      _vm._v(" "),
      !_vm.availability.stock || !_vm.availability.available
        ? _c("basket-availability-notify", {
            attrs: {
              open: _vm.outOfStock !== false,
              variant: _vm.products[_vm.outOfStock || 0].variants[0],
            },
            on: {
              hidden: function ($event) {
                _vm.outOfStock = false
              },
            },
          })
        : _c("basket-out-of-stock", {
            attrs: { open: _vm.outOfStock !== false },
            on: {
              hidden: function ($event) {
                _vm.outOfStock = false
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }