var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.alerts.slice().reverse(), function (alert) {
      return _c(
        "div",
        {
          key: alert.id,
          class: {
            container: !alert.variables.width,
            "container-fluid": alert.variables.width,
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: alert.variables.color || "dark",
                dismissible: alert.closeable,
                show: "",
              },
              on: {
                dismissed: function ($event) {
                  return _vm.$emit("dismissed", alert.id)
                },
              },
            },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(alert.variables.content) },
              }),
              _vm._v(" "),
              alert.counter > 1
                ? _c("b", [
                    _vm._v(
                      "\n                (" +
                        _vm._s(alert.counter) +
                        ")\n            "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }