<template>
    <div v-if="loyalty">
        <div class="row no-gutters">
            <div class="col-12">
                <div class="basket-discount-input-row">
                    <div class="form-field form-field--alternate mb-2">
                        <div>
                            <input
                                v-model="value"
                                :disabled="quantityChangePending"
                                class="form-control form-field__input"
                                :class="{ 'form-field__input--invalid': loyaltyError }"
                                :placeholder="$ll('basketLoyaltyPlaceholder')"
                                @keyup="setPoints($event)"
                                >
                            <label
                                class="form-field__placeholder"
                                v-text="$ll('basketLoyaltyPlaceholder')"
                                />
                            <div
                                v-if="loyaltyError"
                                class="form-field__hint form-field__hint--invalid"
                                v-text="loyaltyError"
                                />
                        </div>
                    </div>
                    <div class="">
                        <button
                            class="btn btn-secondary btn-secondary-slimed px-2 mr-1 w-100"
                            :class="{disabled: quantityChangePending }"
                            :disabled="!value"
                            @click.prevent="setPoints($event)"
                            v-text="$ll('basketLoyaltySubmit')"
                            />
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-12">
                <div
                    v-if="loyalty.points"
                    class="clearer"
                    >
                    <small>
                        {{ $ll('basketLoyaltyUsedPoints') }}: {{ loyalty.points }}
                        <a
                            class="btn-link-inline no-hover d-inline-block ml-2"
                            @click.prevent="clearPoints"
                            >
                            <small><i class="i-close font-weight-bolder" /></small>
                        </a>
                    </small>
                </div>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-12 mb-1">
                <div><small>{{ $ll('basketLoyaltyMaxPoints') }}: {{ loyalty.max_points_value }}</small></div>
            </div>
        </div>
        <!--<hr class="my-3" v-show="!fieldOpen">-->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {

    data() {
        return {
            value: ''
        }
    },

    computed: {
        ...mapState('Basket', [
            'loyalty', 'quantityChangePending', 'loyaltyError'
        ])
    },

    methods: {
        ...mapActions({
            setLoyalty: 'Basket/setLoyalty'
        }),
        setPoints(e) {
            if (this.quantityChangePending) return
            if (e.keyCode === 13 || e.type === 'click') {
                if (this.value) {
                    this.setLoyalty(this.value)
                }
                this.value = ''
            }
        },

        clearPoints() {
            this.value = ''
            this.setLoyalty(0)
        }
    }
}
</script>
