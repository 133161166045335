<template>
    <span>
        <span
            v-text="$ll('productCode')"
            />:&nbsp;<span v-text="(chosenCode.length > 0) ? chosenCode : initial " />
    </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {

    props: {
        initial: { type: String, required: true }
    },
    computed: {
        ...mapState('ProductCard', ['chosenCode']),
        ...mapGetters({
            chosen: 'ProductCard/chosen'
        })
    }

}
</script>
