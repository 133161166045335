<template>
    <div class="btn-group">
        <label
            v-for="(data, value) in field.data"
            :key="value"
            class="btn-outline-primary px-2 py-4 m-2 text-center cursor-pointer"
            :class="{ active: value == field.value ,'vouchers-squares': target=='vouchers'}"
            >
            <input
                v-model="field.value"
                type="radio"
                class="d-none"
                :name="field.name"
                :value="value"
                >
            {{ data }}
        </label>
    </div>
</template>

<script>
export default {
    props: ['field', 'data', 'target']
}
</script>
