import json from '@db'
import axios from 'axios'

/**
 * Dodanie wymaganych pol przy walidacji
 */
if (json.forms) {
    Object
        .keys(json.forms)
        .forEach(form => {
            json.forms[ form ].info = {
                error: false,
                clearAfter: true,
                disabled: false,
                hideOnSuccess: false,
                sendAgain: true,
                message: '',
                check: null
            }
        })
}

export default {
    namespaced: true,
    state: {
        forms: JSON.parse(JSON.stringify(json.forms)),
        last: {}
    },
    getters: {

        fields(state) {
            return id => {
                const obj = state.forms[id]

                return obj && obj.hasOwnProperty('fields') ? obj.fields : {}
            }
        },

        data(state) {
            return id => {
                const obj = state.forms[id]

                return obj && obj.hasOwnProperty('info') ? obj.info : {}
            }
        },

        errorsFound(state) {
            return id => {
                let error = false

                Object.entries(state.forms[id].fields).forEach(([
                    _, item
                ]) => {
                    if (item.error) error = true
                })
                return error
            }
        },

        values(state) {
            return target => {
                const fields = state.forms[ target ].fields
                const isMultipart = Object.values(fields).some(field => field.fieldType == 'file')

                if (isMultipart) {
                    const formDataInstance = new FormData()

                    Object.values(fields).forEach(field => {
                        if (field.fieldType !== 'button') {
                            formDataInstance.append(field.name, field.value || '')
                        }
                    })

                    return formDataInstance
                }

                return Object.keys(fields).reduce((r, k) => {
                    if (fields[ k ].fieldType !== 'button') {
                        r[ k ] = fields[ k ].value || ''
                    }

                    return r
                }, {})
            }
        }
    },
    mutations: {

        setFormValuesFromJSON(state, payload) {
            const { target, fields = {} } = payload

            for (const id in state.forms[ target ].fields) {
                state.forms[ target ].fields[ id ].value = fields[ state.forms[ target ].fields[ id ].name ] || ''
                state.forms[ target ].fields[ id ].error = null
            }
        },

        setFormValuesClear(state, payload) {
            for (const id in state.forms[ payload ].fields) {
                state.forms[ payload ].fields[ id ].value = ''
                state.forms[ payload ].fields[ id ].error = null
            }
        },

        setFormAction(state, payload) {
            const { target, action } = payload

            if (target in state.forms) {
                state.forms[ target ].action = action
            }
        },

        disableClear(state, payload) {
            state.forms[ payload ].info.clearAfter = false
        },

        setDisabled(state, payload) {
            state.forms[ payload ].info.disabled = true
        },

        toggleBoolHidding(state, payload) {
            const { target, field } = payload
            const value = field.value === 't'

            let items = field.when_true || []

            items.forEach(f => {
                state.forms[ target ].fields[ f ].hidden = !value
            })

            items = field.when_false || []
            items.forEach(f => {
                state.forms[ target ].fields[ f ].hidden = value
            })
        },

        addForm(state, payload) {
            const { name, form, action = null, info: {
                error = false,
                clearAfter = true,
                disabled = false,
                hideOnSuccess = false,
                sendAgain = true,
                message = '',
                check = null
            } = {}} = payload

            form.info = {
                error,
                clearAfter,
                disabled,
                hideOnSuccess,
                sendAgain,
                message,
                check
            }

            form.action = action
            state.forms = { ...state.forms, [name]: form }
        },

        response(state, payload) {
            const {
                target,
                response: {
                    data: {
                        error_fields: errors = {},
                        // error = true,
                        status = false,
                        message = '',
                        hide_form_on_success: hideOnSuccess = false,
                        hide_form_send_again: sendAgain = false
                    } = {}
                } = {}
            } = payload

            Object
                .keys(state.forms[ target ].fields)
                .forEach(field => {
                    state.forms[ target ].fields[ field ].error = errors[ field ] || ''

                    //jezeli pole jest passwordem - czyscimy
                    if (state.forms[ target ].fields[ field ].type == 'password') {
                        state.forms[ target ].fields[ field ].value = ''
                    }
                })

            //jezeli formularz zotal wyslany - czyscimy wszystkie pola
            if (status && state.forms[ target].info.clearAfter) {
                Object
                    .keys(state.forms[ target ].fields)
                    .forEach(field => {
                        state.forms[ target ].fields[ field ].value = ''
                    })
            }

            state.forms[ target ].info.disabled = false
            state.forms[ target ].info.sendAgain = !sendAgain
            state.forms[ target ].info.hideOnSuccess = hideOnSuccess
            state.forms[ target ].info.message = message

            //dla $emit poprawnego submita
            if (!Object.keys(errors).length && status) {
                state.last = {
                    // target,
                    ...payload,
                    check: Date.now()
                }
            }
        },

        unhideForm(state, payload) {
            state.forms[ payload ].info.hideOnSuccess = false
        },

        addFile(state, payload) {
            const {
                target,
                name,
                files
            } = payload

            state.forms[ target ].fields[ name ].value = files.length ? files[0] : null
        },
        setSelect(state, payload) {
            state.forms[ payload.target ].fields[ payload.name ].value = payload.value
        }
    },
    actions: {

        submit({ commit, state, getters, dispatch }, payload) {
            commit('setDisabled', payload)
            const formData = getters.values(payload)
            const header = formData instanceof FormData ? { headers: { 'Content-Type': 'multipart/form-data' } } : {}

            axios.post(state.forms[ payload ].action, formData, header)
                .then(response => {
                    const { email, source, consent_regulations } = formData

                    if (payload === 'newsletter' && consent_regulations === 't') {
                        window._edrone = window._edrone || {}
                        _edrone.customer_tags = source
                        _edrone.email = email
                        _edrone.first_name = ''
                        _edrone.action_type = 'subscribe'
                        _edrone.init()
                    }

                    commit('response', {
                        target: payload,
                        response
                    })

                    if (!response.data.hide_form_on_success) {
                        dispatch('Notifications/addResponse', response, { root: true })
                    }
                })
                .catch(error => {
                    commit('response', {
                        target: payload,
                        response: {
                            data: {
                                error: true
                            }
                        }
                    })

                    dispatch('Notifications/addResponse', error, { root: true })
                })
        }
    }
}
