const check = value => Math.abs( parseFloat( value ) || 1 )

export default {
    install(Vue) {
        Vue.directive('quantity-numbers', {
            bind (el, _binding) {
                el.addEventListener('keyup', _ => {
                    el.value = check( el.value )
                    el.dispatchEvent(new CustomEvent('input'))
                })
                el.addEventListener('change', _ => {
                    el.value = check( el.value )
                    el.dispatchEvent(new CustomEvent('input'))
                })
            }
        })
    }
}