var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.checkOrder.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "row no-gutters" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("basket-breadcrumb"),
            _vm._v(" "),
            _c(
              "h1",
              {
                staticClass:
                  "text-uppercase text-center mt-3 mt-lg-5 mb-0 pb-4 bottom-line",
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$ll("basketTitleDelivery")) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row no-gutters", attrs: { id: "delivery-container" } },
        [
          _c(
            "div",
            { staticClass: "col-12 col-lg-6 mt-4 pr-lg-3 pr-0" },
            [
              _c("countries-list"),
              _vm._v(" "),
              _c("delivery-list"),
              _vm._v(" "),
              !_vm.loadDelivery
                ? _c(
                    "div",
                    { staticClass: "py-5 text-center" },
                    [_c("spin-loader")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("delivery-forms"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-lg-6 mt-4 pl-0 pl-lg-3 delivery-products",
            },
            [
              _c(
                "label",
                {
                  staticClass:
                    "text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$ll("basketList")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("basket-list"),
              _vm._v(" "),
              _c("basket-summary"),
              _vm._v(" "),
              _c("basket-consents", { staticClass: "mt-4" }),
              _vm._v(" "),
              _c("basket-buttons"),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-none" }, [
            _c("input", { ref: "submit", attrs: { type: "submit" } }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }