<template>
    <div class="form-field sort-select category-select">
        <label class="form-field__placeholder">
            Sortuj wg:
        </label>
        <select
            v-if="ie"
            class="form-control form-field__select"
            @change="v => selected = v"
            >
            <option
                v-for="val in values"
                :key="val.value"
                :value="val.value"
                >
                {{ val.label }}
            </option>
        </select>
        <v-select
            v-else
            v-model="selected"
            label="label"
            :options="values"
            :searchable="false"
            @input="setSorted"
            />
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    components: {
        vSelect
    },
    data() {
        return {
            values: '',
            selected: '',
            ie: false
        }
    },
    beforeMount() {
        if (navigator.appName == 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
            this.ie = true
        }
        const items = this.$store.state.CategoryFilter.sorting.items
        const selected = this.$store.state.CategoryFilter.sorting.index || ''
        const valuesArr = []
        let defaultSelected

        Object.keys(items).forEach((key) => {
            valuesArr.push({ label: items[key], value: key })
            if (key === selected) {
                defaultSelected = {label: items[key], value: key}
            }
        })
        this.values = valuesArr
        this.selected = defaultSelected
    },
    methods: {
        setSorted(payload) {
            this.$store.commit('CategoryFilter/setSorting', payload.value)
            this.$store.dispatch('CategoryFilter/submit')
        }
    }
}
</script>
<style lang="scss">
.sort-select .vs__dropdown-menu {
    width: 100%;
    left: 0;
}
</style>
