<template>
    <div>
        <div
            v-if="getFixed( type ).length"
            :class="{ 'fixed-top mt-5': type.indexOf('top') >= 0, 'fixed-bottom': type.indexOf('bottom') >= 0 }"
            >
            <notifications-alert
                v-if="getMode( type ) === 'alert'"
                :alerts="getFixed(type)"
                @dismissed="dismissed"
                />
        </div>
        <div
            v-if="getStatic( type ).length"
            >
            <notifications-alert
                v-if="getMode( type ) === 'alert'"
                :alerts="getStatic(type)"
                @dismissed="dismissed"
                />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NotificationsAlert from '@components/notifications-alert'
import json from '@db'

export default {

    components: {
        NotificationsAlert
    },

    props: {
        type: { type: String, required: true }
    },

    computed: {
        ...mapGetters({
            getStatic: 'Notifications/getStatic',
            getFixed: 'Notifications/getFixed',
            getMode: 'Notifications/getNotificationMode'
        })
    },

    mounted() {
        if (this.type == 'notification-www-top') {
            json.alertsData.forEach(alert => {
                alert.variables = {
                    color: alert.type,
                    content: alert.message
                }
                alert.temporary = alert.closable ? 2 : 0
                delete alert.type
                delete alert.message
                this.add(alert)
            })
        }
    },

    methods: {
        ...mapActions({
            dismissed: 'Notifications/dismiss',
            add: 'Notifications/add'
        })
    }
}
</script>
