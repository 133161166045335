<template>
    <span class="text-nowrap">
        <span class="price">
            {{ money }}
        </span>
        <span class="currency">
            {{ viewCurrency || currency }}
        </span>
    </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

    props: {
        value: { type: [
            String, Number
        ],
        required: true },
        viewCurrency: { type: String, default: null } //nadpisanie ustawionej waluty w setings
    },

    computed: {
        ...mapGetters({
            currency: 'Setup/currency'
        }),

        money() {
            return Number(this.value)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
                .replace('.', ',')
        }
    }

}
</script>