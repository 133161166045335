var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-group" },
    _vm._l(_vm.field.data, function (data, value) {
      return _c(
        "label",
        {
          key: value,
          staticClass:
            "btn-outline-primary px-2 py-4 m-2 text-center cursor-pointer",
          class: {
            active: value == _vm.field.value,
            "vouchers-squares": _vm.target == "vouchers",
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.field.value,
                expression: "field.value",
              },
            ],
            staticClass: "d-none",
            attrs: { type: "radio", name: _vm.field.name },
            domProps: { value: value, checked: _vm._q(_vm.field.value, value) },
            on: {
              change: function ($event) {
                return _vm.$set(_vm.field, "value", value)
              },
            },
          }),
          _vm._v("\n        " + _vm._s(data) + "\n    "),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }