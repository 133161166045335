<template>
    <div>
        <h6 class="text-black">
            <strong>{{ details.name }}</strong>
        </h6>
        <p
            v-if="details.address"
            class="mb-0"
            >
            {{ details.address.street }} {{ details.address.building_number }}{{ details.address.flat_number && `/${details.address.flat_number}` }}
        </p>
        <p v-if="details.address">
            {{ details.address.post_code }} {{ details.address.city }}
        </p>

        <h6
            v-if="details.operating_hours"
            class="mt-4"
            >
            {{ $ll('basketDeliveryPointOpenHours') }}:
        </h6>
        <p
            v-if="details.operating_hours"
            v-html="details.operating_hours"
            />

        <h6
            v-if="details.additional_info"
            class="mt-3"
            >
            {{ $ll('basketDeliveryPointAdditionalInfo') }}:
        </h6>
        <p v-html="details.additional_info" />

        <div class="text-center mt-4">
            <button
                type="button"
                class="btn btn-primary"
                @click="$emit('setPoint', details)"
                >
                {{ $ll('basketDeliveryPointCommit') }}
            </button>
        </div>
    </div>
</template>

<script>
//id 4 == odbior osobisty
export default {
    props: ['details']
}
</script>
<style>

</style>