<template>
    <div
        v-show="loadDelivery"
        class="form-group"
        >
        <label
            class="text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black"
            >
            {{ $ll('basketSelectDeliveryMethod') }}
        </label>
        <input
            type="hidden"
            :value="deliveryId"
            required
            class="form-control"
            :class="{ 'is-invalid': !deliveryId && submited }"
            >
        <div class="invalid-feedback">
            {{ $ll('basketDeliveryMethodNotSet') }}
        </div>
        <div
            class="row"
            style="margin-top: -10px"
            >
            <div
                v-for="(delivery, i) in deliveries"
                :key="i"
                class="col-6 col-sm-4 col-md-3 col-lg-4 btn-group"
                >
                <div
                    class="btn-outline-primary text-center p-2"
                    :class="{ active: deliveryId == i }"
                    @click.prevent="setDelivery(i)"
                    >
                    <div>
                        <div
                            class="mb-1"
                            v-text="delivery.name"
                            />
                        <price
                            v-if="!free_delivery"
                            :value="delivery.min"
                            />
                        <div v-else>
                            <price :value="0" />
                            <s>
                                <price :value="delivery.min" />
                            </s>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Price from '@components/price'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {

    components: {
        Price
    },

    computed: {
        ...mapState('Basket', [
            'submited', 'deliveryId', 'free_delivery', 'loadDelivery'
        ]),
        ...mapGetters({
            deliveries: 'Basket/getDeliveries'
        })
    },
    watch: {
        deliveries() {
            this.loadedDelivery()
        }
    },

    methods: {
        ...mapActions({
            setDelivery: 'Basket/setDelivery',
            loadedDelivery: 'Basket/loadedDelivery'
        })
    }
}
</script>
