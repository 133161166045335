<template>
    <carousel
        :per-page="1"
        :loop="true"
        :pagination-enabled="false"
        :navigation-enabled="list.length <= 1 ? false : true"
        :navigation-next-label="''"
        :navigation-prev-label="''"
        >
        <slide
            v-for="(img, id) in list"
            :key="id"
            >
            <Picture
                v-if="img"
                :src="`${img ? $photoUrl(img.path, 400) : ''}`"
                :loading="id > 0 ? 'lazy' : 'eager'"
                :lazy="false"
                class="img-fluid loupe"
                :alt="img.name || img.defaultAlt"
                @click="photoswipe(id)"
                />
            <div
                v-else
                class="img-placeholder"
                />
            <span
                v-if="!id && fiches"
                class="position-absolute"
                style="top: 0; left: 0"
                >
                <Picture
                    v-for="(item, key) in fiches"
                    :key="key"
                    :src="$photoUrl(item.path)"
                    :alt="item.name"
                    class="product-item__badges-item"
                    />
            </span>
            <span
                v-if="!id && variantFiches"
                class="position-absolute"
                style="top: 0; left: 0"
                >
                <Picture
                    v-for="(item, key) in variantFiches"
                    :key="key"
                    :src="$photoUrl(item.path)"
                    :alt="item.name"
                    class="product-item__badges-item"
                    />
            </span>
        </slide>
    </carousel>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { Carousel, Slide } from "vue-carousel"

export default {

    components: {
        Carousel,
        Slide
    },

    computed: {

        ...mapGetters({
            list: "ProductPhotos/list",
            photoswipeList: "ProductPhotos/photoswipe",
            promo: "ProductCard/isPromo"
        }),
        ...mapState('ProductCard', ['variantFiches']),
        ...mapState('ProductPreview', ['fiches'])
    },

    methods: {

        photoswipe(id) {
            this.$photoswipe.open(
                id,
                this.photoswipeList
            )
        }
    }
}

</script>
