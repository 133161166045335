var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.attr, function (item) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "pt-2 product-description-item",
          class: { "params-warning": _vm.paramsWarn && !_vm.chosen[item.id] },
        },
        [
          _c("div", [_vm._v(_vm._s(item.name) + ":")]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "nav nav-pills prod-attr mt-2" },
            _vm._l(item.values, function (value) {
              return _c("li", { key: value.id, staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link cursor-pointer",
                    class: {
                      active: _vm.chosen[item.id] == value.id,
                      disabled: value.disabled,
                      "not-available": value.notifyIco,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.select({
                          index: _vm.index,
                          id: item.id,
                          value: value.id,
                        })
                      },
                    },
                  },
                  [
                    item.type == "attributeList" && value.photo
                      ? [
                          _c("span", {
                            staticClass: "product-color",
                            style: {
                              backgroundImage: `url('${_vm.$photoUrl(
                                value.photo.thumbnail
                              )}')`,
                            },
                            attrs: { id: `setCol-${_vm.index}-${value.id}` },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: `setCol-${_vm.index}-${value.id}`,
                                triggers: "hover focus",
                                "boundary-padding": 0,
                                boundary: "window",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c("Picture", {
                                    attrs: {
                                      src: _vm.$photoUrl(value.photo.full),
                                      "style-img": "max-width: 200px",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "text-uppercase mt-2",
                                    domProps: {
                                      textContent: _vm._s(value.value),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : item.type == "attributeList"
                      ? _c("span", {
                          domProps: { textContent: _vm._s(value.value) },
                        })
                      : _c("span", [
                          _c("span", {
                            staticClass: "product-color",
                            class: { "color-white": value.color == "#ffffff" },
                            style: {
                              background: value.color,
                              "border-color": value.color,
                            },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "d-none color-name",
                            domProps: { textContent: _vm._s(value.value) },
                          }),
                        ]),
                  ],
                  2
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.paramsWarn && !_vm.chosen[item.id]
            ? _c("div", { staticClass: "not-set" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$ll("productCardAttributeNotSet")) +
                    ": " +
                    _vm._s(item.name) +
                    "\n        "
                ),
              ])
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }