/*
Zwraca tablice do paginacji uwzgledniajac aktualny nr strony, liczbe stron 
[ 1 ... 5 6 7 ... 10 ]
*/
export default (page, pagesCount) => {

    const pages = 3    //ile stron w paginacji (liczba nieparzysta!)

    let array = Array( pages )
        .fill(0)
        .map( (_, k) => page + k + Math.ceil( pages / 2 - pages ) )

    // array.unshift( 1 )
    // array.push( pagesCount )

    array = array.filter( (value, index, self) => !Number.isInteger(value) || (self.indexOf(value) === index && value>0 && value<=pagesCount ))

    // if ( array.length>1 && array[0]+1 !== array[1] ) {
    //     array.splice(1, 0, '...')
    // }

    // if ( array.length>1 && array[ array.length-2 ] !== array[ array.length-1 ]-1 ) {
    //     array.splice( array.length-1, 0, '...')
    // }

    return array
}