var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition-group",
    {
      staticClass: "list-unstyled text-left px-2",
      attrs: { tag: "ul", name: "fade-right" },
    },
    _vm._l(_vm.products, function (item) {
      return _c(
        "li",
        {
          key: item.id,
          staticClass: "d-flex mb-3 pb-3",
          class: { shown: _vm.active == item.id },
          on: {
            mouseenter: function ($event) {
              _vm.active = item.id
            },
            mouseleave: function ($event) {
              _vm.active = false
            },
          },
        },
        [
          _c("div", { staticClass: "mini-basket-item" }, [
            _c("div", { staticClass: "image-wrapper" }, [
              _vm.showRemove
                ? _c("div", { staticClass: "basket-remove" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-link",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.remove(item.id)
                          },
                        },
                      },
                      [_c("i", { staticClass: "i-close" })]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "img-basket mx-2", attrs: { href: item.url } },
                [
                  item.photo
                    ? _c("Picture", {
                        staticClass: "img-fluid",
                        attrs: { src: _vm.$photoUrl(item.photo, 100) },
                      })
                    : _c("div", { staticClass: "img-placeholder" }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex-grow-1 d-flex flex-column pl-3 basket-product-content",
              },
              [
                _c("a", {
                  staticClass: "basket-product-title text-uppercase",
                  attrs: { href: item.url },
                  domProps: { textContent: _vm._s(item.name) },
                }),
                _vm._v(" "),
                _c("div", [
                  _c("small", { domProps: { textContent: _vm._s(item.code) } }),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "basket-product-attr text-black",
                  domProps: {
                    textContent: _vm._s(
                      item.attributes.map((p) => p.value).join(" / ")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3 d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "flex-grow-1" },
                    [
                      item.quantity > 1
                        ? _c("span", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.quantity) +
                                " x\n                        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("price", {
                        attrs: {
                          value: item.price.promoted || item.price.regular,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }