<template>
    <picture class="h-100">
        <source
            v-if="!error && webp"
            :srcset="webp"
            type="image/webp"
            :width="width"
            :height="height"
        >
        <source
            :srcset="srcEncode"
            :width="width"
            :height="height"
        >
        <img
            v-if="lazy"
            v-lazy="srcEncode"
            :loading="loading"
            class="img-fluid"
            :class="classImg"
            :style="styleImg"
            :alt="alt"
            :width="width"
            :height="height"
            @click="$emit('click')"
            @error="imgError"
        >
        <img
            v-else
            :src="srcEncode"
            :loading="loading"
            class="img-fluid"
            :class="classImg"
            :style="styleImg"
            :alt="alt"
            :width="width"
            :height="height"
            @click="$emit('click')"
            @error="imgError"
        >
    </picture>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            default: '',
        },
        loading: {
            type: String,
            default: 'lazy',
        },
        lazy: {
            type: Boolean,
            default: true,
        },
        alt: String,
        classImg: String,
        styleImg: String,
        width: [Number, String],
        height: [Number, String],
    },
    data() {
        return {
            error: false,
        }
    },
    computed: {
        webp() {
            return this.srcEncode.match(/.(jpg|png)$/) && this.srcEncode.replace(/.(jpg|png)$/, '.webp')
        },
        srcEncode() {
            return encodeURI(this.src)
        },
    },
    methods: {
        imgError(src) {
            if (this.error === false) {
                this.error = true
            }
        },
    },
}
</script>
