var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.items, function (item) {
      return _c("product-item", {
        key: item.id,
        attrs: { item: item, "view-currency": _vm.viewCurrency },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }