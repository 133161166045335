<template>
    <table
        class="table"
        :class="(data.class || '').replace(/tabs\-/g, '')"
        >
        <thead v-if="data.content.thead">
            <tr
                v-for="(tr, id) in data.content.thead"
                :key="id"
                >
                <th
                    v-for="(th, th_id) in tr.th"
                    :key="th_id"
                    :class="th.class"
                    :rowspan="th.rowspan"
                    :colspan="th.colspan"
                    v-text="th.textContent"
                    />
            </tr>
        </thead>
        <tbody v-if="data.content.tbody">
            <tr
                v-for="(tr, id) in data.content.tbody"
                :key="id"
                >
                <td
                    v-for="(td, td_id) in tr.td"
                    :key="td_id"
                    :class="td.class"
                    :rowspan="td.rowspan"
                    :colspan="td.colspan"
                    v-text="td.textContent"
                    />
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        data: { type: Object, required: true }
    }
}
</script>
