<template>
    <div class="basket__list-item pb-4 mb-4">
        <div class="d-flex flex-nowrap w-100">
            <div class="list-item__photo">
                <a :href="item.url">
                    <Picture
                        :alt="item.name"
                        :src="$photoUrl(item.photo, 100)"
                        />
                </a>
            </div>
            <div class="list-item__containter">
                <strong class="d-inline-block list-item__name w-80">
                    <a
                        :href="item.url"
                        class="mb-0 d-inline-block text-uppercase"
                        v-text="item.name"
                        />
                </strong>
                <slot name="header" />
                <p
                    class="list-item__code mb-0"
                    >
                    <small v-text="item.code" />
                </p>
                <b-alert
                    :show="!!item.error"
                    variant="danger"
                    v-text="item.error"
                    />
                <slot name="body">
                    <div class="d-flex my-4 my-md-0 mt-4 flex-wrap">
                        <div
                            v-for="(attr, i) in item.attributes"
                            :key="attr.key"
                            class="list-item__attr"
                            >
                            <div
                                class="d-none d-md-block text-uppercase"
                                v-text="attr.key"
                                />
                            <strong
                                class="list-item__attr--croped"
                                :class="{'last-attr' : i == item.attributes.length - 1}"
                                v-text="attr.value"
                                />
                        </div>
                        <div class="list-item__attr d-md-none attr-short">
                            <strong v-text="item.quantity" /><strong v-text="$ll('basketListItemPieces')" />
                        </div>
                        <div class="list-item__attr d-none d-md-block">
                            <div
                                class="text-uppercase"
                                v-text="$ll('basketListItemCount')"
                                />
                            <div class="text-nowrap amount-couter mt-1">
                                <a
                                    v-if="action && !isPackingForGift(item.variant)"
                                    class="btn-link-inline list-item__amount-btn"
                                    :class="{ disabled: item.quantity <= 1 || changingQty }"
                                    @click="$emit('quantity', { id: item.id, q: -1})"
                                    >
                                    <i class="mdi mdi-minus mr-1" />
                                </a>
                                <strong v-text="item.quantity" />
                                <a
                                    v-if="action && !isPackingForGift(item.variant)"
                                    class="btn-link-inline list-item__amount-btn"
                                    :class="{ disabled: changingQty }"
                                    @click="$emit('quantity', { id: item.id, q: 1})"
                                    >
                                    <i class="mdi mdi-plus ml-1" />
                                </a>
                            </div>
                        </div>
                        <div
                            v-if="itemPriceVisible"
                            class="list-item__attr d-none d-md-block"
                            >
                            <div
                                class="text-uppercase"
                                v-text="$ll('basketListItemPrice')"
                                />
                            <strong>
                                <basket-price
                                    :price="item.price"
                                    :view-currency="viewCurrency"
                                    />
                            </strong>
                        </div>

                        <div class="list-item__attr d-none d-md-block mr-0">
                            <div
                                class="text-uppercase"
                                v-text="$ll('basketListItemValue')"
                                />
                            <strong>
                                <basket-price
                                    :price="item.value"
                                    :view-currency="viewCurrency"
                                    />
                            </strong>
                        </div>
                    </div>
                </slot>
            </div>
        </div>
        <div class="list-item__footer d-flex d-md-none w-100 pt-4 text-center">
            <div
                v-if="itemPriceVisible"
                class="list-item__attr flex-fill"
                >
                <div
                    v-text="$ll('basketListItemPrice')"
                    />
                <strong>
                    <basket-price
                        :price="item.price"
                        :view-currency="viewCurrency"
                        />
                </strong>
            </div>
            <div class="list-item__attr list-item__attr--border flex-fill">
                <div
                    class="text-uppercase"
                    v-text="$ll('basketListItemCount')"
                    />
                <div class="text-nowrap amount-couter mt-1">
                    <a
                        v-if="action"
                        class="btn-link-inline list-item__amount-btn"
                        :class="{ disabled: item.quantity <= 1 || changingQty }"
                        @click="$emit('quantity', { id: item.id, q: -1})"
                        >
                        <i class="mdi mdi-minus" />
                    </a>
                    <strong v-text="item.quantity" />
                    <a
                        v-if="action"
                        class="btn-link-inline list-item__amount-btn"
                        :class="{ disabled: changingQty }"
                        @click="$emit('quantity', { id: item.id, q: 1})"
                        >
                        <i class="mdi mdi-plus" />
                    </a>
                </div>
            </div>
            <div class="list-item__attr flex-fill">
                <div
                    v-text="$ll('basketListItemValue')"
                    />
                <strong>
                    <basket-price
                        :price="item.value"
                        :view-currency="viewCurrency"
                        />
                </strong>
            </div>
        </div>
        <slot />
        <div class="list-item__footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
import BasketPrice from '@components/basket/price'
import Price from '@components/price'
import { mapState } from 'vuex'

export default {
    components: {
        BasketPrice,
        Price
    },
    props: {
        item: {type: Object,
            default() {
                return {}
            }},
        action: { type: Boolean, default: false },
        url: { type: Boolean, default: false },
        viewCurrency: { type: String, default: '' },
        changingQty: { type: Boolean, default: false },
        itemPriceVisible: {type: Boolean, default: true}
    },
    computed: {
        ...mapState('Basket', ['giftPacking'])
    },
    methods: {
        isPackingForGift(id) {
            return this.giftPacking.id === id
    	}

    }
}
</script>
