<template>
    <div class="d-inline-block mb-1 stars-container">
        <star-rating
            :rating="value"
            :increment="1"
            :read-only="true"
            :star-size="starSizeComputed"
            :show-rating="false"
            :inactive-color="color"
            :active-color="activeColor"
            :border-width="borderWidth"
            :border-color="activeColor"
            :rounded-corners="!isSharpNoBorders"
            />
    </div>
</template>

<script>

import StarRating from 'vue-star-rating'

export default {
    components: {
        StarRating
    },
    props: {
        value: { default: 3, type: Number, required: false },
        color: { default: "#ffffff", type: String, required: false },
        activeColor: { default: "#000", type: String, required: false },
        isSharpNoBorders: { default: false, type: Boolean, required: false },
        starSize: { default: undefined, type: Number, required: false}
    },
    computed: {
        borderWidth() {
            return this.isSharpNoBorders ? 0 : 2
        },
        starSizeComputed() {
            return this.starSize ? this.starSize : 12
        }
    }
}
</script>
