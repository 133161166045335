let lastScrollPosition = 0

export const scroll = function() {
    const newScrollPosition = window.pageYOffset

    if (newScrollPosition < lastScrollPosition) {
        document.body.classList.remove('scroll-down')
        document.body.classList.add('scroll-up')
    }
    else {
        document.body.classList.remove('scroll-up')
        document.body.classList.add('scroll-down')
    }
    lastScrollPosition = newScrollPosition

    // prevent scrolled-down class change if mega menu is open
    if (document.querySelector('.submenu-open')) return

    if (newScrollPosition) {
        document.body.classList.add('scrolled-down')
    }
    else {
        document.body.classList.remove('scrolled-down')
    }
}

window.addEventListener('scroll', scroll)
scroll()