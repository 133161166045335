<template>
    <div
        v-show="(currentPage < pageCount) && (pageCount > 1)"
        class="category-grid__item last-brick"
        >
        <span
            class="product-item"
            @click="setPage(currentPage + 1)"
            >
            <span class="product-item__body">
                <span class="product-item__image">
                    <span class="last-brick__text">
                        <span>{{ $ll('categoryLastBrick') }}</span>
                    </span>
                </span>
            </span>
        </span>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            currentPage: 'CategoryFilter/getPage',
            pageCount: 'CategoryFilter/getPageCount'
        }),
    },
    methods:{
        setPage(p) {
            this.$scrollTo('#twig-container', 500)
            this.$store.dispatch('CategoryFilter/goToPage', p)
        }
    }
}
</script>
