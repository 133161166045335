<template>
    <a
        class="cursor-pointer no-hover"
        @click.prevent="show(true)"
        >
        <i class="i-loupe" />
    </a>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapState('Search', ['modal'])
    },
    methods: {
        ...mapMutations({
            show: 'Search/setModal'
        })
    }
}
</script>
