<template>
    <a
        :href="$langUrl('/basket')"
        class="basket-btn no-hover"
        @click.prevent="toggle"
        >
        <slot />
        <span
            v-if="products.length"
            class="basket-badge"
            >
            <span v-text="products.length || 0" />
        </span>
    </a>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {

    data() {
        return {
            timeout: null
        }
    },

    computed: {
        ...mapState(
            'Basket', ['products']
        )
    },

    methods: {
        ...mapMutations({
            toggle: 'Basket/toggle'

        })
    }
}
</script>