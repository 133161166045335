var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container container-small vouchers-form px-3" },
    [
      _c("custom-form", {
        attrs: {
          target: "vouchers",
          action: "/vouchers/index",
          "clear-after": false,
        },
        on: { submitted: _vm.done },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }