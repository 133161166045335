<template>
    <div class="form-field mb-0 p-0 search-hints">
        <div
            v-show="Array.isArray(hints) && !hints.length"
            class="search-hints__no-results"
            v-text="$ll('searchNothingFound')"
            />
        <ul
            class="list-unstyled m-0 search-hints__list"
            >
            <li
                v-for="hint in hints"
                :key="hint.value"
                >
                <a :href="hintBaseUrl + hint.value">
                    {{ hint.value }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState('Setup', ['default_language_iso']),
        ...mapGetters('Setup', ['language']),
        hintBaseUrl() {
            return this.default_language_iso !== this.language ? '/' + this.language + '/search?phrase=' : '/search?phrase='
        }
    },
    props: {
        hints: { validator: v => typeof v === 'object' || v === 'array', required: true }
    }
}
</script>

