var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("delivery-items"),
      _vm._v(" "),
      _c("payments-items"),
      _vm._v(" "),
      _vm.showMap && _vm.loadDelivery
        ? _c("custom-map", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.point,
                expression: "!point",
              },
            ],
          })
        : _vm._e(),
      _vm._v(" "),
      !!_vm.point ? _c("point-map") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }