<template>
    <div>
        <div class="pt-3 pb-3 product-description-item row no-gutters">
            <div class="col-12 col-sm-3 col-md-12 col-xxl-3 mb-md-3 mb-xxl-0 mb-sm-0 mb-3">
                <div class="text-nowrap product-quantity">
                    <a
                        class="plain-link"
                        :class="{ disabled: quantity <= 1 }"
                        @click="quantity--"
                        >
                        <i class="mdi mdi-minus mr-1" />
                    </a>
                    <input
                        v-model="quantity"
                        v-quantity-numbers
                        type="text"
                        maxlength="5"
                        >
                    <a
                        class="plain-link"
                        @click="quantity++"
                        >
                        <i class="mdi mdi-plus ml-1" />
                    </a>
                </div>
            </div>
            <div class="col-10 col-sm-6 col-md-9 col-xxl-6">
                <div class="text-center ml-md-0 ml-sm-3">
                    <btn-submit
                        :class="`btn px-3 w-100 ${((availability.variants > 1 || availability.stock) && availability.available) ? 'btn-primary' : 'btn-secondary'}`"
                        :disabled="buttonDisabled"
                        :value="((availability.variants > 1 || availability.stock) && availability.available) ? $ll('productCardBasketAdd') : $ll('productCardAvailabilityNotify')"
                        @click="addBasket"
                        />
                </div>
            </div>
            <div class="col-2 col-xl-1 col-xxl-2">
                <product-add-cupboard />
            </div>
        </div>
    </div>
</template>

<script>
import BtnSubmit from '@components/btn-submit'
import ProductAddCupboard from '@components/product/cupboard/button'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

export default {

    components: {
        BtnSubmit,
        ProductAddCupboard
    },

    computed: {
        ...mapState('ProductCard', [
            'paramsWarn', 'buttonDisabled'
        ]),
        ...mapGetters({
            availability: 'ProductCard/availability',
            chosen: 'ProductCard/chosen',
            variants: 'ProductCard/variants',
            productSet: 'ProductCard/productSet'
        }),
        quantity: {
            get() {
                return this.$store.state.ProductCard.quantity
            },
            set(v) {
                if (v > 99999) v = 99999
                this.$store.commit('ProductCard/setQuantity', v)
            }
        },

        btnMinusColor() {
            return this.quantity > 1 ? 'primary' : ''
        }
    },

    methods: {

        ...mapActions({
            addBasket: 'ProductCard/addBasket'
        }),

        ...mapMutations({
            setQuantity: 'ProductCard/setQuantity'
        })
    }
}
</script>
