<template>
    <span
        class="prices"
        itemprop="offers"
        itemscope
        itemtype="http://schema.org/Offer"
        >
        <meta
            itemprop="priceCurrency"
            content="PLN"
            >
        <meta
            itemprop="price"
            :content="price.current"
            >
        <!--<span-->
        <!--v-show="!price.set"-->
        <!--class="price-procent"-->
        <!--&gt;-->
        <!--(-20%)-->
        <!--</span>-->

        <strong class="text-primary">
            <!-- :class="{'text-danger':price.current != price.old, 'text-black':price.current == price.old}"> -->
            <price :value="price.current" />
        </strong>
        <s v-if="price.current != price.old">
            (<price :value="price.old" />)
        </s>
    </span>
</template>

<script>
import { mapGetters } from 'vuex'
import Price from '@components/price'

/**
     * Komponent uzyty na karcie produktu i modalu potwierdzenia dodania do koszyka
     */
export default {

    components: {
        Price
    },

    computed: {
        ...mapGetters({
            price: 'ProductCard/getPrice'
        })
    }
}
</script>
