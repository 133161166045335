<template>
    <span>
        <span
            v-for="item in filter"
            :key="item"
            v-b-tooltip.hover
            class="mt-3 py-1 px-2 mr-2 remove-filter d-inline-block black-text"
            :title="$ll('categoryRemoveFilters')"
            @click="$emit('remove', { id, val: item })"
            >
            {{ name(id,item) }}
            <i class="i-close black-text" />
        </span>
    </span>
</template>

<script>
import { mapGetters} from 'vuex'

export default {

    props: [
        'id', 'filter'
    ],

    computed: {
        ...mapGetters({
            name: 'CategoryFilter/filterDeleteName'
        })
    }
}
</script>
