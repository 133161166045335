<template>
    <!-- Root element of PhotoSwipe. Must have class pswp. -->
    <div
        class="pswp"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        >
        <!-- Background of PhotoSwipe.
            It's a separate element as animating opacity is faster than rgba(). -->
        <div class="pswp__bg overlay-color" />

        <!-- Slides wrapper with overflow:hidden. -->
        <div class="pswp__scroll-wrap">
            <!-- Container that holds slides.
                PhotoSwipe keeps only 3 of them in the DOM to save memory.
                Don't modify these 3 pswp__item elements, data is added later on. -->
            <div class="pswp__container">
                <div class="pswp__item" />
                <div class="pswp__item" />
                <div class="pswp__item" />
            </div>

            <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
            <div class="pswp__ui pswp__ui--hidden">
                <div class="pswp__top-bar">
                    <!--  Controls are self-explanatory. Order can be changed. -->

                    <div class="pswp__counter" />

                    <button
                        class="pswp__button pswp__button--close"
                        title="Close (Esc)"
                        />

                    <button
                        class="pswp__button pswp__button--share"
                        title="Share"
                        />

                    <button
                        class="pswp__button pswp__button--fs"
                        title="Toggle fullscreen"
                        />

                    <button
                        class="pswp__button pswp__button--zoom"
                        title="Zoom in/out"
                        />

                    <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
                    <!-- element will get class pswp__preloader--active when preloader is running -->
                    <div class="pswp__preloader">
                        <div class="pswp__preloader__icn">
                            <div class="pswp__preloader__cut">
                                <div class="pswp__preloader__donut" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                    <div class="pswp__share-tooltip" />
                </div>

                <button
                    class="pswp__button pswp__button--arrow--left"
                    title="Previous (arrow left)"
                    />

                <button
                    class="pswp__button pswp__button--arrow--right"
                    title="Next (arrow right)"
                    />

                <div class="pswp__caption">
                    <span />
                    <div class="pswp__caption__center text-center">
                        <Picture
                            v-for="(img, index) in items"
                            :key="img.src"
                            :src="img.src"
                            :alt="img.name || img.description"
                            :class-img="`image-fluid mx-1 thumb-image ${ active == index ? 'thumb-active' : '' }`"
                            @click="goTo(index)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import PhotoSwipe from 'photoswipe/dist/photoswipe'
import PhotoSwipeDefaultUI from 'photoswipe/dist/photoswipe-ui-default'

export default {
    data() {
        return {
            items: [],
            active: null,
            photoswipe: null
        }
    },
    methods: {
        open(index, items, goToSlide, options) {
            this.items = JSON.parse(JSON.stringify(items))
            this.active = index

            const opts = { index,
                ...{
                    fullscreenEl: false,
                    history: false,
                    shareEl: false,
                    tapToClose: true,
                    bgOpacity: 1
                },
                ...options}

            this.photoswipe = new PhotoSwipe(this.$el, PhotoSwipeDefaultUI, items, opts)
            this.photoswipe.listen('afterChange', () => {
                this.active = this.photoswipe.getCurrentIndex()
                if (typeof goToSlide == 'function') {
                    goToSlide(this.photoswipe.getCurrentIndex())
                }
            })
            this.photoswipe.init()
        },

        goTo(index) {
            this.photoswipe.goTo(index)
        },

        close() {
            this.photoswipe.close()
        }
    }
}
</script>

<style lang="scss">
.thumb-image {
    max-height: 9.5vh;
    opacity: .8;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
}
.thumb-active {
   opacity: 1;
   max-height: 10vh;
}
.pswp__caption__center {
    max-width: none !important;
}
.pswp__ui--hidden .pswp__top-bar,
.pswp__ui--hidden .pswp__caption,
.pswp__ui--hidden .pswp__button--arrow--left,
.pswp__ui--hidden .pswp__button--arrow--right {
  opacity: 1 !important;
}
</style>
