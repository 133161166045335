<template>
    <div class="basket__container">
        <login-basket v-if="loginFrom.includes('/basket')" />
        <login-form v-else />
    </div>
</template>

<script>
// import BasketBreadcrumb from '@components/basket/full/breadcrumb'
import LoginBasket from '@components/login/basket'
import LoginForm from '@components/login/form'
import { mapState } from 'vuex'

export default {
    components: {
        // BasketBreadcrumb,
        LoginBasket,
        LoginForm
    },

    computed: {
        ...mapState('Setup', {
            loginFrom: state => state.login.from
        })
    }
}
</script>
