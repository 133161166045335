<template>
    <div class="filters-toggle">
        <button
            class="btn btn-secondary"
            type="button"
            @click="toggle"
            >
            <span v-text="$ll('categoryFiltersShowModal')" />
            <img
                src="/static/www/images/filter-icon.png"
                :alt="$ll('categoryFiltersShowModal')"
                >
        </button>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {

    computed: {
        ...mapState('CategoryFilter', [
            'show', 'cols'
        ])
    },

    methods: {
        ...mapMutations({
            toggle: 'CategoryFilter/toggle',
            setCols: 'CategoryFilter/setCols'
        })
    }
}
</script>
