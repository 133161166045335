<template>
    <div class="">
        <ul class="filters-fishes-list list-unstyled row">
            <li
                v-for="fiche in fiches.v"
                :key="fiche.id"
                class="col-6"
                >
                <filter-checkbox
                    :filter="{ id: 0 }"
                    :option="fiche"
                    :check="check"
                    @toggle="toggle"
                    />
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import FilterCheckbox from "@components/category/slot/checkbox"

export default {
    components: {
        FilterCheckbox
    },
    data() {
        return {
            check: []
        }
    },
    computed: {
        ...mapState("CategoryFilter", ["fiches_checked"]),
        ...mapGetters({
            fiches: 'CategoryFilter/getFiches'
        })
    },
    watch: {
        fiches_checked: {
            handler() {
                this.check = JSON.parse(JSON.stringify(this.fiches_checked))
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        reset() {
            this.check = []
        },
        cancel() {
            this.check = JSON.parse(JSON.stringify(this.fiches_checked))
        },
        get() {
            return this.check
        },
        toggle(id, set) {
            set ? this.check.push(id) : this.check = this.check.filter(i => i != id)
        }
    }
}
</script>

