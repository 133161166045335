var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center", class: { [`py-${_vm.p}`]: _vm.p } },
    [_c("i", { staticClass: "mdi mdi-36px mdi-loading mdi-spin" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }