<template>
    <b-modal
        v-model="visible"
        size="xl"
        no-close-on-backdrop
        centered
        scrollable
        ok-only
        >
        <div v-html="modalLogin" />
    </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            visible: false
        }
    },
    computed: {
        ...mapState('Setup', ['modalLogin'])
    },

    watch: {
        modalLogin() {
            this.visible = true
        }
    }
}
</script>
