<template>
    <button
        ref="btn"
        class="btn btn-primary"
        :class="{'py-0' : disabled && submit}"
        :disabled="disabled"
        @click="$emit('click')"
        v-html="btnValue"
        />
</template>

<script>
export default {
    name: "BtnSubmit",
    props: {
        value: { type: String, required: true },
        submit: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false }
    },
    computed: {
        wait() {
            return this.$store.getters['Lang/get']('processing')
        },
        btnValue() {
            return this.disabled && this.submit ? '<i class="mdi mdi-24px mdi-loading mdi-spin" />' : this.value
        }
    }
}
</script>
