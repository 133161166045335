/* global cookies */
import json from '@db'
import paginationArray from '@helpers/pagination-array'
import arrayToggle from '@helpers/array-toggle'

//jezeli od php dostaje info ze w url juz sa filtry(paramsy) usun ostatni czlon
function getUri(uri) {
    // if ( !json.category_params ) {
    return uri
    // }

    //jezeli ktos z potomnych bedzie mial problem z podwojnymi filtrami w url oznacza to ze ten kod jednak byl potrzebny
    // const ari = uri.split('/')
    // ari.pop()
    // return ari.join('/')
}
//dodanie brakujacych kluczy filtrow do tablicy zaznaczonych dla reaktywnosci
function checkedArray() {
    if (Array.isArray(json.category_filters_checked)) {
        json.category_filters_checked = {}
    }

    Object.values(json.category_filters)
        .forEach(filter => {
            if (json.category_filters_checked[ filter.id ]) return
            json.category_filters_checked[ filter.id ] = []
        })

    return json.category_filters_checked
}

function prepareProducers(producers = {}) {
    return Object.entries(producers).map(([
        id, name
    ]) => ({
        id,
        name,
        v: name
    }))
}

export default {
    namespaced: true,
    state() {
        const {
            category: {
                perPage: {
                    default: perPageDefault = 36,
                    index: perPageIndex = null,
                    items: perPageItems = {}
                } = {},
                sorting: {
                    index: sortingIndex = null,
                    items: sortingItems = []
                } = {}
            } = {}
        } = json

        return {
            price: {
                'price-from': json.hasOwnProperty('category_filter_prices') ? json.category_filter_prices['price-from'] || 0 : 0,
                'price-to': json.hasOwnProperty('category_filter_prices') ? json.category_filter_prices['price-to'] || 0 : 0
            },
            action: json.category_action || '',
            uri: getUri(json.category_uri || (json.category_phrase != '' ? 'search' : '')),
            submit: false,
            show: false,
            phrase: json.category_phrase || '',
            cols: cookies.get('categoryViewConfig') || false,
            pagination: {
                page: json.category_page, //getUrlParameter('page') || 1,
                pagesCount: json.category_pages,
                products: 1
            },
            filters: json.category_filters || [],
            fiches: json.category_fiches || [],
            fiches_checked: json.category_fiches_checked || [],
            commonFilters: json.category_commonFilters || [],
            checked: json.hasOwnProperty('category_filters') ? checkedArray() : [],
            producers: prepareProducers(json.category_filter_producers || {}) || [],
            producers_checked: json.producers_checked || [],
            separators: json.category_separators,
            perPage: {
                index: perPageIndex,
                items: perPageItems
            },
            perPageDefault,
            sorting: {
                index: sortingIndex || '',
                items: sortingItems
            },
            fixedFilterButtons: false,
            uncommonFilters: false,
            showSeo: true,
            quickFiltersKey: 'initial'
        }
    },
    getters: {

        filters(state) {
            return state.filters
        },

        filterDeleteName(state) {
            return (filterId, valueId) => {
                const filter = state.filters.find(f => f.id == filterId)
                const item = filter.v.find(v => v.id == valueId)

                return `${filter.n}: ${item.v}`
            }
        },

        producerNameFromId(state) {
            return (producerId) => {
                const producer = state.producers.find(s => s.id === producerId)

                return producer.name
            }
        },

        checked(state) {
            return state.checked
        },
        checkedFiches(state) {
            return state.fiches_checked
        },

        checkedProducers(state) {
            return state.producers_checked
        },

        price(state) {
            return state.price
        },

        action(state) {
            return state.action
        },

        uri(state) {
            return state.uri
        },

        submit(state) {
            return state.submit
        },

        //php zwraca zaznaczone id ktorego nie ma na liscie filtorw danej kategorii - funkcja to sprawdza
        keyExists(state) {
            return id => state.filters.find(f => f.id == id)
        },

        //pobierz zaznaczone elementy do obiektu - rozroznienie tablic params i query
        paramsQuery(state) {
            return () => {
                const params = {},
                    query = {}

                Object.entries(state.filters)
                    .forEach(([
                        _, filter
                    ]) => {
                        if (!state.checked[ filter.id ] || !state.checked[ filter.id ].length) {
                            return
                        }

                        if (filter.u) {
                            params[ filter.u ] = filter.v
                                .filter(option => state.checked[ filter.id ].indexOf(option.id) >= 0)
                                .map(option => option.u)
                                .join(state.separators.value)

                            return
                        }

                        query[ filter.id ] = state.checked[ filter.id ].join()
                    })

                return { params, query }
            }
        },

        getParamsString(state, getters) {
            return page => {
                const { params, query } = getters.paramsQuery()

                const params_str = Object.keys(params).length ?
                    `/${state.separators.start}` + Object.entries(params)
                        .map(([
                            key, value
                        ]) => `${key}${state.separators.attribute_value}${value}`)
                        .join(state.separators.attribute)
                    : ''

                const query_str = [
                    Object.keys(query).length ?
                        'filters=' + Object.entries(query)
                            .map(([
                                key, value
                            ]) => `${key}[${value}]`)
                            .join('~')
                        : '',
                    state.fiches_checked.length && `fiches=${state.fiches_checked.join()}`,
                    state.producers_checked.length && `producer=${state.producers_checked.join(',')}`,
                    state.phrase && `phrase=${state.phrase}`,
                    state.price['price-from'] && `price-from=${state.price['price-from']}`,
                    state.price['price-to'] && `price-to=${state.price['price-to']}`,
                    state.perPage.index != json.category.perPage.default && `perPage=${state.perPage.index}`,
                    state.sorting.index != json.category.sorting.default && `sorting=${state.sorting.index}`,
                    page ? page > 1 && `page=${page}` : state.pagination.page > 1 && `page=${state.pagination.page}`
                ]
                    .filter(Boolean)
                    .join('&')

                // if ( !params_str.length && !query_str.length ) {
                //     query['filters-reset'] = ''
                //     query_str = 'filters-reset'
                // }
                if (document.querySelector('.seo-text')) {
                    if (state.pagination.page === 1) {
                        document.querySelector('.seo-text').classList.add('show-seo')
                    }
                    else {
                        document.querySelector('.seo-text').classList.remove('show-seo')
                    }
                }

                return { params, params_str, query, query_str }
            }
        },

        /**
         * @description Pobiera wszystkie fiszki tak jak pobierane sa filtry (dla ujednolicenia widoku)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        getFiches(state) {
            return {
                b: 'attributeList',
                id: 0,
                v: {
                    ...state.fiches.map(i => ({ v: i.name, id: i.id }))
                }
            }
        },

        //paginacja
        getPage(state) {
            return state.pagination.page
        },

        getPageCount(state) {
            return state.pagination.pagesCount
        },

        getPagination(state, getters) {
            return paginationArray(getters.getPage, getters.getPageCount)
        },
        keyCommon(state) {
            return state.commonFilters
        }
    },
    mutations: {

        /**
         * Parsowanie url'a (parametrow i query) na id filtrow
         *
         * @param {Object} payload {router.params, router.query}
         */
        setActiveFilters(state, payload) {
            const {
                params: { pathMatch = '' } = {},
                query: { filters: filtersMatch = '', page = 1, sorting = '', perPage = state.perPageDefault, fiches = '' } = {}
            } = payload

            if (!state.separators) return //nie jest kategoria?

            const begin = `${state.separators.start}`
            const startSplit = new RegExp(`(?:${begin})([^/]+)`, 'g')
            const rawFiltersParams = pathMatch.match(startSplit) || []

            const filters = [].concat.apply([], rawFiltersParams.map(f => f.substr(begin.length).split(state.separators.attribute)))
            const filtersObj = filters.reduce((r, i) => {
                const [
                    name, val
                ] = i.split(state.separators.attribute_value)

                const filterObject = state.filters.find(f => f.u === name)

                r[filterObject.id] = val.split(state.separators.value).map(v => filterObject.v.find(i => i.u === v).id)

                return r
            }, {})

            const rawQuery = filtersMatch.split(state.separators.attribute)

            const query = rawQuery.reduce((r, i) => {
                const num = i.match(/(\d+)/g)

                if (num) {
                    r[num.shift()] = num.map(Number)
                }

                return r
            }, filtersObj)

            Object.values(state.filters)
                .forEach(filter => {
                    if (query[ filter.id ]) return
                    query[ filter.id ] = []
                })

            state.perPage.index = perPage
            state.sorting.index = sorting
            state.pagination.page = Number(page) || 1
            state.checked = query
            state.fiches_checked = fiches.split(',').filter(Number).map(Number)
        },
        //dodaj/usun z tablicy zaznaczonych elementow wartosc inputa
        setInput(state, payload) {
            const { id, val, set } = payload
            const arr = state.checked[id]

            arrayToggle(arr, val, set)

            state.checked[id] = arr
        },
        removeFiche(state, payload) {
            const arr = state.fiches_checked
            const index = arr.indexOf(payload)

            if (index > -1) {
                arr.splice(index, 1)
            }
            state.fiches_checked = arr
            if (!payload) {
                state.fiches_checked = []
            }
        },
        removeProducer(state, payload) {
            const { val, set } = payload
            const arr = state.producers_checked

            arrayToggle(arr, val, set)

            state.producers_checked = arr
        },

        //usun wszystkie zaznaczenia z filtra
        resetFilters(state) {
            Object.keys(state.checked).forEach(id =>{
                state.checked[ id ] = []
            })
            state.producers_checked = []
        },

        setFilters(state, payload) {
            state.checked = payload
        },

        setFiches(state, payload) {
            state.fiches_checked = payload
        },

        setProducers(state, payload) {
            state.producers_checked = payload
        },

        setAction(state, payload) {
            state.action = getUri(payload)
        },

        setUri(state, payload) {
            state.uri = payload
        },

        setSubmit(state) {
            state.submit = true
        },
        setResponse(state) {
            state.submit = false
        },

        setPage(state, payload) {
            state.pagination.page = payload || 1
        },

        setPagination(state, payload) {
            const { currentPage, pagesCount } = payload

            state.pagination.page = currentPage
            state.pagination.pagesCount = pagesCount
        },

        toggle(state) {
            state.show = !state.show
            if (state.show) {
                document.querySelector('body').classList.add('filters-open')
            }
            else {
                document.querySelector('body').classList.remove('filters-open')
            }
        },

        setSorting(state, payload) {
            state.sorting.index = payload
        },

        setCols(state, payload) {
            state.cols = payload

            if (payload) {
                cookies.set('categoryViewConfig', true, 365)
            }
            else {
                cookies.delete('categoryViewConfig')
            }
        },
        setSorted(payload) {
            this.$store.commit('CategoryFilter/setSorting', payload.value)
            this.$store.dispatch('CategoryFilter/submit')
        },
        setUncommonFilters(state) {
            state.uncommonFilters = true
        },
        setQuickFiltersKey(state, payload) {
            state.quickFiltersKey = payload
        }

    },
    actions: {

        setPrice({ state, dispatch }, payload) {
            state.price[ `price-${payload.id}` ] = Number.parseInt(payload.value)
            dispatch('submit')
        },

        setPerPage({ state, dispatch }, payload) {
            state.perPage.index = payload
            dispatch('submit')
        },

        submit({ commit, dispatch, getters }) {
            commit('setPage')
            dispatch('ProductList/getProducts', getters.getParamsString(), { root: true })
            // // needed to reload/remount quick filters component
            commit('setQuickFiltersKey', Math.random().toString())
        },

        goToPage({ commit, getters, dispatch }, payload) {
            const count = getters.getPageCount

            if (payload < 1) payload = 1
            if (payload > count) payload = count

            commit('setPage', payload)
            dispatch('ProductList/getProducts', getters.getParamsString(), { root: true })
        },
        /**
         * Pozycja buttonów w filtrach
         */

        filterButtons({state}) {
            const filterHeight = document.querySelector('.filters').getBoundingClientRect().bottom

            filterHeight > window.innerHeight ? state.fixedFilterButtons = true : state.fixedFilterButtons = false
        }
    }
}
