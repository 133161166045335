<template>
    <div :class="{'mb-4': fieldOpen}">
        <div class="row no-gutters">
            <div class="col-12">
                <div class="basket-discount-input-row">
                    <div class="form-field form-field--alternate mb-2">
                        <input
                            v-model="value"
                            :disabled="quantityChangePending"
                            class="form-control form-field__input"
                            :class="{ 'form-field__input--invalid': voucherError }"
                            :placeholder="$ll('basketVoucherPlaceholder')"
                            @keyup="setVoucherInput($event, value)"
                            >
                        <label
                            class="form-field__placeholder"
                            v-text="$ll('basketVoucherPlaceholder')"
                            />
                        <div
                            v-if="voucherError"
                            class="form-field__hint form-field__hint--invalid"
                            v-text="voucherError"
                            />
                    </div>
                    <div class="">
                        <button
                            class="btn btn-secondary btn-secondary-slimed px-2 mr-1 w-100"
                            :class="{disabled: quantityChangePending }"
                            :disabled="!value"
                            @click.prevent="setVoucher(value); value = ''"
                            v-text="$ll('basketVoucherSubmit')"
                            />
                    </div>
                </div>
                <div
                    v-if="voucher.code"
                    class="clearer"
                    >
                    <small>
                        {{ voucher.name }}: -{{ voucher.value }}
                        <a
                            class="btn-link-inline no-hover d-inline-block ml-2"
                            @click.prevent="clearVoucher"
                            >
                            <small><i class="i-close font-weight-bolder" /></small>
                        </a>
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {

    data() {
        return {
            value: '',
            fieldOpen: false
        }
    },

    computed: {
        ...mapState('Basket', [
            'voucher', 'quantityChangePending', 'voucherError'
        ])
    },

    methods: {
        ...mapActions({
            setVoucher: 'Basket/setVoucher',
            clearVoucher: 'Basket/clearVoucher'
        }),
        toggleField() {
            this.fieldOpen = !this.fieldOpen
        },
        setVoucherInput(e, value) {
            if (this.quantityChangePending) return
            if (e.keyCode === 13 || e.type === 'click') {
                this.setVoucher(value)
                this.value = ''
            }
        }
    }
}
</script>

