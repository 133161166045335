var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "buttons-container" }, [
    _c(
      "div",
      { staticClass: "basket__buttons", class: { fixed: _vm.buttonFixed } },
      [
        _c("div", { class: { container: _vm.buttonFixed } }, [
          _c("div", { staticClass: "row no-gutters justify-content-between" }, [
            _c(
              "div",
              {
                staticClass: "col-12 order-xl-last pl-xl-2",
                class: { "col-xl-6": !_vm.buttonFixed },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-black order-sm-last w-100 px-3 mb-3",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$ll("basketSummaryButton")) +
                        "\n                    "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.buttonFixed,
                    expression: "!buttonFixed",
                  },
                ],
                staticClass: "col-12 col-xl-6 pr-xl-2",
              },
              [
                _c("a", {
                  directives: [
                    {
                      name: "ll",
                      rawName: "v-ll",
                      value: "basketButtonReturnToShopping",
                      expression: "'basketButtonReturnToShopping'",
                    },
                  ],
                  staticClass: "btn btn-secondary w-100 px-3",
                  attrs: { href: _vm.urlReferer },
                }),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }