<template>
    <div class="row mb-3">
        <div class="col-12">
            <ul
                v-if="!!point"
                class="list-unstyled text-black"
                >
                <li>{{ point.name }}</li>
                <li
                    v-if="point.address.street"
                    class="font-weight-bold"
                    >
                    {{ point.address.street }} {{ point.address.building_number }}{{ point.address.flat_number && `/${point.address.flat_number}` }}
                </li>
                <li
                    v-else
                    >
                    {{ point.address.city }} {{ point.address.building_number }}{{ point.address.flat_number && `/${point.address.flat_number}` }}
                </li>
                <li>{{ point.location_description }}</li>
                <li>{{ point.address.post_code }} {{ point.address.city }}</li>
                <li
                    v-if="!!point.operating_hours"
                    class="mt-2"
                    >
                    {{ $ll('basketDeliveryPointOpenHours') }}:
                    <div v-html="point.operating_hours" />
                </li>
                <li
                    v-if="!!point.additional_info"
                    class="mt-2"
                    >
                    {{ $ll('basketDeliveryPointAdditionalInfo') }}:<br>
                    {{ point.additional_info }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('Basket', ['point'])
    }
}
</script>
