export default (products) => {
  window.dataLayer = window.dataLayer || []

  const basketProducts = []
  let basketTotal = 0
  const criteoBasket = []
  const gazetaBasket = []
  for (let i = 0; i < products.length; i++) {
    basketProducts[i] =
        {
            sku: products[i].code,
            id: products[i].product_id,
            name: products[i].name,
            price: products[i].price.promoted || products[i].price.regular,
            quantity: products[i].quantity
        }
      criteoBasket[i] = {
          id: products[i].code,
          price: products[i].price.promoted || products[i].price.regular,
          quantity: products[i].quantity
      }
      gazetaBasket[i] = {
        id: products[i].product_id.toString(),
        name: products[i].name,
        price: products[i].price.promoted || products[i].price.regular,
        quantity: products[i].quantity,          
    }
      basketTotal += products[i].price.promoted || products[i].price.regular
  }
  basketTotal = Math.round(basketTotal * 100) / 100
  window.dataLayer.push({
      basketTotal,
      basketProducts,
      criteoBasket,
      gazetaBasket
  })
}