<template>
    <div
        class="btn-group"
        :class="{'vouchers-img': target=='vouchers', 'voucher-error': field.error}"
        >
        <label
            v-for="(data, value) in field.data"
            :key="value"
            class="btn-outline-primary p-1 m-2 cursor-pointer"
            :class="{ active: value == field.value }"
            >
            <input
                v-model="field.value"
                type="radio"
                class="d-none"
                :name="field.name"
                :value="value"
                >
            <Picture
                :src="$photoUrl(data.url)"
                />
        </label>
    </div>
</template>

<script>
export default {
    props: [
        'field', 'data', 'target'
    ]
}
</script>

