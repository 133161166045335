import VueRouter from 'vue-router'
import Vue from 'vue'
import store from '@/store'

import NestedRoute from '@components/nested-route'
import Basket from '@components/basket/full'
import CupboardList from '@components/cupboard-list'
import CategoryRoute from '@components/category-route'
import Step1 from '@components/basket/full/step1'
import Step2 from '@components/basket/full/step2'
import Step3 from '@components/basket/full/step3'
import Login from '@components/login'
import VoucherForm from '@components/voucher/form'
import VoucherConfirm from '@components/voucher/confirm'
import langUrl from '@/helpers/lang-url'

Vue.use(VueRouter)

/**
 * Meta tagi w routingu:
 *
 * auth: true - podstrona wymaga zalogowania
 * twigContainer: true - na podstronie NIE ZOSTANIE usuniety div#twigContainer - z calą zawartoscia
 *
 */
const router = new VueRouter({
    mode: 'history',
    routes: [{
        path: '/:lang?',
        component: NestedRoute,
        children: [
            {
                path: 'basket',
                component: Basket,
                children: [
                    {
                        path: '',
                        component: Step1
                    },
                    {
                        path: 'delivery',
                        component: Step2,
                        meta: {
                            auth: true
                        }
                    },
                    {
                        path: 'summary',
                        component: Step3,
                        meta: {
                            auth: true
                        },
                        beforeEnter: (_to, _from, next) => {
                            const basketEmpty = store.state.Basket.products.length === 0
                            const isPaymentSet = store.state.Basket.payment !== null
                            const isDeliverySet = store.state.Basket.deliveryId !== null

                            if (basketEmpty) next('/basket')
                            else if (!isDeliverySet || !isPaymentSet) next('/basket/delivery')
                            else next()
                        }
                    }
                ]
            },
            {
                path: 'access/login',
                component: Login
            },
            {
                path: 'cupboard/:id?',
                props: route => ({ id: Number(route.params.id) || 0 }),
                component: CupboardList
            },
            {
                path: 'vouchers',
                component: NestedRoute,
                children: [
                    {
                        path: '',
                        component: VoucherForm,
                        meta: {
                            twigContainer: true
                        }
                    },
                    {
                        path: 'confirm',
                        component: VoucherConfirm,
                        meta: {
                            twigContainer: true
                        }
                    }
                ]
            },
            {
                path: 'myAccount',
                component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account'),
                children: [
                    {
                        path: 'cupboard',
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/cupboard')
                    },
                    {
                        path: 'orders/:page?',
                        props: route => ({
                            page: Number(route.params.page) || 1,
                            id: Number(route.hash.substr(1)) || 0
                        }),
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/orders/index')
                    },
                    {
                        path: 'returns/new',
                        props: route => ({
                            id: Number(route.hash.substr(1)) || 0
                        }),
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/returns/new')
                    },
                    {
                        path: 'returns/preview',
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/returns/preview'),
                        beforeEnter(_to, _from, next) {
                            const { products = [] } = store.state.MyAccount.returns

                            products.length ? next() : next(langUrl(store.state.Setup.default_language_iso, store.getters['Setup/language'], '/myAccount/returns/new'))
                        }
                    },
                    {
                        path: 'returns/:page?',
                        props: route => ({
                            page: Number(route.params.page) || 1,
                            id: Number(route.hash.substr(1)) || 0
                        }),
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/returns/index')
                    },
                    {
                        path: 'personalData',
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/personal-data')
                    },
                    {
                        path: 'loyaltyProgram',
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/loyalty')
                    },
                    {
                        path: 'opinions',
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/opinions')
                    },
                    {
                        path: 'passwordChange',
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/password-change')
                    },
                    // {
                    //     path: 'downloadData',
                    //     component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/download-data'),
                    // },
                    {
                        path: 'bok/:page?',
                        props: route => ({
                            page: Number(route.params.page) || 1
                        }),
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/bok')
                    },
                    // {
                    //     path: 'bok/create',
                    //     component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/bok/create'),
                    // },
                    {
                        path: 'bok/conversation/:id',
                        props: route => ({ id: Number(route.params.id) }),
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/bok/conversation')
                    },
                    {
                        path: 'address',
                        component: () => import(/* webpackChunkName: "my-account" */ '@components/my-account/address')
                    },
                    {
                        path: '*',
                        redirect: 'personalData'
                    }
                ]
            },
            {
                path: 'bony',
                redirect: 'vouchers'
            },
            {
                path: '*',
                component: CategoryRoute,
                meta: {
                    twigContainer: true
                }
            }
        ]
    }],
    scrollBehavior (/*to, from, savedPosition*/) {
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    store.commit('Setup/setLang', to.params.lang)

    if (!to.meta.auth || store.state.Setup.login.logged || store.state.Setup.login.basketNotLogged) {
        if (to.path.endsWith('/access/login')) {
            // store.commit('Setup/loginTo', from.path)
        }
        next()
    }
    else {
        store.commit('Setup/loginFrom', from.path)
        store.commit('Setup/loginTo', to.path)
        next(langUrl(store.state.Setup.default_language_iso, store.getters['Setup/language'], '/access/login'))
    }
})

let first

router.afterEach((to) => {
    if (!first) {
        first = true
        return
    }

    // if (to.meta.twigContainer) {
    //     return
    // }

    setTimeout(_ => {
        if (!to.meta.twigContainer && document.querySelector('#vue-container') && document.querySelector('#vue-container').hasChildNodes() && document.querySelector('#vue-container').childNodes[0].nodeName !== '#comment') {
            document.querySelector('#twig-container').classList.add('d-none')
        }
        else {
            document.querySelector('#twig-container').classList.remove('d-none')
        }
    })
})

export default router
