<template>
    <div class="container container-small vouchers-confirm px-3">
        <div class="row no-gutters">
            <div class="col-12">
                <div class="row no-gutters">
                    <div
                        class="col-12 col-md-6"
                        >
                        <span
                            class="d-block  mt-4 mb-3"
                            v-html="data.personalTitle.html"
                            />
                        <span
                            class="d-block"
                            v-html="'<strong>' + data.forename.label + '</strong>: ' + data.forename.value"
                            />
                        <span
                            class="d-block"
                            v-html="'<strong>' + data.surname.label + '</strong>: ' + data.surname.value"
                            />
                        <span
                            class="d-block"
                            v-html="'<strong>' + data.email.label + '</strong>: ' + data.email.value"
                            />
                        <span
                            class="d-block"
                            v-html="'<strong>' + data.phone.label + '</strong>: ' + data.phone.value"
                            />
                    </div>
                    <div
                        class="col-12 col-md-6"
                        >
                        <span
                            class="d-block  mt-4 mb-3"
                            v-html="data.discountsTitle.html"
                            />
                        <span
                            class="d-block"
                            v-html="'<strong>' + data.discount_id.label + '</strong>: ' + data.discount_id.data[data.discount_id.value].name"
                            />
                        <div class="row no-gutters">
                            <div class="col-12 col-sm-6 py-4">
                                <Picture
                                    :src="$photoUrl(data.discount_id.data[data.discount_id.value].url)"
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 offset-md-6">
                <btn-submit
                    :value="$ll('basketConfirmOrderButton')"
                    :disabled="disabled"
                    @click="$store.dispatch('Voucher/order')"
                    />
            </div>
        </div>
    </div>
</template>

<script>
import BtnSubmit from '@components/btn-submit'
import { mapState } from 'vuex'

export default {
    components: {
        BtnSubmit
    },
    computed: {
        ...mapState('Voucher', ['disabled']),
        data() {
            return this.$store.state.CustomForm.forms.vouchers.fields
        }
    },
    beforeMount() {
        setTimeout(()=>{
            document.querySelector('.text-page-content').classList.add('d-none')
        }, 0)
    }
}
</script>
