<template>
    <section
        class="basket"
        :class="{ show }"
        >
        <div class="basket-content pb-5">
            <div class="text-right">
                <a
                    class="btn btn-link"
                    @click="toggle"
                    >
                    <i class="i-close" />
                </a>
            </div>

            <h5 class="text-center pt-2 mb-5">
                {{ $ll('basketTitle') }} ({{ products.length }})
            </h5>
            <basket-view />
        </div>

        <div
            class="basket-overlay overlay-color"
            @click="toggle"
            />
    </section>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import BasketView from '@components/basket/mini/view'

export default {

    components: {
        BasketView
    },

    computed: {
        ...mapState(
            'Basket', [
                'show', 'products'
            ]
        )
    },

    watch: {
        show(v) {
            if (v) {
                document.body.classList.add('modal-open')
                this.miniButtons()
            }
            else {
                document.body.classList.remove('modal-open')
            }
        }
    },

    created () {
        window.addEventListener('resize', this.miniButtons)
    },

    methods: {
        ...mapMutations({
            toggle: 'Basket/toggle'
        }),
        ...mapActions({
            miniButtons: 'Basket/miniButtons'
        })
    }

}
</script>

<style lang="scss">
    .basket {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1002;
        width: 25vw;
        margin-right: -100%;
        transition: margin .7s;
        padding: 5px 10px;
        @media(max-width: 991px){
            width: 35vw;
        }
        @media(max-width: 767px){
            width: 50vw;
        }
        @media(max-width: 480px){
            width: 80vw;
        }

        &-content {
            padding: 0.5rem 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #ffffff;
            z-index: 1003;
            overflow: auto;
        }

        &.show {
            margin-right: 0;

            .basket-overlay {
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: 1002;
                cursor: pointer;
            }
        }

        .btn-link {
            padding: .2rem;
        }
    }
</style>
