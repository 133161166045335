<template>
    <div>
        <div>
            {{ $ll('basketDeliveryPoint') }}:<br>
            <strong class="text-black">
                {{ details.i }}
            </strong>
        </div>
        <p v-if="details.address">
            <strong class="text-black">
                {{ details.address.street }} {{ details.address.building_number }}{{ details.address.flat_number && `/${details.address.flat_number}` }}<br>
            	{{ details.address.post_code }} {{ details.address.city }}
            </strong><br>
            {{ details.address.post_code }} {{ details.address.city }}
        </p>

        <div class="text-left mt-3">
            <button
                type="button"
                class="btn btn-primary"
                @click="$emit('setPoint', details)"
                >
                {{ $ll('basketDeliveryPointCommit') }}
            </button>
        </div>

        <div class="mt-3">
            {{ $ll('basketDeliveryPointOpenHours') }}:
        </div>
        <p v-html="details.operating_hours" />

        <div class="mt-3">
            {{ $ll('basketDeliveryPointAdditionalInfo') }}:
        </div>
        <p v-html="details.location_description" />
    </div>
</template>

<script>
//id 3 == paczkomaty
export default {
    props: ['details']
}
</script>
