<template>
    <span>
        <template v-if="price.promo_brutto">
            <strong class="text-primary">
                <price :value="price.promo_brutto" />
            </strong>
            <s>
                <price :value="price.brutto" />
            </s>
        </template>

        <price
            v-else
            :value="price.brutto"
            />
    </span>
</template>

<script>
import Price from '@components/price'

export default {

    components: {
        Price
    },

    props: {
        price: { type: Object, required: true }
    }
}
</script>
