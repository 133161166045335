var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.filterAttributes, function (item) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "pt-2 product-description-item",
          class: { "params-warning": _vm.paramsWarn && !_vm.chosen[item.id] },
        },
        [
          _c("div", { staticClass: "text-uppercase text-primary" }, [
            _vm._v("\n            " + _vm._s(item.name) + ": "),
            _vm.chosenColorName && item.color
              ? _c("span", {
                  domProps: { textContent: _vm._s(_vm.chosenColorName) },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "nav nav-pills prod-attr mt-2" },
            _vm._l(item.values, function (value) {
              return _c("li", { key: value.id, staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link cursor-pointer",
                    class: {
                      active: _vm.chosen[item.id] == value.id,
                      disabled: value.disabled,
                      "not-available": value.notifyIco,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.select({ id: item.id, value: value.id })
                      },
                    },
                  },
                  [
                    value.photo
                      ? _c("div", { staticClass: "position-relative" }, [
                          _c("link", {
                            attrs: {
                              rel: "preload",
                              as: "image",
                              href: _vm.$photoUrl(value.photo.full),
                            },
                          }),
                          _vm._v(" "),
                          _c("link", {
                            attrs: {
                              rel: "preload",
                              as: "image",
                              href: _vm
                                .$photoUrl(value.photo.full)
                                .replace(/.(jpg|png)$/, ".webp"),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "product-color position-relative prod-attr__popover-trigger",
                              attrs: { id: `col-${item.id}-${value.id}` },
                            },
                            [
                              _c("Picture", {
                                attrs: {
                                  src: _vm.$photoUrl(value.photo.thumbnail),
                                  "class-img":
                                    "img-cover position-absolute pos-reset",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column prod-attr__popover-content popover bs-popover-top",
                            },
                            [
                              _c("div", { staticClass: "arrow" }),
                              _vm._v(" "),
                              _c("Picture", {
                                staticStyle: { "max-width": "100px" },
                                attrs: {
                                  src: _vm.$photoUrl(value.photo.full),
                                  width: "100",
                                  height: "100",
                                  "style-img": "max-width: unset !important",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "text-uppercase mt-2",
                                domProps: { textContent: _vm._s(value.value) },
                              }),
                            ],
                            1
                          ),
                        ])
                      : item.type == "attributeList"
                      ? _c("span", {
                          domProps: { textContent: _vm._s(value.value) },
                        })
                      : item.type == "attributeColor"
                      ? _c("span", {
                          staticClass: "product-color",
                          style: { background: value.color },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.type == "attributeColor"
                      ? _c("span", {
                          staticClass: "d-none color-name",
                          domProps: { textContent: _vm._s(value.value) },
                        })
                      : _vm._e(),
                  ]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.paramsWarn && !_vm.chosen[item.id]
            ? _c("div", { staticClass: "not-set" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$ll("productCardAttributeNotSet")) +
                    ": " +
                    _vm._s(item.name) +
                    "\n        "
                ),
              ])
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }