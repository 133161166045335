<template>
    <div>
        <p v-if="paid || paidStatus">
            <i class="mdi mdi-check text-success" />
            {{ $ll('orderPaymentStatusOK') }}
        </p>
        <p v-else-if="!error">
            <i class="mdi mdi-spin mdi-loading" />
            {{ $ll('orderPaymentWaiting') }}
        </p>
        <p v-else>
            <i class="mdi mdi-close text-danger" />
            {{ error }}
        </p>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        paid: { type: Boolean, required: true },
        transaction: { type: String, required: true },
        verifyUrl: { type: String, default: '/order/verify' }
    },

    data() {
        return {
            paidStatus: false,
            count: 0,
            error: false
        }
    },

    mounted() {
        this.update()
    },

    methods: {
        update() {
            if (!this.paid && !this.paidStatus) {
                this.count++
                const time = this.count < 10 ? 2000 : 2000 + (200 * this.count - 10)

                setTimeout(() => {
                    axios
                        .post(this.$langUrl(this.verifyUrl), { transaction_key: this.transaction })
                        .then(res => {
                            this.paidStatus = res.data.status
                            if (!res.data.status) this.update()
                        })
                        .catch(err => {
                            this.paidStatus = false
                            this.error = err.response.data.message
                        })
                }, time)
            }
        }
    }
}
</script>
