<template>
    <div class="filters-collapse">
        <button
            v-b-toggle="`toggle-${toggleId}`"
            class="filters-collapse__toggle btn-text"
            >
            <slot name="collapseTitle" />
            <span class="custom-arrow">
                <i />
            </span>
        </button>
        <b-collapse
            :id="`toggle-${toggleId}`"
            class="filters-collapse__body"
            >
            <slot name="body" />
        </b-collapse>
    </div>
</template>

<script>
export default {
    props: {
        toggleId: {
            type: [
                String,
                Number
            ],
            required: true
        }
    }
}
</script>