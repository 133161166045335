var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banderolle__outer" },
    _vm._l(_vm.items, function (item) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "banderolle",
          style: { backgroundColor: item.variables.backgroundColor },
        },
        [
          _c(
            "div",
            { class: { container: !_vm.fluid, "container-fluid": _vm.fluid } },
            [
              _c("div", {
                staticClass: "banderolle__content",
                domProps: { innerHTML: _vm._s(item.variables.content) },
              }),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }