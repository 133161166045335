<template>
    <div>
        <h6 class="text-primary">
            {{ details.name }}
        </h6>
        <p
            v-if="details.address && details.address.street"
            class="mb-0"
            >
            {{ details.address.street }} {{ details.address.building_number }}{{ details.address.flat_number && `/${details.address.flat_number}` }}
        </p>
        <p
            v-else-if="details.address"
            class="mb-0"
            >
            {{ details.address.city }} {{ details.address.building_number }}{{ details.address.flat_number && `/${details.address.flat_number}` }}
        </p>
        <p
            v-if="details.address"
            class="mb-0"
            >
            {{ details.address.post_code }} {{ details.address.city }}
        </p>
        <p v-html="details.location_description" />
        <div class="text-center mt-4">
            <button
                type="button"
                class="btn btn-primary"
                @click="$emit('setPoint', details)"
                >
                {{ $ll('basketDeliveryPointCommit') }}
            </button>
        </div>
    </div>
</template>

<script>
//id 7 == Odbiór w punkcie
export default {
    props: ['details']
}
</script>
