var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "basket-btn no-hover",
      attrs: { href: _vm.$langUrl("/basket") },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.toggle.apply(null, arguments)
        },
      },
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.products.length
        ? _c("span", { staticClass: "basket-badge" }, [
            _c("span", {
              domProps: { textContent: _vm._s(_vm.products.length || 0) },
            }),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }