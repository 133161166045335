var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a", {
    staticClass: "plain-link cursor-pointer size-table-button",
    domProps: { textContent: _vm._s(_vm.$ll("productSizeTableLink")) },
    on: {
      click: function ($event) {
        $event.preventDefault()
        return _vm.handleOpen.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }