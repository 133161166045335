<template>
    <form
        class="form"
        @submit.prevent="checkOrder"
        >
        <div class="row no-gutters">
            <div class="col-12">
                <basket-breadcrumb />
                <h1 class="text-uppercase text-center mt-3 mt-lg-5 mb-0 pb-4 bottom-line">
                    {{ $ll('basketTitleDelivery') }}
                </h1>
            </div>
        </div>
        <div
            id="delivery-container"
            class="row no-gutters"
            >
            <div class="col-12 col-lg-6 mt-4 pr-lg-3 pr-0">
                <countries-list />
                <delivery-list />
                <div
                    v-if="!loadDelivery"
                    class="py-5 text-center"
                    >
                    <spin-loader />
                </div>
                <delivery-forms />
            </div>
            <div class="col-12 col-lg-6 mt-4 pl-0 pl-lg-3 delivery-products">
                <label class="text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black">
                    {{ $ll('basketList') }}
                </label>
                <basket-list />
                <basket-summary />
                <basket-consents class="mt-4" />
                <basket-buttons />
            </div>
            <div class="d-none">
                <input
                    ref="submit"
                    type="submit"
                    >
            </div>
        </div>
    </form>
</template>

<script>
// import Addresses from '@components/basket/full/step2/addresses'
import BasketList from '@components/basket/full/list'
import CountriesList from '@components/basket/full/step2/countries'
import DeliveryList from '@components/basket/full/step2/delivery'
// import DeliveryForm from '@components/basket/full/step2/form'
import DeliveryForms from '@components/basket/full/step2/form-data'
import BasketSummary from '@components/basket/full/summary'
import BasketBreadcrumb from '@components/basket/full/breadcrumb'
import BasketButtons from '@components/basket/full/step2/buttons'
import BasketConsents from '@components/basket/full/step2/consents'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    components: {
        // Addresses,
        BasketList,
        CountriesList,
        DeliveryList,
        DeliveryForms,
        BasketSummary,
        BasketBreadcrumb,
        BasketButtons,
        BasketConsents
    },

    computed: {
        ...mapState('Basket', [
            'submited', 'loadDelivery'
        ]),
        ...mapGetters({
            showForm: 'Basket/showForm'
        })
    },

    watch: {
        submited() {
            if (this.showForm) {
                this.$refs.submit.click()
            }
        }
    },

    methods: {
        ...mapActions({
            checkOrder: 'Basket/checkOrder'
        }),
        ...mapGetters({
            deliveries: 'Basket/getDeliveries'
        })
    }
}
</script>
<style>

</style>