//pokazywanie, ukrywanie menu
document.addEventListener('DOMContentLoaded', () => {
    const list = document.querySelectorAll('.left-menu .custom-arrow')
    const openMenu = function(el) {
        const isShow = el.parentElement.classList.contains('show')

        el.parentElement.classList.toggle('show', !isShow)
    }

    for (let i = 0; i < list.length; i++) {
        list[i].addEventListener("click", () => {
            openMenu(list[i])
        })
    }
})