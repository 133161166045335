import json from '@db'

export default {
    install (Vue) {
        Vue.prototype.$rt = key => {

            if ( json.rt[key] ) {
                return json.rt[key]
            }

            console.warn(`Brak klucza ${key}`)
            return key
        }
    }
}