<template>
    <filters-collapse :toggle-id="filter.id">
        <span slot="collapseTitle">
            {{ filter.n }}
        </span>
        <ul
            slot="body"
            class="list-unstyled row"
            >
            <li
                v-for="option in filter.v"
                :key="option.id"
                class="col-12 col-md-6"
                >
                <filter-color
                    v-if="filter.b === 'attributeColor'"
                    :filter="filter"
                    :option="option"
                    :check="check"
                    @toggle="toggle"
                    />

                <filter-checkbox
                    v-else
                    :filter="filter"
                    :option="option"
                    :check="check"
                    @toggle="toggle"
                    />
            </li>
        </ul>
    </filters-collapse>
</template>

<script>
import FilterCheckbox from '@components/category/slot/checkbox'
import FilterColor from '@components/category/slot/color'
import FiltersCollapse from '@components/category/filters-collapse'

export default {

    components: {
        FilterCheckbox,
        FilterColor,
        FiltersCollapse
    },

    props: {
        checked: { type: Array, required: true },
        filter: { type: Object, required: true }
    },

    data() {
        return {
            check: JSON.parse(JSON.stringify(this.checked))
        }
    },

    methods: {
        reset() {
            this.check = []
        },
        cancel() {
            this.check = JSON.parse(JSON.stringify(this.checked))
        },
        get() {
            return { [ this.filter.id ]: this.check }
        },
        toggle(id, set) {
            set ? this.check.push(id) : this.check = this.check.filter(i => i != id)
        }
    }
}
</script>
