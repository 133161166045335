var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container set-container" },
    [
      _vm._l(_vm.products, function (product, index) {
        return _c("div", { key: product.code, staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 col-md-6 col-xl-3 d-flex flex-column justify-content-center",
            },
            [
              _c("product-set-carousel", {
                attrs: { images: product.photos, url: product.url },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-md-6 col-xl-5 pr-4 d-flex flex-column justify-content-center",
            },
            [
              _c(
                "h3",
                { staticClass: "mb-1 pb-1 mt-3 mt-md-0 text-uppercase" },
                [
                  _c("a", {
                    attrs: { href: product.url },
                    domProps: { textContent: _vm._s(product.name) },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("product-set-price", { attrs: { index: index } }),
              _vm._v(" "),
              _c("p", {
                staticClass: "text-black-50 set-prod-desc",
                domProps: { innerHTML: _vm._s(product.description) },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-md-6 col-xl-4 mt-4" },
            [
              _c("product-set-attr", { attrs: { index: index } }),
              _vm._v(" "),
              product.size_table_identifier
                ? _c(
                    "div",
                    { staticClass: "size-table-container" },
                    [
                      _c("product-size-table-trigger", {
                        attrs: { id: product.size_table_identifier },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-4" },
                [_c("product-set-add-basket", { attrs: { index: index } })],
                1
              ),
            ],
            1
          ),
        ])
      }),
      _vm._v(" "),
      _c("product-set-modals"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }