<template>
    <div
        v-show="loadDelivery"
        class="form-group"
        >
        <label
            class="text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black"
            for="countryList"
            >
            {{ $ll('basketSelectDeliveryPoint') }}
        </label>
        <input
            type="hidden"
            :value="point"
            required
            class="form-control"
            :class="{ 'is-invalid': !point && submited }"
            >
        <div
            v-if="!point && submited"
            class="invalid-feedback"
            >
            {{ $ll('basketDeliveryPointNotSet') }}
        </div>
        <div class="form-field">
            <gmap-autocomplete
                :options="autocompleteOptions"
                class="form-control form-field__input"
                :placeholder="$ll('basketDeliveryPointSearchAutocompleteLabel')"
                @place_changed="place"
                />
            <label
                class="form-field__placeholder"
                v-text="$ll('basketDeliveryPointSearchAutocompleteLabel')"
                />
        </div>
        <GmapMap
            ref="gmap"
            :zoom="6"
            :center="{lat:52.04, lng:19.13}"
            map-type-id="terrain"
            style="width: 100%; height: 50vh;"
            @bounds_changed="geoLocation = true"
            >
            <GmapInfoWindow
                :options="infoOptions"
                :position="details.g"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen=false"
                >
                <info-window-paczkomaty
                    v-if="getDeliveryId == 4 || getDeliveryId == 5"
                    :details="details"
                    @setPoint="setPoint"
                    />
                <info-window-osobisty
                    v-if="getDeliveryId == 3"
                    :details="details"
                    @setPoint="setPoint"
                    />
                <info-window-punkty-pp
                    v-if="getDeliveryId == 7"
                    :details="details"
                    @setPoint="setPoint"
                    />
                <info-window-pocztex
                    v-if="getDeliveryId == 10 || getDeliveryId == 12"
                    :details="details"
                    @setPoint="setPoint"
                    />
                <info-window-orlen
                    v-if="getDeliveryId == 28"
                    :details="details"
                    @setPoint="setPoint"
                />
                <info-window-orlen-pobranie
                    v-if="getDeliveryId == 29"
                    :details="details"
                    @setPoint="setPoint"
                />
            </GmapInfoWindow>
        </GmapMap>
    </div>
</template>

<script>
/* global google */
import { mapState, mapGetters, mapMutations } from 'vuex'
import MarkerClusterer from 'marker-clusterer-plus'

import infoWindowPaczkomaty from '@components/basket/full/step2/delivery/info-window/id-3'
import infoWindowOsobisty from '@components/basket/full/step2/delivery/info-window/id-4'
import infoWindowPunktyPp from '@components/basket/full/step2/delivery/info-window/id-7'
import infoWindowPocztex from '@components/basket/full/step2/delivery/info-window/id-10'
import infoWindowOrlen from '@components/basket/full/step2/delivery/info-window/id-28'
import infoWindowOrlenPobranie from '@components/basket/full/step2/delivery/info-window/id-29'
import axios from 'axios'

export default {

    components: {
        infoWindowPaczkomaty,
        infoWindowOsobisty,
        infoWindowPunktyPp,
        infoWindowPocztex,
        infoWindowOrlen,
        infoWindowOrlenPobranie
    },

    data() {
        return {
            geoLocation: false,
            details: {},
            infoContent: '',
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            markerClusterer: null,
            //optional: offset infowindow so it visually sits nicely on top of our marker
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -50
                }
            },
            autocompleteOptions: {
                componentRestrictions: {
                    country: ['pl']
                }
            }
        }
    },

    computed: {
        ...mapState('Basket', [
            'loading', 'point', 'submited', 'deliveryId', 'loadDelivery', 'payment'
        ]),
        ...mapGetters({
            getDeliveryId: 'Basket/getDeliveryId'
        })
    },

    watch: {
        deliveryId: {
            immediate: true,
            handler() {
                this.load()
            }
        },
        payment: 'load',
        geoLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }

                    this.$refs.gmap.$mapObject.setZoom(11.2)
                    this.$refs.gmap.$mapObject.panTo(pos)
                }, () => {
                })
            }
        }
    },

    methods: {
        ...mapMutations({
            setPoint: 'Basket/setPoint'
        }),
        clearMarker() {
            this.infoWinOpen = false
        },
        setMarker(payload) {
            if (this.currentMidx == payload.i) {
                this.infoWinOpen = !this.infoWinOpen
            }
            else {
                this.currentMidx = payload.i
                axios.post('/ajax/basket/points/details', { id: this.getDeliveryId, point: payload.i, pid: this.payment })
                    .then(response => {
                        this.infoWinOpen = true
                        this.details = response.data
                    })
            }
        },
        place(payload) {
            const bounds = new google.maps.LatLngBounds()

            bounds.extend({
                lat: payload.geometry.viewport.getSouthWest().lat(),
                lng: payload.geometry.viewport.getSouthWest().lng()
            })
            bounds.extend({
                lat: payload.geometry.viewport.getNorthEast().lat(),
                lng: payload.geometry.viewport.getNorthEast().lng()
            })

            this.$refs.gmap.$mapObject.fitBounds(bounds)
        },
        load() {
            this.$nextTick(_ => {
                axios.post('/ajax/basket/points/all', { id: this.deliveryId, pid: this.payment })
                    .then(res => {
                        this.infoWinOpen = false
                        if (this.markerClusterer) {
                            this.markerClusterer.clearMarkers()
                        }
                        this.$refs.gmap.$mapObject.markers
                        const markers = res.data.items.map(p => {
                            const marker = new google.maps.Marker({
                                position: p.g,
                                icon: `/static/www/map/${this.getDeliveryId}/point.png`
                            })

                            marker.addListener('click', _ => this.setMarker(p))
                            return marker
                        })

                        if (this.point) {
                            const isPointOnNewMap = Boolean(res.data.items.find(item => item.i === this.point.i))

                            if (!isPointOnNewMap) this.setPoint()
                        }

                        this.markerClusterer = new MarkerClusterer(this.$refs.gmap.$mapObject, markers, {
                            imagePath: `/static/www/map/${this.getDeliveryId}/m`
                        })

                        this.markerClusterer.setStyles(this.markerClusterer.getStyles().map(style => {
                            style.textColor = '#fff'
                            return style
                        }))
                    })
            })
        }
    }
}
</script>
