<template>
    <div
        v-show="point && loadDelivery"
        class="form-group"
        >
        <label
            class="text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black"
            >
            {{ $ll('basketSelectedDeliveryPoint') }}
        </label>
        <delivery-point-details />
        <a
            href="#"
            class="plain-link"
            @click.prevent="setPoint()"
            >
            {{ $ll('basketSelectDifferentDeliveryPoint') }}
        </a>
    </div>
</template>

<script>
import DeliveryPointDetails from '@components/basket/full/delivery-point-details'
import { mapMutations, mapState } from 'vuex'

export default {

    components: {
        DeliveryPointDetails
    },
    computed: {
        ...mapState('Basket', [
            'point', 'loadDelivery'
        ])
    },

    methods: {
        ...mapMutations({
            setPoint: 'Basket/setPoint'
        })
    }
}
</script>
