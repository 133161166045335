var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "pt-3 pb-3 product-description-item row no-gutters" },
      [
        _c(
          "div",
          {
            staticClass:
              "col-12 col-sm-3 col-md-12 col-xxl-3 mb-md-3 mb-xxl-0 mb-sm-0 mb-3",
          },
          [
            _c("div", { staticClass: "text-nowrap product-quantity" }, [
              _c(
                "a",
                {
                  staticClass: "plain-link",
                  class: { disabled: _vm.quantity <= 1 },
                  on: {
                    click: function ($event) {
                      _vm.quantity--
                    },
                  },
                },
                [_c("i", { staticClass: "mdi mdi-minus mr-1" })]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.quantity,
                    expression: "quantity",
                  },
                  { name: "quantity-numbers", rawName: "v-quantity-numbers" },
                ],
                attrs: { type: "text", maxlength: "5" },
                domProps: { value: _vm.quantity },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.quantity = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "plain-link",
                  on: {
                    click: function ($event) {
                      _vm.quantity++
                    },
                  },
                },
                [_c("i", { staticClass: "mdi mdi-plus ml-1" })]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-10 col-sm-6 col-md-9 col-xxl-6" }, [
          _c(
            "div",
            { staticClass: "text-center ml-md-0 ml-sm-3" },
            [
              _c("btn-submit", {
                class: `btn px-3 w-100 ${
                  (_vm.availability.variants > 1 || _vm.availability.stock) &&
                  _vm.availability.available
                    ? "btn-primary"
                    : "btn-secondary"
                }`,
                attrs: {
                  disabled: _vm.buttonDisabled,
                  value:
                    (_vm.availability.variants > 1 || _vm.availability.stock) &&
                    _vm.availability.available
                      ? _vm.$ll("productCardBasketAdd")
                      : _vm.$ll("productCardAvailabilityNotify"),
                },
                on: { click: _vm.addBasket },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-2 col-xl-1 col-xxl-2" },
          [_c("product-add-cupboard")],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }