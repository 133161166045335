<template>
    <div class="buttons-container">
        <div
            class="basket__buttons"
            :class="{'fixed' : buttonFixed}"
            >
            <div :class="{'container' : buttonFixed}">
                <div class="row no-gutters justify-content-between">
                    <div
                        class="col-12 order-xl-last pl-xl-2 "
                        :class="{'col-xl-6': !buttonFixed}"
                        >
                        <button
                            class="btn btn-black order-sm-last w-100 px-3 mb-3"
                            @click.prevent="submit"
                            >
                            {{ $ll('basketSummaryButton') }}
                        </button>
                    </div>
                    <div
                        v-show="!buttonFixed"
                        class="col-12 col-xl-6 pr-xl-2"
                        >
                        <a
                            v-ll="'basketButtonReturnToShopping'"
                            :href="urlReferer"
                            class="btn btn-secondary w-100 px-3"
                            />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import PriceSingle from '@components/price'

export default {
    components: {
        PriceSingle
    },
    data() {
        return {
            buttonFixed: false
        }
    },
    computed: {
        ...mapState('Basket', ['urlReferer']),
        ...mapGetters({
            shipment: 'Basket/getTotalWithShipment'
        })
    },
    mounted() {
        this.getPosition()
    },
    created () {
        window.addEventListener('scroll', this.checkPosition)
        window.addEventListener('resize', this.checkPosition)
    },
    methods: {
        ...mapMutations({
            submit: 'Basket/setSubmit'
        }),
        getPosition() {
            const D = document
            const height = Math.max(
                D.body.scrollHeight, D.documentElement.scrollHeight,
                D.body.offsetHeight, D.documentElement.offsetHeight,
                D.body.clientHeight, D.documentElement.clientHeight
            )
            const viewport = document.documentElement.clientHeight

            if (height > viewport) {
                this.buttonFixed = true
            }
        },
        checkPosition() {
            let smallBreakpoint = false

            if (document.querySelector('.basket__container .container').offsetWidth < 721) {
                smallBreakpoint = true
            }
            const buttons = document.querySelector('.buttons-container')

            if (buttons) {
                const bottomPosition = buttons.getBoundingClientRect()

                bottomPosition.top + 40 > window.innerHeight && smallBreakpoint ? this.buttonFixed = true : this.buttonFixed = false
            }
        }
    }

}
</script>
