<template>
    <div
        class="col-8 px-0 ml-3 mt-1"
        >
        <a
            :disabled="loading"
            @click="addCupboard(index)"
            >
            <i class="i-cupboard cursor-pointer" />
        </a>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    props: {
        index: { type: Number, required: true }
    },

    computed: {
        ...mapState('ProductCupboard', ['loading'])
    },

    methods: {
        ...mapActions({
            addCupboard: 'ProductSets/addCupboard'
        })
    }
}
</script>
