var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showForm && _vm.loadDelivery
    ? _c(
        "div",
        [
          _c(
            "label",
            {
              staticClass:
                "text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black",
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$ll("basketEnterDeliveryAddress")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("not-filled", { attrs: { id: "delivery" } }),
          _vm._v(" "),
          _c("fill-list", { attrs: { data: "delivery" } }),
          _vm._v(" "),
          _vm.formVisible("delivery")
            ? _c("custom-form", {
                attrs: {
                  target: "delivery",
                  action: "/ajax/basket/address/form",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass:
                "text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black",
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$ll("basketEnterDeliveryInvoiceData")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row no-gutters" }, [
            _c(
              "div",
              { staticClass: "col-12 py-2" },
              [
                _c("custom-checkbox", {
                  attrs: { field: _vm.invoiceField },
                  on: { checked: _vm.setInvoice },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.wantInvoice
            ? [
                _c("not-filled", { attrs: { id: "invoice" } }),
                _vm._v(" "),
                _c("fill-list", { attrs: { data: "invoice" } }),
                _vm._v(" "),
                _vm.formVisible("invoice")
                  ? _c("custom-form", {
                      attrs: {
                        target: "invoice",
                        action: "/ajax/basket/address/form",
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }