var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "text-nowrap" }, [
    _c("span", { staticClass: "price" }, [
      _vm._v("\n        " + _vm._s(_vm.money) + "\n    "),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "currency" }, [
      _vm._v(
        "\n        " + _vm._s(_vm.viewCurrency || _vm.currency) + "\n    "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }