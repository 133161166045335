var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filters-collapse" },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: `toggle-${_vm.toggleId}`,
              expression: "`toggle-${toggleId}`",
            },
          ],
          staticClass: "filters-collapse__toggle btn-text",
        },
        [_vm._t("collapseTitle"), _vm._v(" "), _vm._m(0)],
        2
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticClass: "filters-collapse__body",
          attrs: { id: `toggle-${_vm.toggleId}` },
        },
        [_vm._t("body")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "custom-arrow" }, [_c("i")])
  },
]
render._withStripped = true

export { render, staticRenderFns }