<template>
    <div :class="{'required-label' : (field.rules || [] ).includes('required') && labelRequired}">
        <select
            v-if="ie"
            class="form-control form-field__select"
            @change="setValue"
            >
            <option
                v-for="val in values"
                :key="val.value"
                :value="val.value"
                >
                {{ val.label }}
            </option>
        </select>
        <v-select
            v-else
            label="label"
            :options="values"
            :name="field.name"
            :disabled="field.disabled"
            :value="values.find(v=> v.value == field.value) || field.label"
            @input="setValue"
            />
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
    components: {
        vSelect
    },
    props: [
        'field', 'data', 'target'
    ],
    data() {
        return {
            values: '',
            ie: false,
            labelRequired: true
        }
    },
    beforeMount() {
        if (navigator.appName == 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
            this.ie = true
        }
        const items = this.field.data
        let valuesArr = [
            {"label": this.field.label, "value": ''}

        ]
        Object.keys(items || {}).forEach(function(key) {
            valuesArr.push({ "label": items[key] , "value" :  key })
        })
        this.values = valuesArr
    },
    methods: {
        setValue(payload) {
            if (this.ie) {
                this.$store.commit('CustomForm/setSelect', {
                    target: this.target,
                    name: this.field.name,
                    value: payload.target.value
                })
            }else{
                this.$store.commit('CustomForm/setSelect', {
                    target: this.target,
                    name: this.field.name,
                    value: payload.value
                })
                payload.value.length === 0 ? this.labelRequired = true : this.labelRequired = false
            }
        }
    }
}
</script>
