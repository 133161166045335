window.addEventListener('load', () => {
    const longDescButtonShow = document.querySelector('.description-button-show')
    const longDescButtonHide = document.querySelector('.description-button-hide')

    if (longDescButtonShow) {
        longDescButtonShow.addEventListener('click', () => {
            longDescButtonShow.classList.add('d-none')
            longDescButtonHide.classList.remove('d-none')
            document.querySelector('.product-long-description').classList.add('open')
        })
        longDescButtonHide.addEventListener('click', () => {
            longDescButtonHide.classList.add('d-none')
            longDescButtonShow.classList.remove('d-none')
            document.querySelector('.product-long-description').classList.remove('open')
        })
    }

    const allTabs = Array.from(document.querySelectorAll('.tabs__tab'))
    const allTabContent = Array.from(document.querySelectorAll('.tabs__panel'))

    const matchAttribute = (array, attribute, thingToMatch) => array.find(item => item.getAttribute(attribute) === thingToMatch)

    if (allTabs.length && allTabContent.length) {
        allTabs.forEach(tab => {
            tab.addEventListener('click', () => {
                allTabs.forEach(item => {
                    item.classList.remove('selected')
                    item.setAttribute('aria-selected', 'false')
                })
                tab.classList.add('selected')
                tab.setAttribute('aria-selected', 'true')
                allTabContent.forEach(tabContent => tabContent.classList.add("d-none"))
                const tabToShow = tab.getAttribute('data-show')
                const selectedTab = matchAttribute(allTabContent, 'id', tabToShow)

                selectedTab.classList.remove('d-none')
            })
        })
        allTabs[0].classList.add('selected')
        allTabs[0].setAttribute('aria-selected', 'true')

        const initiallyShownTab = matchAttribute(allTabContent, 'id', allTabs[0].getAttribute('data-show'))

        initiallyShownTab.classList.remove('d-none')
    }

    const readMoreBtn = document.querySelector('.tab-content__read-more-button')

    if (readMoreBtn) {
        readMoreBtn.addEventListener('click', () => {
            readMoreBtn.classList.toggle('show-last')
        })
    }
})

