<template>
    <div id="basket-list">
        <template v-if="products.length">
            <basket-breadcrumb />
            <h1 class="text-uppercase text-center my-3 my-lg-5">
                {{ $ll('basketTitle') }}
            </h1>
            <hr class="mb-5">
            <!-- <club-info-banner v-if="login.logged" /> -->
            <div class="row">
                <div class="col-12 col-xl-8">
                    <basket-list
                        :action="true"
                        alternative
                        />
                    <gift-packing
                        v-if="giftPacking.available"
                        class="mb-3"
                        :gift-packing-id="giftPacking.id"
                        />
                </div>
                <div class="col-12 col-xl-4">
                    <div class="mt-n3">
                        <basket-discount />
                        <basket-vouchers />
                        <basket-loyalty />
                    </div>

                    <div class="mt-5">
                        <basket-summary class="mb-5" />
                        <basket-buttons />
                    </div>
                </div>
            </div>
        </template>

        <div v-else>
            <div class="h2 text-center my-5">
                {{ $ll('basketIsEmpty') }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import BasketDiscount from '@components/basket/full/step1/discount'
import BasketLoyalty from '@components/basket/full/step1/loyalty'
import BasketList from '@components/basket/full/list'
import BasketSummary from '@components/basket/full/summary'
import BasketButtons from '@components/basket/full/step1/buttons'
import BasketVouchers from '@components/basket/full/step1/voucher'
import BasketBreadcrumb from '@components/basket/full/breadcrumb'
import basketDataLayer from '@helpers/basket-dataLayer'
// import clubInfoBanner from '@components/club-info-banner'
import GiftPacking from '@components/basket/full/step1/gift-pack'

export default {

    components: {
        BasketDiscount,
        BasketLoyalty,
        BasketList,
        BasketSummary,
        BasketButtons,
        BasketVouchers,
        BasketBreadcrumb,
        // clubInfoBanner,
        GiftPacking
    },

    computed: {
        ...mapState('Basket', [
            'products', 'giftPacking'
        ]),
        ...mapState('Setup', ['login'])
    },
    mounted() {
        basketDataLayer(this.products)
    }

}
</script>

