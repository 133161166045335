import { render, staticRenderFns } from "./top-button.vue?vue&type=template&id=64ed61cd"
import script from "./top-button.vue?vue&type=script&lang=js"
export * from "./top-button.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\projekty\\mastivo\\static\\sources\\www\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64ed61cd')) {
      api.createRecord('64ed61cd', component.options)
    } else {
      api.reload('64ed61cd', component.options)
    }
    module.hot.accept("./top-button.vue?vue&type=template&id=64ed61cd", function () {
      api.rerender('64ed61cd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "scripts/components/basket/mini/top-button.vue"
export default component.exports