<template>
    <div v-if="showForm && loadDelivery">
        <label class="text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black">
            {{ $ll('basketEnterDeliveryAddress') }}
        </label>
        <not-filled id="delivery" />
        <fill-list
            data="delivery"
            />
        <custom-form
            v-if="formVisible('delivery')"
            target="delivery"
            action="/ajax/basket/address/form"
            />

        <label class="text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black">
            {{ $ll('basketEnterDeliveryInvoiceData') }}
        </label>
        <div class="row no-gutters">
            <div class="col-12 py-2">
                <custom-checkbox
                    :field="invoiceField"
                    @checked="setInvoice"
                    />
            </div>
        </div>
        <template v-if="wantInvoice">
            <not-filled id="invoice" />
            <fill-list data="invoice" />
            <custom-form
                v-if="formVisible('invoice')"
                target="invoice"
                action="/ajax/basket/address/form"
                />
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import CustomForm from '@components/custom-form/items'
import CustomCheckbox from '@components/custom-form/fields/checkbox'
import FillList from '@components/basket/full/step2/form-data/fill-list'
import NotFilled from '@components/basket/full/step2/form-data/not-filled'

export default {
    components: {
        CustomForm,
        CustomCheckbox,
        FillList,
        NotFilled
    },
    computed: {
        ...mapState('Basket', [
            'addresses', 'addressIndex', 'payment', 'editForm', 'wantInvoice', 'loadDelivery'
        ]),
        ...mapGetters({
            showForm: 'Basket/showForm',
            errors: 'CustomForm/errorsFound'
        }),
        invoiceField() {
            return {
                fieldType: 'checkbox',
                value: this.wantInvoice && 't',
                name: 'wantInvoice',
                label: this.$ll('basketIWantInvoice')
            }
        }
    },
    methods: {
        ...mapActions({
            checkOrder: 'Basket/checkOrder'
        }),
        ...mapMutations({
            setInvoice: 'Basket/setInvoice'
        }),
        formVisible(id) {
            return this.showForm && (this.addresses[id].length && this.addressIndex[id] === 'own' || !this.addresses[id].length || this.errors(id) || this.editForm[id])
        }
    }
}
</script>
