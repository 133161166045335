import json from '@db'
import axios from 'axios'
import router from '@/router'
import langUrl from '@/helpers/lang-url'

/**
 * @description Przygotowanie listy obiektów do wysłania do backendu zawierające dane potrzebne do usuniecia ze schowka lub dodania do koszyka
 * @param {*} state
 * @param {*} payload opcjonalny obiekt z widoku (jeden item)
 * @returns {Array} tablica itemów
 */
const prepareItemsFromCupboard = (state, payload) => {
    const { cupboard_id = 0, variant: { variant = 0, quantity = 0, id = '' } = {} } = payload

    return variant ? [{ cupboard_id, variant, quantity, id }] : state.select
}

export default {
    namespaced: true,
    state() {
        return {
            id: (json.cupboards.find(item => item.isDefault) || {}).cupboardId || 0,
            list: json.cupboards,
            products: json.cupboard_product || [],
            name: '',
            loading: false,

            //tylko dla modali
            modal: false,
            response: {},

            //lista schowkow - edycja
            cupboardId: null,
            select: []
        }
    },
    getters: {
        /**
         * @description Lista dostepnych schowków (dla popovera)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @returns {Array}
         */
        getList(state) {
            return state.list.map(item => ({
                text: item.cupboardName,
                value: item.cupboardId,
                products: item.products
            }))
        },

        /**
         * @description Pobieranie danych schowka po jego ID
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @returns {Object} obiekt schowka
         */
        getCupboardData(state) {
            return id => state.list.find(item => item.cupboardId == id)
        },

        /**
         * @description Nazwa aktualnie wybranego schowka
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @returns {String}
         */
        getSetName(state) {
            return (state.list.find(item => item.cupboardId == state.id) || {}).cupboardName
        },

        /**
         * @description Zwraca sumę produktów (ich wersji) we wszystkich dostepnych schowkach (wyswietlana na gorze)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @returns {Number}
         */
        getItemCount(state) {
            return state.list.reduce((count, item) => count += item.products.length, 0)
        }
    },
    mutations: {

        /**
         * @description ID aktualnie wybranego schowka
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {*} payload id schowka
         */
        setId(state, payload) {
            state.id = payload
            state.cupboardId = payload
        },

        /**
         * @description Podmienia CAŁĄ listę dostepnych schowków
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {*} payload zwrotka z axios
         */
        setCupboards(state, payload) {
            const { data: { cupboards = [], status = false, added_cupboard_id: id = null } = {} } = payload

            // console.log(cupboards, status)
            if (status) {
                state.list = cupboards
                state.id = id
            }
        },

        /**
         * @description Nazwa wpisywana przez uzytkownika do dodania nowego schowka (aktualizowana z inputa)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {String} payload
         */
        setName(state, payload) {
            state.name = payload
        },

        /**
         * @description Ustawia loading (trwa request axios)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Boolean} payload
         */
        setLoading(state, payload) {
            state.loading = payload
        },

        /**
         * @description Czy zostało wywołane w modalu
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Boolean} payload
         */
        setModal(state, payload) {
            state.modal = payload
        },

        /**
         * @description Ustawia odpowiedz z serwera dla modala (wyswietla wewnetrzny alert)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {*} payload zwrotka z axios
         */
        setResponse(state, payload) {
            const { data: { message = '', status = false } = {} } = payload

            state.response = {
                message,
                status
            }
        },

        /**
         * @description Zmienia listę wyswietlanych produktow na stronie schowka (resetowane są zaznaczenia)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Array} payload response ajax
         */
        setProducts(state, payload) {
            state.products = payload
            state.select = []
        },

        /**
         * @description Manipulacja tablica zaznaczonych produktów na widoku schowka (dodawanie/usuwanie)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload dane z komponentu { set, item }
         */
        setSelect(state, payload) {
            const { set = false, item: { cupboard_id = 0, variant: { variant = 0, quantity = 1, id = '' } = {} } = {} } = payload

            if (set) {
                state.select.push({ cupboard_id, variant, quantity, id })
            }
            else {
                const index = state.select.findIndex(i => i.cupboard_id == cupboard_id && i.variant == variant && i.quantity == quantity)

                state.select.splice(index, 1)
            }
        },

        /**
         * @description Dodaje produkt do schowka
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload response z ajax
         */
        addProduct(state, payload) {
            const { data: { cupboard: { cupboardId = 0 } = {}, item } = {} } = payload

            if (!item) return

            const cupboard = state.list.find(i => i.cupboardId == cupboardId)

            cupboard.products.push(item)
        },

        /**
         * @description Usuwa produkt ze schowka
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload response z ajax
         */
        removeProduct(state, payload) {
            const { data: { cupboards } = {} } = payload

            state.list = cupboards
        },

        /**
         * @description Usuwa z aktualnego widoku produktów te o podanych parametrach
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload dane z state.select lub pojedynczy widok
         */
        deleteProducts(state, payload) {
            payload.forEach(item => {
                const index = state.products.findIndex(product => product.cupboard_id == item.cupboard_id && product.variant.variant == item.variant)

                state.products.splice(index, 1)

                const sIndex = state.select.findIndex(select => select.cupboard_id == item.cupboard_id && select.variant == item.variant)

                state.select.splice(sIndex, 1)
            })
        }
    },
    actions: {

        /**
         * @description Dodaj produkt w odpowiednim wariancie do schowka
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {*} payload { obiekt wariantu }
         */
        add({ dispatch, state, commit }, payload) {
            const { variant_id = 0 } = payload

            commit('setLoading', true)
            axios.post('/ajax/cupboard/products/add', { id: variant_id, cupboard_id: state.id })
                .then(res => {
                    dispatch('response', res)
                    commit('addProduct', res)
                })
                .then(_ => commit('setLoading', false))
        },

        /**
         * @description Usun produkt w odpowiednim wariancie ze schowka
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {*} payload { obiekt wariantu }
         */
        delete({ dispatch, state, commit }, payload) {
            const { variant_id = 0 } = payload

            commit('setLoading', true)
            axios.post('/ajax/cupboard/products/remove', { id: variant_id, cupboard_id: state.id })
                .then(res => {
                    dispatch('response', res)
                    commit('removeProduct', res)
                })
                .then(_ => commit('setLoading', false))
        },

        /**
         * @description Dodajemy nowy schowek
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        newCupboard({ state, dispatch, commit }) {
            commit('setLoading', true)
            axios.post('/ajax/cupboard/add', { name: state.name })
                .then(res => {
                    dispatch('response', res)
                    commit('setCupboards', res)
                })
                .then(_ => {
                    commit('setLoading', false)
                    commit('setName', '')
                })
        },

        /**
         * @description Usunięcie aktualnie wybranego schowka
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        removeCupboard({ state, dispatch, commit, rootGetters, rootState }) {
            commit('setLoading', true)
            axios.post('/ajax/cupboard/remove', { id: state.cupboardId })
                .then(res => {
                    dispatch('response', res)
                    commit('setCupboards', res)
                })
                .then(_ => {
                    commit('setLoading', false)
                    commit('setName', '')
                })
                .finally(_ => router.push({ path: langUrl(rootState.Setup.default_language_iso, rootGetters['Setup/language'], '/myAccount/cupboard')}))
        },

        /**
         * @description Sprawdza czy jest wywołany modal(lista na kategorii) czy inline(karta produktu) i wyswietla w odpowiednim miejscu message od php
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {*} payload obiekt zwrotki z axios
         */
        response({ state, dispatch, commit }, payload) {
            if (!state.modal) {
                dispatch('Notifications/addResponse', payload, { root: true })
            }
            else {
                commit('setResponse', payload)
            }
        },

        /**
         * @description Zmiana widocznego schowka (reset produktow + sciagniecie nowych danych)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Integer} payload id schowka
         */
        setCupboard({ state, commit }, payload) {
            state.cupboardId = payload
            commit('setLoading', true)

            axios.get(`/ajax/cupboard/index/${payload}`)
                .then(res => {
                    const { data: { data = []} = {} } = res

                    commit('setProducts', data)
                    commit('setLoading', false)
                })
        },

        /**
         * @description Wysyla do PHP produkty dodane do koszyka
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload dane z widoku (pojedynczy obiekt) - dane opcjonalne
         */
        addBasket({ commit, state, dispatch }, payload = {}) {
            const items = prepareItemsFromCupboard(state, payload)

            axios.post('/ajax/basket/addFromCupboard', { items, delete: true })
                .then(res => {
                    const { data: { items = [] } = {} } = res

                    commit('deleteProducts', items)
                    commit('Basket/update', res, { root: true })
                    commit('setCupboards', res)
                    dispatch('Notifications/addResponse', res, { root: true })
                })
        },

        /**
         * @description Usuwamy produkty ze schowka bez dodawania do koszyka
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload dane z widoku (pojedynczy obiekt) - dane opcjonalne
         */
        deleteProducts({ commit, state, dispatch }, payload = {}) {
            const items = prepareItemsFromCupboard(state, payload)

            axios.post('/ajax/cupboard/products/delete', { items })
                .then(res => {
                    const { data: { items = [] } = {} } = res

                    commit('deleteProducts', items)
                    commit('setCupboards', res)
                    dispatch('Notifications/addResponse', res, { root: true })
                })
        },

        setDefault({ commit, dispatch }, payload) {
            axios.post(`/ajax/cupboard/setDefault/${payload}`)
                .then(data => {
                    commit('setCupboards', data)
                    dispatch('Notifications/addResponse', data, { root: true })
                })
        }
    }
}