var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filters-toggle" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-secondary",
        attrs: { type: "button" },
        on: { click: _vm.toggle },
      },
      [
        _c("span", {
          domProps: {
            textContent: _vm._s(_vm.$ll("categoryFiltersShowModal")),
          },
        }),
        _vm._v(" "),
        _c("img", {
          attrs: {
            src: "/static/www/images/filter-icon.png",
            alt: _vm.$ll("categoryFiltersShowModal"),
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }