var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c("span", { domProps: { textContent: _vm._s(_vm.$ll("productCode")) } }),
    _vm._v(": "),
    _c("span", {
      domProps: {
        textContent: _vm._s(
          _vm.chosenCode.length > 0 ? _vm.chosenCode : _vm.initial
        ),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }