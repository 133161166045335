<template>
    <div>
        <basket-modal
            :open="basketModal !== false"
            :variant="variantBasket[0]"
            :photo-path="products[basketModal || 0].thumbnail"
            @hidden="basketModal = false"
            />
        <basket-availability-notify
            v-if="!availability.stock || !availability.available"
            :open="outOfStock !== false"
            :variant="products[outOfStock || 0].variants[0]"
            @hidden="outOfStock = false"
            />
        <basket-out-of-stock
            v-else
            :open="outOfStock !== false"
            @hidden="outOfStock = false"
            />
    </div>
</template>

<script>
import BasketModal from './modals/add-basket'
import BasketOutOfStock from './modals/out-of-stock'
import BasketAvailabilityNotify from './modals/availability-notify-set'
import { mapGetters, mapState } from 'vuex'

export default {
    components: {
        BasketModal,
        BasketAvailabilityNotify,
        BasketOutOfStock
    },
    computed: {
        basketModal: {
            get() {
                return this.$store.state.ProductSets.basketModal
            },
            set(payload) {
                this.$store.state.ProductSets.basketModal = payload
            }
        },
        outOfStock: {
            get() {
                return this.$store.state.ProductSets.outOfStock
            },
            set(payload) {
                this.$store.commit('ProductSets/outOfStock', payload)
            }
        },
        ...mapState('ProductSets', ['products']),
        ...mapGetters({
            availabilityList: 'ProductSets/availability',
            chosen: 'ProductSets/getChosen',
            variantsList: 'ProductSets/variants',
            photoList: 'ProductPhotos/list'
        }),
        variantBasket() {
            return this.variantsList(this.basketModal || 0, this.chosen(this.basketModal || 0))
        },
        availability() {
            return this.availabilityList(this.outOfStock)
        },
        variantOutOfStock() {
            return this.variantsList(this.outOfStock, this.basketModal || 0)
        }

    }
}
</script>
