var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group mt-2 mb-2 float-right toggle-view" },
    [
      _c(
        "div",
        {
          staticClass: "toggle-view-btn",
          class: { active: !_vm.cols, "cursor-pointer": _vm.cols },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.setCols(false)
            },
          },
        },
        [
          _c("i", { staticClass: "i-grid-2 d-none d-sm-inline-block" }),
          _vm._v(" "),
          _c("i", {
            staticClass: "mdi mdi-square-outline d-sm-none",
            staticStyle: { "font-size": "18px" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "toggle-view-btn",
          class: { active: _vm.cols, "cursor-pointer": !_vm.cols },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.setCols(true)
            },
          },
        },
        [
          _c("i", { staticClass: "i-grid-3 d-none d-sm-inline-block" }),
          _vm._v(" "),
          _c("i", { staticClass: "i-grid-2 d-sm-none" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }