var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "list-unstyled" },
    _vm._l(_vm.variants()[0].features_list, function (item, filterId) {
      return _c("li", { key: item.variant_attribute_id }, [
        _vm._v("\n        " + _vm._s(item.attribute_name) + ": \n        "),
        _c("strong", [
          item.is_filterable
            ? _c("a", {
                attrs: {
                  href: _vm.$langUrl(
                    `/${_vm.category}?filters=${filterId}[${item.attribute_value_id}]`
                  ),
                },
                domProps: { textContent: _vm._s(item.value) },
              })
            : _c("span", { domProps: { textContent: _vm._s(item.value) } }),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }