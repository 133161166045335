<template>
    <div class="modal-category-preview">
        <b-modal
            v-model="modal"
            :title="cupboard ? $ll('previewWishlistTitle') : product.name"
            :class="{'wishlist-modal': cupboard}"
            :hide-footer="!loaded"
            centered
            :cancel-title="$ll('basketBackToShoppingButton')"
            :ok-title="cupboard ? $ll('cupboardAddButton') : ((availability.variants > 1 || availability.stock) && availability.available) ? $ll('productCardBasketAdd') : $ll('productCardAvailabilityNotify')"
            @ok="ok"
            >
            <div
                v-if="loaded"
                class="container product-card"
                style="text-align: center"
                >
                <product-add-cupboard
                    v-if="cupboard"
                    :button="false"
                    />
                <!-- dla zmiany tekstu w alercie: availability.stock ? 'wszystko w koszyku' : 'powiadom o dostepn' -->
                <b-alert
                    :show="outOfStock"
                    variant="danger"
                    v-text="$ll('productBasketModalOutOfStockTitle')"
                    />
                <b-alert
                    :show="basketAdd"
                    variant="success"
                    v-text="$ll('productBasketModalTitle')"
                    />
                <b-alert
                    :show="!!response.message"
                    :variant="response.status ? 'success' : 'danger'"
                    v-text="response.message"
                    />
                <div
                    v-if="cupboard"
                    class="row"
                    >
                    <div class="col-12">
                        <h4
                            class="text-sm-left mt-2 mb-4"
                            v-text="product.name"
                            />
                    </div>
                </div>
                <div class="row product-preview">
                    <div
                        class="col-12 col-sm-4 pl-sm-3 px-0"
                        >
                        <div class="img-container">
                            <span
                                v-lazy:background-image="`/${$photoUrl(photo, 520)}`"
                                class="product-item__image position-relative"
                                >
                                <Picture
                                    :src="`/${$photoUrl(photo, 520)}`"
                                />
                            </span>
                        </div>
                    </div>
                    <div
                        class="col-12 col-sm-8 px-0"
                        >
                        <product-card-price />
                        <product-attr />
                    </div>
                </div>
            </div>
            <spin-loader
                v-else
                :p="5"
                />
        </b-modal>
        <basket-availability-notify v-if="loaded && (!availability.stock || !availability.available)" />
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import BasketAvailabilityNotify from '@components/product/add-basket-modals/modals/availability-notify'
import ProductAddCupboard from '@components/product/cupboard/button'
import ProductCardPrice from '@components/product/price'
import ProductAttr from '@components/product-attr'

export default {
    components: {
        BasketAvailabilityNotify,
        ProductAddCupboard,
        ProductCardPrice,
        ProductAttr
    },
    computed: {
        ...mapState('ProductPreview', [
            'id', 'product', 'loaded', 'cupboard'
        ]),
        ...mapState('ProductCard', [
            'outOfStock', 'chosen', 'paramsWarn'
        ]),
        ...mapState('ProductCupboard', ['response']),
        ...mapGetters({
            availability: 'ProductCard/availability'
        }),
        ...mapState('Setup', ['login']),

        modal: {
            get() {
                return !!this.id
            },
            set(v) {
                if (!v) this.$store.commit('ProductPreview/setID', null)
            }
        },
        photo: {
            get() {
                if (!this.product.photos) return ''

                const id = Object.keys(this.product.photos).find(i => this.product.photos[i].main)

                if (!id) return ''

                return this.product.photos[id].path
            }
        },
        basketAdd: {
            get() {
                return this.$store.state.ProductCard.basketModal
            },
            set(payload) {
                this.$store.commit('ProductCard/setBasket', payload)
            }
        }
    },
    watch: {
        modal() {
            this.$store.commit('ProductCupboard/setResponse', {})
            this.$store.dispatch('ProductPreview/load')
        },
        chosen() {
            this.$store.commit('ProductCard/outOfStock', false)
            this.$store.commit('ProductCard/setBasket', false)
        }
    },
    methods: {
        ...mapActions({
            addBasket: 'ProductCard/addBasket',
            checkAddCupboard: 'ProductCard/checkAddCupboard'
        }),
        ok(e) {
            e.preventDefault()

            if (this.cupboard) {
                this.$store.commit('ProductCupboard/setModal', true)
                    this.$store.dispatch('ProductCard/addCupboard')
            }
            else {
                this.basketAdd = false
                this.addBasket()
            }
        }
    }
}
</script>
