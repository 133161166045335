import BtnSubmit from '@components/btn-submit'
import UniqueID from '@helpers/unique-id'
import { mapState } from 'vuex'

export default {

    components: {
        BtnSubmit
    },

    props: {
        target: { type: String, required: true },
        action: { type: String, required: true },
        clearAfter: { type: Boolean, default: true }
    },

    data() {
        return {
            uid: UniqueID.generate(),
            boolHidding: {}
        }
    },

    created() {
        this.$store.commit('CustomForm/setFormAction', {
            target: this.target,
            action: this.action
        })

        if (!this.clearAfter) {
            this.$store.commit('CustomForm/disableClear', this.target)
        }
    },

    computed: {
        fields() {
            return this.$store.getters['CustomForm/fields'](this.target)
        },
        data() {
            return this.$store.getters['CustomForm/data'](this.target)
        },
        ...mapState('CustomForm', ['last'])
    },
    methods: {
        submit() {
            this.$refs.submit.click()
        },
        dispatch() {
            this.$store.dispatch('CustomForm/submit', this.target)
        },
        changeSelect(field, payload) {
            field.value = payload
        },
        toggleCheckbox(field) {
            field.value = field.value === 't' ? '' : 't'
        },
        toogleBoolHidding(field) {
            this.toggleCheckbox(field)
            this.$store.commit('CustomForm/toggleBoolHidding', {
                target: this.target,
                field
            })
        }
    },

    watch: {
        last() {
            if (this.last.target === this.target) {
                this.$emit('submitted')
            }
        }
    }
}