var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 stars-bars" },
    _vm._l(_vm.arrRating, function (value, key) {
      return _c("div", { key: key }, [
        _c("div", { staticClass: "mb-1 d-flex" }, [
          _c(
            "span",
            { staticClass: "d-inline-block text-left w-20" },
            [
              _c("span", {
                staticClass: "d-inline-block align-sub",
                staticStyle: { width: "1ch" },
                domProps: { textContent: _vm._s(value.stars) },
              }),
              _vm._v(" "),
              _c("star-rating", {
                attrs: {
                  rating: 1,
                  increment: 1,
                  "read-only": true,
                  "max-rating": 1,
                  "star-size": 12,
                  "show-rating": false,
                  "active-color": "#d40d1b",
                  "border-color": "#d40d1b",
                  "border-width": 2,
                  "rounded-corners": true,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "d-inline-block w-80 pl-2" },
            [
              _c("b-progress", {
                attrs: { value: value.opinions, max: _vm.maxOpinions },
              }),
            ],
            1
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }