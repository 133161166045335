<template>
    <ul
        v-show="showLanguage"
        class="nav lang-nav"
        >
        <li
            v-for="( lang, id) in languages"
            :key="id"
            class="nav-item"
            :class="{ active: current == id }"
            >
            <a
                :disabled="lang.iso == $route.params.lang"
                :href="lang.url || `/${lang.iso}`"
                :data="langPicked(current)"
                v-text="lang.name"
                />
        </li>
    </ul>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            showLanguage: false
        }
    },
    methods: {
        langPicked(lang) {
            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage(lang)
            }
        }
    },
    computed: {
        ...mapState('Setup', {
            current: state => state.language.current,
            languages: state => state.language.languages
        })
    },
    beforeMount() {
        Object.keys(this.languages).length > 1 ? this.showLanguage = true : this.showLanguage = false
    }
}
</script>
