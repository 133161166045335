<template>
    <b-modal
        :visible="open"
        :title="$ll('productBasketModalTitle')"
        centered
        :ok-title="$ll('basketGoToBasketButton')"
        :cancel-title="$ll('basketBackToShoppingButton')"
        @cancel="$emit('cancel')"
        @hidden="$emit('hidden')"
        @ok="hrefBasket"
        >
        <div class="d-flex text-left">
            <div class="row modal-basket-product">
                <div class="col-4 col-sm-3">
                    <Picture :src="$photoUrl(photoPath, 270)" />
                </div>
                <div class="col-8 col-sm-9">
                    <h2>
                        {{ variant.product_name }}
                    </h2>
                    <ul class="list-unstyled my-4">
                        <li
                            v-for="item in variant.attributes"
                            :key="item.attribute_value_id"
                            >
                            <strong>{{ item.attribute_name }}</strong>:
                            {{ item.value }}
                        </li>
                    </ul>
                    <p>
                        <price :price="variant.prices[ currency ]" />
                    </p>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import Price from '@components/product/price'
import { mapGetters } from 'vuex'

export default {

    components: {
        Price
    },

    props: {
        open: { type: Boolean, default: false },
        variant: { type: Object, required: true },
        photoPath: { type: String, required: true }
    },

    computed: {
        ...mapGetters({
            currency: 'Setup/currencyId'
        })
    },

    methods: {
        hrefBasket() {
            location.href = this.$langUrl('/basket')
        }
    }
}
</script>
