<template>
    <div>
        {{ $ll('basketOrderCountrySummary') }}: <strong v-text="countries.name" />
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('Basket', {
            countries: state => state.countries.items.find(i => i.id == state.countries.selected)
        })
    }
}
</script>
