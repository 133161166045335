var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.addresses[_vm.data].length
    ? _c("div", { staticClass: "form-group adresses-form-group" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm._l(_vm.addresses[_vm.data], function (item, id) {
              return _c(
                "div",
                { key: id, staticClass: "col-12 col-sm-6 btn-group" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "btn-outline-primary text-center position-relative",
                      class: { active: id === _vm.addressIndex[_vm.data] },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setAddressIndex({ data: _vm.data, id })
                        },
                      },
                    },
                    [
                      _vm.data == "delivery"
                        ? _c("list-address", { attrs: { data: item } })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.data == "invoice"
                        ? _c("list-invoice", { attrs: { data: item } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("a", {
                        staticClass: "plain-link p-1 m-0 position-absolute",
                        class: { visible: _vm.addressIndex[_vm.data] == id },
                        attrs: { href: "#" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$ll("basketLoggedAddressesEditBtn")
                          ),
                        },
                        on: { click: _vm.editCurrentAddresHandler },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-6 btn-group" }, [
              _c(
                "div",
                {
                  staticClass: "btn-outline-primary p-2 text-center",
                  class: { active: "own" === _vm.addressIndex[_vm.data] },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addNewAddresHandler.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$ll("basketLoggedAddressesOtherAddress")) +
                        "...\n                "
                    ),
                  ]),
                ]
              ),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c("label", { ref: "form" }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }