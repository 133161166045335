export default (products) => {
    window.dataLayer = window.dataLayer || []

    let searchProductsID = []
    let searchProductsSKU = []
    for (var i = 0; i < products.length; i++) {
        searchProductsID.push(products[i].product_id)
        searchProductsSKU.push(products[i].code)
    }


    window.dataLayer.push({
        'searchProductsID': searchProductsID,
        'searchProductsSKU': searchProductsSKU
    })
}