import json from '@db'
import axios from 'axios'
import router from '@/router'

const langIso = json.setup.language.languages[ json.setup.language.current ].iso.toLowerCase().trim()
const loginToDefault = langIso === json.setup.default_language_iso ? '/' : `/${langIso}`

export default {
    namespaced: true,
    state() {
        const { setup: { login }, socialNetworkProviders = {} } = json

        return {
            ...json.setup,
            login: {
                ...login,
                from: '',
                basketNotLogged: false,
                busy: false,
                to: loginToDefault,
                defaultUrl: loginToDefault
            },
            modalLogin: null,
            socialNetworkProviders
        }
    },
    getters: {

        currencyId(state) {
            return state.currency.current
        },

        currency(state) {
            return state.currency.symbol
        },

        languageId(state) {
            return state.language.current
        },

        language(state) {
            return state.language.languages[ state.language.current ].iso
        },
        langUrl(state, getters) {
            return url => `/${getters.language}${url}`
        }
    },

    mutations: {
        loginFrom(state, payload) {
            if (!(payload || '').includes('/access/login')) {
                state.login.from = payload
            }
        },
        loginTo(state, payload) {
            state.login.to = payload
        },
        setGuest(state) {
            state.login.basketNotLogged = true
            router.push({ path: state.login.to })
            // state.login.to = loginToDefault
        },
        setLang(state, payload) {
            const langKeys = Object.keys(state.language.languages)

            langKeys.forEach(key => {
                if (state.language.languages[ key ].iso === payload) {
                    state.language.current = key
                }
            })
        }
    },

    actions: {
        login({ commit, dispatch, state }, payload) {
            state.login.busy = true

            axios.post('/ajax/access', payload)
                .then(response => {
                    state.login.busy = false
                    state.login.logged = response.data.status

                    if (response.data.message) {
                        dispatch('Notifications/addResponse', response, { root: true })
                    }

                    if (response.data.status) {
                        if (response.data.redirect) {
                            location.href = response.data.redirect
                            return
                        }
                        if (state.login.to === loginToDefault) {
                            location.href = state.login.to
                        }
                        else {
                            router.push({ path: state.login.to })
                        }

                        commit('Basket/setAddresses', response.data.data.addresses, { root: true })
                        state.login.to = loginToDefault
                        state.login.name = `${response.data.data.login.forename} ${response.data.data.login.surname}`
                    }

                    if (response.data.modalLogin) {
                        state.modalLogin = response.data.modalLogin
                    }
                })
        },

        logout({ dispatch, state }) {
            axios.post('/ajax/logout')
                .then(response => {
                    state.login.logged = !response.data.status
                    dispatch('Notifications/addResponse', response, { root: true })
                    location.href = response.data.redirect || '/'
                })
        }
    }
}
