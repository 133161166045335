export default (array, value, set) => {
    if (set) {
        const index = array.indexOf(value)

        if (index === -1) array.push(value)
    }
    else {
        const index = array.indexOf(value)

        if (index !== -1) array.splice(index, 1)
    }
}