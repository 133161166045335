import json from '@db'
import Vue from 'vue'
import axios from 'axios'

const dataPrepare = attr => {
    const chosen = {}

    attr.forEach(item => {
        //dodajemy wylacznik dla kazdego elementu VALUE
        item.values.forEach(value => {
            value.disabled = false
        })

        //tworzymy obiekt chosen dla zaznaczen
        chosen[ item.id ] = null
    })

    return { chosen, attr }
}

const
    attrList = [],
    btnDisabled = [],
    chosenList = [],
    lastAttr = [],
    paramsWarn = [],
    products = json.productSets || [],
    quantity = []

products.forEach(product => {
    const { chosen, attr } = dataPrepare(product.attributes)

    attrList.push(attr)
    btnDisabled.push(false)
    chosenList.push(chosen)
    lastAttr.push([])
    paramsWarn.push(false)
    quantity.push(1)
})

export default {
    namespaced: true,
    state() {
        return {
            attrList,
            btnDisabled,
            chosenList,
            lastAttr,
            paramsWarn,
            products,
            quantity,
            outOfStock: false,
            basketModal: false
        }
    },
    getters: {
        getAttr(state) {
            return index => state.attrList[index]
        },
        getChosen(state) {
            return index => state.chosenList[index]
        },
        getParamsWarn(state) {
            return index => state.paramsWarn[index]
        },
        getBtnDisabled(state) {
            return index => state.btnDisabled[index]
        },
        variants(state) {
            return (index, chosen) =>
                state.products[index].variants.filter(v => {
                    for (const attr in v.attributes) {
                        if (chosen[attr] && v.attributes[attr].attribute_value_id != chosen[attr]) return false
                    }
                    return true
                })
        },
        productSet(state) {
            return index => Object.keys(state.chosenList[index]).every(i => state.chosenList[index][ i ] !== null)
        },
        getPrice(_state, getters, _rootState, rootGetters) {
            return (index) => {
                const chosen = getters.getChosen(index)
                const prices = getters
                    .variants(index, chosen)
                    .map(v => {
                        const obj = v.prices[ rootGetters['Setup/currencyId'] ]

                        return {
                            current: obj.promo_brutto ? obj.promo_brutto : obj.brutto,
                            old: obj.brutto
                        }
                    })
                    .sort((a, b) => a.current > b.current ? 1 : -1)

                return {
                    set: getters.productSet(index),
                    ...prices.shift()
                }
            }
        },
        availability(_state, getters, rootState) {
            return (index) => {
                const chosen = getters.getChosen(index || 0)
                const variant = getters.variants(index || 0, chosen)

                return {
                    variants: variant.length,
                    stock: (variant[0] || {}).stock - (variant[0] || {}).blocked_stock,
                    inBasket: (rootState.Basket.products.find(p => p.variant == (variant[0] || {}).variant_id) || {}).quantity || 0,
                    available: (variant[0] || {}).available
                }
            }
        }
    },
    mutations: {
        setAttr(state, payload) {
            const { index, attributes, chosen, lastAttr } = payload

            Vue.set(state.attrList, index, attributes)
            Vue.set(state.chosenList, index, chosen)
            Vue.set(state.lastAttr, index, lastAttr)
        },
        outOfStock(state, payload) {
            state.outOfStock = payload
        },
        setBtnDisabled(state, payload) {
            const { index, value } = payload

            Vue.set(state.btnDisabled, index, value)
        }
    },
    actions: {
        setAttr({ dispatch, state, getters }, payload) {
            const { index, id, value } = payload

            const chosen = JSON.parse(JSON.stringify(getters.getChosen(index)))
            let lastAttr = JSON.parse(JSON.stringify(state.lastAttr[index]))

            chosen[ id ] = chosen[ id ] === value ? null : value

            // historia zmian atrybutow - ostatnia zmiana na koncu
            lastAttr = lastAttr.filter(i => i !== id)
            lastAttr.push(id)

            dispatch('setDisabledNotify', { index, chosen, lastAttr })
        },
        setDisabledNotify({ commit, state, getters }, payload = {}) {
            //kopia stanu dla zmiennych domyslnych (jezeli nie przyszly z setAttr)
            const index = payload.index
            let chosen,
                lastAttr

            const attributes = JSON.parse(JSON.stringify(getters.getAttr(index)))

            if (payload.chosen) {
                chosen = payload.chosen
                lastAttr = payload.lastAttr
            }
            else {
                chosen = JSON.parse(JSON.stringify(getters.getChosen(index)))
                lastAttr = JSON.parse(JSON.stringify(state.lastAttr[index]))
            }

            let available = getters.variants(index, chosen)

            //jezeli liczba wariantow == 0, odznaczaj poszczegolne atrybuty az do uzyskania min. 1 dostepnego wariantu
            const undo = lastAttr.slice()

            while (!available.length && undo.length) {
                chosen[ undo.shift() ] = null
                available = getters.variants(index, chosen)
            }
            //close all
            attributes.forEach(attr => {
                attr.values.forEach(val => {
                    val.disabled = true
                    val.notifyIco = true
                })
            })

            //open avail
            available.forEach(v => {
                Object.entries(v.attributes).forEach(([
                    id, {attribute_value_id: value}
                ]) => {
                    attributes.find(a => a.id == id).values.find(v => v.id == value).disabled = false

                    if (v.stock - v.blocked_stock && v.available) {
                        attributes.find(a => a.id == id).values.find(v => v.id == value).notifyIco = false
                    }
                })
            })

            //oznacz mozliwe do zaznaczenia przy zmianie tego samego wariantu
            Object.keys(chosen).forEach(i => {
                if (chosen[i] !== null) {
                    const tmp = getters.variants(index, { ...chosen, [i]: null })

                    tmp.forEach(v => {
                        const {
                            attributes: {
                                [i]: atrs = {}
                            } = {}
                        } = v

                        const state_attr = attributes.find(a => a.id == i).values.find(v => v.id == atrs.attribute_value_id)

                        if (typeof state_attr === 'object' && state_attr.hasOwnProperty('disabled')) {
                            attributes.find(a => a.id == i).values.find(v => v.id == atrs.attribute_value_id).disabled = false

                            //sprawdzenie czy atrybut jest dostepny
                            if (v.stock - v.blocked_stock && v.available && !Object.entries(chosen).every(([
                                ch_k, ch_v
                            ]) => v.attributes[ch_k].attribute_value_id === ch_v)) {
                                attributes.find(a => a.id == i).values.find(v => v.id == atrs.attribute_value_id).notifyIco = false
                            }
                        }
                    })
                }
            })

            commit('setAttr', {
                index,
                attributes,
                chosen,
                lastAttr
            })

            //odznacz warning tylko jezeli jzu byl wczesniej oznaczony jako TRUE
            if (state.paramsWarn[index]) {
                state.paramsWarn[index] = !getters.productSet(index)
            }
        },

        addBasket({ state, getters, commit, dispatch }, payload) {
            Vue.set(state.paramsWarn, payload, !getters.productSet(payload))
            if (state.paramsWarn[[payload]]) {
                return
            }

            const chosen = getters.getChosen(payload)
            const variant = getters.variants(payload, chosen)[0]

            if (!(variant.stock - variant.blocked_stock)) {
                commit('outOfStock', payload)
                return
            }

            commit('setBtnDisabled', {index: payload, value: true})

            axios
                .post('/ajax/basket/add', {
                    id: variant.variant_id,
                    quantity: state.quantity[payload]
                })
                .then(response => {
                    if (response.data.status) {
                        state.basketModal = payload
                        window.dataLayer.push({
                            event: "addToCartSet",
                            productSetId: variant.product_id
                        })
                    }
                    else {
                        commit('outOfStock', payload)
                    }

                    commit('Basket/update', response, { root: true })
                })
                .catch(error => {
                    dispatch('Notifications/addResponse', error, { root: true })
                })
                .then(() => commit('setBtnDisabled', {index: payload, value: false}))
        },

        addCupboard({ state, getters, dispatch }, payload) {
            Vue.set(state.paramsWarn, payload, !getters.productSet(payload))
            if (state.paramsWarn[[payload]]) {
                return
            }

            const chosen = getters.getChosen(payload)
            const variant = getters.variants(payload, chosen)[0]

            window.dataLayer.push({
                event: "addToCupboard",
                addToCupboardId: variant.product_id
            })

            dispatch('ProductCupboard/add', variant, {root: true})
        }
    }
}