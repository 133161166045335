var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.price.promo_brutto
        ? [
            _c(
              "strong",
              { staticClass: "text-primary" },
              [_c("price", { attrs: { value: _vm.price.promo_brutto } })],
              1
            ),
            _vm._v(" "),
            _c("s", [_c("price", { attrs: { value: _vm.price.brutto } })], 1),
          ]
        : _c("price", { attrs: { value: _vm.price.brutto } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }