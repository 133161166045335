<template>
    <section
        class="text-center"
        :class="{'fixed': fixedMiniBasketButtons}"
        >
        <basket-list />
        <template v-if="products.length">
            <!--<basket-summary />-->
            <div class="mini-basket-buttons">
                <a
                    :href="$langUrl('/basket')"
                    class="btn btn-primary mb-3 mt-4"
                    >
                    {{ $ll('basketGoToBasketButton') }}
                </a>
                <a
                    :href="$langUrl('/basket')"
                    class="btn btn-secondary"
                    @click.prevent="toggle"
                    >
                    {{ $ll('basketBackToShoppingButton') }}
                </a>
            </div>
        </template>
        
        <template v-else>
            {{ $ll('basketIsEmpty') }}
        </template>
    </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import BasketList from '@components/basket/mini/list'
// import BasketSummary from '@components/basket/mini/summary'

export default {

    components: {
        BasketList,
        // BasketSummary
    },

    computed: {
        ...mapState(
            'Basket', ['products', 'fixedMiniBasketButtons']
        )
    },

    methods: {
        ...mapMutations({
            toggle: 'Basket/toggle'
        })
    }
}
</script>
