var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "basket__summary" },
    [
      _c("div", { staticClass: "row no-gutters" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("basket-breadcrumb"),
            _vm._v(" "),
            _c(
              "h1",
              {
                staticClass:
                  "text-uppercase text-center mt-3 mt-lg-5 mb-0 pb-4 bottom-line",
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$ll("basketTitleSummary")) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row no-gutters" }, [
        _c(
          "div",
          { staticClass: "col-12 col-lg-6 mt-4 pr-lg-3 pr-0" },
          [
            _c(
              "label",
              {
                staticClass:
                  "text-uppercase d-flex justify-content-between mt-3 mb-4 pb-3 bottom-line text-black summary-label",
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$ll("basketConfirmDeliveryAndPayment")) +
                    "\n                "
                ),
                _c("router-link", {
                  attrs: { to: _vm.$langUrl("/basket/delivery") },
                  domProps: {
                    textContent: _vm._s(_vm.$ll("basketConfirmChangeButton")),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 bottom-line" },
                [_c("payment-info")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 pt-4" },
                [_c("basket-summary")],
                1
              ),
            ]),
            _vm._v(" "),
            !!_vm.point
              ? [
                  _c(
                    "label",
                    {
                      staticClass:
                        "text-uppercase d-flex justify-content-between mt-3 mb-4 pb-3 bottom-line text-black summary-label",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$ll("basketConfirmDeliveryPoint")) +
                          "\n                    "
                      ),
                      _c("router-link", {
                        attrs: { to: _vm.$langUrl("/basket/delivery") },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$ll("basketConfirmChangeButton")
                          ),
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.setPoint()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("delivery-point-details"),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass:
                  "text-uppercase d-flex justify-content-between mt-3 mb-4 pb-3 bottom-line text-black summary-label",
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$ll("basketConfirmAddress")) +
                    "\n                "
                ),
                _c("router-link", {
                  attrs: { to: _vm.$langUrl("/basket/delivery") },
                  domProps: {
                    textContent: _vm._s(_vm.$ll("basketConfirmChangeButton")),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("country-info"),
            _vm._v(" "),
            _c("delivery-info", { attrs: { form: "delivery" } }),
            _vm._v(" "),
            _vm.wantInvoice
              ? [
                  _c(
                    "label",
                    {
                      staticClass:
                        "text-uppercase d-flex justify-content-between mt-3 mb-4 pb-3 bottom-line text-black summary-label",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$ll("basketConfirmAddressInvoice")) +
                          "\n                    "
                      ),
                      _c("router-link", {
                        attrs: { to: _vm.$langUrl("/basket/delivery") },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$ll("basketConfirmChangeButton")
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("delivery-info", { attrs: { form: "invoice" } }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-12 col-lg-6 mt-4 pl-0 pl-lg-3 delivery-products",
          },
          [
            _c(
              "label",
              {
                staticClass:
                  "text-uppercase d-flex justify-content-between mt-3 mb-4 pb-3 bottom-line text-black summary-label",
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$ll("basketList")) +
                    "\n                "
                ),
                _c("router-link", {
                  attrs: { to: _vm.$langUrl("/basket") },
                  domProps: {
                    textContent: _vm._s(_vm.$ll("basketConfirmChangeButton")),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("basket-list"),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("basket-buttons"),
      _vm._v(" "),
      _vm.showTpayCardModal ? _c("tpay-card-modal") : _vm._e(),
      _vm._v(" "),
      _vm.showTpayBlikModal ? _c("tpay-blik-modal") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }