var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-field sort-select category-select" },
    [
      _c("label", { staticClass: "form-field__placeholder" }, [
        _vm._v("\n        Sortuj wg:\n    "),
      ]),
      _vm._v(" "),
      _vm.ie
        ? _c(
            "select",
            {
              staticClass: "form-control form-field__select",
              on: { change: (v) => (_vm.selected = v) },
            },
            _vm._l(_vm.values, function (val) {
              return _c(
                "option",
                { key: val.value, domProps: { value: val.value } },
                [_vm._v("\n            " + _vm._s(val.label) + "\n        ")]
              )
            }),
            0
          )
        : _c("v-select", {
            attrs: { label: "label", options: _vm.values, searchable: false },
            on: { input: _vm.setSorted },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }