import axios from 'axios'

export default {
    namespaced: true,
    state() {
        return {
            disabled: false
        }
    },
    mutations: {
        setDisabled(state, payload) {
            state.disabled = payload
        }
    },
    actions: {
        order({ commit, rootGetters }) {
            commit('setDisabled', true)
            axios.post('/vouchers/order', rootGetters['CustomForm/values']('vouchers'))
                .then(res => {
                    if (res.data.status && res.data.transaction) {
                        location.href = `/${rootGetters['Setup/language']}/voucherOrder/${res.data.transaction}`
                    }
                    commit('setDisabled', false)
                })
        }
    }
}