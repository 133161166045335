var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container container-small vouchers-confirm px-3" },
    [
      _c("div", { staticClass: "row no-gutters" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "row no-gutters" }, [
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c("span", {
                staticClass: "d-block mt-4 mb-3",
                domProps: { innerHTML: _vm._s(_vm.data.personalTitle.html) },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "d-block",
                domProps: {
                  innerHTML: _vm._s(
                    "<strong>" +
                      _vm.data.forename.label +
                      "</strong>: " +
                      _vm.data.forename.value
                  ),
                },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "d-block",
                domProps: {
                  innerHTML: _vm._s(
                    "<strong>" +
                      _vm.data.surname.label +
                      "</strong>: " +
                      _vm.data.surname.value
                  ),
                },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "d-block",
                domProps: {
                  innerHTML: _vm._s(
                    "<strong>" +
                      _vm.data.email.label +
                      "</strong>: " +
                      _vm.data.email.value
                  ),
                },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "d-block",
                domProps: {
                  innerHTML: _vm._s(
                    "<strong>" +
                      _vm.data.phone.label +
                      "</strong>: " +
                      _vm.data.phone.value
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c("span", {
                staticClass: "d-block mt-4 mb-3",
                domProps: { innerHTML: _vm._s(_vm.data.discountsTitle.html) },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "d-block",
                domProps: {
                  innerHTML: _vm._s(
                    "<strong>" +
                      _vm.data.discount_id.label +
                      "</strong>: " +
                      _vm.data.discount_id.data[_vm.data.discount_id.value].name
                  ),
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row no-gutters" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6 py-4" },
                  [
                    _c("Picture", {
                      attrs: {
                        src: _vm.$photoUrl(
                          _vm.data.discount_id.data[_vm.data.discount_id.value]
                            .url
                        ),
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-md-6 offset-md-6" },
          [
            _c("btn-submit", {
              attrs: {
                value: _vm.$ll("basketConfirmOrderButton"),
                disabled: _vm.disabled,
              },
              on: {
                click: function ($event) {
                  return _vm.$store.dispatch("Voucher/order")
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }