var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "form",
      attrs: { action: _vm.action },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.dispatch.apply(null, arguments)
        },
      },
    },
    [
      _vm.data.hideOnSuccess
        ? _c("form-success", { attrs: { target: _vm.target } })
        : _c("form-items", {
            attrs: { action: _vm.action, target: _vm.target },
          }),
      _vm._v(" "),
      _c("button", { ref: "submit", staticClass: "d-none" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }