var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container mb-2 my-account-menu" }, [
      _c("h1", {
        staticClass: "mt-4 mb-5 text-center text-uppercase",
        domProps: { textContent: _vm._s(_vm.$ll("myCupboardsTitle")) },
      }),
      _vm._v(" "),
      _vm.list.length > 1
        ? _c(
            "ul",
            { staticClass: "nav justify-content-center" },
            _vm._l(_vm.list, function (item) {
              return _c(
                "li",
                {
                  key: item.cupboardId,
                  staticClass: "nav-item",
                  class: { active: _vm.cupboardId == item.cupboardId },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link cursor-pointer",
                      on: {
                        click: function ($event) {
                          _vm.$router.push(
                            _vm.$langUrl(
                              `/cupboard${
                                (item.cupboardId && `/${item.cupboardId}`) || ""
                              }`
                            )
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.cupboardName) +
                          " (" +
                          _vm._s(item.products.length) +
                          ")\n                "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }