import { render, staticRenderFns } from "./id-7.vue?vue&type=template&id=4eed68c8"
import script from "./id-7.vue?vue&type=script&lang=js"
export * from "./id-7.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\projekty\\mastivo\\static\\sources\\www\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4eed68c8')) {
      api.createRecord('4eed68c8', component.options)
    } else {
      api.reload('4eed68c8', component.options)
    }
    module.hot.accept("./id-7.vue?vue&type=template&id=4eed68c8", function () {
      api.rerender('4eed68c8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "scripts/components/basket/full/step2/delivery/info-window/id-7.vue"
export default component.exports