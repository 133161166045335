var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    {
      staticClass: "table",
      class: (_vm.data.class || "").replace(/tabs\-/g, ""),
    },
    [
      _vm.data.content.thead
        ? _c(
            "thead",
            _vm._l(_vm.data.content.thead, function (tr, id) {
              return _c(
                "tr",
                { key: id },
                _vm._l(tr.th, function (th, th_id) {
                  return _c("th", {
                    key: th_id,
                    class: th.class,
                    attrs: { rowspan: th.rowspan, colspan: th.colspan },
                    domProps: { textContent: _vm._s(th.textContent) },
                  })
                }),
                0
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.content.tbody
        ? _c(
            "tbody",
            _vm._l(_vm.data.content.tbody, function (tr, id) {
              return _c(
                "tr",
                { key: id },
                _vm._l(tr.td, function (td, td_id) {
                  return _c("td", {
                    key: td_id,
                    class: td.class,
                    attrs: { rowspan: td.rowspan, colspan: td.colspan },
                    domProps: { textContent: _vm._s(td.textContent) },
                  })
                }),
                0
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }