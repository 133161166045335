<template>
    <div class="w-100 stars-bars">
        <div
            v-for="(value,key) in arrRating"
            :key="key"
            >
            <div class="mb-1 d-flex">
                <span class="d-inline-block text-left w-20">
                    <span
                        class="d-inline-block align-sub"
                        style="width: 1ch;"
                        v-text="value.stars"
                        />
                    <star-rating
                        :rating="1"
                        :increment="1"
                        :read-only="true"
                        :max-rating="1"
                        :star-size="12"
                        :show-rating="false"
                        active-color="#d40d1b"
                        border-color="#d40d1b"
                        :border-width="2"
                        :rounded-corners="true"
                        />
                </span>
                <span class="d-inline-block w-80 pl-2">
                    <b-progress
                        :value="value.opinions"
                        :max="maxOpinions"
                        />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import { mapActions, mapState } from 'vuex'
export default {
    components: {
        StarRating
    },
    props: {
        rating: { type: Object, required: false }
    },
    data() {
        return {
            arrRating: [
                {stars: 5, opinions: 0},
                {stars: 4, opinions: 0},
                {stars: 3, opinions: 0},
                {stars: 2, opinions: 0},
                {stars: 1, opinions: 0}
            ]
        }
    },
    computed: {
        ...mapState('ProductCard', ['maxOpinions']),
        ...mapActions({
            setMax: 'ProductCard/setMax'
        })
    },
    mounted() {
        this.setMax
        const arr = this.arrRating
        const rating = this.rating

        Object.keys(rating).forEach((key) => {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].stars == key) {
                    arr[i].opinions = rating[key]
                }
            }
        })
        this.arrRating = arr
    }

}
</script>
