<template>
    <div class="container container-small vouchers-form px-3">
        <custom-form
            target="vouchers"
            action="/vouchers/index"
            :clear-after="false"
            @submitted="done"
            />
    </div>
</template>

<script>
import CustomForm from '@components/custom-form'

export default {
    components: {
        CustomForm
    },
    beforeMount() {
        setTimeout(()=>{
            document.querySelector('.text-page-content').classList.remove('d-none')
        }, 0)
    },

    methods: {
        done() {
            this.$router.push('vouchers/confirm')
        }
    }
}
</script>
