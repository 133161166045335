<template>
    <div class="club-info-banner text-center pt-3 pb-1 mb-5">
        <h3>
            {{ $ll('clubBannerTitle') }}
            <span class="text-danger light font-italic">
                {{ $ll('clubBannerName') }}
            </span>
        </h3>
        <div
            class="row mx-md-0"
            >
            <div
                class="col-11 col-md-10 px-md-0 m-auto"
                >
                <h6
                    v-if="text"
                    class="light mt-2 mb-4"
                    v-html="text.replace('%0', `${club_value} ${club_valueIso}`)"
                    />
                <p v-else>
                    {{ $ll('clubInfoStart') }} <strong class="text-black">
                        <price
                            :value="club_value"
                            :view-curency="club_valueIso"
                            />
                    </strong> {{ $ll('clubInfoEnd') }}
                </p>
            </div>
        </div>
        <div class="row mx-md-0">
            <custom-form
                class="col-12 col-md-6 m-auto"
                target="clubForm"
                action="/ajax/basket/clubSignUp"
                />
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import CustomForm from '@components/custom-form'
import Price from '@components/price'

export default {
    components: {
        CustomForm,
        Price
    },
    props: {
        text: {type: String, default: '' }
    },
    computed: {
        ...mapState('Basket', [
            'club_value', 'club_valueIso'
        ])
    }
}
</script>
