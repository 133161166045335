var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.modals, function (modal, key) {
      return _c(
        "b-modal",
        {
          key: modal.id,
          ref: "modal",
          refInFor: true,
          class: {
            "modal-image": modal.type === "modal-image",
            "modal-title-empty": !modal.variables.title,
          },
          attrs: {
            title: modal.type === "modal-text" ? modal.variables.title : "",
            size: modal.variables.size || "md",
            "hide-footer": "",
          },
          on: {
            hidden: function ($event) {
              return _vm.close(key)
            },
          },
        },
        [
          modal.type === "modal-text"
            ? _c("div", {
                domProps: { innerHTML: _vm._s(modal.variables.content) },
              })
            : _vm._e(),
          _vm._v(" "),
          modal.type === "modal-image"
            ? _c("div", [
                modal.variables.link.externalLinkText == "" ||
                modal.variables.link.internalLinkText == ""
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href:
                            modal.variables.link.externalLinkHref ||
                            modal.variables.link.internalElementUrl,
                          target: modal.variables.link.targetBlank
                            ? "_blank"
                            : "_self",
                          rel:
                            modal.variables.link.parameters != null
                              ? "nofollow"
                              : "",
                        },
                      },
                      [
                        _c("Picture", {
                          staticClass: "body-image",
                          attrs: {
                            src: _vm.$photoUrl(modal.variables.image[0].file),
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("Picture", {
                          staticClass: "body-image",
                          attrs: {
                            src: _vm.$photoUrl(modal.variables.image[0].file),
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "button-container" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                href:
                                  modal.variables.link.externalLinkHref ||
                                  modal.variables.link.internalElementUrl,
                                target: modal.variables.link.targetBlank
                                  ? "_blank"
                                  : "_self",
                                rel:
                                  modal.variables.link.parameters != null
                                    ? "nofollow"
                                    : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.close(key)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    modal.variables.link.internalLinkText ||
                                      modal.variables.link.externalLinkText
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
              ])
            : _vm._e(),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }