<template>
    <ul class="list-unstyled">
        <li
            v-for="item in attributes"
            :key="item.key"
            >
            <strong v-text="item.key" />: <span v-text="item.value" />
        </li>
    </ul>
</template>

<script>
export default {
    props: ['attributes']
}
</script>
