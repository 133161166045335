<template>
    <div class="products-per-page float-left mt-3">
        <span class="pr-2 d-none d-md-flex">
            {{ $ll('categoryPerPage') }}:
        </span>
        <span>
            <div
                v-for="item in items"
                :key="item"
                class="item"
                :class="{ active: item == index }"
                @click="set(item)"
                v-text="item"
                />
        </span>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {

    computed: {
        ...mapState('CategoryFilter', {
            index: state => state.perPage.index,
            items: state => state.perPage.items
        })
    },

    methods: {
        ...mapActions({
            set: 'CategoryFilter/setPerPage'
        })
    }
}
</script>

<style lang="scss" scoped>
.products-per-page {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    flex-wrap: nowrap;
    .item {
        cursor: pointer;
        width: 40px;
        padding: 5px 0;
        &.active {
            color: #de5d71;
            font-weight: bold;
            text-decoration: none;
            cursor: default;
            pointer-events: none;
        }
        &:hover {
            color: #de5d71;
            text-decoration: underline;
        }
    }
    span {
        display: flex;
        align-items:center;
        justify-content: flex-end;
    }
}
</style>
