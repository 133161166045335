<template>
    <div
        class="loader-overflow"
        :class="{ 'active': hide }"
        />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: mapGetters({
        hide: 'Loader/disabled'
    })
}
</script>
