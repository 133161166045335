<template>
    <div class="form-inline">
        <div class="form-group">
            <label
                for="price-input-from"
                class="col-form-label"
                >
                Cena od
            </label>
            <div>
                <input 
                    id="price-input-from"
                    class="form-control mx-2"
                    :value="from"
                    list="price-list"
                    @change="set({id: 'from', value: $event.target.value })"
                    >
            </div>
        </div>

        <div class="form-group">
            <label
                for="price-input-to"
                class="col-form-label"
                >
                Cena do
            </label>
            <div>
                <input
                    id="price-input-to"
                    class="form-control mx-2"
                    :value="to"
                    list="price-list"
                    @change="set({id: 'to', value: $event.target.value })"
                    >
            </div>
        </div>

        <datalist
            v-if="hints"
            id="price-list"
            >
            <option
                v-for="(num,id) in hints"
                :key="id"
                v-text="num"
                />
        </datalist>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {

    props: {
        between: { type: Object }
    },

    computed: {
        ...mapState('CategoryFilter', {
            from: state => state.price['price-from'] || '',
            to: state => state.price['price-to'] || ''
        }),
        hints() {
            if ( !this.between ) return

            const diff = Math.ceil( ( this.between.max - this.between.min ) / 10 )

            if ( diff<5 ) {
                return [Math.floor( this.between.min ), Math.ceil( this.between.max )]
            }

            return Array(11).fill(0).map((v,i) => (Math.floor( this.between.min ) + i*diff ))
        }
    },

    methods: {
        ...mapActions({
            set: 'CategoryFilter/setPrice'
        })
    }
}
</script>
