var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "basket__list-item basket__list-item--alternative pb-4 mb-4",
    },
    [
      _c("div", { staticClass: "d-flex flex-nowrap w-100" }, [
        _c("div", { staticClass: "list-item__photo" }, [
          _c(
            "a",
            { attrs: { href: _vm.item.url } },
            [
              _c("Picture", {
                attrs: {
                  alt: _vm.item.name,
                  src: _vm.$photoUrl(_vm.item.photo, 100),
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "list-item__containter" },
          [
            _c(
              "strong",
              { staticClass: "d-inline-block list-item__name w-80" },
              [
                _c("a", {
                  staticClass: "mb-0 d-inline-block font-weight-normal",
                  attrs: { href: _vm.item.url },
                  domProps: { textContent: _vm._s(_vm.item.name) },
                }),
              ]
            ),
            _vm._v(" "),
            _vm._t("header"),
            _vm._v(" "),
            _c("p", { staticClass: "list-item__code mb-0" }, [
              _c("small", { domProps: { textContent: _vm._s(_vm.item.code) } }),
            ]),
            _vm._v(" "),
            _c("b-alert", {
              attrs: { show: !!_vm.item.error, variant: "danger" },
              domProps: { textContent: _vm._s(_vm.item.error) },
            }),
            _vm._v(" "),
            _vm._t("body", function () {
              return [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _vm._l(_vm.item.attributes, function (attr) {
                        return _c("div", { key: attr.key }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(attr.key) +
                              ": "
                          ),
                          _c("span", {
                            domProps: { textContent: _vm._s(attr.value) },
                          }),
                        ])
                      }),
                      _vm._v(" "),
                      _vm.itemPriceVisible
                        ? _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c("basket-price", {
                                attrs: {
                                  price: _vm.item.price,
                                  "view-currency": _vm.viewCurrency,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-2 d-flex justify-content-center align-items-center mt-4 mt-md-0",
                    },
                    [
                      _c("div", { staticClass: "text-nowrap amount-couter" }, [
                        _vm.action && !_vm.isPackingForGift(_vm.item.variant)
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "btn-link-inline list-item__amount-btn mr-1",
                                class: {
                                  disabled:
                                    _vm.item.quantity <= 1 || _vm.changingQty,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("quantity", {
                                      id: _vm.item.id,
                                      q: -1,
                                    })
                                  },
                                },
                              },
                              [_c("i", { staticClass: "mdi mdi-minus" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("strong", {
                          staticClass: "title-1",
                          domProps: { textContent: _vm._s(_vm.item.quantity) },
                        }),
                        _vm._v(" "),
                        _vm.action && !_vm.isPackingForGift(_vm.item.variant)
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "btn-link-inline list-item__amount-btn ml-1",
                                class: { disabled: _vm.changingQty },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("quantity", {
                                      id: _vm.item.id,
                                      q: 1,
                                    })
                                  },
                                },
                              },
                              [_c("i", { staticClass: "mdi mdi-plus" })]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-md-2 d-flex justify-content-center align-items-center mt-4 mt-md-0",
                    },
                    [
                      _c(
                        "strong",
                        { staticClass: "title-1" },
                        [
                          _c("basket-price", {
                            attrs: {
                              price: _vm.item.value,
                              "view-currency": _vm.viewCurrency,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-none d-md-flex col-md-2 justify-content-center align-items-center",
                    },
                    [
                      _vm.action
                        ? _c(
                            "a",
                            {
                              class: { disabled: _vm.changingQty },
                              attrs: {
                                lass: "float-right btn-link-inline d-inline-block d-md-none list-item__remove",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("remove")
                                },
                              },
                            },
                            [_c("i", { staticClass: "i-close" })]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("div", { staticClass: "list-item__footer" }, [_vm._t("footer")], 2),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }