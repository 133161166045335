import axios from 'axios'
import json from '@db'

export default {
    namespaced: true,
    state() {
        return {
            id: json.sizeTableIdentifier || '',
            data: null,
            open: false
        }
    },
    mutations: {
        response(state, payload) {
            state.data = payload.data
        },
        setId(state, payload) {
            state.id = payload
        },
        toggleOpen(state, payload) {
            state.open = payload
        }
    },
    actions: {
        load({ commit, state }, payload) {
            if (!payload || !!state.data || !state.id) {
                return
            }

            axios.get(`/ajax/getSizeTable/${state.id}`)
                .then(res => commit('response', res))
        }
    }
}