var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        Object.keys(_vm.products).length > 4
          ? _c(
              "carousel",
              {
                staticClass: "VueProductCarousel product-carousel",
                attrs: {
                  "per-page-custom": [
                    [100, 1],
                    [480, 2],
                    [768, 3],
                    [992, 4],
                  ],
                  loop: true,
                  "pagination-enabled": false,
                  "navigation-enabled": true,
                  "scroll-per-page": false,
                  "navigation-next-label": "",
                  "navigation-prev-label": "",
                  "center-mode": "",
                },
              },
              _vm._l(_vm.products, function (product, id) {
                return _c(
                  "slide",
                  { key: id },
                  [_c("product-brick", { attrs: { product: product } })],
                  1
                )
              }),
              1
            )
          : Object.keys(_vm.products).length === 4
          ? _c(
              "div",
              { staticClass: "row mt-3" },
              [
                _vm._l(_vm.products, function (product, id) {
                  return _c(
                    "div",
                    {
                      key: id,
                      staticClass:
                        "col-12 col-sm-4 col-lg-3 mb-3 px-0 px-xl-2 d-none d-lg-block",
                    },
                    [_c("product-brick", { attrs: { product: product } })],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-lg-none col-12 px-0" },
                  [
                    _c(
                      "carousel",
                      {
                        staticClass: "VueProductCarousel",
                        attrs: {
                          "per-page-custom": [
                            [100, 1],
                            [480, 2],
                            [768, 3],
                          ],
                          loop: true,
                          "pagination-enabled": false,
                          "navigation-enabled": true,
                          "scroll-per-page": false,
                          "navigation-next-label": "",
                          "navigation-prev-label": "",
                        },
                      },
                      _vm._l(_vm.products, function (product, id) {
                        return _c(
                          "slide",
                          { key: id },
                          [
                            _c("product-brick", {
                              attrs: { product: product },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            )
          : Object.keys(_vm.products).length === 3
          ? _c(
              "div",
              { staticClass: "row mt-3" },
              [
                _vm._l(_vm.products, function (product, id) {
                  return _c(
                    "div",
                    {
                      key: id,
                      staticClass:
                        "col-12 col-sm-4 col-lg-3 mb-3 px-2 d-none d-md-block",
                    },
                    [_c("product-brick", { attrs: { product: product } })],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-md-none col-12 px-0" },
                  [
                    _c(
                      "carousel",
                      {
                        staticClass: "VueProductCarousel",
                        attrs: {
                          loop: true,
                          "pagination-enabled": false,
                          "navigation-enabled": true,
                          "scroll-per-page": false,
                          "navigation-next-label": "",
                          "navigation-prev-label": "",
                        },
                      },
                      _vm._l(_vm.products, function (product, id) {
                        return _c(
                          "slide",
                          { key: id },
                          [
                            _c("product-brick", {
                              attrs: { product: product },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            )
          : _c(
              "div",
              { staticClass: "row mt-3" },
              _vm._l(_vm.products, function (product, id) {
                return _c(
                  "div",
                  { key: id, staticClass: "col-6 col-md-4 col-lg-3 mb-3 px-2" },
                  [_c("product-brick", { attrs: { product: product } })],
                  1
                )
              }),
              0
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }