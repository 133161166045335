import json from '@db'
import photoMiniature from '@helpers/photo-miniature'

if (json.productPhotos) {
    Object.values(json.productPhotos).forEach(collection => {
        collection.forEach(photo => photo.defaultAlt = json.productName)
    })
}

export default {
    namespaced: true,
    state() {
        return {
            ...json.productPhotos
        }
    },
    getters: {
        list(state, getters, rootState, rootGetters) {
            if (!state.main) return []

            //wybrane elementy (id)
            const chosen = rootGetters['ProductCard/chosenIds']

            //dodatkowe zdjecia po wybraniu poszczegolnych cech
            const chosenProductAdditional = Object.keys(state.additional).reduce((r, img) => {
                if (state.additional[img].values.some(id => chosen.includes(id))) r.push(state.additional[img])
                return r
            }, [])

            if (chosen && chosenProductAdditional.length) {
                return chosenProductAdditional
            }

            return [
                ...chosenProductAdditional,

                //najpierw zdjecie ze statusem main=true
                state.main[
                    Object
                        .keys(state.main)
                        .find(img => state.main[img].main)
                ],

                //reszta zdjec glownych
                ...Object
                    .keys(state.main)
                    .reduce((r, img) => {
                        if (!state.main[img].main) r.push(state.main[img])
                        return r
                    }, [])
            ].filter(Boolean)
        },

        photoswipe(state, getters) {
            //generuj liste dla photoSwipe
            return getters.list.map(img => {
                const sizeW = 1500
                const sizeH = 2125
                const ratioW = img.w / sizeW
                const ratioH = img.h / sizeH

                return {
                    src: photoMiniature(img.path, 'raw'),
                    w: img.w / ratioW,
                    h: img.h / ratioH,
                    title: '_',
                    description: img.name || img.defaultAlt
                }
            })
        }
    }
}
