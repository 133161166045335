var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", [
    _vm.pageCount > 1
      ? _c(
          "ul",
          {
            staticClass: "pagination b-pagination pagination-md",
            attrs: { role: "menubar" },
          },
          [
            _c(
              "li",
              {
                staticClass: "page-item",
                class: { disabled: _vm.current == 1 },
                attrs: { role: "none presentation" },
              },
              [
                _vm.current > 1
                  ? _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: {
                          role: "menuitem",
                          tabindex: "-1",
                          "aria-label": "Go to previous page",
                          target: "_self",
                          href: _vm.url(_vm.current - 1),
                          rel: "prev",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.current = _vm.current - 1
                          },
                        },
                      },
                      [_c("i", { staticClass: "i-arrow-left-small" })]
                    )
                  : _c(
                      "span",
                      { staticClass: "page-link", attrs: { role: "menuitem" } },
                      [_c("i", { staticClass: "i-arrow-left-small" })]
                    ),
              ]
            ),
            _vm._v(" "),
            _c("li", { staticClass: "page-item" }, [
              _c(
                "span",
                {
                  staticClass: "page-link",
                  attrs: {
                    role: "menuitemradio",
                    "aria-label": `Go to page ${_vm.current}`,
                    "aria-posinset": _vm.current,
                    tabindex: "-1",
                    target: "_self",
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.current) +
                      "\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "pagination-separator" }, [
              _vm._v("\n            z\n        "),
            ]),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "page-item",
                attrs: { role: "none presentation" },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: {
                      role: "menuitemradio",
                      "aria-label": `Go to page ${_vm.pageCount}`,
                      "aria-checked": "false",
                      "aria-posinset": _vm.pageCount,
                      "aria-setsize": "65",
                      tabindex: "-1",
                      target: "_self",
                      href: _vm.url(_vm.pageCount),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.current = _vm.pageCount
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.pageCount) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "page-item",
                class: { disabled: _vm.current == _vm.pageCount },
                attrs: { role: "none presentation" },
              },
              [
                _vm.current != _vm.pageCount
                  ? _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: {
                          role: "menuitem",
                          tabindex: "-1",
                          "aria-label": "Go to next page",
                          target: "_self",
                          href: _vm.url(_vm.current + 1),
                          rel: "next",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.current = _vm.current + 1
                          },
                        },
                      },
                      [_c("i", { staticClass: "i-arrow-right-small" })]
                    )
                  : _c(
                      "span",
                      { staticClass: "page-link", attrs: { role: "menuitem" } },
                      [_c("i", { staticClass: "i-arrow-right-small" })]
                    ),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }