var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.price
    ? _c(
        "div",
        {
          class: [
            "product-card__lowest-month-price",
            { "product-card__lowest-month-price--italic": _vm.italic },
          ],
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$ll("productCardLowestMonthPrice")) + " "
          ),
          _c(
            "b",
            { staticClass: "product-card__lowest-month-price-value" },
            [_c("price", { attrs: { value: _vm.price } })],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }