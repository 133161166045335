<template>
    <ul class="nav lang-nav">
        <!-- <span class="mr-2">
            {{ $ll('footerCurrencyText') }}
        </span> -->
        <li
            v-for="currency in currencies"
            :key="currency.currency_id"
            class="nav-item"
            :class="{ active: current == currency.currency_id }"
            >
            <a
                :href="`/currency/set/${currency.currency_id}`"
                v-text="currency.iso"
                />
        </li>
    </ul>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState('Setup', {
            current: state => state.currency.current,
            currencies: state => state.currency.currencies
        })
    }
}
</script>