//pokazywanie, ukrywanie menu
document.addEventListener('DOMContentLoaded', () => {
    let showOpButton = document.querySelector('.opinions-container .btn-secondary')
    if(showOpButton){
        showOpButton.addEventListener("click", function () {
            let list = document.querySelectorAll('.product-card .opinion-item.d-none')
            for (let i = 0; i < list.length && 3; i++) {
                list[i].classList.remove('d-none')
            }
            if(list.length < 4){
                showOpButton.classList.add('d-none')
            }else{
                showOpButton.querySelector('opinion-count').innerHTML('(' + list.length - 3 + ')')
            }
        })
    }
})