var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-form", {
    attrs: {
      target: _vm.account_create ? "consentsWithRegistration" : "consents",
      action: "/ajax/basket/address/form",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }