document.addEventListener('DOMContentLoaded', () => {
  if ('objectFit' in document.documentElement.style === false) {
    const imgCover = document.querySelectorAll('.img-cover');

    imgCover.forEach(img => {
      let container = img.parentElement;
      const imageSrc = img.src;

      if (container.nodeName === 'PICTURE') {
        container = container.parentElement;
      }

      img.setAttribute('style', 'display: none !important;');
    
      container.style.backgroundSize = 'cover';
      
      container.style.backgroundImage = 'url(' + imageSrc + ')';
      
      container.style.backgroundPosition = 'center center';
    });
  }
})