<template>
    <div class="w-100 basket-summary">
        <ul class="list-unstyled w-100">
            <li
                :class="free_delivery_missing_amount ? 'mb-3' : 'mb-2'"
                >
                <span class="mb-0">
                    {{ $ll('basketSummaryProducts') }}:
                </span>
                <price-single
                    :value="total.regular"
                    class="float-right"
                    />
            </li>
            <li
                v-if="free_delivery_missing_amount"
                class="mb-2"
                >
                <span class="mb-0">
                    &nbsp;
                </span>
                <div
                    class="float-right"
                    >
                    {{ $ll('basketSummaryFreeDeliveryLackStart') }}&nbsp;<strong>
                        <price-single
                            :value="free_delivery_missing_amount"
                            />
                    </strong>&nbsp;{{ $ll('basketSummaryFreeDeliveryLackEnd') }}
                </div>
            </li>
            <li
                v-if="benefit"
                :class="{'mb-3' : loyalty && !loyalty.points && voucher && !voucher.name }"
                >
                <span class="mb-1">
                    {{ $ll('basketSummaryPromotion') }}:
                </span>

                <price-single
                    :value="benefit"
                    class="float-right"
                    />
            </li>
            <li
                v-if="loyalty && loyalty.points"
                :class="{'mb-3' : voucher && !voucher.name}"
                >
                <span class="mb-0">
                    {{ $ll('basketLoyaltyDiscount') }}:
                </span>
                <price-single
                    :value="loyalty.current_discount"
                    class="float-right"
                    />
            </li>
            <li
                v-if="voucher && voucher.name"
                class="mb-3"
                >
                <span class="mb-0">
                    {{ $ll('basketVoucherSet') }}:
                </span>
                <price-single
                    :value="voucher.value"
                    class="float-right"
                    />
            </li>
            <li
                v-if="four_plus_one_promotion_value"
                >
                <span class="mb-0">
                    {{ $ll('basketFourPlusOnePromotionValue') }}:
                </span>
                <price-single
                    class="float-right"
                    :value="four_plus_one_promotion_value"
                    />
            </li>
            <li
                v-if="promotion && promotion.name"
                class="mb-3"
                >
                <span class="mb-0">
                    {{ $ll('basketPromotionSet') }} - {{ promotion.name }}:
                </span>
                <price-single
                    :value="promotion.value"
                    class="float-right"
                    />
            </li>
            <li>
                <span class="mb-0">
                    {{ $ll('basketSummaryDelivery') }}:
                </span>
                <div class="float-right">
                    <span v-if="free_delivery">
                        {{ $ll('basketSummaryFreeDelivery') }}
                    </span>
                    <price-single
                        v-else-if="!!payment"
                        :value="cost"
                        />
                    <span v-else>
                        {{ $ll('basketSummaryDeliveryNotSet') }}
                    </span>
                </div>
            </li>

            <li class="mt-4 mb-0 basket-summary__total">
                <span>
                    <span class="">
                        {{ $ll('basketSummaryFull') }}:
                    </span>
                </span>
                <span>
                    <price-single
                        :value="shipment.final"
                        class="float-right text-black"
                        />
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import Price from '@components/basket/price'
import PriceSingle from '@components/price'

export default {

    components: {
        // Price,
        PriceSingle
    },

    computed: {
        ...mapState('Basket', [
            'total', 'benefit', 'payment', 'loyalty', 'voucher', 'promotion', 'free_delivery', 'free_delivery_missing_amount', 'free_delivery_min_value', 'four_plus_one_promotion_value'
        ]),
        ...mapGetters({
            cost: 'Basket/getPaymentPrice',
            shipment: 'Basket/getTotalWithShipment'
        })
    }
}
</script>
