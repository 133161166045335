var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "list-unstyled" },
    _vm._l(_vm.attributes, function (item) {
      return _c("li", { key: item.key }, [
        _c("strong", { domProps: { textContent: _vm._s(item.key) } }),
        _vm._v(": "),
        _c("span", { domProps: { textContent: _vm._s(item.value) } }),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }