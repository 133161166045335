var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row no-gutters" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "carousel",
          {
            staticClass: "opinions-carousel",
            attrs: {
              "per-page-custom": [
                [320, 1],
                [768, 2],
                [992, 3],
              ],
              loop: true,
              "pagination-enabled": false,
              "navigation-enabled": true,
              "scroll-per-page": false,
              "navigation-next-label": "",
              "navigation-prev-label": "",
            },
          },
          _vm._l(_vm.opinions, function (opinion, i) {
            return _c(
              "slide",
              { key: i },
              [_c("opinion-item", { attrs: { item: opinion } })],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }