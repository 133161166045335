import axios from 'axios'

export default {
    namespaced: true,
    state: {
        address: {},
        downloadLinks: null,
        loading: false,
        opinions: [],
        orders: [],
        ordersPayment: {},
        points: {},
        subloading: false,
        threads: [],
        paginator: {},
        returns: {
            creator: {},
            orders: [],
            products: [],
            returned: []
        },
        conversation: {
            isClosed: true,
            failed: false,
            messages: [],
            thread: {}
        }
    },
    getters: {

        /**
         * @description Pobieranie informacji czy mozna jeszcze dodac adres do danej grupy
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @returns {Boolean}
         */
        canAddMore(state) {
            return payload => {
                const key = `canAddMore${payload}`
                const { address = {} } = state

                return address[key]
            }
        },

        /**
         * @description Zwraca walute ktora jest aktualnie wybrana do zwrotów
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @returns {String}
         */
        getReturnsCurrency(state) {
            const { returns: { creator: { currency_data: currency } = {}, orders} = {}} = state
            const order = currency || orders[0] || {}

            return order.symbol || ''
        },

        /**
         * @description Zwraca zamowienie z ktorego pochodzi wybrany produkt
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @returns {Object} order
         */
        getOrderFromReturnsList(state) {
            return (payload = {}) => state.returns.orders.find(order => order.products.find(product => product.id == payload.id))
        }
    },
    mutations: {

        /**
         * @description Ustawienie flagi ładowania komponentu (dla pokazania paska loading)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Boolean} payload
         */
        setLoading(state, payload) {
            state.loading = payload
        },

        /**
         * @description Ustawienie błedow z PHP dla poszczegolnych produktow na liscie zwrotu
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload zwrotka z Php
         */
        returnProductErrors(state, payload = []) {
            state.returns.products.forEach(product => {
                product.error = (payload.find(item => item.order_item_id == product.order_item_id) || {}).message || null
            })
        }
    },
    actions: {

        getCupboardForm({ state, commit }, payload) {
            state.subloading = true
            const { type, id } = payload

            axios.get(`/ajax/cupboard/${type}Action/${id}`)
                .then(data => {
                    commit('CustomForm/addForm', {
                        name: 'myAccountCupboard',
                        action: `/ajax/cupboard/${type}Action/${id}`,
                        form: data.data.form
                    }, { root: true })

                    // dispatch('ProductCupboard/setCupboard', data, { root: true })

                    state.subloading = false
                })
        },

        /**
         * @description Pobieranie formularza z danymi zalogowanej osoby
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        editPersonalData({ state, commit }) {
            state.loading = true

            axios.get('/myAccount/ajax/personalData/editPersonalData')
                .then(data => {
                    commit('CustomForm/addForm', {
                        name: 'personalData',
                        action: '/myAccount/ajax/personalData/editPersonalData',
                        form: data.data.form,
                        info: {
                            clearAfter: false
                        }
                    }, { root: true })

                    state.loading = false
                })
        },

        /**
         * @description Pobieranie formularza do zmiany hasła
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        passwordChange({ state, commit }) {
            state.loading = true

            axios.get('/myAccount/ajax/personalData/passwordChange')
                .then(data => {
                    commit('CustomForm/addForm', {
                        name: 'passwordChange',
                        action: '/myAccount/ajax/personalData/passwordChange',
                        form: data.data.form
                    }, { root: true })

                    state.loading = false
                })
        },

        /**
         * @description Pobieranie listy z danymi mozliwymi do pobrania
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        downloadData({ state }) {
            if (state.downloadLinks) return

            // state.loading = true
            axios.get('/myAccount/ajax/personalData/download')
                .then(data => {
                    const { data: { links = {} } } = data

                    links.forEach(link => {
                        link.sent = false
                        link.lock = false
                        link.status = false
                    })

                    // state.loading = false
                    state.downloadLinks = links
                })
        },

        /**
         * @description Pobieranie konkretnego pliku z listy
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {String} payload nazwa danej do pobrania
         */
        downloadFile({ dispatch, state }, payload) {
            const link = state.downloadLinks.find(link => link.name == payload)

            if (link.lock) return

            link.lock = true
            axios.post(`/${link.url}`)
                .then(data => {
                    link.lock = false
                    link.sent = true
                    link.status = data.data.status
                    dispatch('Notifications/addResponse', data, { root: true })
                })
        },

        /**
         * @description Pobieranie listy dostepnych adresow oraz informacji czy mozna dodac kolejne
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        getAddress({ state }) {
            state.loading = true

            axios.get('/myAccount/ajax/address/overview')
                .then(data => {
                    state.address = data.data
                    state.loading = false
                })
        },

        /**
         * @description Pobieranie formularza do edycji lub dodawania adresu
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload dane do backendu { typ, id } - typ: edit/add
         */
        getAddressForm({ state, commit }, payload) {
            state.subloading = true
            const { type, id } = payload

            axios.get(`/myAccount/ajax/address/${type}/${id}`)
                .then(data => {
                    commit('CustomForm/addForm', {
                        name: 'myAccountAddress',
                        action: `/myAccount/ajax/address/${type}/${id}`,
                        form: data.data.form
                    }, { root: true })

                    state.subloading = false
                })
        },

        /**
         * @description Ustawienie wybranego adresu jako domyslny w danej grupie
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Number} payload id adresu
         */
        setDefaultAddress({ state, dispatch }, payload) {
            state.loading = true
            axios.get(`/myAccount/ajax/address/setDefault/${payload}`)
                .then(_ => dispatch('getAddress'))
        },

        /**
         * @description Usuwanie wybranego adresu
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Number} payload id adresu
         */
        removeAddress({ state, dispatch }, payload) {
            state.loading = true
            axios.delete(`/myAccount/ajax/address/delete/${payload}`)
                .then(_ => dispatch('getAddress'))
        },

        /**
         * @description Pobieranie listy z dostepnymi punktami i listy zamowien
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        loyalty({ state }) {
            state.loading = true
            axios.get(`/myAccount/ajax/loyaltyProgram/index`)
                .then(data => {
                    state.points = data.data
                    state.loading = false
                })
        },

        /**
         * @description Pobieranie listy produktow z opiniami (z dodanymi i mozliwymi do dodania)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        opinions({ state }) {
            state.loading = true
            axios.get(`/myAccount/ajax/opinions/overview`)
                .then(data => {
                    const { data: { items = []} } = data

                    state.opinions = items
                    state.loading = false
                })
        },

        /**
         * @description Pobieranie całej listy zamówień - ze szczegółami
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Number} payload - nr strony
         */
        orders({ state }, payload) {
            state.loading = true
            axios.get(`/myAccount/ajax/orders/overview${payload ? `/${payload}` : ''}`)
                .then(data => {
                    const { data: { orders = [], payment = {}, paginator = {} } } = data

                    state.orders = orders
                    state.ordersPayment = payment
                    state.paginator = paginator
                    state.loading = false
                })
        },

        /**
         * @description Pobieranie listy tematów w BOK
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Number} payload - nr strony
         */
        bok({ state }, payload) {
            state.loading = true
            axios.get(`/myAccount/ajax/bok/overview${payload ? `/${payload}` : ''}`)
                .then(data => {
                    const { data: { threads = [], paginator = {} } } = data

                    state.threads = threads
                    state.paginator = paginator
                    state.loading = false
                })
        },

        /**
         * @description Pobieranie formularza do tworzenia nowego tematu w BOK (na modalu)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        getBokCreateForm({commit, state}) {
            state.subloading = true
            axios.get(`/myAccount/ajax/bok/create`)
                .then(data => {
                    commit('CustomForm/addForm', {
                        name: 'myAccountBokCreate',
                        action: `/myAccount/ajax/bok/create`,
                        form: data.data.form
                    }, { root: true })

                    state.subloading = false
                })
        },

        /**
         * @description Pobieranie listy wiadomości w wybranym temacie
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Number} payload id tematu
         */
        conversation({ commit, state }, payload) {
            state.loading = true
            axios.get(`/myAccount/ajax/bok/conversation/${payload}`)
                .then(data => {
                    const {
                        data: {
                            form = null,
                            isClosed = true,
                            failed = false,
                            messages = [],
                            thread = {}
                        }
                    } = data

                    state.conversation = {
                        isClosed,
                        failed,
                        messages,
                        thread
                    }

                    commit('CustomForm/addForm', {
                        name: 'myAccountBokReply',
                        action: `/myAccount/ajax/bok/conversation/${payload}`,
                        form
                    }, { root: true })

                    state.loading = false
                })
                .catch(_ => {
                    state.conversation.failed = true
                    state.loading = false
                })
        },

        /**
         * @description Pobieranie listy produtków dla wybranego zwrotu z listy (lazy loader)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Number} payload id zwrotu
         */
        getReturnedItems({ state }, payload) {
            if (!payload) return
            const item = state.returns.returned.find(i => i.return_id == payload)

            if (item.products.length) return

            axios.get(`/ajax/returns/items/${payload}`)
                .then(data => {
                    item.products = data.data.items
                })
        },

        /**
         * @description Zwraca liste dokonanych zwrotow z paginacja
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Number} payload nr strony
         */
        getReturned({ state }, payload) {
            state.loading = true
            axios.get(`/ajax/returns/index/${payload}`)
                .then(data => {
                    const { data: { returns = [], paginator = {} } } = data

                    returns.forEach(item => item.products = [])

                    state.returns.returned = returns
                    state.paginator = paginator
                    state.loading = false
                })
        },

        /**
         * @description Lista przygotowana pod nowe zwroty
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        getReturns({ state }) {
            state.loading = true
            axios.get(`/ajax/returns/create`)
                .then(data => {
                    const {
                        data: {
                            items: orders = []
                        }
                    } = data

                    state.returns.orders = orders
                    state.loading = false
                })
        },

        /**
         * @description Kliknieto produkt do zwrotu na zamowieniu - dodaj go do listy produktow
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload obiekt z danymi z produktu (caly json)
         */
        addReturnProduct({ dispatch, state, getters, rootGetters }, payload) {
            const orderAdded = getters.getOrderFromReturnsList(state.returns.products[0]) || {}
            const orderToAdd = getters.getOrderFromReturnsList(payload) || {}

            if (orderAdded.iso && orderAdded.iso != orderToAdd.iso) {
                dispatch('Notifications/add', {
                    temporary: 5,
                    variables: {
                        color: 'danger',
                        content: rootGetters['Lang/get']('js_myAccount-returns-cannot-add-multiple-currencies')
                    }
                }, { root: true })
                return
            }

            //kopuiujemy produkt zeby nie operowac na tym z listy
            const product = JSON.parse(JSON.stringify(payload))

            //ustawienie max wartosci dla "ilosc" takiej jaka byla ilosc zakupionego towaru (do componentu 'action')
            product.stock = product.quantity
            state.returns.products.push(product)

            dispatch('getReturnsValue')
            setTimeout(()=>{
                const list = document.querySelector('#product-return-list').getBoundingClientRect()

                window.scrollTo({
                    top: list.top + window.scrollY - 80,
                    behavior: 'smooth'
                })
            }, 1000)
        },

        /**
         * @description Usuwanie produktu ze zwrotow (calkowicie)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload obiekt z danymi produktu
         */
        removeReturnProduct({ dispatch, state }, payload) {
            const index = state.returns.products.findIndex(p => p.id == payload.id)

            state.returns.products.splice(index, 1)

            dispatch('getReturnsValue')
        },

        /**
         * @description Zmiana ilości sztuk zwracanego produktu
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload { id, q }
         */
        returnQuantity({ dispatch, state }, payload) {
            if (state.loading) return

            const { id, q } = payload
            const product = state.returns.products.find(p => p.id == id)

            product.quantity += q

            if (product.quantity < 1) product.quantity = 1
            if (product.quantity > product.stock) product.quantity = product.stock

            dispatch('getReturnsValue')
        },

        /**
         * @description Zwraca sume kwot jaka zostanie zwrocona przy obecnym stanie zwrotu (podsumowanie)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        getReturnsValue({ commit, state }) {
            const items = state.returns.products.reduce((r, item) => {
                r.push({
                    order_item_id: item.order_item_id,
                    quantity: item.quantity
                })
                return r
            }, [])

            state.loading = true
            axios.post(`/ajax/returns/set`, { items })
                .then(data => {
                    const { data: { creator = {}, errors = [] } } = data

                    // #71560 - php nie chcialo sie robic wiec trzeba liczyc tu...
                    creator.items.forEach(item => {
                        const product = state.returns.products.find(product => product.order_item_id === item.order_item_id)

                        Object.keys(product.price).forEach(price => {
                            product.value[price] = product.price[price] * product.quantity
                        })
                        product.value.promoted = item.sumValueToDisplay
                    })
                    // jak php sie ogarnie to usunac i zamienic na obsluge ich magicznych zwrotek
                    state.returns.creator = creator
                    commit('returnProductErrors', errors)
                    state.loading = false
                })
        },

        /**
         * @description Pobieranie formularza do tworzenia nowego tematu w BOK (na modalu)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        getReturnsForm({commit, state}) {
            state.subloading = true
            axios.get(`/ajax/returns/setClient`)
                .then(data => {
                    commit('CustomForm/addForm', {
                        name: 'myAccountReturnsForm',
                        action: `/ajax/returns/setClient`,
                        form: data.data.form,
                        info: {
                            clearAfter: false
                        }
                    }, { root: true })

                    state.subloading = false
                })
        },

        /**
         * @description Pobieranie formularza ze zgodami na stronie potwierdzenia
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        getReturnsConfirmForm({ commit, state}) {
            state.loading = true
            axios.get(`/ajax/returns/preview`)
                .then(data => {
                    commit('CustomForm/addForm', {
                        name: 'myAccountReturnConfirmForm',
                        action: `/ajax/returns/preview`,
                        form: data.data.form
                    }, { root: true })

                    state.loading = false
                })
        },

        /**
         * @description Czyszczenie danych ze zwrotu po wyslaniu formularza z potwierdzeniem
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        clearReturns({ state }) {
            state.returns = {
                creator: {},
                orders: [],
                products: [],
                returned: []
            }
        }

    }
}
