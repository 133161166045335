var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "required-label":
          (_vm.field.rules || []).includes("required") && _vm.labelRequired,
      },
    },
    [
      _vm.ie
        ? _c(
            "select",
            {
              staticClass: "form-control form-field__select",
              on: { change: _vm.setValue },
            },
            _vm._l(_vm.values, function (val) {
              return _c(
                "option",
                { key: val.value, domProps: { value: val.value } },
                [_vm._v("\n            " + _vm._s(val.label) + "\n        ")]
              )
            }),
            0
          )
        : _c("v-select", {
            attrs: {
              label: "label",
              options: _vm.values,
              name: _vm.field.name,
              disabled: _vm.field.disabled,
              value:
                _vm.values.find((v) => v.value == _vm.field.value) ||
                _vm.field.label,
            },
            on: { input: _vm.setValue },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }