var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.$ll("productBasketModalAvailabilityNotifyTitle"),
        "ok-title": _vm.$ll("basketModalAvailabilityNotifySubmit"),
        "cancel-title": _vm.$ll("basketBackToShoppingButton"),
        centered: "",
        "ok-disabled": _vm.sent,
      },
      on: {
        ok: (e) => {
          e.preventDefault()
          _vm.$refs.form.submit()
        },
        hidden: function ($event) {
          return _vm.$emit("hidden")
        },
      },
      model: {
        value: _vm.outOfStock,
        callback: function ($$v) {
          _vm.outOfStock = $$v
        },
        expression: "outOfStock",
      },
    },
    [
      _c(
        "div",
        { staticClass: "text-left" },
        [
          _c("custom-form", {
            ref: "form",
            attrs: {
              target: "availabilityNotice",
              action: `/ajax/availabilityNotice/${
                _vm.variants()[0].variant_id
              }`,
            },
            on: {
              submitted: function ($event) {
                _vm.sent = true
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }