<template>
    <div>
        <product-item
            v-for="item in items"
            :key="item.id"
            :item="item"
            :view-currency="viewCurrency"
            />
    </div>
</template>

<script>
import ProductItem from '@components/product/short-preview'
export default {
    components: {
        ProductItem
    },

    props: {
        productDetail: {type: String, default: ''},
        viewCurrency: {type: String, default: ''}
    },
    data() {
        return {
            items: JSON.parse(this.productDetail) || {}
        }
    }
}
</script>
