import json from '@db'
import axios from 'axios'

const dataPrepare = attr => {
    const chosen = {}

    attr.forEach(item => {
        //dodajemy wylacznik dla kazdego elementu VALUE
        item.values.forEach(value => {
            value.disabled = false
            value.notifyIco = false
        })

        //tworzymy obiekt chosen dla zaznaczen
        chosen[ item.id ] = item.values.length > 1 ? null : item.values[0].id

        // przypisujemy id koloru z url hasha jeżeli istnieje
        // Trzeba w środku atrybutu szukać zdjęć i jeśli takowe są to, też potraktować to jako atrybut kolor - K. Wieloch nie chciał dodać specjalnego typu
        if (item.type == 'attributeColor' || item.type == 'attributeList' && item.values[0].hasOwnProperty('photo')) {
            const hash = location.href.split('#')[1] || null
            const found = !!item.values.find(color => color.id == hash)

            chosen[ item.id ] = found && hash || (item.values.length > 1 ? null : item.values[0].id)
        }
    })

    return { chosen, attr }
}

if (json.productAttr) {
    const { chosen, attr } = dataPrepare(json.productAttr)

    json.chosen = chosen
    json.productAttr = attr
}

/**
 * Kolejne smieci dodane do merlina bo php nie chce sie poprawic ....
 * colorId - oznaczenie nieoznaczonego koloru bo nie...
 *
 */
(json.productAttr || []).forEach(attr => {
    attr.color = attr.id === json.colorId
})

/**
 * #62148 - wyrzucenie wariantow ktore sa puste
 * dodatkowo usuniecie atrybutow ktore nie maja swojego wariantu po segregacji
*/
// if ( json.variants ) {

//     json.variants = json.variants.filter(v => ( v.stock - v.blocked_stock))
// }
// if ( json.productAttr ) {

//     const variants = json.variants.reduce((r,i) => {
//         Object.keys(i.attributes).forEach(a => {
//             r[a] = r[a] || []
//             r[a].push(i.attributes[a].attribute_value_id)
//         })
//         return r
//     }, {})

//     json.productAttr.forEach(a => {
//         a.values = a.values.filter(v => variants[a.id].includes(v.id))
//     })
// }

export default {
    namespaced: true,
    state: () => ({
        buttonDisabled: false,
        basketModal: false,
        outOfStock: false,
        paramsWarn: false,
        quantity: 1,
        attributes: json.productAttr || [],
        chosen: json.chosen || {},
        variants: json.variants || [],
        lastAttr: [],
        visibility: (json.product || {}).visibility,
        modelGroups: json.modelGroups || false,
        notifyLoggedInfo: null,
        maxOpinions: 0,
        chosenCode: '',
        variantFiches: [],
        category: ((json.productCategory || []).pop() || {}).name
    }),
    getters: {

        quantity(state) {
            return state.quantity
        },

        chosen(state) {
            return state.chosen
        },

        chosenIds(state) {
            return Object.values(state.chosen).filter(Boolean).map(Number)
        },

        variants(state) {
            return (chosen = state.chosen) =>
                state.variants.filter(v => {
                    for (const attr in v.attributes) {
                        if (chosen[attr] && v.attributes[attr].attribute_value_id != chosen[attr]) return false
                    }
                    return true
                })
        },

        productSet(state) {
            return Object.keys(state.chosen).every(i => state.chosen[ i ] !== null)
        },

        isPromo(state) {
            return state.variants.some(v => v.is_promotion)
        },

        getPrice(state, getters, rootState, rootGetters) {
            const prices = getters
                .variants()
                .map(v => {
                    const obj = v.prices[ rootGetters['Setup/currencyId'] ]

                    return {
                        current: obj.promo_brutto ? obj.promo_brutto : obj.brutto,
                        old: obj.brutto,
                        lowestMonthPrice: obj.lowest_month_price
                    }
                })
                .sort((a, b) => a.current > b.current ? 1 : -1)

            return {
                set: getters.productSet,
                ...prices.shift()
            }
        },

        availability(state, getters, rootState) {
            const variant = getters.variants()

            return {
                available: (variant[0] || {}).available,
                variants: variant.length,
                stock: (variant[0] || {}).stock - (variant[0] || {}).blocked_stock,
                inBasket: (rootState.Basket.products.find(p => p.variant == (variant[0] || {}).variant_id) || {}).quantity || 0
            }
        }
    },
    actions: {

        /**
         * Podmiana wybranego atrybuty na karcie produktu (zaznacz/odznacz)
         *
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} payload {id, value wariantu}
         */
        setAttr({ state, dispatch }, payload) {
            const { id, value, disableToggle } = payload
            //caly czas pracujemy na kopii stanu (commit na koncu kolejnej akcji)
            const stateCopy = JSON.parse(JSON.stringify(state))
            const { chosen } = stateCopy
            let { lastAttr } = stateCopy

            if (disableToggle) {
                chosen[ id ] = value
            }
            else {
                chosen[ id ] = chosen[ id ] === value ? null : value
            }

            // historia zmian atrybutow - ostatnia zmiana na koncu
            lastAttr = lastAttr.filter(i => i !== id)
            lastAttr.push(id)

            dispatch('setDisabledNotify', { chosen, lastAttr })
        },

        /**
         * Wybór pierwszego dostępnego atrybutu
         *
         * @param {Number} payload filterIndex
         */
        setAvailableAttr({ dispatch, state }, payload) {
            const filterIndex = payload ? payload : 0
            const attributes = state.attributes.filter(attr => !state.modelGroups || !attr.color)
            const filter = attributes[filterIndex]

            if (filter) {
                const availableAttr = filter.values.find(f => f.notifyIco === false && f.disabled === false)
                const id = filter.id

                if (availableAttr) {
                    const value = availableAttr.id

                    dispatch('setAttr', {id, value, disableToggle: true})
                    return dispatch('setAvailableAttr', filterIndex + 1)
                }
            }
            else if (attributes.length < filterIndex) {
                return dispatch('setAvailableAttr', filterIndex + 1)
            }
        },

        /**
         * Sprawdzanie i oznaczanie flag (disabled, notify (koperta)) na atrybutach w miare zaznaczania/odznaczania wariantów
         *
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} [payload={}] Dane z metody setAttr (chosen, lastAttr)
         */
        setDisabledNotify({ commit, state, getters }, payload = {}) {
            //kopia stanu dla zmiennych domyslnych (jezeli nie przyszly z setAttr)
            const altState = JSON.parse(JSON.stringify(state))

            const {
                attributes = altState.attributes,
                chosen = altState.chosen,
                lastAttr = altState.lastAttr
            } = payload

            let available = getters.variants(chosen)

            //jezeli liczba wariantow == 0, odznaczaj poszczegolne atrybuty az do uzyskania min. 1 dostepnego wariantu
            const undo = lastAttr.slice()

            while (!available.length && undo.length) {
                chosen[ undo.shift() ] = null
                available = getters.variants(chosen)
            }
            //close all
            attributes.forEach(attr => {
                attr.values.forEach(val => {
                    val.disabled = true
                    val.notifyIco = true
                })
            })

            //open avail
            available.forEach(v => {
                Object.entries(v.attributes).forEach(([
                    id, {attribute_value_id: value}
                ]) => {
                    attributes.find(a => a.id == id).values.find(v => v.id == value).disabled = false

                    if (v.stock - v.blocked_stock && v.available) {
                        attributes.find(a => a.id == id).values.find(v => v.id == value).notifyIco = false
                    }
                })
            })

            //oznacz mozliwe do zaznaczenia przy zmianie tego samego wariantu
            Object.keys(chosen).forEach(i => {
                if (chosen[i] !== null) {
                    const tmp = getters.variants({ ...chosen, [i]: null })

                    tmp.forEach(v => {
                        const {
                            attributes: {
                                [i]: atrs = {}
                            } = {}
                        } = v

                        const state_attr = attributes.find(a => a.id == i).values.find(v => v.id == atrs.attribute_value_id)

                        if (typeof state_attr === 'object' && state_attr.hasOwnProperty('disabled')) {
                            attributes.find(a => a.id == i).values.find(v => v.id == atrs.attribute_value_id).disabled = false

                            //sprawdzenie czy atrybut jest dostepny
                            if (v.stock - v.blocked_stock && v.available && !Object.entries(chosen).every(([
                                ch_k, ch_v
                            ]) => v.attributes[ch_k].attribute_value_id === ch_v)) {
                                attributes.find(a => a.id == i).values.find(v => v.id == atrs.attribute_value_id).notifyIco = false
                            }
                        }
                    })
                }
            })
            available.length === 1 ? state.chosenCode = available[0].code : state.chosenCode = ''
	    const ficheName = []

            state.variantFiches = []
            state.variants.filter(v => {
                for (const attr in v.attributes) {
                    for (const fiche in v.fiches) {
                        if (v.fiches[fiche].attributes_values_ids.includes(chosen[attr])) {
                            if (state.variantFiches.length) {
                                if (!ficheName.includes(v.fiches[fiche].name)) {
                                    ficheName.push(v.fiches[fiche].name)
                                    state.variantFiches.push(v.fiches[fiche])
                                }
                            }
                            else {
                                ficheName.push(v.fiches[fiche].name)
                                state.variantFiches.push(v.fiches[fiche])
                            }
                        }
                    }
                }
                return true
            })
            commit('setAttr', {
                attributes,
                chosen,
                lastAttr
            })

            //odznacz warning tylko jezeli jzu byl wczesniej oznaczony jako TRUE
            if (state.paramsWarn) {
                state.paramsWarn = !getters.productSet
            }
        },

        addBasket({ state, getters, dispatch, commit }) {
            state.paramsWarn = !getters.productSet
            if (state.paramsWarn) {
                return
            }

            const variant = getters.variants()[0]

            if (!(variant.stock - variant.blocked_stock) || !variant.available) {
                commit('outOfStock', true)
                return
            }

            commit('setBtnDisabled', true)
            axios.post('/ajax/basket/add', {
                id: variant.variant_id,
                quantity: state.quantity
            })
                .then(response => {
                    if (response.data.status) {
                        state.basketModal = true
                        const addSizes = variant.attributes['246'] ? [variant.attributes['246'].value] : []

                        window.dataLayer.push({
                            event: "addToCart",
                            addId: variant.product_id,
                            addName: variant.product_name,
                            addPrice: variant.prices['1'].promo_brutto ? variant.prices['1'].promo_brutto : variant.prices['1'].brutto,
                            addSizes,
                            addCategory: state.category,
                            addVariant: variant.variant_id,
                            addQuantity: state.quantity,
                            coupon: response.data.coupon ? response.data.coupon : ''
                        })
                        window._edrone = window._edrone || {}
                        window._edrone.action_type = "add_to_cart"
                        window._edrone.init?.()
                    }
                    else {
                        commit('outOfStock', true)
                    }

                    commit('Basket/update', response, { root: true })
                })
                .catch(error => {
                    console.error(error)
                    dispatch('Notifications/addResponse', error, { root: true })
                })
                .then(() => commit('setBtnDisabled', false))
        },

        checkAddCupboard({ state, getters }) {
            state.paramsWarn = !getters.productSet
            if (state.paramsWarn) {
                return
            }
        },

        addCupboard({ state, getters, dispatch }) {
            state.paramsWarn = !getters.productSet
            if (state.paramsWarn) {
                return
            }
            window.dataLayer.push({
                event: "addToCupboard",
                addToCupboardId: getters.variants()[0].product_id
            })

            dispatch('ProductCupboard/add', getters.variants()[0], {root: true})
        },
        setMax({state}, payload) {
            const rating = payload.rating
            let max = 0

            Object.keys(rating).forEach((key) => {
                max += rating[key]
            })
            state.maxOpinions = max
        }
    },
    mutations: {

        setAttribute(state, payload) {
            const { id, value } = payload

            state.chosen[ id ] = state.chosen[ id ] == value ? null : value

            state.lastAttr = state.lastAttr.filter(last => last !== id)
            state.lastAttr.push(id)
        },

        setBasket(state, payload) {
            state.basketModal = payload
        },

        setAttr(state, payload) {
            const { attributes, chosen, lastAttr } = payload

            state.attributes = attributes
            state.chosen = chosen
            state.lastAttr = lastAttr
        },

        setQuantity(state, payload) {
            typeof payload !== 'object' ? state.quantity = parseInt(payload) : state.quantity += payload.q === 'inc' ? 1 : -1
            state.quantity = state.quantity > 0 ? state.quantity : 1
        },

        outOfStock(state, payload) {
            state.outOfStock = payload
        },

        setBtnDisabled(state, payload) {
            state.buttonDisabled = payload
        },

        setFromAjax(state, payload) {
            const { attributes = [], variants = [] } = payload
            const { chosen, attr } = dataPrepare(attributes)

            state.paramsWarn = false
            state.attributes = attr
            state.chosen = chosen
            state.variants = variants
        }
    }
}
