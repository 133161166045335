var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "prices",
      attrs: {
        itemprop: "offers",
        itemscope: "",
        itemtype: "http://schema.org/Offer",
      },
    },
    [
      _c("meta", { attrs: { itemprop: "priceCurrency", content: "PLN" } }),
      _vm._v(" "),
      _c("meta", { attrs: { itemprop: "price", content: _vm.price.current } }),
      _vm._v(" "),
      !_vm.price.set
        ? _c("span", { domProps: { textContent: _vm._s("od") } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "strong",
        { class: { "text-primary": _vm.price.current != _vm.price.old } },
        [_c("price", { attrs: { value: _vm.price.current } })],
        1
      ),
      _vm._v(" "),
      _vm.price.current != _vm.price.old
        ? _c(
            "s",
            [
              _vm._v("\n        ("),
              _c("price", { attrs: { value: _vm.price.old } }),
              _vm._v(")\n    "),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }