var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "text-center",
      class: { fixed: _vm.fixedMiniBasketButtons },
    },
    [
      _c("basket-list"),
      _vm._v(" "),
      _vm.products.length
        ? [
            _c("div", { staticClass: "mini-basket-buttons" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary mb-3 mt-4",
                  attrs: { href: _vm.$langUrl("/basket") },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$ll("basketGoToBasketButton")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { href: _vm.$langUrl("/basket") },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggle.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$ll("basketBackToShoppingButton")) +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ]
        : [_vm._v("\n        " + _vm._s(_vm.$ll("basketIsEmpty")) + "\n    ")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }