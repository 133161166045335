var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.list, function (img, id) {
      return _c(
        "div",
        {
          key: id,
          staticClass: "col-12 col-lg-6 pb-3 position-relative img-container",
        },
        [
          img
            ? _c("Picture", {
                staticClass: "img-fluid loupe",
                attrs: {
                  src: _vm.$photoUrl(img.path, 520),
                  alt: img.name || img.defaultAlt,
                  width: img.w,
                  height: img.h,
                },
                on: {
                  click: function ($event) {
                    return _vm.photoswipe(id)
                  },
                },
              })
            : _c("div", { staticClass: "img-placeholder" }),
          _vm._v(" "),
          !id && _vm.fiches
            ? _c(
                "span",
                {
                  staticClass: "position-absolute",
                  staticStyle: { top: "5px", left: "15px" },
                },
                _vm._l(_vm.fiches, function (item, key) {
                  return _c("Picture", {
                    key: key,
                    staticClass: "product-item__badges-item",
                    attrs: { src: _vm.$photoUrl(item.path), alt: item.name },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !id && _vm.variantFiches
            ? _c(
                "span",
                {
                  staticClass: "position-absolute",
                  staticStyle: { top: "0", left: "0" },
                },
                _vm._l(_vm.variantFiches, function (item, key) {
                  return _c("Picture", {
                    key: key,
                    staticClass: "product-item__badges-item",
                    attrs: { src: _vm.$photoUrl(item.path), alt: item.name },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }