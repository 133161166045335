import json from '@db'
import axios from 'axios'

export default {
    namespaced: true,
    state() {
        return {
            modal: false,
            disabled: false,
            search: json.category_phrase || '',
            hints: null,
            hintsVisible: true,
            searchCol: false
        }
    },
    mutations: {
        setModal(state, payload) {
            state.modal = payload
        },
        setSearch(state, payload) {
            state.search = payload
            state.searchCol = true
            if (!payload) {
                state.hintsVisible = false
            }
            else {
                state.hintsVisible = true
            }
        },
        setDisabled(state, payload) {
            state.disabled = !!payload
        },
        setHints(state, payload) {
            const {
                data: {
                    hints = []
                } = {}
            } = payload

            state.hints = hints || []
        }
    },
    actions: {
        query({ commit, state }) {
            commit('setDisabled', true)
            axios.get('/search/hints', { params: { phrase: state.search }})
                .then(res => {
                    commit('setDisabled', false)
                    commit('setHints', res)
                    // const query = "phrase=" + state.search

                    // dispatch('ProductList/getProducts', {params_str: "", query_str: query}, { root: true })
                })
        },
        getProducts({ dispatch, state }) {
            if (state.search !== '') {
                state.searchCol = true
                const query = "phrase=" + state.search

                dispatch('ProductList/getProducts', {params_str: "", query_str: query}, { root: true })
            }
        }
    }
}