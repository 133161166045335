<template>
    <form v-if="show">
        <label
            class="text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black"
            v-text="$ll('basketGetDeliveryCountry')"
            />
        <div class="form-field">
            <select
                v-if="ie"
                v-model.number="selected"
                class="form-control form-field__select"
                >
                <option
                    v-for="country in countries"
                    :key="country.id"
                    :value="country.id"
                    v-text="country.name"
                    />
            </select>
            <v-select
                v-else
                label="label"
                :options="customCountries"
                :value="customCountries.find(v=> v.value == selected)"
                @input="setValue"
                />
        </div>
    </form>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    components: {
        vSelect
    },
    data() {
        return {
            ie: false
        }
    },
    computed: {
        selected: {
            get() {
                return this.$store.state.Basket.countries.selected
            },
            set(payload) {
                this.$store.dispatch('Basket/setCountry', payload)
            }
        },
        ...mapState('Basket', {
            countries: state => state.countries.items
        }),
        ...mapState('Basket', ['customCountries']),
        show: {
            get() {
                return this.countries.length > 1
            }
        }
    },

    mounted() {
        this.getList()
    },
    methods: {
        ...mapActions({
            getList: 'Basket/getCountryList'
        }),
        setValue(payload) {
            if (this.ie) {
                this.$store.dispatch('Basket/setCountry', payload.target.value)
            }
            else {
                this.$store.dispatch('Basket/setCountry', payload.value)
            }
        }
    },
    beforeMount() {
        if (navigator.appName == 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
            this.ie = true
        }
    }
}
</script>
