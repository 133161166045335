<template>
    <transition-group
        class="list-unstyled text-left px-2"
        tag="ul"
        name="fade-right"
        >
        <!-- <li :key="'test'" v-text="' '" /> -->
        <li
            v-for="item in products"
            :key="item.id"
            class="d-flex mb-3 pb-3"
            :class="{ shown: active == item.id }"
            @mouseenter="active = item.id"
            @mouseleave="active = false"
            >
            <!-- link usun -->
            <div class="mini-basket-item">
                <div class="image-wrapper">
                    <div
                        v-if="showRemove"
                        class="basket-remove"
                        >
                        <a
                            class="btn btn-link"
                            @click.prevent="remove(item.id)"
                            >
                            <i class="i-close" />
                        </a>
                    </div>
                    <!-- miniaturka -->
                    <a
                        :href="item.url"
                        class="img-basket mx-2"
                        >
                        <Picture
                            v-if="item.photo"
                            class="img-fluid"
                            :src="$photoUrl(item.photo, 100)"
                            />
                        <div
                            v-else
                            class="img-placeholder"
                            />
                    </a>
                </div>
                <div class="flex-grow-1 d-flex flex-column pl-3 basket-product-content">
                    <!-- link tytul -->
                    <a
                        class="basket-product-title text-uppercase"
                        :href="item.url"
                        v-text="item.name"
                        />
                    <div>
                        <small v-text="item.code" />
                    </div>
                    <!-- atrybuty -->
                    <div
                        class="basket-product-attr text-black"
                        v-text="item.attributes.map(p => p.value).join(' / ')"
                        />

                    <!-- ilosc/cena -->
                    <div class="mt-3 d-flex">
                        <!--<div class="flex-grow-1">-->
                        <!--<div v-text="'Ilosc'" />-->
                        <!--<div v-text="item.quantity" />-->
                        <!--</div>-->
                        <div class="flex-grow-1">
                            <!--<div v-text="'Cena'" />-->
                            <span v-if="item.quantity > 1">
                                {{ item.quantity }} x
                            </span>
                            <price :value="item.price.promoted || item.price.regular" />
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </transition-group>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Price from '@components/price'

export default {

    components: {
        Price
    },

    props: {
        showRemove: { type: Boolean, default: true }
    },
    data() {
        return {
            basketHover: false,
            active: false
        }
    },

    computed: {
        ...mapState(
            'Basket', ['products']
        )
    },

    methods: {
        ...mapActions({
            remove: 'Basket/remove'
        })
    }
}
</script>

<style lang="scss">

@keyframes fade-from-right {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-right-enter-active {
    animation: fade-from-right .5s ease 1;
}

.fade-right-leave-active {
    animation: fade-from-right .5s ease 1 reverse;
}
</style>

