var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "size-table-modal",
      attrs: {
        title: _vm.$ll("productSizeTableModalTitle"),
        size: "lg",
        centered: "",
        "ok-only": "",
      },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _vm.data
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { class: { "col-7": _vm.data.photo, "col-12": !_vm.data.photo } },
              [
                _c("size-table", {
                  staticClass: "table-responsive-lg",
                  attrs: { data: _vm.data.table_structure },
                }),
                _vm._v(" "),
                _c("p", {
                  staticClass: "text-left",
                  domProps: { innerHTML: _vm._s(_vm.data.how_to_measure) },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.data.photo
              ? _c(
                  "div",
                  { staticClass: "col-5" },
                  [
                    _c("Picture", {
                      attrs: { src: _vm.$photoUrl(_vm.data.photo.url) },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _c("div", { staticClass: "text-center py-5" }, [
            _c("i", { staticClass: "mdi mdi-36px mdi-loading mdi-spin" }),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }