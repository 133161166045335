<template>
    <div
        v-if="price"
        :class="['product-card__lowest-month-price', {'product-card__lowest-month-price--italic': italic}]"
        >
        {{ $ll('productCardLowestMonthPrice') }} <b class="product-card__lowest-month-price-value">
            <price :value="price" />
        </b>
    </div>
</template>

<script>
import Price from '@components/price'

export default {
    components: {
        Price
    },
    props: {
        value: {
            type: Number
        },
        italic: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        price() {
            return this.value || this.$store.getters['ProductCard/getPrice'].lowestMonthPrice
        }
    }
}
</script>

<style lang="scss" scoped>
.product-card {
  &__lowest-month-price {
    line-height: 1.5;
    color: black;
    font-size: 11px;
    @media(max-width: 992px) {
      margin: auto;
    }
    &--italic {
      font-style: italic;
    }
    &-value {
      font-weight: 900;
    }
  }
}
</style>
