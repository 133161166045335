<template>
    <div
        v-if="select.length"
        class="fixed-bottom"
        >
        <div class="card p-3">
            <div class="container">
                <div class="d-flex justify-content-end flex-wrap">
                    <button
                        class="order-md-2 btn btn-primary ml-md-3 mb-3 mb-md-0 w-sm-100"
                        @click.prevent="addBasket()"
                        v-text="$ll('myCupboardsButtonAddSelectedToBasket')"
                        />
                    <button
                        class="order-md-1 btn btn-secondary w-sm-100"
                        @click.prevent="deleteProducts()"
                        v-text="$ll('myCupboardsButtonDeleteSelected')"
                        />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState('ProductCupboard', ['select'])
    },
    methods: {
        ...mapActions({
            addBasket: 'ProductCupboard/addBasket',
            deleteProducts: 'ProductCupboard/deleteProducts',
        })
    }
}
</script>
