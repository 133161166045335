var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-field" }, [
    _c(
      "div",
      { staticClass: "form-field__checkbox form-field__checkbox--bordered" },
      [
        _c("input", {
          attrs: {
            id: "fc-" + _vm.filterIdVal + "-" + _vm.option.id + "-" + _vm.uid,
            type: "checkbox",
          },
          domProps: { checked: _vm.check.indexOf(_vm.option.id) >= 0 },
          on: {
            change: function ($event) {
              return _vm.$emit("toggle", _vm.option.id, $event.target.checked)
            },
          },
        }),
        _vm._v(" "),
        _c("label", {
          staticClass: "form__checkbox-label",
          attrs: {
            for: "fc-" + _vm.filterIdVal + "-" + _vm.option.id + "-" + _vm.uid,
          },
          domProps: { textContent: _vm._s(_vm.option.v) },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }