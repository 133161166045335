document.addEventListener('DOMContentLoaded', ()=> {
    const accordionButtons = Array.from(document.getElementsByClassName('footer-menu-trigger'))

    window.addEventListener('resize', updateAccordionCollapse)
    accordionButtons.forEach((el) => {
        el.addEventListener('click', () => {
            el.classList.toggle('active')
            const accContent = el.nextElementSibling

            accContent.classList.toggle('show')
            if (el.getAttribute('data-active') === 'true') {
                accContent.style.maxHeight = null
                el.setAttribute('data-active', false)
            }
            else {
                accContent.style.maxHeight = accContent.scrollHeight + 'px'
                el.setAttribute('data-active', true)
            }
        })
    })
    function updateAccordionCollapse() {
        if (window.innerWidth > 991) {
            accordionButtons.forEach(el=> {
                el.classList.remove('active')
                el.setAttribute('data-active', false)
                const accContent = el.nextElementSibling

                accContent.style.maxHeight = null
                accContent.classList.remove('show')
            })
        }
    }
})