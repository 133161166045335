var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "products-per-page float-left mt-3" }, [
    _c("span", { staticClass: "pr-2 d-none d-md-flex" }, [
      _vm._v("\n        " + _vm._s(_vm.$ll("categoryPerPage")) + ":\n    "),
    ]),
    _vm._v(" "),
    _c(
      "span",
      _vm._l(_vm.items, function (item) {
        return _c("div", {
          key: item,
          staticClass: "item",
          class: { active: item == _vm.index },
          domProps: { textContent: _vm._s(item) },
          on: {
            click: function ($event) {
              return _vm.set(item)
            },
          },
        })
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }