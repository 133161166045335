<template>
    <span
        v-b-tooltip.hover
        class="mt-3 py-1 px-2 mr-2 remove-filter d-inline-block black-text"
        :title="$ll('categoryRemoveFilters')"
        @click="$emit('remove', producerId)"
        >
        {{ $ll('producerFilterRemoveButtonLabel') }}: {{ name(producerId) }}
        <i class="i-close black-text" />
    </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        producerId: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            name: 'CategoryFilter/producerNameFromId'
        })
    }
}
</script>
