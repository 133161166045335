var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "basket-list" } },
    [
      _vm.products.length
        ? [
            _c("basket-breadcrumb"),
            _vm._v(" "),
            _c(
              "h1",
              { staticClass: "text-uppercase text-center my-3 my-lg-5" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$ll("basketTitle")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("hr", { staticClass: "mb-5" }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-xl-8" },
                [
                  _c("basket-list", {
                    attrs: { action: true, alternative: "" },
                  }),
                  _vm._v(" "),
                  _vm.giftPacking.available
                    ? _c("gift-packing", {
                        staticClass: "mb-3",
                        attrs: { "gift-packing-id": _vm.giftPacking.id },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-xl-4" }, [
                _c(
                  "div",
                  { staticClass: "mt-n3" },
                  [
                    _c("basket-discount"),
                    _vm._v(" "),
                    _c("basket-vouchers"),
                    _vm._v(" "),
                    _c("basket-loyalty"),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _c("basket-summary", { staticClass: "mb-5" }),
                    _vm._v(" "),
                    _c("basket-buttons"),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        : _c("div", [
            _c("div", { staticClass: "h2 text-center my-5" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$ll("basketIsEmpty")) +
                  "\n        "
              ),
            ]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }