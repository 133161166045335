<template>
    <div>
        <basket-modal
            :open="basketModal"
            :variant="variants()[0]"
            :photo-path="photo[0].path"
            @hidden="basketModal = false"
            />
        <basket-availability-notify
            v-if="!availability.stock || !availability.available"
            :open="outOfStock"
            :variant="variants()[0]"
            @hidden="outOfStock = false"
            />
        <basket-out-of-stock
            v-else
            :open="outOfStock"
            @hidden="outOfStock = false"
            />
    </div>
</template>

<script>
import BasketModal from './modals/add-basket'
import BasketOutOfStock from './modals/out-of-stock'
import BasketAvailabilityNotify from './modals/availability-notify'
import { mapGetters } from 'vuex'

export default {
    components: {
        BasketModal,
        BasketAvailabilityNotify,
        BasketOutOfStock
    },
    computed: {
        basketModal: {
            get() {
                return this.$store.state.ProductCard.basketModal
            },
            set(payload) {
                this.$store.commit('ProductCard/setBasket', payload)
            }
        },
        outOfStock: {
            get() {
                return this.$store.state.ProductCard.outOfStock
            },
            set(payload) {
                this.$store.commit('ProductCard/outOfStock', payload, { root: true })
            }
        },
        ...mapGetters({
            availability: 'ProductCard/availability',
            variants: 'ProductCard/variants',
            photo: 'ProductPhotos/list'
        })
    }
}
</script>
