<template>
    <div class="quick-filters">
        <quick-filter
            v-for="filter in filtersList"
            ref="filters"
            :key="filter.id"
            :check="checked[filter.id]"
            :filter="filter"
            :disabled="disabled"
            @submit="submit"
            />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import QuickFilter from './filter.vue'
export default {
    name: 'QuickFilters',
    components: {
        QuickFilter
    },
    data() {
        return {
            showAllFilters: true,
            isLoading: false
        }
    },
    computed: {
        ...mapState('CategoryFilter', [
            'filters', 'checked'
        ]),
        ...mapGetters('CategoryFilter', {
            common: 'keyCommon'
        }),
        ...mapGetters('Loader', {
            disabled: 'disabled'
        }),
        filtersList() {
            if (this.showAllFilters) return this.filters

            const commonFilters = this.common

            return this.filters.reduce((res, item) => {
                let filterId = item.id

                filterId = filterId.toString()
                if (commonFilters.includes(filterId)) {
                    res.push(item)
                }
                return res
            }, [])
        }
    },
    methods: {
        submit() {
            let checked = {}

            this.$refs.filters.forEach(component => {
                checked = { ...checked, ...component.get() }
            })

            this.$store.commit("CategoryFilter/setFilters", {...this.checked, ...checked })
            this.$store.dispatch("CategoryFilter/submit")
        }
    }
}
</script>
