var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "basket__view" },
    [
      _c("div", { staticClass: "basket__container" }, [
        _c(
          "div",
          { staticClass: "container px-3 px-sm-0" },
          [_c("router-view")],
          1
        ),
      ]),
      _vm._v(" "),
      _c("bottom-info"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }