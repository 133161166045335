var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.select.length
    ? _c("div", { staticClass: "fixed-bottom" }, [
        _c("div", { staticClass: "card p-3" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "d-flex justify-content-end flex-wrap" }, [
              _c("button", {
                staticClass:
                  "order-md-2 btn btn-primary ml-md-3 mb-3 mb-md-0 w-sm-100",
                domProps: {
                  textContent: _vm._s(
                    _vm.$ll("myCupboardsButtonAddSelectedToBasket")
                  ),
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addBasket()
                  },
                },
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "order-md-1 btn btn-secondary w-sm-100",
                domProps: {
                  textContent: _vm._s(
                    _vm.$ll("myCupboardsButtonDeleteSelected")
                  ),
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.deleteProducts()
                  },
                },
              }),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }