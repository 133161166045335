import json from '@db'
import axios from 'axios'
// import debounce from 'debounce'
import router from '@/router'
import langUrl from '@/helpers/lang-url'

/**
 * Debounce dla pobierania punktow na mapie w systemie platnosci
 */
// const getPoints = debounce(function(state, getters, payload) {

//     const id = getters.getDeliveryId
//     const geo = payload.toJSON()

//     //ograniczenie - wysylaj zadanie tylko gdy widok jest odpowiednio przyblizony
//     // if ( geo.north - geo.south > 5 ) {
//     //     // state.points = []
//     //     return
//     // }

//     state.loading = true
//     axios.post('/ajax/basket/points/part', { id, geo })
//         .then(res => {
//             state.points = res.data.items
//             state.loading = false
//         })
// }, 700)

export default {
    namespaced: true,
    state() {
        const {
            basket: {
                addresses: {
                    delivery = [],
                    invoice = []
                } = {},
                free_delivery = false,
                free_delivery_missing_amount = null,
                free_delivery_min_value = null,
                four_plus_one_promotion_value = 0,
                benefit = null,
                loyalty = {},
                voucher = {},
                promotion = {},
                discount = {},
                products = [],
                total = {},
                club_value = 0,
                club_valueIso = "",
                urlReferer = "/",
                giftPacking = {},
                payment_methods = []
            } = {}
        } = json

        return {
            addresses: {
                delivery,
                invoice
            },
            addressIndex: {
                delivery: null,
                invoice: null
            },
            countries: {
                items: {},
                selected: null
            },
            account_create: false,
            benefit,
            deliveries_costs: [],
            deliveryId: null,
            free_delivery,
            discount,
            loading: false,
            loyalty,
            voucher,
            promotion,
            payment: null,
            paymentId: null,
            paymentWallId: null,
            payment_methods,
            point: null,
            // points: [],
            products,
            show: false,
            editForm: {
                delivery: null,
                invoice: null
            },
            submited: false,
            afterSubmit: false,
            total,
            wantInvoice: false,
            fixedMiniBasketButtons: false,
            temporaryPsw: false,
            club_value,
            club_valueIso,
            urlReferer,
            quantityChangePending: false,
            loadDelivery: false,
            customCountries: [],
            discountError: null,
            loyaltyError: null,
            voucherError: null,
            free_delivery_missing_amount,
            free_delivery_min_value,
            four_plus_one_promotion_value,
            giftPacking: {
                ...giftPacking, active: false
            },
            tpay: {
                card: {
                    show: false,
                    html: '',
                    rsa: '',
                    redirectUrl: ''
                },
                blik: {
                    show: false,
                    html: '',
                    savedBlik: false
                }
            },
            redirectAfterMakeOrderUrl: ''
        }
    },

    getters: {
        /**
         * Flaga sterowania widocznością modalu płatności Blik Tpay.
         */
        showTpayBlikModal(state) {
            return state.tpay.blik.show
        },
        /**
         * Flaga sterowania widocznością modalu płatności kartą Tpay.
         */
        showTpayCardModal(state) {
            return state.tpay.card.show
        },
        /**
         * Pobierz wszystkie dostepne formy dostawy z obiektu z cenami
         */
        getDeliveries(state) {
            return state.deliveries_costs.reduce((r, i) => {
                r[ i.delivery_id ] = r[ i.delivery_id ] || {
                    name: i.delivery_name,
                    min: state.deliveries_costs.reduce((r1, i1)=> {
                        if (i1.delivery_id != i.delivery_id) return r1
                        return r1 > Number(i1.cost) ? Number(i1.cost) : r1
                    }, Infinity),
                    items: {}
                }
                r[ i.delivery_id ].items[ i.delivery_cost_id ] = {
                    id: i.delivery_cost_id,
                    name: i.payment_name,
                    cost: Number(i.cost),
                    paymentId: i.payment_id,
                    behaviour: i.payment_behaviour
                }
                return r
            }, {})
        },

        /**
         * Pobierz akutalnie wybrana forme wysyłki
         */
        getDeliveryId(state) {
            const item = state.deliveries_costs.find(i => i.delivery_cost_id == state.payment) || {}

            return item.delivery_id
        },

        /**
         * Cena dla wybranego sposobu platnosci
         */
        getPaymentPrice(state) {
            const item = state.deliveries_costs.find(i => i.delivery_cost_id == state.payment) || {}

            return item.cost || 0
        },

        /**
         * Suma ceny razem (produkty + wysylka)
         */
        getTotalWithShipment(state) {
            const item = state.deliveries_costs.find(i => i.delivery_cost_id == state.payment) || {}
            // const loyalty = (state.loyalty || {}).current_discount || 0
            const voucher = (state.voucher || {}).value || 0

            let final = Math.max(0, Number(state.total.final) + (state.free_delivery ? 0 : Number(item.cost || 0)))

            if (voucher > state.total.regular) {
                const reminder = voucher - state.total.regular

                final = Math.max(0, final - reminder)
            }

            return {
                final
            }
        },

        /**
         * Czy aktualny sposob platnosci jest powiazany z mapa
         */
        showMap(state) {
            const item = state.deliveries_costs.find(i => i.delivery_cost_id == state.payment) || {}

            return item.is_map
        },

        /**
         * Pokaz formularz (jezeli wybrano sposob platnosci i ew. punkt na mapie)
         */
        showForm(state) {
            const item = state.deliveries_costs.find(i => i.delivery_cost_id == state.payment) || {}

            return state.payment && (!item.is_map || item.is_map && !!state.point)
        },

        getOrderJSON(state, getters, rootState) {
            const json = {
                account_create: state.account_create,
                products: state.products,
                payment: state.payment,
                point: state.point,
                delivery: (rootState.CustomForm.forms.delivery || {}).fields,
                consents: (rootState.CustomForm.forms.consents || {}).fields,
                consentsWithRegistration: (rootState.CustomForm.forms.consentsWithRegistration || {}).fields,
                paymentGatewayID: state.payment_methods[state.paymentId] && state.payment_methods[state.paymentId][state.paymentWallId]
                    ? state.payment_methods[state.paymentId][state.paymentWallId].id
                    : ''
            }

            if (state.wantInvoice) {
                json.invoice = (rootState.CustomForm.forms.invoice || {}).fields
            }

            return json
        },
        isPackingInBasket(state) {
            if (!state.giftPacking.id) return false
            const isInBasket = state.products.findIndex(p =>p.variant == state.giftPacking.id) > -1

            return isInBasket
        }
    },

    mutations: {
        setPaymentWall(state, payload) {
            state.paymentWallId = payload
        },
        setPaymentBehaviour(state, payload) {
            state.behaviour = payload
            state.submited = false
        },
        setPaymentId(state, payload) {
            state.paymentId = payload
        },
        /**
         * Po zalogowaniu w koszyku zostaja przeslane adresy klienta do wysylki
         * Ich lista zostaje zapisana w modelu
         *
         * @param {Array} payload odpowiedz ajax z adresami klienta
         */
        setAddresses(state, payload) {
            state.addresses.delivery = payload.delivery || []
            state.addresses.invoice = payload.invoice || []
        },

        /**
         * Pokaz ukryj mini-koszyk
         */
        toggle(state) {
            state.show = !state.show
        },

        /**
         * Zmien zawartosc koszyka (products) oraz sumy zamowienia (total)
         *
         * @param {Object} payload (basket: {total, products})
         */
        update(state, payload) {
            const {
                data: {
                    basket: {
                        benefit = null,
                        total = {},
                        products = [],
                        loyalty_program: loyalty = {},
                        free_delivery,
                        free_delivery_missing_amount,
                        free_delivery_min_value,
                        four_plus_one_promotion_value,
                        voucher = {},
                        promotion = {},
                        club_value = null
                    } = {},
                    discount = null
                } = {}
            } = payload

            if (Array.isArray(products)) {
                state.benefit = benefit
                state.total = total
                state.loyalty = loyalty
                state.products = products
                state.voucher = voucher
                state.promotion = promotion
            }

            if (club_value != undefined) {
                state.club_value = club_value
            }
            if (free_delivery != undefined) {
                state.free_delivery = free_delivery
            }
            state.free_delivery_missing_amount = free_delivery_missing_amount
            state.free_delivery_min_value = free_delivery_min_value
            state.four_plus_one_promotion_value = four_plus_one_promotion_value

            if (discount) {
                state.discount = discount
            }
        },

        /**
         * Ustaw dostepne kraje do wysylki
         *
         * @param {Object} payload response from ajax
         */
        setCountries(state, payload) {
            const {
                data: {
                    countries: {
                        items = {},
                        selected
                    } = {}
                } = {}
            } = payload

            state.countries.items = Object
                .entries(items)
                .map(i => ({
                    id: i[0],
                    name: i[1].name
                }))
                .sort((i, j) => i.name.localeCompare(j.name))

            state.countries.selected = selected

            if (state.countries) {
                const valuesArr = []

                Object.keys(state.countries.items).forEach((key) => {
                    valuesArr.push({ label: state.countries.items[key].name, value: state.countries.items[key].id })
                })
                state.customCountries = valuesArr
            }
        },

        /**
         * Uzytkownik dokonal wyboru sposobu platnosci lub ten zostal zresetowany
         *
         * @param {Number|null} payload
         */
        setPayment(state, payload) {
            state.payment = payload
        },

        /**
         * Ustaw dostepne formy wysylki wraz z cenami
         *
         * @param {Object} payload response from ajax
         */
        setDeliveriesCosts(state, payload) {
            const {
                data: {
                    deliveries_costs
                } = {}
            } = payload

            if (deliveries_costs) {
                state.deliveries_costs = deliveries_costs
                state.loadDelivery = true
                state.point = null
                state.deliveryId = null
                state.payment = null
            }
        },

        /**
         * Zapisz wybrany przez uzytkownika punkt na mapie
         * lub reset punktu po zmianie sposobu platnosci
         *
         * @param {Object|null} payload { dane punktu, id, geo, adres itp }
         */
        setPoint(state, payload) {
            state.point = payload
        },

        /**
         * Uzytkownik kliknal w przycisk podsumowania
         * (zostaje zapisany ts klikniecia dla zmiany i wywolania innych watcherow dla niektorych widokow)
         */
        setSubmit(state) {
            state.submited = Date.now()
            setTimeout(() => {
                let isInvalid
                const inputs = document.querySelectorAll('.form-field__input')

                for (let i = 0; i < inputs.length; i++) {
                    if (inputs[i].value == '') {
                        isInvalid = inputs[i]
                        i = inputs.length
                    }
                }

                isInvalid = isInvalid || document.querySelector('.form-field__input--invalid') || document.querySelector('.is-invalid')

                if (isInvalid) {
                    const box = isInvalid.parentElement.getBoundingClientRect()
                    const body = document.body

                    const scrollTop = window.pageYOffset
                    const clientTop = body.clientTop || 0
                    const top = box.top + scrollTop - clientTop

                    if (isInvalid) {
                        window.scrollTo(
                            {
                                top: top - 50,
                                left: 0,
                                behavior: 'smooth'
                            }
                        )
                    }
                }
            }, 1000)
        },

        /**
         * Formularz został wysłany i odebrany AJAX
         * Trigger dla scrolla aby sprawdzic czy nie ma błędów #63156
         */
        setAfterSubmit(state) {
            state.afterSubmit = Date.now()
        },

        /**
         * Uzytkownik kliknal edytuj na liscie adresow - pokazujemy wtedy formularz do edycji aktualnych danych
         * po zmianie adresu (na liscie) - ukrywamy go
         *
         * @param {Boolean} payload
         */
        setEditForm(state, payload) {
            const { data, set } = payload

            state.editForm[data] = set
        },

        /**
         * Czy potrzebna bedzie faktura VAT - pokazuje formularz itd
         *
         * @param {Boolean} payload
         */
        setInvoice(state, payload) {
            state.wantInvoice = payload
        },

        /**
         * @description W przypadku wymuszenia zmiany hasła pokazyjemy modal
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Boolean} payload pokaz/ukryj modal
         */
        setTemporaryPsw(state, payload) {
            state.temporaryPsw = payload
        },

        /**
         * @description Zaznaczenie flagi wysyłanej do PHP ze klient wybrał utworzenie konta
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        setAccountCreate(state, payload = true) {
            state.account_create = payload
        },
        /**
         * @description Zmiana flagi mówiącej o tym czy aktualnie dokonywana jest zmiana ilości produktu w koszyku
         * @author Maksymilian Dziag <mdziag@media4u.pl>
         * @param {Boolean} payload
         */
        setQtyChangePending(state, payload) {
            state.quantityChangePending = payload
        },

        /**
         * @description Zmiana flagi basket loading
         * @author Maksymilian Dziag <mdziag@media4u.pl>
         * @param {Boolean} payload
         */
        setLoading(state, payload) {
            state.loading = payload
        }
    },

    actions: {

        /**
         * Ustawia wybrany index z listy adresow dla zalogowanego uzytkownia
         * Numer = index wybrany z listy (addresses)
         * 'own' = podaj wlasne (wyswietl form)
         *
         * @param {Number|String} payload
         */
        setAddressIndex({ commit, state }, payload) {
            const { data, id } = payload

            if (state.addressIndex[data] !== id) {
                state.addressIndex[data] = id
                // commit('CustomForm/setFormValuesClear', 'delivery', { root: true })
                const fields = id === 'own' ? Array.from(json.forms.delivery.fields).reduce((r, field) => {
                    r[field.name] = field.value
                    return r
                }, {}) : state.addresses[data][id]

                commit('setEditForm', {data, set: false})
                commit('CustomForm/setFormValuesFromJSON', { target: data, fields }, { root: true })
            }
        },

        /**
         * Usun element o podanym ID z koszyka
         *
         * @param {String} id
         */
        remove({state, commit, dispatch }, id) {
            if (state.quantityChangePending) return

            axios.post('/ajax/basket/delete', { id })
                .then(response => {
                // dispatch('Notifications/addResponse', response, { root: true });
                    commit('update', response)
                })
                .catch(error => dispatch('catchException', error))
        },

        /**
         * Zmien ilosc sztuk danego towaru ( id ) o podana ilosc towaru ( q )
         *
         * @param {Object} payload {id: String, q: Number}
         */
        setQuantity({ state, dispatch, commit }, payload) {
            if (state.quantityChangePending) return
            commit('setQtyChangePending', true)

            const product = state.products.find(i => i.id === payload.id)

            if (payload.q < 0 && product.quantity <= 1) {
                commit('setQtyChangePending', false)

                return
            }
            // product.quantity += payload.q

            axios.post('/ajax/basket/quantity', { id: payload.id, quantity: product.quantity + payload.q })
                .then(response => {
                    dispatch('Notifications/addResponse', response, { root: true })
                    commit('update', response)
                })
                .catch(error => dispatch('catchException', error))
                .finally(()=> commit('setQtyChangePending', false))
        },

        /**
         * Wpisany kod rabatowy w koszyku
         *
         * @param {String} payload wpisany kod rabatowy
         */
        setDiscount({ state, dispatch, commit }, payload) {
            axios.post('/ajax/basket/discount', { code: payload })
                .then(response => {
                    dispatch('Notifications/addResponse', response, { root: true })
                    commit('update', response)
                    response.data.status === false ? state.discountError = response.data.message : state.discountError = null
                })
                .catch(error => dispatch('catchException', error))
        },

        /**
         * Usun wpisnay kod rabatowy
         *
         */
        clearDiscount({ dispatch, commit }) {
            axios.post('/ajax/basket/discount/clear')
                .then(response => {
                    dispatch('Notifications/addResponse', response, { root: true })
                    commit('update', response)
                })
                .catch(error => dispatch('catchException', error))
        },

        /**
         *
         * @param {number} id id wybranego kraju
         */
        setCountry({ state, commit, dispatch }, id) {
            state.countries.selected = id
            state.loadDelivery = false
            axios.post('/ajax/basket/country/set', { id })
                .then(response => {
                    commit('update', response)
                    commit('setDeliveriesCosts', response)
                    dispatch('setDelivery')
                })
                .catch(error => dispatch('catchException', error))
        },

        /**
         * Pobierz liste dostepnych krajow wysylki + liste dostepnych kosztow wysylki
         */
        getCountryList({ state, commit, dispatch }, id) {
            if (state.countries.selected) return
            state.countries.selected = id

            axios.post('/ajax/basket/payments')
                .then(response => {
                    commit('update', response)
                    commit('setCountries', response)
                    commit('setDeliveriesCosts', response)
                })
                .catch(error => dispatch('catchException', error))
        },

        /**
         * Wybierz wszystkie punkty dla wybranego sposobu platnosci na mapie
         * Obsluga wylaczona ze wzgledu na lag
         */
        // getPoints({ state, getters, dispatch }) {

        //     const id = getters.getDeliveryId
        //     state.loading = true
        //     state.points = []

        //     axios.post('/ajax/basket/points', { id })
        //         .then(res => {

        //             const partial = () => setTimeout(func)
        //             const func = () => {
        //                 state.points = state.points.concat(res.data.items.splice(0,100))
        //                 if (res.data.items.length) {
        //                     partial()
        //                 } else {
        //                     state.loading = false
        //                 }
        //             }
        //             partial()
        //         })
        //         .catch( error => dispatch('catchException', error))
        // },

        /**
         * Ustawia wybrany przez uzytkownika format wysylki
         * Jezeli wybrany format ma tylko 1 forme platnocsi - jest ona od razu zaznaczana
         *
         * @param {Number|null} payload id formy wysylki
         */
        setDelivery({ state, getters, commit }, payload) {
            state.deliveryId = payload

            const payments = payload ? Object.keys(getters.getDeliveries[ payload ].items) : []

            commit('setPayment', payments.length == 1 ? getters.getDeliveries[ payload ].items[payments[0]].id : null)
            commit('setPaymentId', payments.length == 1 ? getters.getDeliveries[ payload ].items[payments[0]].paymentId : null)
            commit('setPoint')
            setTimeout(()=>{
                window.scrollTo({
                    top: document.querySelector('.payments-label').getBoundingClientRect().top + window.pageYOffset - 90,
                    left: 0,
                    behavior: 'smooth'
                })
            }, 0)
        },

        /**
         * Metoda do pobierania punktow na mapie uwzgledniajaca aktualny widok
         * Wykorzystuje metode debounce do pobierania tylko co okreslony czas
         *
         * @param {Object} payload zwrotka z google maps api z wspolrzednymi
         */
        // boundsChanged({ state, getters }, payload) {
        //     getPoints.call(this, state, getters, payload)
        // },

        /**
         * Wysylanie ajax z punktami loyalnosciowymi
         */
        setLoyalty({ state, commit, dispatch }, payload) {
            payload = Number(payload) || 0

            if (payload < 0) payload = 0
            if (payload > state.loyalty.max_points_value) payload = state.loyalty.max_points_value

            commit('setLoading', true)
            axios.post('/ajax/basket/loyaltyProgram', { points: payload })
                .then(response => {
                    commit('setLoading', false)
                    dispatch('Notifications/addResponse', response, { root: true })
                    commit('update', response)
                    response.data.status === false ? state.loyaltyError = response.data.message : state.loyaltyError = null
                })
                .catch(error => dispatch('catchException', error))
        },

        /**
         * @description Oblsuga bledu serwera z ajax
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Object} error zwrotka z ajax
         */
        catchException({ commit, dispatch, rootGetters, rootState }, error) {
            commit('update', error.response)
            dispatch('Notifications/addResponse', error.response, { root: true })
            commit('setLoading', false)
            router.push({ path: langUrl(rootState.Setup.default_language_iso, rootGetters['Setup/language'], '/basket') })
        },

        /**
         * Wysylanie ajax z voucherami cenowymi
         */
        setVoucher({ state, commit, dispatch }, payload) {
            commit('setLoading', true)
            axios.post('/ajax/basket/voucher', { voucher: payload })
                .then(response => {
                    commit('setLoading', false)
                    dispatch('Notifications/addResponse', response, { root: true })
                    commit('update', response)
                    response.data.status === false ? state.voucherError = response.data.message : state.voucherError = null
                })
                .catch(error => dispatch('catchException', error))
        },

        /**
         * Usun wpisnay voucher
         *
         */
        clearVoucher({ dispatch, commit }) {
            axios.post('/ajax/basket/voucher/clear')
                .then(response => {
                    dispatch('Notifications/addResponse', response, { root: true })
                    commit('update', response)
                })
                .catch(error => dispatch('catchException', error))
        },

        /**
         * Metoda do wysylania danych do php w celu walidacji
         */
        checkOrder({ state, getters, dispatch, rootState, rootGetters, commit }) {
            axios.post('/ajax/basket/summary', getters.getOrderJSON)
                .then(response => {
                    const {
                        errors: {
                            basket: {
                                products = null,
                                total = null
                            } = {},
                            forms: {
                                consents = [],
                                consentsWithRegistration = [],
                                delivery = [],
                                invoice = []
                            } = {}
                        } = {},
                        status = false
                    } = response.data

                    if (products) {
                        state.products = products
                        state.total = total

                        router.push({ path: langUrl(rootState.Setup.default_language_iso, rootGetters['Setup/language'], '/basket') })
                    }

                    if (delivery.length) {
                        rootState.CustomForm.forms.delivery.fields = delivery
                    }

                    if (consents.length) {
                        rootState.CustomForm.forms.consents.fields = consents
                    }

                    if (consentsWithRegistration.length) {
                        rootState.CustomForm.forms.consentsWithRegistration.fields = consentsWithRegistration
                    }

                    if (invoice.length) {
                        rootState.CustomForm.forms.invoice.fields = invoice
                    }

                    commit('update', response)
                    commit('setAfterSubmit')

                    if (status) {
                        router.push({ path: langUrl(rootState.Setup.default_language_iso, rootGetters['Setup/language'], '/basket/summary') })
                    }
                })
                .catch(error => dispatch('catchException', error))
        },

        order({ state, getters, dispatch, rootState, rootGetters, commit }) {
            commit('setLoading', true)
            axios.post('/ajax/basket/order', getters.getOrderJSON)
                .then(res => {
                    const {
                        errors: {
                            basket: {
                                products = null
                            } = {}
                        } = {},
                        tpay_card: tpayCard = null,
                        tpay_blik: tpayBlik = null
                    } = res.data

                    state.redirectAfterMakeOrderUrl = langUrl(rootState.Setup.default_language_iso, rootGetters['Setup/language'], `/order/${res.data.transaction}`)
                    if (products) {
                        state.products = products

                        router.push({ path: langUrl(rootState.Setup.default_language_iso, rootGetters['Setup/language'], '/basket') })
                    }

                    if (!res.data.transaction) {
                        commit('setLoading', false)
                        return
                    }
                    if (state.behaviour === 'tpay_card' && tpayCard) {
                        state.tpay.card.show = true
                        state.tpay.card.html = tpayCard.html
                        state.tpay.card.rsa = tpayCard.params.key
                        state.tpay.card.redirectUrl = `/order/tpaycard/${tpayCard.params.transaction}`
                    }
                    else if (state.behaviour === 'tpay_blik' && tpayBlik) {
                        state.tpay.blik.show = true
                        state.tpay.blik.html = tpayBlik.html
                        state.tpay.blik.savedBlik = tpayBlik.params.alias_register
                    }
                    else {
                        location.href = state.redirectAfterMakeOrderUrl
                    }
                })
                .catch(error => dispatch('catchException', error))
        },
        /**
         * Pozycja buttonów w mini koszyku
         */

        miniButtons({state}) {
            if (state.show) {
                const buttons = document.querySelector('.basket .mini-basket-buttons')

                if (buttons) {
                    const bottomPosition = buttons.getBoundingClientRect()

                    bottomPosition.top + 130 < window.innerHeight ? state.fixedMiniBasketButtons = false : state.fixedMiniBasketButtons = true
                }
            }
        },
        loadedDelivery({state}) {
            state.loadDelivery = true
        }
    }
}
