var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "filters-collapse",
    { attrs: { "toggle-id": "filter-producers" } },
    [
      _c("span", {
        directives: [
          {
            name: "ll",
            rawName: "v-ll",
            value: "categorySelectProducers",
            expression: "'categorySelectProducers'",
          },
        ],
        attrs: { slot: "collapseTitle" },
        slot: "collapseTitle",
      }),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "list-unstyled row",
          attrs: { slot: "body" },
          slot: "body",
        },
        _vm._l(_vm.producers, function (producer) {
          return _c(
            "li",
            { key: producer.id, staticClass: "col-12 col-md-6" },
            [
              _c("filter-checkbox", {
                attrs: {
                  filter: { id: 0 },
                  option: producer,
                  check: _vm.check,
                },
                on: { toggle: _vm.toggle },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }