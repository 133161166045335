import { render, staticRenderFns } from "./product-add-basket.vue?vue&type=template&id=1d73f978"
import script from "./product-add-basket.vue?vue&type=script&lang=js"
export * from "./product-add-basket.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\projekty\\mastivo\\static\\sources\\www\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1d73f978')) {
      api.createRecord('1d73f978', component.options)
    } else {
      api.reload('1d73f978', component.options)
    }
    module.hot.accept("./product-add-basket.vue?vue&type=template&id=1d73f978", function () {
      api.rerender('1d73f978', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "scripts/components/product-add-basket.vue"
export default component.exports