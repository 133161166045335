<template>
    <div
        v-show="modal"
        class="modal-backdrop search-overlay"
        @keydown.esc="show(false)"
        >
        <a
            class="float-right mr-5 mt-3 cursor-pointer no-hover"
            @click.prevent="show(false)"
            >
            <i class="i-close" />
        </a>
        <div class="container p-5">
            <search-form />
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SearchForm from '@components/search/form'

export default {
    components: {
        SearchForm
    },
    computed: {
        ...mapState('Search', ['modal'])
    },
    methods: {
        ...mapMutations({
            show: 'Search/setModal'
        })
    }
}
</script>

<style lang="scss">
.search-overlay {
    opacity: 1;
    background: white !important;
    i{
        color:#000000 !important;
        line-height: 1 !important;
    }
}
</style>
