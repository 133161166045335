<template>
    <a
        :href="$langUrl('/cupboard')"
        class="basket-btn no-hover"
        >
        <slot />
        <span
            v-if="count"
            class="badge basket-badge"
            v-text="`(${count})`"
            />
    </a>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            count: 'ProductCupboard/getItemCount'
        })
    }
}
</script>
