<template>
    <div>
        <delivery-items />
        <payments-items />
        <custom-map
            v-if="showMap && loadDelivery"
            v-show="!point"
            />
        <point-map v-if="!!point" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DeliveryItems from '@components/basket/full/step2/delivery/delivery'
import PaymentsItems from '@components/basket/full/step2/delivery/payments'
import CustomMap from '@components/basket/full/step2/delivery/map'
import PointMap from '@components/basket/full/step2/delivery/point'

export default {

    components: {
        DeliveryItems,
        PaymentsItems,
        CustomMap,
        PointMap
    },

    computed: {
        ...mapGetters({
            showMap: 'Basket/showMap'
        }),
        ...mapState('Basket', [
            'point', 'loadDelivery'
        ])
    }

}
</script>
