var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-center" },
      [
        _c("div", { staticClass: "icon icon--order-success svg" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "72px",
                height: "72px",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    fill: "none",
                    stroke: "#8EC343",
                    "stroke-width": "2",
                  },
                },
                [
                  _c("circle", {
                    staticStyle: {
                      "stroke-dasharray": "240px, 240px",
                      "stroke-dashoffset": "480px",
                    },
                    attrs: { cx: "36", cy: "36", r: "35" },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticStyle: {
                      "stroke-dasharray": "50px, 50px",
                      "stroke-dashoffset": "0px",
                    },
                    attrs: { d: "M17.417,37.778l9.93,9.909l25.444-25.393" },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ml-3" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.data.message || "Message Sent") +
              "\n        "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.data.sendAgain
      ? _c("div", { staticClass: "text-center mt-5" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.unhideForm(_vm.target)
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$ll("formSendAgain")) +
                  "\n        "
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }