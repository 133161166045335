//brak routera - obsluga przegladarkowych przyciskow wstecz i dalej przez przeladowanie strony 
window.addEventListener('popstate', e => {

    //pomijamy linki hashowe #
    if ( window.location.href == e.target.location.href || `${window.location.href}#` == e.target.location.href ) {
        return 
    }

    //pomijamy elementy dla ktorych jest router
    if ( e.target.location.pathname.indexOf('basket') === -1 && e.target.location.href != '#' ) {
        window.location.href = e.target.location.href
    }
})