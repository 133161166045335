var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "basket-btn no-hover",
      attrs: { href: _vm.$langUrl("/cupboard") },
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.count
        ? _c("span", {
            staticClass: "badge basket-badge",
            domProps: { textContent: _vm._s(`(${_vm.count})`) },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }