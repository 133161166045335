var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "basket__products-list",
      class: { disabled: _vm.quantityChangePending },
    },
    _vm._l(_vm.products, function (item) {
      return _c(
        "div",
        { key: item.id },
        [
          _vm.alternative
            ? _c(
                "product-item-alternative",
                {
                  attrs: { item: item, action: _vm.action },
                  on: {
                    remove: function ($event) {
                      return _vm.remove(item.id)
                    },
                    quantity: _vm.quantity,
                  },
                },
                [
                  _vm.action
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "float-right btn-link-inline d-inline-block d-md-none list-item__remove",
                          class: { disabled: _vm.quantityChangePending },
                          attrs: { slot: "header" },
                          on: {
                            click: function ($event) {
                              return _vm.remove(item.id)
                            },
                          },
                          slot: "header",
                        },
                        [_c("i", { staticClass: "i-close" })]
                      )
                    : _vm._e(),
                ]
              )
            : _c(
                "product-item",
                {
                  attrs: { item: item, action: _vm.action },
                  on: {
                    click: function ($event) {
                      return _vm.remove(item.id)
                    },
                    quantity: _vm.quantity,
                  },
                },
                [
                  _vm.action
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "float-right btn-link-inline d-none list-item__remove",
                          class: { disabled: _vm.quantityChangePending },
                          attrs: { slot: "header" },
                          on: {
                            click: function ($event) {
                              return _vm.remove(item.id)
                            },
                          },
                          slot: "header",
                        },
                        [_c("i", { staticClass: "i-close" })]
                      )
                    : _vm._e(),
                ]
              ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }