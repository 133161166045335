<template>
    <div>
        <div
            class="filters-container"
            :class="{show: show}"
            >
            <section class="filters">
                <div class="filters__title">
                    <h3 class="h5">
                        {{ $ll('categorySelectFiches') }}
                    </h3>
                    <close-container />
                </div>

                <filter-slot
                    v-for="filter in filters"
                    :key="filter.id"
                    ref="filters"
                    class="filters__partial"
                    :filter="filter"
                    :checked="checked[ filter.id ]"
                    />
                <producers-elem
                    v-if="producers.length"
                    ref="producers"
                    class="filters__partial"
                    />
                <fiches
                    ref="fiches"
                    class="filters__partial"
                    />
                <div class="filters__actions">
                    <btn-submit
                        :value="$ll('categoryFiltersSubmit')"
                        class="w-100"
                        @click="submit"
                        />
                </div>
            </section>
        </div>
        <div>
            <category-filter-remove
                v-for="(filter, key) in checked"
                :id="key"
                :key="key"
                :filter="filter"
                @remove="removeVariant"
                />
            <category-fiches-remove @removeFiches="removeFiches" />
            <category-producer-remove
                v-for="producerId in checkedProducers"
                :key="producerId"
                :producer-id="producerId"
                @remove="handleProducerRemove"
                />
            <div
                v-if="someFilterChecked"
                class="row mx-0 my-2"
                >
                <div class="col-12 d-flex justify-content-end">
                    <button
                        class="btn btn--link p-0 remove-filters-button"
                        @click="reset"
                        >
                        x {{ $ll('categoryFiltersClear') }}
                    </button>
                </div>
            </div>
        </div>
        <div
            class="filters-overlay overlay-color"
            :class="{show: show}"
            @click="toggle"
            />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import CategoryFilterRemove from '@components/category/filter-remove'
import CategoryFichesRemove from '@components/category/fiches-remove'
import CategoryProducerRemove from '@components/category/producer-filter-remove'

import BtnSubmit from "@components/btn-submit"
import CloseContainer from "@components/category/close"
import FilterSlot from "@components/category/slot"
import Fiches from "@components/category/fiches"
import ProducersElem from '@components/category/producers'

export default {
    name: 'FiltersContainer',
    components: {
        BtnSubmit,
        CloseContainer,
        FilterSlot,
        Fiches,
        ProducersElem,
        CategoryFilterRemove,
        CategoryFichesRemove,
        CategoryProducerRemove
    },
    data() {
        return {
            includeCommon: true
        }
    },

    computed: {
        ...mapState("CategoryFilter", [
            "show",
            "producers",
            'fiches_checked'
        ]),
        ...mapGetters({
            checked: "CategoryFilter/checked",
            checkedProducers: "CategoryFilter/checkedProducers",
            common: 'CategoryFilter/keyCommon'
        }),
        filters: {
            get() {
                const filters = this.$store.getters['CategoryFilter/filters']

                if (this.includeCommon) return filters

                const commonFilters = this.common

                return filters.reduce((res, item) => {
                    let filterId = item.id

                    filterId = filterId.toString()
                    if (!commonFilters.includes(filterId)) {
                        res.push(item)
                    }
                    return res
                }, [])
            }

        },
        someFilterChecked() {
            const someFIlterChecked = Object.values(this.checked).some(val => val.length > 0)

            return someFIlterChecked || this.checkedProducers.length || this.fiches_checked.length
        }
    },

    watch: {
        show() {
            this.$refs.filters.forEach(component => component.cancel())
            setTimeout(() => {
                this.filterButtons()
            }, 100)
            window.addEventListener("scroll", this.filterButtons)
        }
    },
    mounted() {
        if (this.filters.length > 0) {
            this.setUncommonFilters()
        }
    },

    methods: {
        ...mapMutations({
            toggle: "CategoryFilter/toggle",
            setUncommonFilters: "CategoryFilter/setUncommonFilters",
            removeFiche: 'CategoryFilter/removeFiche',
            removeProducer: 'CategoryFilter/removeProducer',
            setInput: 'CategoryFilter/setInput'
        }),
        ...mapActions({
            filterButtons: "CategoryFilter/filterButtons",
            formSubmit: 'CategoryFilter/submit'
        }),
        reset() {
            this.$store.commit("CategoryFilter/resetFilters")
            this.$refs.filters.forEach(component => component.reset())
            this.$refs.fiches.reset()
            this.removeFiche()
            this.$store.dispatch("CategoryFilter/submit")
            // this.$nextTick(this.toggle)
        },
        removeVariant(payload) {
            this.setInput({ ...payload, set: false })
            this.formSubmit()
        },
        removeFiches(payload) {
            this.removeFiche(payload)
            this.formSubmit()
        },
        handleProducerRemove(producerId) {
            this.removeProducer({val: producerId, set: false})
            this.formSubmit()
        },
        submit() {
            let checked = {}

            this.$refs.filters.forEach(component => {
                checked = { ...checked, ...component.get() }
            })

            this.$store.commit("CategoryFilter/setFilters", {...this.checked, ...checked })
            this.$store.commit("CategoryFilter/setFiches", this.$refs.fiches.get())
            if (this.$refs.producers) {
                this.$store.commit("CategoryFilter/setProducers", this.$refs.producers.get())
            }
            this.$store.dispatch("CategoryFilter/submit")
            this.$nextTick(this.toggle)
        }
    }

}
</script>

<style lang="scss">
.filters-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1002;
    cursor: pointer;
}
.filters {
    z-index: 1003;
    position: relative;
    background: #ffffff;
}
</style>
