/* global cookies */
import json from '@db'

const setNotificationCookie = name => {
    if (!name) return

    const cookieName = json.setup.cookieNotificationName
    const notificationCookie =
        decodeURIComponent(document.cookie)
            .split(';')
            .find(c => c.search(cookieName) >= 0) || '{}'

    const data = JSON.parse(notificationCookie.match(/{.*}/)[0])

    data[ name ] = Date.now()

    cookies.set(cookieName, JSON.stringify(data), 10000)
}

export default {
    namespaced: true,
    state: {
        notifications: json.notifications
    },
    getters: {

        getStatic(state) {
            return type => state.notifications.filter(n => n.type === type && !n.variables.fixed)
        },

        getFixed(state) {
            return type => state.notifications.filter(n => n.type === type && n.variables.fixed)
        },

        getByType(state) {
            return type => state.notifications.filter(n => n.type === type)
        },

        get(state) {
            return id => state.notifications.find(n => n.id === id)
        },

        getByContent(state) {
            return content => state.notifications.find(n => n.variables.content === content)
        },

        getModals(state) {
            return state.notifications.filter(n => n.type.search('modal-') >= 0)
        },

        getIndex(state) {
            return id => state.notifications.findIndex(n => n.id === id)
        },

        getNotificationMode() {
            return type => {
                if (type.search('notification-www-') === 0) return 'alert'
                if (type.search('modal-') === 0) return 'modal'
                return ''
            }
        }
    },

    mutations: {

        dismiss(state, payload) {
            state.notifications.splice(payload, 1)
        },

        add(state, payload) {
            state.notifications.push(payload)
        }
    },

    actions: {

        dismiss({ commit, getters }, payload) {
            const alert = getters.get(payload)

            if (!alert) return

            !!alert.callback && alert.callback()
            setNotificationCookie(alert.cookieName)
            commit('dismiss', getters.getIndex(payload))
        },

        add({ commit, rootGetters, dispatch, getters }, payload) {
            const {
                closeable = true,
                cookieLifetime = null,
                cookieName = null,
                id = Date.now(),
                type = 'notification-www-top',
                temporary = 0,
                callback = () => {},
                variables: {
                    color = 'dark',
                    content = rootGetters['Lang/get']('unknownError'),
                    fixed = true,
                    logged = false,
                    width = false
                } = {}
            } = payload

            const exists = getters.getByContent(content)
            let counter = 1

            if (exists) {
                counter = exists.counter + 1
                dispatch('dismiss', exists.id)
            }

            commit('add', {
                closeable,
                cookieLifetime,
                cookieName,
                id,
                type,
                temporary,
                callback,
                counter,
                variables: {
                    color,
                    content,
                    fixed,
                    logged,
                    width
                }
            })

            if (temporary) {
                setTimeout(dispatch.bind(this, 'dismiss', id), temporary * 1000)
            }
        },

        addResponse({ dispatch, rootGetters }, payload) {
            const { data = {} } = payload

            dispatch('add', {
                temporary: 4,
                variables: {
                    color: data.status ? 'success' : 'danger',
                    content: data.message || rootGetters['Lang/get']('jsError')
                }
            })
        }
    }
}
