<template>
    <div
        class="text-black mb-5"
        v-html="items"
        />
</template>

<script>
export default {
    props: {
        form: { type: String, required: true }
    },
    computed: {
        items() {
            const data = Object
                .entries(this.$store.state.CustomForm.forms[this.form].fields)
                .map(([_, item]) => item.fieldType !== 'boolHidding' && item.fieldType !== 'bool' && !!item.value ? `${item.label}: <strong>${item.value}</strong><br>` : '' )
                .filter(Boolean)
                .join('\n')
            return data
        }
    }
}
</script>
