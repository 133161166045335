<template>
    <div>
        <size-table-modal
            :open="open"
            @open="v => henadleOpen(v)"
            />
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SizeTableModal from '@components/product/size-table/modal'

export default {
    components: {
        SizeTableModal
    },
    computed: {
        ...mapState('ProductSizeTable', ['open'])
    },
    methods: {
        ...mapMutations('ProductSizeTable', [
            'toggleOpen', 'response'
        ]),
        henadleOpen(v) {
            this.toggleOpen(v)

            // clear table data
            if (!v) {
                setTimeout(() => {
                    this.response({data: null})
                }, 150)
            }
        }
    }
}
</script>
