<template>
    <div>
        <div class="pt-3 pb-3 product-description-item row no-gutters">
            <div class="col-12 col-sm-3 col-md-12 col-xl-4 col-xxl-3 mb-md-3 mb-xl-0 mb-sm-0 mb-3">
                <div class="text-nowrap product-quantity">
                    <a
                        class="plain-link"
                        :class="{ disabled: quantity <= 1 }"
                        @click="quantity--"
                        >
                        <i class="mdi mdi-minus mr-1" />
                    </a>
                    <input
                        v-model="quantity"
                        v-quantity-numbers
                        type="text"
                        >
                    <a
                        class="plain-link"
                        @click="quantity++"
                        >
                        <i class="mdi mdi-plus ml-1" />
                    </a>
                </div>
            </div>
            <div class="col-10 col-sm-6 col-md-9 col-xl-7 col-xxl-6">
                <div class="text-center ml-xl-3 ml-md-0 ml-sm-3">
                    <btn-submit
                        :class="`btn px-3 w-100 ${(availability.variants > 1 || (availability.stock && availability.available)) ? 'btn-primary' : 'btn-secondary'}`"
                        :disabled="disabled"
                        :value="(availability.variants > 1 || (availability.stock && availability.available) ) ? $ll('productCardBasketAdd') : $ll('productCardAvailabilityNotify')"
                        @click="addBasket(index)"
                        />
                </div>
            </div>
            <div class="col-2 col-xl-1 col-xxl-2">
                <add-cupboard :index="index" />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import AddCupboard from './set-add-cupboard'
import BtnSubmit from '@components/btn-submit'
import { mapActions, mapGetters } from 'vuex'

export default {

    components: {
        AddCupboard,
        BtnSubmit
    },

    props: {
        index: { type: Number, required: true }
    },

    computed: {
        quantity: {
            get() {
                return this.$store.state.ProductSets.quantity[ this.index ]
            },
            set(q) {
                if (q < 1) return
                Vue.set(this.$store.state.ProductSets.quantity, this.index, q)
            }
        },
        ...mapGetters({
            availabilityList: 'ProductSets/availability',
            btnDisabledList: 'ProductSets/getBtnDisabled'
        }),
        availability() {
            return this.availabilityList(this.index)
        },
        disabled() {
            return this.btnDisabledList(this.index)
        }
    },

    methods: {
        ...mapActions({
            addBasket: 'ProductSets/addBasket'
        })
    }
}
</script>
