var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "carousel",
    {
      attrs: {
        "per-page": 1,
        loop: true,
        "pagination-enabled": false,
        "navigation-enabled": _vm.list.length <= 1 ? false : true,
        "navigation-next-label": "",
        "navigation-prev-label": "",
      },
    },
    _vm._l(_vm.list, function (img, id) {
      return _c(
        "slide",
        { key: id },
        [
          img
            ? _c("Picture", {
                staticClass: "img-fluid loupe",
                attrs: {
                  src: `${img ? _vm.$photoUrl(img.path, 400) : ""}`,
                  loading: id > 0 ? "lazy" : "eager",
                  lazy: false,
                  alt: img.name || img.defaultAlt,
                },
                on: {
                  click: function ($event) {
                    return _vm.photoswipe(id)
                  },
                },
              })
            : _c("div", { staticClass: "img-placeholder" }),
          _vm._v(" "),
          !id && _vm.fiches
            ? _c(
                "span",
                {
                  staticClass: "position-absolute",
                  staticStyle: { top: "0", left: "0" },
                },
                _vm._l(_vm.fiches, function (item, key) {
                  return _c("Picture", {
                    key: key,
                    staticClass: "product-item__badges-item",
                    attrs: { src: _vm.$photoUrl(item.path), alt: item.name },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !id && _vm.variantFiches
            ? _c(
                "span",
                {
                  staticClass: "position-absolute",
                  staticStyle: { top: "0", left: "0" },
                },
                _vm._l(_vm.variantFiches, function (item, key) {
                  return _c("Picture", {
                    key: key,
                    staticClass: "product-item__badges-item",
                    attrs: { src: _vm.$photoUrl(item.path), alt: item.name },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }