<template>
    <div :class="{'py-5 my-5 row mx-0' : alone}">
        <div :class="{'col-12 col-md-8 col-lg-6 m-auto': alone}">
            <h3 class="mb-4 pb-3 text-center text-uppercase bottom-line light">
                {{ $ll('loginTitle') }}
            </h3>
            <form
                class="form"
                @submit.prevent="login({email, psw, basket:!alone})"
                >
                <div class="form-field">
                    <input
                        id="InputEmail"
                        v-model="email"
                        type="email"
                        :placeholder="$ll('loginEmail')"
                        class="form-control form-field__input"
                        :disabled="busy"
                        >
                    <label
                        class="form-field__placeholder"
                        v-text="$ll('loginEmail')"
                        />
                </div>
                <div class="form-field">
                    <input
                        id="InputPsw"
                        v-model="psw"
                        type="password"
                        :placeholder="$ll('loginPassword')"
                        class="form-control form-field__input"
                        :disabled="busy"
                        >
                    <label
                        class="form-field__placeholder"
                        v-text="$ll('loginPassword')"
                        />
                </div>
                <div
                    class="text-center mb-4"
                    >
                    <btn-submit
                        class="btn btn-primary mb-3 mt-3 w-100"
                        :class="{ 'w-100': !alone }"
                        :disabled="busy"
                        :value="$ll('loginButton')"
                        />
                    <a
                        :href="$rt('resetPassword')"
                        class="d-inline-block plain-link px-4"
                        >
                        {{ $ll('loginPasswordForgotten') }}
                    </a>
                </div>
                <!--<div class="text-center or-section">-->
                <!--<span class="d-inline-block">-->
                <!--{{ $ll('basketLoginOr') }}-->
                <!--</span>-->
                <!--</div>-->
                <!--<div class="form-field">-->
                <!--<a-->
                <!--v-for="provider in providers"-->
                <!--:key="provider.id"-->
                <!--class="btn btn-default w-100 mb-2"-->
                <!--:href="provider.url"-->
                <!--:class="`provider-${provider.id}`"-->
                <!--&gt;-->
                <!--{{ $ll(provider.name) }}-->
                <!--</a>-->
                <!--</div>-->
            </form>
            <a href="/access/registration_step1">
                <button
                    class="btn btn-outline-secondary w-100"
                    >
                    {{ $ll('topRegister') }}
                </button>
            </a>
        </div>
        <modal-message />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BtnSubmit from '@components/btn-submit'
import ModalMessage from '@components/login/modal-login-message'

export default {

    components: {
        BtnSubmit,
        ModalMessage
    },
    props: {
        alone: { type: Boolean, default: true }
    },

    data() {
        return {
            email: '',
            psw: ''
        }
    },

    computed: {
        ...mapState('Setup', {
            busy: state => state.login.busy,
            providers: state => state.socialNetworkProviders
        })
    },

    methods: {
        ...mapActions({
            login: 'Setup/login'
        })
    }
}
</script>

<style lang="scss">
.provider-facebook {
    background-color: #3b5998 !important;
    color: white !important;
}
</style>
