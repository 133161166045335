var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid bg-light basket__info" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
          _c("div", { staticClass: "feature-item" }, [
            _c("i", { staticClass: "i-conversation" }),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4" }, [
              _c("strong", {}, [
                _vm._v(
                  "\n                            " +
                    _vm._s(_vm.$ll("basketInfoFeature1")) +
                    "\n                        "
                ),
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "mt-2 mb-0",
                domProps: {
                  innerHTML: _vm._s(_vm.$ll("basketInfoFeature1Subtitle")),
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
          _c("div", { staticClass: "feature-item" }, [
            _c("i", { staticClass: "i-package" }),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4" }, [
              _c("strong", {}, [
                _vm._v(
                  "\n                            " +
                    _vm._s(_vm.$ll("basketInfoFeature2")) +
                    "\n                        "
                ),
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "mt-2 mb-0",
                domProps: {
                  innerHTML: _vm._s(_vm.$ll("basketInfoFeature2Subtitle")),
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
          _c("div", { staticClass: "feature-item" }, [
            _c("i", { staticClass: "i-truck" }),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4" }, [
              _c("strong", {}, [
                _vm._v(
                  "\n                            " +
                    _vm._s(_vm.$ll("basketInfoFeature3")) +
                    "\n                        "
                ),
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "mt-2 mb-0",
                domProps: {
                  innerHTML: _vm._s(_vm.$ll("basketInfoFeature3Subtitle")),
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
          _c("div", { staticClass: "feature-item" }, [
            _c("i", { staticClass: "i-shopping-bag" }),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4" }, [
              _c("strong", {}, [
                _vm._v(
                  "\n                            " +
                    _vm._s(_vm.$ll("basketInfoFeature4")) +
                    "\n                        "
                ),
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass: "mt-2 mb-0",
                domProps: {
                  innerHTML: _vm._s(_vm.$ll("basketInfoFeature4Subtitle")),
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }