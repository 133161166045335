<template>
    <b-modal
        v-model="opened"
        :title="$ll('productSizeTableModalTitle')"
        size="lg"
        centered
        class="size-table-modal"
        ok-only
        >
        <div
            v-if="data"
            class="row"
            >
            <div :class="{ 'col-7': data.photo, 'col-12': !data.photo }">
                <size-table
                    :data="data.table_structure"
                    class="table-responsive-lg"
                    />
                <p
                    class="text-left"
                    v-html="data.how_to_measure"
                    />
            </div>
            <div
                v-if="data.photo"
                class="col-5"
                >
                <Picture
                    :src="$photoUrl(data.photo.url)"
                />
            </div>
        </div>
        <div
            v-else
            class="text-center py-5"
            >
            <i class="mdi mdi-36px mdi-loading mdi-spin" />
        </div>
    </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SizeTable from '@components/product/size-table/table'

export default {
    components: {
        SizeTable
    },
    props: ['open'],
    computed: {
        opened: {
            get() {
                return this.open
            },
            set(v) {
                this.$emit('open', v)
            }
        },
        ...mapState('ProductSizeTable', ['data'])
    },
    watch: {
        opened: 'load'
    },
    methods: {
        ...mapActions({
            load: 'ProductSizeTable/load'
        })
    }
}
</script>
