var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.open,
        title: _vm.$ll("productBasketModalTitle"),
        centered: "",
        "ok-title": _vm.$ll("basketGoToBasketButton"),
        "cancel-title": _vm.$ll("basketBackToShoppingButton"),
      },
      on: {
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
        hidden: function ($event) {
          return _vm.$emit("hidden")
        },
        ok: _vm.hrefBasket,
      },
    },
    [
      _c("div", { staticClass: "d-flex text-left" }, [
        _c("div", { staticClass: "row modal-basket-product" }, [
          _c(
            "div",
            { staticClass: "col-4 col-sm-3" },
            [
              _c("Picture", {
                attrs: { src: _vm.$photoUrl(_vm.photoPath, 270) },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 col-sm-9" }, [
            _c("h2", [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.variant.product_name) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "list-unstyled my-4" },
              _vm._l(_vm.variant.attributes, function (item) {
                return _c("li", { key: item.attribute_value_id }, [
                  _c("strong", [_vm._v(_vm._s(item.attribute_name))]),
                  _vm._v(
                    ":\n                        " +
                      _vm._s(item.value) +
                      "\n                    "
                  ),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("price", {
                  attrs: { price: _vm.variant.prices[_vm.currency] },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }