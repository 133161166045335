<template>
    <div>
        <b-modal
            v-for="(modal, key) in modals"
            :key="modal.id"
            ref="modal"
            :title="modal.type === 'modal-text' ? modal.variables.title : ''"
            :size="modal.variables.size || 'md'"
            :class="{ 'modal-image': modal.type === 'modal-image', 'modal-title-empty': !modal.variables.title }"
            hide-footer
            @hidden="close(key)"
            >
            <div
                v-if="modal.type === 'modal-text'"
                v-html="modal.variables.content"
                />
            <div
                v-if="modal.type === 'modal-image'"
                >
                <a
                    v-if="modal.variables.link.externalLinkText == '' || modal.variables.link.internalLinkText == '' "
                    :href="modal.variables.link.externalLinkHref || modal.variables.link.internalElementUrl"
                    :target="modal.variables.link.targetBlank? '_blank' : '_self'"
                    :rel=" modal.variables.link.parameters != null ? 'nofollow': '' "
                    >
                    <Picture 
                        class="body-image"
                        :src="$photoUrl(modal.variables.image[0].file)"
                        />
                </a>
                <div v-else>
                    <Picture 
                        class="body-image"
                        :src="$photoUrl(modal.variables.image[0].file)"
                        />
                    <div class="button-container">
                        <a
                            class="btn btn-primary"
                            :href="modal.variables.link.externalLinkHref || modal.variables.link.internalElementUrl"
                            :target="modal.variables.link.targetBlank? '_blank' : '_self'"
                            :rel=" modal.variables.link.parameters != null ? 'nofollow': '' "
                            @click="close(key)"
                            >
                            {{ modal.variables.link.internalLinkText || modal.variables.link.externalLinkText }}
                        </a>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

    computed: {
        ...mapGetters({
            modals: 'Notifications/getModals'
        })
    },

    mounted() {
        if (this.modals.length) {
            this.showModal(0)
        }
    },

    methods: {
        showModal(id) {
            setTimeout(this.$refs.modal[ id ].show.bind(this), this.modals[ 0 ].variables.delay * 1000)
        },
        close(id) {
            this.$store.dispatch('Notifications/dismiss', this.modals[ id ].id)
            if (this.modals.length) {
                this.showModal(++id)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-image {
    .modal-content {
        overflow: hidden;
    }
    header {
        height: 0 !important;
        padding: 0;
        overflow: hidden;
        button {
            position: absolute;
            right: 15px;
            top: 15px;
            z-index: 10000;
            opacity: 0.5;
        }
    }
    .modal-body {
        padding: 0;
        position: relative;
    }
    .body-image {
        width: 100%;
    }
    .button-container{
        position: absolute;
        bottom: 25%;
        left:0;
        right: 0;
        text-align: center;
    }
}
.modal-title-empty {
    header {
        border-bottom-width: 0;
    }
}
</style>
