var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "mb-1" }, [
      _vm._v("\n        " + _vm._s(_vm.data.invoice_company_name) + "\n    "),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "d-block" }, [
      _vm._v("\n        " + _vm._s(_vm.data.invoice_tax_identifier) + "\n    "),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "d-block" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.data.invoice_address) +
          " " +
          _vm._s(_vm.data.invoice_building_number) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "d-block" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.data.invoice_postcode) +
          " " +
          _vm._s(_vm.data.invoice_city) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "d-block" }, [
      _vm._v("\n        " + _vm._s(_vm.data.invoice_phone) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }