<template>
    <form
        ref="form"
        :action="action"
        class="form"
        @submit.prevent="dispatch"
        >
        <form-success
            v-if="data.hideOnSuccess"
            :target="target"
            />
        <form-items
            v-else
            :action="action"
            :target="target"
            />
        <button
            ref="submit"
            class="d-none"
            />
    </form>
</template>

<script>
import FormMixin from '@mixins/custom-form'
import FormItems from '@components/custom-form/items'
import FormSuccess from '@components/custom-form/success'

export default {

    components: {
        FormItems,
        FormSuccess
    },
    mixins: [FormMixin],

    props: {
        target: { type: String, required: true },
        action: { type: String, required: true }
    }
}
</script>
