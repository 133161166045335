<template>
    <div>
        <div class="mb-1">
            {{ data.forename }} {{ data.surname }}
        </div>
        <span class="d-block">
            {{ data.address }}
        </span>
        <span class="d-block">
            {{ data.postcode }} {{ data.city }}
        </span>
        <span class="d-block">
            {{ data.email }}
        </span>
        <span class="d-block">
            {{ data.phone }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        data: { type: Object, required: true }
    }
}
</script>
