var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.open,
        title: _vm.$ll("productBasketModalOutOfStockTitle"),
        "ok-title": _vm.$ll("basketBackToShoppingButton"),
        "ok-only": "",
        centered: "",
      },
      on: {
        hidden: function ($event) {
          return _vm.$emit("hidden")
        },
      },
    },
    [
      _c("div", { staticClass: "d-flex" }, [
        _vm._v(
          "\n        " + _vm._s(_vm.$ll("productOutOfStockMessage")) + "\n    "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }