<template>
    <div>
        <search-button />
        <search-overlay />
    </div>
</template>

<script>
import SearchButton from '@components/search/button'
import SearchOverlay from '@components/search/overlay'

export default {
    components: {
        SearchButton,
        SearchOverlay
    }    
}
</script>
