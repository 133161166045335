var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.currentPage < _vm.pageCount && _vm.pageCount > 1,
          expression: "(currentPage < pageCount) && (pageCount > 1)",
        },
      ],
      staticClass: "category-grid__item last-brick",
    },
    [
      _c(
        "span",
        {
          staticClass: "product-item",
          on: {
            click: function ($event) {
              return _vm.setPage(_vm.currentPage + 1)
            },
          },
        },
        [
          _c("span", { staticClass: "product-item__body" }, [
            _c("span", { staticClass: "product-item__image" }, [
              _c("span", { staticClass: "last-brick__text" }, [
                _c("span", [_vm._v(_vm._s(_vm.$ll("categoryLastBrick")))]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }