var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quick-filter" },
    [
      _c(
        "button",
        {
          staticClass: "quick-filter__trigger",
          class: { "quick-filter__trigger--open": _vm.isOpen },
          on: { click: _vm.toggleOpen },
        },
        [
          _c("span", { domProps: { textContent: _vm._s(_vm.filter.n) } }),
          _vm._v(" "),
          _c("i", { staticClass: "i-arrow-down-small" }),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isOpen
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "on-clickaway",
                    rawName: "v-on-clickaway",
                    value: _vm.handleClose,
                    expression: "handleClose",
                  },
                ],
                staticClass: "quick-filter-dropdown",
              },
              [
                _c(
                  "div",
                  { staticClass: "quick-filter-dropdown__content" },
                  _vm._l(_vm.filter.v, function (item) {
                    return _c("checkbox", {
                      key: item.id,
                      attrs: {
                        check: _vm.checked,
                        "filter-id": _vm.filter.id + "quick",
                        option: item,
                        disabled: _vm.disabled,
                      },
                      on: { toggle: _vm.toggle },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "quick-filter-dropdown__actions" }, [
                  _c("button", {
                    directives: [
                      {
                        name: "ll",
                        rawName: "v-ll",
                        value: "categoryFiltersSubmit",
                        expression: "'categoryFiltersSubmit'",
                      },
                    ],
                    staticClass: "btn btn-secondary w-100",
                    on: { click: _vm.handleClose },
                  }),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }