var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-sm-2 py-2" }, [
    _vm.language.current == "1"
      ? _c("div", { staticClass: "container" }, [
          _vm.products.length
            ? _c(
                "ol",
                { staticClass: "breadcrumb basket__breadcrumb" },
                _vm._l(
                  _vm.stepsPl.filter((step) => !step.hide),
                  function (step) {
                    return _c(
                      "li",
                      {
                        key: step.path,
                        staticClass: "breadcrumb-item",
                        class: { active: _vm.$route.path == step.path },
                      },
                      [
                        _vm.$route.path.endsWith(step.path)
                          ? _c("span", {
                              staticClass: "text-black",
                              domProps: { textContent: _vm._s(step.name) },
                            })
                          : _c("span", {
                              staticClass: "light-gray",
                              domProps: { textContent: _vm._s(step.name) },
                            }),
                      ]
                    )
                  }
                ),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass: "basket__breadcrumb--line d-none d-md-block",
          }),
        ])
      : _c("div", { staticClass: "container" }, [
          _vm.products.length
            ? _c(
                "ol",
                { staticClass: "breadcrumb basket__breadcrumb" },
                _vm._l(
                  _vm.stepsEn.filter((step) => !step.hide),
                  function (step) {
                    return _c(
                      "li",
                      {
                        key: step.path,
                        staticClass: "breadcrumb-item",
                        class: { active: _vm.$route.path == step.path },
                      },
                      [
                        _vm.$route.path.endsWith(step.path)
                          ? _c("span", {
                              staticClass: "font-weight-bold",
                              domProps: { textContent: _vm._s(step.name) },
                            })
                          : _c("span", {
                              domProps: { textContent: _vm._s(step.name) },
                            }),
                      ]
                    )
                  }
                ),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass: "basket__breadcrumb--line d-none d-md-block",
          }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }