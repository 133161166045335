var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-item__colors" },
    _vm._l(_vm.colors, function (color, index) {
      return _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: index < 10,
              expression: "index < 10",
            },
          ],
          key: color.variant_id,
          staticClass: "color-wrapper",
        },
        [
          _c(
            "a",
            { attrs: { href: `${_vm.url}#${color.attribute_value_id}` } },
            [
              color.image_url
                ? _c("div", {
                    staticClass: "color color--image",
                    style: { backgroundImage: `url(/${color.image_url})` },
                  })
                : _c("div", {
                    staticClass: "color",
                    style: { backgroundColor: color.color },
                  }),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }