var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.payment &&
    _vm.showForm &&
    _vm.addresses[_vm.id].length &&
    !_vm.showMap
    ? _c(
        "div",
        {
          staticClass: "form-group adresses-form-group",
          class: {
            "is-invalid": _vm.addressIndex[_vm.id] === null && _vm.submited,
          },
        },
        [
          _c("input", {
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.addressIndex[_vm.id] === null && _vm.submited,
            },
            attrs: { type: "hidden", required: "" },
            domProps: { value: _vm.addressIndex[_vm.id] },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "invalid-feedback" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$ll(`basketLogged${_vm.lang}NotSet`)) +
                "\n    "
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }