<template>
    <div class="quick-filter">
        <button
            class="quick-filter__trigger"
            :class="{'quick-filter__trigger--open': isOpen}"
            @click="toggleOpen"
            >
            <span v-text="filter.n" />
            <i class="i-arrow-down-small" />
        </button>
        <transition name="fade">
            <div
                v-if="isOpen"
                v-on-clickaway="handleClose"
                class="quick-filter-dropdown"
                >
                <div class="quick-filter-dropdown__content">
                    <checkbox
                        v-for="item in filter.v"
                        :key="item.id"
                        :check="checked"
                        :filter-id="filter.id + 'quick'"
                        :option="item"
                        :disabled="disabled"
                        @toggle="toggle"
                        />
                </div>
                <div class="quick-filter-dropdown__actions">
                    <button
                        v-ll="'categoryFiltersSubmit'"
                        class="btn btn-secondary w-100"
                        @click="handleClose"
                        />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import Checkbox from '../slot/checkbox'

export default {
    name: 'QuickFilter',
    components: {
        Checkbox
    },
    mixins: [clickaway],
    props: {
        filter: {
            type: Object,
            required: true
        },
        check: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            isOpen: false,
            checked: []
        }
    },
    watch: {
        isOpen: {
            handler() {
                this.checked = JSON.parse(JSON.stringify(this.check))
            },
            immediate: true
        }
    },
    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen
        },
        handleClose() {
            this.isOpen = false
            this.$emit('submit')
        },
        get() {
            return { [ this.filter.id ]: this.checked }
        },
        toggle(id, set) {
            set ? this.checked.push(id) : this.checked = this.checked.filter(i => i != id)
        }
    }
}
</script>