var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center cupboard-list-container" },
    [
      _vm.button
        ? _c("div", { staticClass: "col-8 px-0 ml-3 mt-1" }, [
            _c(
              "a",
              {
                attrs: { disabled: _vm.loading && !_vm.show },
                on: { click: _vm.addCupboard },
              },
              [_c("i", { staticClass: "i-cupboard cursor-pointer" })]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }