var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showLanguage,
          expression: "showLanguage",
        },
      ],
      staticClass: "nav lang-nav",
    },
    _vm._l(_vm.languages, function (lang, id) {
      return _c(
        "li",
        {
          key: id,
          staticClass: "nav-item",
          class: { active: _vm.current == id },
        },
        [
          _c("a", {
            attrs: {
              disabled: lang.iso == _vm.$route.params.lang,
              href: lang.url || `/${lang.iso}`,
              data: _vm.langPicked(_vm.current),
            },
            domProps: { textContent: _vm._s(lang.name) },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }