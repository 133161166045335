var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    _vm._l(_vm.filter, function (item) {
      return _c(
        "span",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: { hover: true },
            },
          ],
          key: item,
          staticClass:
            "mt-3 py-1 px-2 mr-2 remove-filter d-inline-block black-text",
          attrs: { title: _vm.$ll("categoryRemoveFilters") },
          on: {
            click: function ($event) {
              return _vm.$emit("remove", { id: _vm.id, val: item })
            },
          },
        },
        [
          _vm._v("\n        " + _vm._s(_vm.name(_vm.id, item)) + "\n        "),
          _c("i", { staticClass: "i-close black-text" }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }