var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quick-filters" },
    _vm._l(_vm.filtersList, function (filter) {
      return _c("quick-filter", {
        key: filter.id,
        ref: "filters",
        refInFor: true,
        attrs: {
          check: _vm.checked[filter.id],
          filter: filter,
          disabled: _vm.disabled,
        },
        on: { submit: _vm.submit },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }