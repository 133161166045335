var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.paid || _vm.paidStatus
      ? _c("p", [
          _c("i", { staticClass: "mdi mdi-check text-success" }),
          _vm._v(
            "\n        " + _vm._s(_vm.$ll("orderPaymentStatusOK")) + "\n    "
          ),
        ])
      : !_vm.error
      ? _c("p", [
          _c("i", { staticClass: "mdi mdi-spin mdi-loading" }),
          _vm._v(
            "\n        " + _vm._s(_vm.$ll("orderPaymentWaiting")) + "\n    "
          ),
        ])
      : _c("p", [
          _c("i", { staticClass: "mdi mdi-close text-danger" }),
          _vm._v("\n        " + _vm._s(_vm.error) + "\n    "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }