<template>
    <div class="row no-gutters">
        <div class="col-12">
            <carousel
                v-if="Object.keys(images).length > 1"
                class="VueProductCarousel product-carousel"
                :per-page="1"
                :loop="false"
                :pagination-enabled="false"
                :navigation-enabled="true"
                :navigation-next-label="''"
                :navigation-prev-label="''"
                >
                <slide
                    v-for="(product, id) in images"
                    :key="id"
                    >
                    <a :href="url">
                        <Picture
                            :src="$photoUrl(product.path, 380)"
                            :width="product.w"
                            :height="product.h"
                            />
                    </a>
                </slide>
            </carousel>

            <div
                v-else
                class="row mt-3"
                >
                <div
                    v-for="(product, id) in images"
                    :key="id"
                    class="col-12 mb-3 pr-5"
                    >
                    <a :href="url">
                        <Picture
                            :src="$photoUrl(product.path, 380)"
                            :width="product.w"
                            :height="product.h"
                            />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel"

export default {

    components: {
        Carousel,
        Slide
    },

    props: {
        images: { type: Object, required: true },
        url: { type: String, required: true}
    }
}
</script>

<style lang="scss">
// .VueProductCarousel {
//   .VueCarousel-wrapper{
//     margin: -15px 60px;
//     padding: 15px 0px;
//     width: auto;
//   }
// }
</style>
