var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.getFixed(_vm.type).length
      ? _c(
          "div",
          {
            class: {
              "fixed-top mt-5": _vm.type.indexOf("top") >= 0,
              "fixed-bottom": _vm.type.indexOf("bottom") >= 0,
            },
          },
          [
            _vm.getMode(_vm.type) === "alert"
              ? _c("notifications-alert", {
                  attrs: { alerts: _vm.getFixed(_vm.type) },
                  on: { dismissed: _vm.dismissed },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.getStatic(_vm.type).length
      ? _c(
          "div",
          [
            _vm.getMode(_vm.type) === "alert"
              ? _c("notifications-alert", {
                  attrs: { alerts: _vm.getStatic(_vm.type) },
                  on: { dismissed: _vm.dismissed },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }