var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-field mb-0 p-0 search-hints" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: Array.isArray(_vm.hints) && !_vm.hints.length,
          expression: "Array.isArray(hints) && !hints.length",
        },
      ],
      staticClass: "search-hints__no-results",
      domProps: { textContent: _vm._s(_vm.$ll("searchNothingFound")) },
    }),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "list-unstyled m-0 search-hints__list" },
      _vm._l(_vm.hints, function (hint) {
        return _c("li", { key: hint.value }, [
          _c("a", { attrs: { href: _vm.hintBaseUrl + hint.value } }, [
            _vm._v(
              "\n                " + _vm._s(hint.value) + "\n            "
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }