<template>
    <div
        class="category-grid"
        :class="{ 'category-grid--small-items': cols || small }"
        >
        <product-brick
            v-for="product in products"
            :key="product.product_id"
            class="category-grid__item"
            :product="product"
            >
            <product-brick-colors
                slot="footer"
                :colors="product.colors || []"
                :url="product.url"
                />
        </product-brick>
        <slot v-if="ajax" />
        <slot
            v-if="ajax && !products.length"
            name="empty"
            />
        <!-- <last-brick v-show="loaded && products.length" /> -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProductBrick from '@components/product/brick'
import ProductBrickColors from '@components/product/brick/colors'
import searchDataLayer from '@helpers/search-dataLayer'
// import ProductBrickCategory from '@components/product/brick/category'
// import LastBrick from '@components/category/last-brick'

export default {
    components: {
        ProductBrick,
        ProductBrickColors
        // LastBrick
    },
    props: {
        small: { default: false }
    },
    data() {
        return {
            loaded: false
        }
    },
    computed: {
        ...mapState('ProductList', [
            'products', 'ajax'
        ]),
        ...mapState('CategoryFilter', [
            'cols', 'show'
        ])
    },
    watch: {
        // show(v) {
        //     if (!v) this.$scrollTo('#twig-container', 500)
        // }
    },
    beforeMount() {
        this.$vnode.data.staticClass = ''
    },
    mounted() {
        this.loaded = true
        setTimeout(()=> {
            searchDataLayer(this.products)
        }, 1000)
    }
}
</script>
