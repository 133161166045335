<template>
    <div class="form-field">
        <input
            ref="input"
            :value="search"
            class="form-control form-field__input"
            :placeholder="$ll('searchLabel')"
            @input="setInput"
            @keyup.enter="go"
            >
        <label
            class="form-field__placeholder"
            v-text="$ll('searchLabel')"
            />
        <div class="form-field__append">
            <button
                :disabled="search.length === 0"
                class="btn-icon"
                @click.prevent="search = ''"
                >
                <i class="i-close" /><span class="d-none d-sm-inline">
                    {{ $ll('searchClearButton') }}
                </span>
            </button>
            <button
                class="btn-icon"
                :disabled="search.length < 3"
                @click.prevent="go"
                >
                <i class="i-loupe" /><span class="d-none d-sm-inline">
                    {{ $ll('searchSubmitButton') }}
                </span>
            </button>
        </div>
        <hints-list
            v-show="hintsVisible"
            :hints="hints"
            />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import debounce from 'debounce'
import HintsList from './hints-list'

export default {
    components: {
        HintsList
    },
    computed: {
        search: {
            get() {
                return this.$store.state.Search.search
            },
            set(q) {
                this.$store.commit('Search/setSearch', q)
            }
        },
        ...mapState('Search', [
            'modal', 'disabled', 'hints', 'hintsVisible'
        ]),
        ...mapState('Setup', ['default_language_iso']),
        ...mapGetters('Setup', ['language'])

    },
    watch: {
        modal: 'focus',
        disabled: 'focus'
    },
    methods: {
        query: debounce(function(ev) {
            if (this.search.length >= 3) {
                this.$store.dispatch('Search/query')
            }
        }, 900),
        setInput(ev) {
            this.search = ev.target.value
            this.query()
        },
        focus() {
            this.$nextTick(() => this.$refs.input.focus())
        },
        go() {
            if (this.search.length >= 3) {
                location.href = `${this.default_language_iso !== this.language ? '/' + this.language : ''}/search?phrase=${this.search}`
            }
        }
    }
}
</script>
<style lang="scss">
    .btn-icon{
        background: transparent;
        border:0 transparent;
        font-weight:100;
        outline: none !important;
        i{
            margin-right: 10px;
            bottom: -3px;
            position: relative;
            font-size: 18px !important;
            color: #000000 !important;
        }
    }
</style>