<template>
    <div class="row no-gutters">
        <div class="col-12">
            <carousel
                :per-page-custom="[[320,1],[768,2],[992,3]]"
                class="opinions-carousel"
                :loop="true"
                :pagination-enabled="false"
                :navigation-enabled="true"
                :scroll-per-page="false"
                :navigation-next-label="''"
                :navigation-prev-label="''"
                >
                <slide
                    v-for="(opinion, i) in opinions"
                    :key="i"
                    >
                    <opinion-item :item="opinion" />
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel"
import OpinionItem from '@components/opinions-carousel/carousel-item'

export default {
    components: {
        Carousel,
        Slide,
        OpinionItem
    },
    props: {
        list: { type: String, required: true }
    },
    data() {
        return {
            opinions: []
        }
    },
    mounted() {
        if (this.list) {
            this.opinions = JSON.parse(this.list)
        }
    }
}
</script>
