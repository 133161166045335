var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-8 px-0 ml-3 mt-1" }, [
    _c(
      "a",
      {
        attrs: { disabled: _vm.loading },
        on: {
          click: function ($event) {
            return _vm.addCupboard(_vm.index)
          },
        },
      },
      [_c("i", { staticClass: "i-cupboard cursor-pointer" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }