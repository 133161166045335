<template>
    <section
        class="basket__products-list"
        :class="{disabled: quantityChangePending}"
        >
        <div
            v-for="item in products"
            :key="item.id"
            >
            <product-item-alternative
                v-if="alternative"
                :item="item"
                :action="action"
                @remove="remove(item.id )"
                @quantity="quantity"
                >
                <a
                    v-if="action"
                    slot="header"
                    class="float-right btn-link-inline d-inline-block d-md-none list-item__remove"
                    :class="{disabled: quantityChangePending}"
                    @click="remove(item.id)"
                    >
                    <i class="i-close" />
                </a>
            </product-item-alternative>
            <product-item
                v-else
                :item="item"
                :action="action"
                @click="remove(item.id )"
                @quantity="quantity"
                >
                <a
                    v-if="action"
                    slot="header"
                    class="float-right btn-link-inline d-none list-item__remove"
                    :class="{disabled: quantityChangePending}"
                    @click="remove(item.id)"
                    >
                    <i class="i-close" />
                </a>
            </product-item>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProductItem from '@components/product/short-preview'
import ProductItemAlternative from '@components/product/short-preview/alternative'

export default {

    components: {
        ProductItem,
        ProductItemAlternative
    },

    props: {
        action: { type: Boolean, default: false },
        alternative: { type: Boolean, default: false }
    },

    computed: {
        ...mapState(
            'Basket', [
                'products', 'quantityChangePending'
            ]
        )
    },

    methods: {
        ...mapActions({
            remove: 'Basket/remove',
            quantity: 'Basket/setQuantity'
        })
    }
}
</script>

