<template>
    <custom-form
        :target="account_create ? 'consentsWithRegistration' : 'consents'"
        :action="'/ajax/basket/address/form'"
        />
</template>

<script>
import CustomForm from '@components/custom-form/items'
import { mapState } from 'vuex'

export default {
    components: {
        CustomForm
    },
    computed: {
        ...mapState('Basket', ['account_create'])
    }
}
</script>

