<template>
    <div class="container px-2">
        <div
            v-if="!loading"
            class="row mx-0"
            >
            <div class="col-12">
                <div class="category-grid category-grid--small-items">
                    <div
                        v-for="item in products"
                        :key="`${item.cupboard_id}-${item.variant.variant}`"
                        class="category-grid__item cupboard__item"
                        >
                        <product-brick :product="item.product">
                            <product-checkbox
                                slot="badges"
                                :item="item"
                                class="m-1"
                                />

                            <div
                                slot="footer"
                                class="m-3 cupboard__buttons"
                                >
                                <!-- <b-badge pill>{{ getCupboardData(item.cupboard_id).cupboardName }}</b-badge> -->
                                <button
                                    v-if="item.variant.stock"
                                    class="btn btn-primary btn-bg-white px-3 mb-2 w-100"
                                    @click.prevent="addBasket(item)"
                                    v-text="$ll('myCupboardsButtonAddThisProductToBasket')"
                                    />
                                <button
                                    class="btn btn-secondary btn-bg-white px-3 w-100"
                                    @click.prevent="deleteProducts(item)"
                                    v-text="$ll('myCupboardsButtonRemoveThisProduct')"
                                    />
                            </div>
                            <div
                                slot="footer"
                                class="product-item__preview product-item__preview--mobile p-3"
                                >
                                <!-- <b-badge pill>{{ getCupboardData(item.cupboard_id).cupboardName }}</b-badge> -->
                                <button
                                    v-if="item.variant.stock"
                                    class="btn btn-primary px-2 mb-2 w-100"
                                    @click.prevent="addBasket(item)"
                                    v-text="$ll('myCupboardsButtonAddThisProductToBasket')"
                                    />
                                <button
                                    class="btn btn-secondary px-2 w-100"
                                    @click.prevent="deleteProducts(item)"
                                    v-text="$ll('myCupboardsButtonRemoveThisProduct')"
                                    />
                            </div>

                            <template slot="attributes">
                                <!-- <div v-text="getCupboardData(item.cupboard_id).cupboardName" /> -->
                                <attributes-list :attributes="item.variant.attributes" />
                            </template>

                            <price
                                slot="price"
                                :price="item.variant.price"
                                />
                        </product-brick>
                    </div>
                </div>
            </div>
            <div
                v-if="!products.length"
                class="col-12 text-center"
                >
                <div v-text="$ll('myCupboardsListIsEmpty')" />
                <button
                    v-if="cupboardId"
                    class="btn btn-primary"
                    @click.prevent="removeCupboard"
                    v-text="$ll('myCupboardsRemoveCupboard')"
                    />
            </div>
        </div>
        <spin-loader
            v-else
            :p="5"
            />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AttributesList from '@components/product/brick/attributes-list'
// import CupboardHeader from '@components/cupboard-list/header'
import Price from "@components/basket/price"
import ProductCheckbox from '@components/cupboard-list/checkbox'
import ProductBrick from '@components/product/brick'

export default {
    components: {
        AttributesList,
        // CupboardHeader,
        Price,
        ProductCheckbox,
        ProductBrick
    },
    computed: {
        ...mapState('CategoryFilter', ['cols']),
        ...mapState('ProductCupboard', [
            'cupboardId', 'list', 'products', 'loading'
        ])
    },
    methods: {
        getCupboardData(id) {
            return this.$store.getters['ProductCupboard/getCupboardData'](id)
        },
        ...mapActions({
            addBasket: 'ProductCupboard/addBasket',
            deleteProducts: 'ProductCupboard/deleteProducts',
            removeCupboard: 'ProductCupboard/removeCupboard'
        })
    }
}
</script>