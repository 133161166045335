<template>
    <div class="basket__view">
        <div class="basket__container">
            <div class=" container px-3 px-sm-0">
                <router-view />
            </div>
        </div>
        <bottom-info />
    </div>
</template>

<script>
// import BasketBreadcrumb from '@components/basket/full/breadcrumb'
import BottomInfo from '@components/basket/full/bottom-info'

export default {

    components: {
        // BasketBreadcrumb,
        BottomInfo
    },
    mounted () {
        this.checkHeight()
        window.addEventListener('resize', this.checkHeight)
    },
    methods: {
        checkHeight() {
            const basketView = document.querySelector('.basket__view')

            if (basketView) {
                if (basketView.offsetHeight < window.innerHeight) {
                    basketView.classList.add('fixed-info')
                }
                else {
                    basketView.classList.remove('fixed-info')
                }
            }
        }
    }
}
</script>

