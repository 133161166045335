var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        size: "xl",
        "no-close-on-backdrop": "",
        centered: "",
        scrollable: "",
        "ok-only": "",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [_c("div", { domProps: { innerHTML: _vm._s(_vm.modalLogin) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }