document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelectorAll(".homepage-video")) {
        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
        const myVideo = document.querySelectorAll(".homepage-video")

        const playPause = function(video, banner) {
            if (video.paused) {
                video.play()
                banner.classList.remove('d-block')
                banner.classList.add('d-none')
            }
            else {
                video.pause()
                banner.classList.remove('d-none')
                banner.classList.add('d-block')
            }
        }

        for (let i = 0; i < myVideo.length; ++i) {
            const bannerTitle = myVideo[i].parentElement.querySelector('.home-banner__title')

            if (!iOS) {
                myVideo[i].removeAttribute("controls")
            }
            else {
                bannerTitle.classList.add('ios')
            }

            myVideo[i].addEventListener("click", () => {
                playPause(myVideo[i], bannerTitle)
            })

            myVideo[i].addEventListener('ended', () => {
                myVideo[i].load()
                bannerTitle.classList.remove('d-none')
                bannerTitle.classList.add('d-block')
            })
        }
        const videoSection = document.querySelectorAll(".homepage-section-video .home-banner")

        for (let i = 0; i < videoSection.length; ++i) {
            videoSection[i].classList.remove('d-none')
        }
    }
})
