var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-3" }, [
    _c("div", { staticClass: "col-12" }, [
      !!_vm.point
        ? _c("ul", { staticClass: "list-unstyled text-black" }, [
            _c("li", [_vm._v(_vm._s(_vm.point.name))]),
            _vm._v(" "),
            _vm.point.address.street
              ? _c("li", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.point.address.street) +
                      " " +
                      _vm._s(_vm.point.address.building_number) +
                      _vm._s(
                        _vm.point.address.flat_number &&
                          `/${_vm.point.address.flat_number}`
                      ) +
                      "\n            "
                  ),
                ])
              : _c("li", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.point.address.city) +
                      " " +
                      _vm._s(_vm.point.address.building_number) +
                      _vm._s(
                        _vm.point.address.flat_number &&
                          `/${_vm.point.address.flat_number}`
                      ) +
                      "\n            "
                  ),
                ]),
            _vm._v(" "),
            _c("li", [_vm._v(_vm._s(_vm.point.location_description))]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                _vm._s(_vm.point.address.post_code) +
                  " " +
                  _vm._s(_vm.point.address.city)
              ),
            ]),
            _vm._v(" "),
            !!_vm.point.operating_hours
              ? _c("li", { staticClass: "mt-2" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$ll("basketDeliveryPointOpenHours")) +
                      ":\n                "
                  ),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.point.operating_hours) },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            !!_vm.point.additional_info
              ? _c("li", { staticClass: "mt-2" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$ll("basketDeliveryPointAdditionalInfo")) +
                      ":"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.point.additional_info) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }