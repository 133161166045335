<template>
    <div class="form-field">
        <div class="form-field__checkbox form-field__checkbox--bordered">
            <input
                :id="'fc-' + filterIdVal + '-' + option.id + '-' + uid"
                type="checkbox"
                :checked="check.indexOf(option.id) >= 0"
                @change="$emit('toggle', option.id, $event.target.checked)"
            >
            <label
                :for="'fc-' + filterIdVal + '-' + option.id + '-' + uid"
                class="form__checkbox-label"
                v-text="option.v"
            />
        </div>
    </div>
</template>

<script>
import UniqueID from '@helpers/unique-id'

export default {
    props: {
        filter: {
            type: Object,
            required: false,
            default: null,
        },
        filterId: {
            type: [Number, String],
            required: false,
            default: '',
        },
        option: {
            type: Object,
            required: true,
        },
        check: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            uid: UniqueID.generate(),
        }
    },
    computed: {
        filterIdVal() {
            return this.filterId ? this.filterId : this.filter.id
        },
    },
}
</script>
