<script>
export default {

    watch: {
        $route() {
            this.$store.commit('CategoryFilter/setActiveFilters', { params: this.$route.params, query: this.$route.query })
            this.$store.dispatch('ProductList/getProductsFromAjax')
        }
    },

    mounted() {
        this.$store.commit('ProductList/setRouter', this.$router)
        this.$store.commit('CategoryFilter/setActiveFilters', { params: this.$route.params, query: this.$route.query })
    },

    render() {
        return ''
    }

}
</script>