<template>
    <div class="basket__list-item basket__list-item--alternative pb-4 mb-4">
        <div class="d-flex flex-nowrap w-100">
            <div class="list-item__photo">
                <a :href="item.url">
                    <Picture
                        :alt="item.name"
                        :src="$photoUrl(item.photo, 100)"
                        />
                </a>
            </div>
            <div class="list-item__containter">
                <strong class="d-inline-block list-item__name w-80">
                    <a
                        :href="item.url"
                        class="mb-0 d-inline-block font-weight-normal"
                        v-text="item.name"
                        />
                </strong>
                <slot name="header" />
                <p
                    class="list-item__code mb-0"
                    >
                    <small v-text="item.code" />
                </p>
                <b-alert
                    :show="!!item.error"
                    variant="danger"
                    v-text="item.error"
                    />
                <slot name="body">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div
                                v-for="attr in item.attributes"
                                :key="attr.key"
                                class=""
                                >
                                {{ attr.key }}:&nbsp;<span v-text="attr.value" />
                            </div>
                            <div
                                v-if="itemPriceVisible"
                                class="mt-2"
                                >
                                <basket-price
                                    :price="item.price"
                                    :view-currency="viewCurrency"
                                    />
                            </div>
                        </div>
                        <div class="col-6 col-md-2 d-flex justify-content-center align-items-center mt-4 mt-md-0">
                            <div class="text-nowrap amount-couter">
                                <a
                                    v-if="action && !isPackingForGift(item.variant)"
                                    class="btn-link-inline list-item__amount-btn mr-1"
                                    :class="{ disabled: item.quantity <= 1 || changingQty }"
                                    @click="$emit('quantity', { id: item.id, q: -1})"
                                    >
                                    <i class="mdi mdi-minus" />
                                </a>
                                <strong
                                    class="title-1"
                                    v-text="item.quantity"
                                    />
                                <a
                                    v-if="action && !isPackingForGift(item.variant)"
                                    class="btn-link-inline list-item__amount-btn ml-1"
                                    :class="{ disabled: changingQty }"
                                    @click="$emit('quantity', { id: item.id, q: 1})"
                                    >
                                    <i class="mdi mdi-plus" />
                                </a>
                            </div>
                        </div>

                        <div class="col-6 col-md-2 d-flex justify-content-center align-items-center mt-4 mt-md-0">
                            <strong class="title-1">
                                <basket-price
                                    :price="item.value"
                                    :view-currency="viewCurrency"
                                    />
                            </strong>
                        </div>
                        <div class="d-none d-md-flex col-md-2 justify-content-center align-items-center">
                            <a
                                v-if="action"
                                lass="float-right btn-link-inline d-inline-block d-md-none list-item__remove"
                                :class="{disabled: changingQty}"
                                @click="$emit('remove')"
                                >
                                <i class="i-close" />
                            </a>
                        </div>
                    </div>
                </slot>
            </div>
        </div>
        <slot />
        <div class="list-item__footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
import BasketPrice from '@components/basket/price'
import Price from '@components/price'
import { mapState } from 'vuex'

export default {
    components: {
        BasketPrice,
        Price
    },
    props: {
        item: {type: Object,
            default() {
                return {}
            }},
        action: { type: Boolean, default: false },
        url: { type: Boolean, default: false },
        viewCurrency: { type: String, default: '' },
        changingQty: { type: Boolean, default: false },
        itemPriceVisible: {type: Boolean, default: true}
    },
    computed: {
        ...mapState('Basket', ['giftPacking'])
    },
    methods: {
        isPackingForGift(id) {
            return this.giftPacking.id === id
    	}

    }
}
</script>
