<template>
    <div :class="{'form-field__select--invalid' : error }">
        <v-select
            label="label"
            :options="values"
            :disabled="disabled"
            :name="name"
            :value="selected"
            @input="setSorted"
            />
    </div>
</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
    components: {
        vSelect
    },
    props: {
        options: { type: Array, required: true },
        name: { type: String, required: true },
        disabled: { type: Boolean, required: true },
        selecting: { required: false },
        error: { required: false }
    },
    data() {
        return {
            selected: this.selecting,
            values: ''
        }
    },
    beforeMount() {
        const items = this.options
        let valuesArr = [
            {"label": this.name, "value": '0'}

        ]
        Object.keys(items).forEach(function(key) {
            valuesArr.push({ "label": items[key] , "value" :  key })
        })
        this.values = valuesArr
    },
    methods: {
        setSorted(payload) {
            this.$emit('change', payload.value)
        }
    }
}
</script>
