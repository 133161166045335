export default {
    namespaced: true,
    state() {

        return {
            disabled: false,
        }
    },
    getters: {
        disabled(state) {
            return state.disabled
        },
    },
    mutations: {
        setDisabled(state) {
            state.disabled = true
        },

        setUndisable(state) {
            state.disabled = false
        },
    }
}