var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "club-info-banner text-center pt-3 pb-1 mb-5" },
    [
      _c("h3", [
        _vm._v(
          "\n        " + _vm._s(_vm.$ll("clubBannerTitle")) + "\n        "
        ),
        _c("span", { staticClass: "text-danger light font-italic" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.$ll("clubBannerName")) + "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mx-md-0" }, [
        _c("div", { staticClass: "col-11 col-md-10 px-md-0 m-auto" }, [
          _vm.text
            ? _c("h6", {
                staticClass: "light mt-2 mb-4",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.text.replace(
                      "%0",
                      `${_vm.club_value} ${_vm.club_valueIso}`
                    )
                  ),
                },
              })
            : _c("p", [
                _vm._v(
                  "\n                " + _vm._s(_vm.$ll("clubInfoStart")) + " "
                ),
                _c(
                  "strong",
                  { staticClass: "text-black" },
                  [
                    _c("price", {
                      attrs: {
                        value: _vm.club_value,
                        "view-curency": _vm.club_valueIso,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" " + _vm._s(_vm.$ll("clubInfoEnd")) + "\n            "),
              ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row mx-md-0" },
        [
          _c("custom-form", {
            staticClass: "col-12 col-md-6 m-auto",
            attrs: { target: "clubForm", action: "/ajax/basket/clubSignUp" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }