import Vue from 'vue'
import Vuex from 'vuex'
import '@/store/axios'

import Basket from '@store/basket'
import CategoryFilter from '@store/category-filter'
import CustomForm from '@store/custom-form'
import Lang from '@store/lang'
import Loader from '@store/loader'
import MyAccount from '@store/my-account'
import Notifications from '@store/notifications'
import ProductCard from '@store/product-card'
import ProductCupboard from '@store/product-cupboard'
import ProductPhotos from '@store/product-photos'
import ProductPreview from '@store/product-preview'
import ProductList from '@store/product-list'
import ProductSizeTable from '@store/product-size-table'
import ProductSets from '@store/product-sets'
import Setup from '@store/setup'
import Search from '@store/search'
import Voucher from '@store/voucher'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        Basket,
        CategoryFilter,
        CustomForm,
        Lang,
        Loader,
        MyAccount,
        Notifications,
        ProductCard,
        ProductCupboard,
        ProductPhotos,
        ProductPreview,
        ProductList,
        ProductSizeTable,
        ProductSets,
        Setup,
        Search,
        Voucher,
    },
})
