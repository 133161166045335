<template>
    <ul class="list-unstyled">
        <!-- <li
            v-for="item in variants()[0].features"
            :key="item.variant_attribute_id"
            >
            {{ item.attribute_name }}: 
            <strong>
                <span v-text="item.value" />
            </strong>
        </li> -->
        <li
            v-for="(item, filterId) in variants()[0].features_list"
            :key="item.variant_attribute_id"
            >
            {{ item.attribute_name }}: 
            <strong>
                <a
                    v-if="item.is_filterable"
                    :href="$langUrl(`/${category}?filters=${filterId}[${item.attribute_value_id}]`)"
                    v-text="item.value"
                    />
                <span
                    v-else
                    v-text="item.value"
                    />
            </strong>
        </li>
    </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import json from '@db'

export default {
    computed: {
        ...mapGetters({
            variants: 'ProductCard/variants'
        }),
        category() {
            return ((json.productCategory || []).pop() || {}).name_url
        }
    }
}
</script>
