var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.field.listType == "radioImages"
        ? _c("type-images", {
            attrs: { field: _vm.field, data: _vm.data, target: _vm.target },
          })
        : _vm.field.listType == "radioSquares"
        ? _c("type-squares", {
            attrs: { field: _vm.field, data: _vm.data, target: _vm.target },
          })
        : _vm.field.listType == "ratingStars"
        ? _c("star-rating", {
            attrs: {
              "max-rating": _vm.field.ratingStarsSetting.max,
              increment: _vm.field.ratingStarsSetting.increment,
              "inactive-color": "#ffffff",
              "active-color": "#d40d1b",
              "border-color": "#d40d1b",
              "star-size": 34,
              "border-width": 3,
              "rounded-corners": true,
            },
            on: {
              "rating-selected": function ($event) {
                _vm.field.value = $event
              },
            },
          })
        : _c("type-default", {
            attrs: { field: _vm.field, data: _vm.data, target: _vm.target },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }