<template>
    <div
        class="text-center"
        :class="{ [`py-${p}`]: p }"
        >
        <i class="mdi mdi-36px mdi-loading mdi-spin" />
    </div>
</template>

<script>
export default {
    props: ['p']
}
</script>
