var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "nav lang-nav" },
    _vm._l(_vm.currencies, function (currency) {
      return _c(
        "li",
        {
          key: currency.currency_id,
          staticClass: "nav-item",
          class: { active: _vm.current == currency.currency_id },
        },
        [
          _c("a", {
            attrs: { href: `/currency/set/${currency.currency_id}` },
            domProps: { textContent: _vm._s(currency.iso) },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }