<template>
    <b-modal
        :visible="open"
        :title="$ll('productBasketModalOutOfStockTitle')"
        :ok-title="$ll('basketBackToShoppingButton')"
        ok-only
        centered
        @hidden="$emit('hidden')"
        >
        <div class="d-flex">
            {{ $ll('productOutOfStockMessage') }}
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        open: { type: Boolean, default: false }
    }
}
</script>
