<template>
    <div class="opinion-item">
        <a
            :href="item.url"
            class="opinion-item__image-wrapper"
            >
            <Picture
                :src="$photoUrl(item.photo)"
                :alt="item.name"
                />
        </a>
        <div class="opinion-item__content">
            <a
                :href="item.url"
                class="opinion-item__product-name"
                v-text="item.name"
                />
            <div class="opinion-item__score">
                <stars-preview :value="item.opinion.score" />
                <b v-text="item.opinion.author.name" />
            </div>
            <p
                class="opinion-item__opinion-text"
                v-text="item.opinion.content && item.opinion.content.length > 150 ? item.opinion.content.substr(0, 150) + '...' : item.opinion.content"
                />

            <a
                class="opinion-item__more-link"
                :href="`${item.url}#product-opinions`"
                >
                {{ $ll('read-more') }}
            </a>
        </div>
    </div>
</template>

<script>
import StarsPreview from "@components/my-account/stars-preview"

export default {
    components: {
        StarsPreview
    },
    props: {
        item: { type: Object, required: true }
    }
}
</script>
