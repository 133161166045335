<template>
    <div class="basket-gift-pack">
        <div class="form-field__checkbox form-field__checkbox--alternate form-field--alternate form-field__checkbox--image">
            <input
                id="gift-pack"
                v-model="vValue"
                type="checkbox"
                >
            <label
                class="form__checkbox-label"
                for="gift-pack"
                >
                <span>{{ $ll('giftPackingLabel') }}</span>
                <i class="i-gift" />
            </label>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
    name: 'GiftPacking',
    props: {
        giftPackingId: {
            type: Number || null,
            required: false,
            default: null
        }
    },
    data() {
        return {
            checkbox: false
        }
    },
    computed: {
        ...mapGetters('Basket', ['isPackingInBasket']),
        ...mapState('Basket', ['giftPacking']),
        vValue: {
            get() {
                return this.isPackingInBasket
            },
            set(v) {
                axios.post('/ajax/basket/giftWrapper', { value: v ? this.giftPacking.id : v }).then(
                    res => {
                        this.addResponse(res)
                        this.updateBasket(res)
                    }
                ).catch(error => this.catchException(error))
            }
        }
    },
    methods: {
        ...mapMutations('Basket', { updateBasket: 'update'}),
        ...mapActions('Basket', { catchException: 'catchException' }),
        ...mapActions('Notifications', { addResponse: 'addResponse' })
    }
}
</script>
