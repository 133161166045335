var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "category-grid",
      class: { "category-grid--small-items": _vm.cols || _vm.small },
    },
    [
      _vm._l(_vm.products, function (product) {
        return _c(
          "product-brick",
          {
            key: product.product_id,
            staticClass: "category-grid__item",
            attrs: { product: product },
          },
          [
            _c("product-brick-colors", {
              attrs: {
                slot: "footer",
                colors: product.colors || [],
                url: product.url,
              },
              slot: "footer",
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.ajax ? _vm._t("default") : _vm._e(),
      _vm._v(" "),
      _vm.ajax && !_vm.products.length ? _vm._t("empty") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }