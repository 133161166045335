var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "opinion-item" }, [
    _c(
      "a",
      {
        staticClass: "opinion-item__image-wrapper",
        attrs: { href: _vm.item.url },
      },
      [
        _c("Picture", {
          attrs: { src: _vm.$photoUrl(_vm.item.photo), alt: _vm.item.name },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "opinion-item__content" }, [
      _c("a", {
        staticClass: "opinion-item__product-name",
        attrs: { href: _vm.item.url },
        domProps: { textContent: _vm._s(_vm.item.name) },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "opinion-item__score" },
        [
          _c("stars-preview", { attrs: { value: _vm.item.opinion.score } }),
          _vm._v(" "),
          _c("b", {
            domProps: { textContent: _vm._s(_vm.item.opinion.author.name) },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", {
        staticClass: "opinion-item__opinion-text",
        domProps: {
          textContent: _vm._s(
            _vm.item.opinion.content && _vm.item.opinion.content.length > 150
              ? _vm.item.opinion.content.substr(0, 150) + "..."
              : _vm.item.opinion.content
          ),
        },
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "opinion-item__more-link",
          attrs: { href: `${_vm.item.url}#product-opinions` },
        },
        [_vm._v("\n            " + _vm._s(_vm.$ll("read-more")) + "\n        ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }