<template>
    <a
        class="plain-link cursor-pointer size-table-button"
        @click.prevent="handleOpen"
        v-text="$ll('productSizeTableLink')"
        />
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapState('ProductSizeTable', ['open'])
    },
    methods: {
        ...mapMutations('ProductSizeTable', [
            'toggleOpen', 'setId'
        ]),
        handleOpen() {
            this.toggleOpen(true)
            this.setId(this.id)
        }
    }
}
</script>
