import json from '@db'
import axios from 'axios'
import langUrl from '@/helpers/lang-url'

const { products, productsColorPhotos } = json

// Dołączanie zdjęć wariantów do tablicy colors w każdym produkcie
// Php uważa, że po stronie serwera to za długo trwa - Bug #72208
function assignColorPhotos(products, productsColorPhotos) {
    products.forEach(product=>{
        const {product_id} = product

        if (product.colors && product.colors.length) {
            const colorPhotos = productsColorPhotos[product_id]

            if (colorPhotos && Array.isArray(product.colors)) {
                Object.keys(colorPhotos).forEach(colorCode=>{
                    const colorAttr = product.colors.find(color => color.attribute_value_id === +colorCode)

                    if (colorAttr) {
                        colorAttr.image_url = colorPhotos[colorCode]
                    }
                })
            }
        }
    })
}

if (products && productsColorPhotos) {
    assignColorPhotos(products, productsColorPhotos)
}

export default {
    namespaced: true,
    state() {
        return {
            ajax: false,
            // firstAjax: false,
            router: {},
            products: json.products || []
        }
    },
    getters: {
        getUrl(_state, _getters, rootState, rootGetters) {
            return page => {
                const { params_str, query_str } = rootGetters['CategoryFilter/getParamsString'](page)
                const uri = rootGetters['CategoryFilter/uri']

                return langUrl(rootState.Setup.default_language_iso, rootGetters['Setup/language'], `${ uri && `/${uri}` }${ params_str }${ query_str && `?${query_str}` }`)
            }
        }
    },
    mutations: {
        setProducts(state, payload) {
            state.products = payload || []
        },
        setRouter(state, payload) {
            state.router = payload
        }
    },
    actions: {
        getProducts({ commit, state, rootGetters, rootState }, payload) {
            const { params_str, query_str } = payload
            // const action = rootGetters['CategoryFilter/action']
            const uri = rootGetters['CategoryFilter/uri']

            // state.router.push(`/${rootGetters['Setup/language']}${ uri && `/${uri}` }${ params_str }${ query_str && `?${query_str}` }`)
            state.router.push(langUrl(rootState.Setup.default_language_iso, rootGetters['Setup/language'], `${ uri && `/${uri}` }${ params_str }${ query_str && `?${query_str}` }`))

            // state.ajax = true
            // history.pushState(null, null, `/${rootGetters['Setup/language']}${ uri && `/${uri}` }${ params_str }${ query_str && `?${query_str}` }`)

            // // if (!state.firstAjax && state.products.length) {
            // //     state.firstAjax = true
            // //     return
            // // }

            // commit('CategoryFilter/setSubmit', {}, { root: true })
            // commit('Loader/setDisabled', {}, { root: true })
            // history.pushState(null, null, getters.getUrl())
            // console.log(`${ action }${ params_str }${ query_str && `?${query_str}` }`)
            // axios.post(`${ action }${ params_str }${ query_str && `?${query_str}` }`)
            //     .then(res => {
            //         commit('setProducts', res.data.products)
            //         commit('CategoryFilter/setPagination', res.data, { root: true })
            //         setTimeout(() => {
            //             commit('Loader/setUndisable', {}, { root: true })
            //         }, 100)
            //     })
        },

        getProductsFromAjax({ commit, rootGetters }) {
            const { params_str, query_str } = rootGetters['CategoryFilter/getParamsString'](rootGetters['CategoryFilter/getPage'])
            const action = rootGetters['CategoryFilter/action']

            commit('Loader/setDisabled', {}, { root: true })
            axios.post(`${ action }${ params_str }${ query_str && `?${query_str}` }`)
                .then(res => {
                    // Dołączanie zdjęć wariantów do tablicy colors w każdym produkcie
                    // Php uważa, że po stronie serwera to za długo trwa Bug #72208
                    const {products, productsColorPhotos } = res.data

                    if (products && productsColorPhotos) {
                        assignColorPhotos(products, productsColorPhotos)
                    }

                    commit('setProducts', products)
                    commit('CategoryFilter/setPagination', res.data, { root: true })
                }).finally(() => {
                    setTimeout(() => {
                        commit('Loader/setUndisable', {}, { root: true })
                    }, 100)
                })
        }
    }
}
