import Sticky from 'sticky-js'
let stk

function createSticky() {
    if (document.querySelector('#product-photos')) {
        const matches = window.matchMedia('min-width: 768px')

        if (!matches) return
        if (stk) stk.destroy()

        const header = document.querySelector('.header-wrapper')
        const headerHeight = header ? header.getBoundingClientRect().height : 0

        const productData = document.querySelector('#product-data')

        productData.setAttribute('data-margin-top', Math.round(headerHeight) + 10)

        stk = new Sticky('#product-data')
    }
}

const ua = window.navigator.userAgent
const isIE = /MSIE|Trident/.test(ua)

if (!isIE) {
    document.addEventListener('DOMContentLoaded', createSticky)
    window.addEventListener('resize', createSticky)

    // ! workaround for slow connections :) temporary
    setTimeout(() => createSticky(), 1000)
    setTimeout(() => createSticky(), 3000)
}

