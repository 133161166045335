<template>
    <div class="form-group mt-2 mb-2 float-right toggle-view">
        <div
            class="toggle-view-btn"
            :class="{ active: !cols, 'cursor-pointer': cols }"
            @click.prevent="setCols(false)"
            >
            <i class="i-grid-2 d-none d-sm-inline-block" />
            <i
                class="mdi mdi-square-outline d-sm-none"
                style="font-size: 18px"
                />
        </div>
        <div
            class="toggle-view-btn"
            :class="{ active: cols, 'cursor-pointer': !cols }"
            @click.prevent="setCols(true)"
            >
            <i class="i-grid-3 d-none d-sm-inline-block" />
            <i class="i-grid-2 d-sm-none" />
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {

    computed: {
        ...mapState('CategoryFilter', [
            'show', 'cols'
        ])
    },

    methods: {
        ...mapMutations({
            toggleGrid: 'CategoryFilter/toggle-grid',
            setCols: 'CategoryFilter/setCols'
        })
    }
}
</script>
