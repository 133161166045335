var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-field__checkbox" }, [
    _vm.field.type != "disclaimer"
      ? _c("input", {
          class: { "is-invalid": _vm.field.error },
          attrs: {
            id: `${_vm.field.name}-${_vm.uid}`,
            type: "checkbox",
            required: (_vm.field.rules || []).includes("required"),
          },
          domProps: { checked: _vm.field.value == "t" },
          on: { change: _vm.emits },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("label", {
      staticClass: "form__checkbox-label",
      attrs: { for: `${_vm.field.name}-${_vm.uid}` },
      domProps: { innerHTML: _vm._s(_vm.field.label) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }