var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("a", {
        staticClass: "cursor-pointer",
        class: _vm.css,
        domProps: { textContent: _vm._s(_vm.title) },
        on: {
          click: function ($event) {
            _vm.modalShow = true
          },
        },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.modalTitle,
            "ok-title": _vm.modalOkTitle,
            "cancel-title": _vm.modalCancelTitle,
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          on: { ok: _vm.ok },
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.modalBody) + "\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }