<template>
    <div>
        <basket-breadcrumb />
        <div class="container mt-4 pb-5 px-2">
            <div class="row no-gutters justify-content-between">
                <div class="col-12 col-lg-5 col-md-6 mt-5 pr-0 pr-md-3">
                    <login-form :alone="false" />
                </div>

                <div class="col-12 col-lg-5 col-md-6 mt-5 pl-0 pl-md-3">
                    <h3 class="mb-4 text-center">
                        {{ $ll('basketLoginTitle') }}
                    </h3>
                    <div>
                        <p><strong>{{ $ll('basketLoginPluses') }}:</strong></p>
                        <p><i class="mdi mdi-18px mdi-check text-success" /> {{ $ll('basketLoginSaveAddress') }} </p>
                        <p><i class="mdi mdi-18px mdi-check text-success" /> {{ $ll('basketLoginOrderStatus') }}</p>
                        <p><i class="mdi mdi-18px mdi-check text-success" /> {{ $ll('basketLoginOrderHistory') }}</p>
                        <button
                            class="btn btn-outline-secondary w-100"
                            @click.prevent="setAccountCreate"
                            >
                            {{ $ll('basketLoginCreateAccount') }}
                        </button>
                        <p class="mt-2 text-center">
                            {{ $ll('basketLoginOr') }}
                        </p>
                        <button
                            class="btn btn-outline-secondary w-100"
                            @click.prevent="setGuestWithoutAccout"
                            >
                            {{ $ll('basketLoginContinueWithout') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BasketBreadcrumb from '@components/basket/full/breadcrumb'
import LoginForm from '@components/login/form'
import { mapMutations } from 'vuex'

export default {

    components: {
        BasketBreadcrumb,
        LoginForm
    },

    methods: {
        ...mapMutations({
            setGuest: 'Setup/setGuest'
        }),
        setGuestWithoutAccout() {
            this.$store.commit('Basket/setAccountCreate', false)
            this.setGuest()
        },
        setAccountCreate() {
            this.$store.commit('Setup/loginTo', this.$langUrl('/basket/delivery'))
            this.$store.commit('Basket/setAccountCreate')
            this.setGuest()
        }
    }
}
</script>
