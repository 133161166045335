import json from '@db'

const available = json.setup.photosSizes.map(i => i.split('x').map(Number))
const size = (width = 0) => available.find(([w]) => w >= width)

/**
 * Zwraca najblizszy wyzszy rozmiar obrazkow z podanego przedzialu
 */
export default (url, width) => {
    if (!url) return '/static/www/cartalo-placeholder.svg'

    const reg = /^((http|https|ftp):\/\/)/

    if (!reg.test(url)) url = url.startsWith('/') ? url : '/' + url

    if (!width || width === 'raw') return url

    const last = url.lastIndexOf('/')

    return `${ url.slice(0, last) }/${ size(width).join('x') }${ url.slice(last) }`
}