<template>
    <div class="container set-container">
        <div
            v-for="(product, index) in products"
            :key="product.code"
            class="row"
            >
            <div class="col-12 col-md-6 col-xl-3 d-flex flex-column justify-content-center">
                <product-set-carousel
                    :images="product.photos"
                    :url="product.url"
                    />
            </div>
            <div class="col-12 col-md-6 col-xl-5 pr-4 d-flex flex-column justify-content-center">
                <h3
                    class="mb-1 pb-1 mt-3 mt-md-0 text-uppercase"
                    >
                    <a
                        :href="product.url"
                        v-text="product.name"
                        />
                </h3>
                <product-set-price :index="index" />
                <p
                    class="text-black-50 set-prod-desc"
                    v-html="product.description"
                    />
            </div>
            <div class="col-12 col-md-6 col-xl-4 mt-4">
                <product-set-attr :index="index" />

                <div
                    v-if="product.size_table_identifier"
                    class="size-table-container"
                    >
                    <product-size-table-trigger :id="product.size_table_identifier" />
                </div>
                <div class="mt-4">
                    <product-set-add-basket :index="index" />
                </div>
            </div>
        </div>
        <product-set-modals />
    </div>
</template>

<script>
import ProductSetCarousel from './set-carousel'
import ProductSetAttr from './set-attr'
import ProductSetPrice from './set-price'
import ProductSetAddBasket from './set-add-basket'
import ProductSetModals from '@components/product/add-basket-modals/product-sets'
import { mapState } from 'vuex'
import ProductSizeTableTrigger from '@components/product/size-table/trigger'

export default {
    components: {
        ProductSetCarousel,
        ProductSetAttr,
        ProductSetPrice,
        ProductSetAddBasket,
        ProductSetModals,
        ProductSizeTableTrigger
    },
    computed: {
        ...mapState('ProductSets', ['products'])
    }
}
</script>
