<template>
    <div class="basket__summary">
        <div class="row no-gutters">
            <div class="col-12">
                <basket-breadcrumb />
                <h1 class="text-uppercase text-center mt-3 mt-lg-5 mb-0 pb-4 bottom-line">
                    {{ $ll('basketTitleSummary') }}
                </h1>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-12 col-lg-6 mt-4 pr-lg-3 pr-0">
                <label
                    class="text-uppercase d-flex justify-content-between mt-3 mb-4 pb-3 bottom-line text-black summary-label"
                    >
                    {{ $ll('basketConfirmDeliveryAndPayment') }}
                    <router-link
                        :to="$langUrl('/basket/delivery')"
                        v-text="$ll('basketConfirmChangeButton')"
                        />
                </label>
                <div class="row">
                    <div class="col-12 bottom-line">
                        <payment-info />
                    </div>
                    <div class="col-12 pt-4">
                        <basket-summary />
                    </div>
                </div>

                <template v-if="!!point">
                    <label
                        class="text-uppercase d-flex justify-content-between mt-3 mb-4 pb-3 bottom-line text-black summary-label"
                        >
                        {{ $ll('basketConfirmDeliveryPoint') }}
                        <router-link
                            :to="$langUrl('/basket/delivery')"
                            @click.native="setPoint()"
                            v-text="$ll('basketConfirmChangeButton')"
                            />
                    </label>
                    <delivery-point-details />
                </template>
                <label
                    class="text-uppercase d-flex justify-content-between mt-3 mb-4 pb-3 bottom-line text-black summary-label"
                    >
                    {{ $ll('basketConfirmAddress') }}
                    <router-link
                        :to="$langUrl('/basket/delivery')"
                        v-text="$ll('basketConfirmChangeButton')"
                        />
                </label>
                <country-info />
                <delivery-info form="delivery" />
                <template v-if="wantInvoice">
                    <label
                        class="text-uppercase d-flex justify-content-between mt-3 mb-4 pb-3 bottom-line text-black summary-label"
                        >
                        {{ $ll('basketConfirmAddressInvoice') }}
                        <router-link
                            :to="$langUrl('/basket/delivery')"
                            v-text="$ll('basketConfirmChangeButton')"
                            />
                    </label>
                    <delivery-info form="invoice" />
                </template>
            </div>
            <div class="col-12 col-lg-6 mt-4 pl-0 pl-lg-3 delivery-products">
                <label
                    class="text-uppercase d-flex justify-content-between mt-3 mb-4 pb-3 bottom-line text-black summary-label"
                    >
                    {{ $ll('basketList') }}
                    <router-link
                        :to="$langUrl('/basket')"
                        v-text="$ll('basketConfirmChangeButton')"
                        />
                </label>
                <basket-list />
            </div>
        </div>
        <basket-buttons />
        <tpay-card-modal v-if="showTpayCardModal" />
        <tpay-blik-modal v-if="showTpayBlikModal" />
    </div>
</template>

<script>
import BasketList from '@components/basket/full/list'
import BasketSummary from '@components/basket/full/summary'
import DeliveryPointDetails from '@components/basket/full/delivery-point-details'
import DeliveryInfo from '@components/basket/full/step3/delivery-info'
import PaymentInfo from '@components/basket/full/step3/payment-info'
import BasketBreadcrumb from '@components/basket/full/breadcrumb'
import BasketButtons from '@components/basket/full/step3/buttons'
import CountryInfo from '@components/basket/full/step3/country-info'
const TpayCardModal = () => import(/* webpackChunkName: 'tpay-card-modal' */ '@components/basket/full/step3/tpay-card-modal')
const TpayBlikModal = () => import(/* webpackChunkName: 'tpay-blik-modal' */ '@components/basket/full/step3/tpay-blik-modal')

import { mapState, mapMutations, mapGetters } from 'vuex'

export default {

    components: {
        BasketList,
        BasketSummary,
        DeliveryPointDetails,
        DeliveryInfo,
        PaymentInfo,
        BasketBreadcrumb,
        BasketButtons,
        CountryInfo,
        TpayCardModal,
        TpayBlikModal
    },

    computed: {
        ...mapState('Basket', [
            'products', 'payment', 'point', 'wantInvoice'
        ]),
        ...mapGetters('Basket', [
            'showTpayCardModal', 'showTpayBlikModal'
        ]),
        ...mapState('CustomForm', ['forms'])
    },

    methods: {
        ...mapMutations({
            setPoint: 'Basket/setPoint'
        })

    }
}
</script>
