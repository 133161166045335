var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "filters-container", class: { show: _vm.show } }, [
      _c(
        "section",
        { staticClass: "filters" },
        [
          _c(
            "div",
            { staticClass: "filters__title" },
            [
              _c("h3", { staticClass: "h5" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$ll("categorySelectFiches")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("close-container"),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.filters, function (filter) {
            return _c("filter-slot", {
              key: filter.id,
              ref: "filters",
              refInFor: true,
              staticClass: "filters__partial",
              attrs: { filter: filter, checked: _vm.checked[filter.id] },
            })
          }),
          _vm._v(" "),
          _vm.producers.length
            ? _c("producers-elem", {
                ref: "producers",
                staticClass: "filters__partial",
              })
            : _vm._e(),
          _vm._v(" "),
          _c("fiches", { ref: "fiches", staticClass: "filters__partial" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filters__actions" },
            [
              _c("btn-submit", {
                staticClass: "w-100",
                attrs: { value: _vm.$ll("categoryFiltersSubmit") },
                on: { click: _vm.submit },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm._l(_vm.checked, function (filter, key) {
          return _c("category-filter-remove", {
            key: key,
            attrs: { id: key, filter: filter },
            on: { remove: _vm.removeVariant },
          })
        }),
        _vm._v(" "),
        _c("category-fiches-remove", {
          on: { removeFiches: _vm.removeFiches },
        }),
        _vm._v(" "),
        _vm._l(_vm.checkedProducers, function (producerId) {
          return _c("category-producer-remove", {
            key: producerId,
            attrs: { "producer-id": producerId },
            on: { remove: _vm.handleProducerRemove },
          })
        }),
        _vm._v(" "),
        _vm.someFilterChecked
          ? _c("div", { staticClass: "row mx-0 my-2" }, [
              _c("div", { staticClass: "col-12 d-flex justify-content-end" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn--link p-0 remove-filters-button",
                    on: { click: _vm.reset },
                  },
                  [
                    _vm._v(
                      "\n                    x " +
                        _vm._s(_vm.$ll("categoryFiltersClear")) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "filters-overlay overlay-color",
      class: { show: _vm.show },
      on: { click: _vm.toggle },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }