<template>
    <span class="list-item__prices">
        <price
            :value="price.promoted || price.regular"
            :view-currency="viewCurrency"
            />
        <s
            v-if="price.promoted && price.promoted != price.regular"
            class="d-block light-gray"
            >
            <price
                :value="price.regular"
                :view-currency="viewCurrency"
                />
        </s>
    </span>
</template>

<script>
import Price from '@components/price'

export default {

    components: {
        Price
    },

    props: {
        price: { type: Object, required: true },
        viewCurrency: { type: String, default: null }
    }
}
</script>
