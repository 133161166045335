/**
 * Hover obrazków ktore nie sa jeszcze obslugiwane przez Vue - dla listy kategorii
 */
document.addEventListener('DOMContentLoaded', () => {

    [].forEach.call( document.querySelectorAll('.product-item__img'), href => {

        if ( href.querySelectorAll('img').length < 2 ) return 

        href.addEventListener('mouseover', () => {
            href.querySelector('img:first-child').classList.add('d-none')
            href.querySelector('img:last-child').classList.remove('d-none')
        })
        href.addEventListener('mouseout', () => {
            href.querySelector('img:first-child').classList.remove('d-none')
            href.querySelector('img:last-child').classList.add('d-none')
        })
    })
})