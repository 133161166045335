<template>
    <div
        v-if="addresses[data].length"
        class="form-group adresses-form-group"
        >
        <div class="row">
            <div
                v-for="(item, id) in addresses[data]"
                :key="id"
                class="col-12 col-sm-6 btn-group"
                >
                <div
                    class="btn-outline-primary text-center position-relative"
                    :class="{ active: id === addressIndex[data] }"
                    @click.prevent="setAddressIndex({data,id})"
                    >
                    <list-address
                        v-if="data == 'delivery'"
                        :data="item"
                        />
                    <list-invoice
                        v-if="data == 'invoice'"
                        :data="item"
                        />
                    <a
                        :class="{ visible: (addressIndex[data] == id) }"
                        href="#"
                        class="plain-link p-1 m-0 position-absolute"
                        @click="editCurrentAddresHandler"
                        v-text="$ll('basketLoggedAddressesEditBtn')"
                        />
                </div>
            </div>
            <div class="col-12 col-sm-6 btn-group">
                <div
                    class="btn-outline-primary p-2 text-center"
                    :class="{ active: 'own' === addressIndex[data] }"
                    @click.prevent="addNewAddresHandler"
                    >
                    <div>
                        {{ $ll('basketLoggedAddressesOtherAddress') }}...
                    </div>
                </div>
            </div>
        </div>
        <label ref="form" />
    </div>
</template>

<script>
import ListAddress from '@components/basket/full/step2/form-data/fill-list-address'
import ListInvoice from '@components/basket/full/step2/form-data/fill-list-invoice'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    components: {
        ListAddress,
        ListInvoice
    },
    props: {
        data: { type: String, required: true }
    },
    computed: {
        ...mapState('Basket', [
            'addresses', 'addressIndex'
        ])
    },
    methods: {
        ...mapActions({
            setAddressIndex: 'Basket/setAddressIndex'
        }),
        ...mapMutations({
            setEditForm: 'Basket/setEditForm'
        }),
        editCurrentAddresHandler() {
            this.setEditForm({data: this.data, set: true})
            this.$scrollTo(this.$refs.form, {
                offset: -50
            })
        },
        addNewAddresHandler() {
            this.setAddressIndex({data: this.data, id: 'own'})
            this.$scrollTo(this.$refs.form, {
                offset: -50
            })
        }
    }
}
</script>
