var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "mb-1" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.data.forename) +
          " " +
          _vm._s(_vm.data.surname) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "d-block" }, [
      _vm._v("\n        " + _vm._s(_vm.data.address) + "\n    "),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "d-block" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.data.postcode) +
          " " +
          _vm._s(_vm.data.city) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "d-block" }, [
      _vm._v("\n        " + _vm._s(_vm.data.email) + "\n    "),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "d-block" }, [
      _vm._v("\n        " + _vm._s(_vm.data.phone) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }