var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container px-2" },
    [
      !_vm.loading
        ? _c("div", { staticClass: "row mx-0" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "category-grid category-grid--small-items" },
                _vm._l(_vm.products, function (item) {
                  return _c(
                    "div",
                    {
                      key: `${item.cupboard_id}-${item.variant.variant}`,
                      staticClass: "category-grid__item cupboard__item",
                    },
                    [
                      _c(
                        "product-brick",
                        { attrs: { product: item.product } },
                        [
                          _c("product-checkbox", {
                            staticClass: "m-1",
                            attrs: { slot: "badges", item: item },
                            slot: "badges",
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "m-3 cupboard__buttons",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              item.variant.stock
                                ? _c("button", {
                                    staticClass:
                                      "btn btn-primary btn-bg-white px-3 mb-2 w-100",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$ll(
                                          "myCupboardsButtonAddThisProductToBasket"
                                        )
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.addBasket(item)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("button", {
                                staticClass:
                                  "btn btn-secondary btn-bg-white px-3 w-100",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$ll(
                                      "myCupboardsButtonRemoveThisProduct"
                                    )
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteProducts(item)
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "product-item__preview product-item__preview--mobile p-3",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              item.variant.stock
                                ? _c("button", {
                                    staticClass:
                                      "btn btn-primary px-2 mb-2 w-100",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$ll(
                                          "myCupboardsButtonAddThisProductToBasket"
                                        )
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.addBasket(item)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("button", {
                                staticClass: "btn btn-secondary px-2 w-100",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$ll(
                                      "myCupboardsButtonRemoveThisProduct"
                                    )
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteProducts(item)
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "template",
                            { slot: "attributes" },
                            [
                              _c("attributes-list", {
                                attrs: { attributes: item.variant.attributes },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("price", {
                            attrs: { slot: "price", price: item.variant.price },
                            slot: "price",
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            !_vm.products.length
              ? _c("div", { staticClass: "col-12 text-center" }, [
                  _c("div", {
                    domProps: {
                      textContent: _vm._s(_vm.$ll("myCupboardsListIsEmpty")),
                    },
                  }),
                  _vm._v(" "),
                  _vm.cupboardId
                    ? _c("button", {
                        staticClass: "btn btn-primary",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$ll("myCupboardsRemoveCupboard")
                          ),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.removeCupboard.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _c("spin-loader", { attrs: { p: 5 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }