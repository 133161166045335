var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.point && _vm.loadDelivery,
          expression: "point && loadDelivery",
        },
      ],
      staticClass: "form-group",
    },
    [
      _c(
        "label",
        {
          staticClass:
            "text-uppercase text-center d-block mt-3 mb-4 pb-3 bottom-line text-black",
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$ll("basketSelectedDeliveryPoint")) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("delivery-point-details"),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "plain-link",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.setPoint()
            },
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$ll("basketSelectDifferentDeliveryPoint")) +
              "\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }