<template>
    <div class="buttons-container">
        <div
            class="basket__buttons"
            :class="{'fixed' : buttonFixed}"
            >
            <div :class="{'container' : buttonFixed}">
                <div class="row no-gutters justify-content-end">
                    <div
                        v-show="buttonFixed"
                        class="col-12 mb-3"
                        >
                        <label><strong>{{ $ll('basketSummaryFull') }}:</strong></label>
                        <strong>
                            <price-single
                                :value="shipment.final"
                                class="float-right"
                                />
                        </strong>
                    </div>
                    <div
                        class="col-12 order-sm-last"
                        :class="{'col-sm-6': !buttonFixed}"
                        >
                        <button
                            class="btn btn-black w-100"
                            :class="{disabled: loading}"
                            :disabled="loading"
                            @click.prevent="order"
                            v-text="$ll('basketConfirmOrderButton')"
                            />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import PriceSingle from '@components/price'

export default {
    components: {
        PriceSingle
    },
    data() {
        return {
            buttonFixed: false
        }
    },
    computed: {
        ...mapState('Basket', ['loading']),
        ...mapGetters({
            shipment: 'Basket/getTotalWithShipment'
        })
    },
    mounted() {
        this.getPosition()
    },
    created () {
        window.addEventListener('scroll', this.checkPosition)
        window.addEventListener('resize', this.checkPosition)
    },
    methods: {
        ...mapActions({
            order: 'Basket/order'
        }),

        getPosition() {
            const D = document
            const height = Math.max(
                D.body.scrollHeight, D.documentElement.scrollHeight,
                D.body.offsetHeight, D.documentElement.offsetHeight,
                D.body.clientHeight, D.documentElement.clientHeight
            )
            const viewport = document.documentElement.clientHeight

            if (height > viewport) {
                this.buttonFixed = true
            }
        },
        checkPosition() {
            const buttons = document.querySelector('.buttons-container')

            if (buttons) {
                if (document.querySelector('section .container').offsetWidth < 721) {
                    const bottomPosition = buttons.getBoundingClientRect()

                    bottomPosition.top + 40 < window.innerHeight ? this.buttonFixed = false : this.buttonFixed = true
                }
                else {
                    this.buttonFixed = false
                }
            }
        }
    }
}
</script>
