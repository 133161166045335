var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-brick" }, [
    _c("a", { staticClass: "product-item", attrs: { href: _vm.product.url } }, [
      _c(
        "span",
        { staticClass: "product-item__body" },
        [
          _vm._t("badges", function () {
            return [
              _c(
                "span",
                { staticClass: "product-item__badges" },
                _vm._l(_vm.product.fiches, function (item, key) {
                  return _c("Picture", {
                    key: key,
                    staticClass: "product-item__badges-item",
                    attrs: { src: _vm.$photoUrl(item.path), alt: item.name },
                  })
                }),
                1
              ),
            ]
          }),
          _vm._v(" "),
          _vm.product.thumbnail
            ? _c(
                "span",
                {
                  staticClass: "product-item__image position-relative",
                  attrs: { href: _vm.product.url },
                },
                [
                  _c("Picture", {
                    attrs: {
                      src: _vm.$photoUrl(_vm.product.thumbnail, 520),
                      alt: _vm.product.name,
                      "class-img": "img-cover position-absolute pos-reset",
                      width: _vm.product.thumbnail_width,
                      height: _vm.product.thumbnail_height,
                    },
                  }),
                  _vm._v(" "),
                  _vm.product.hover
                    ? _c("Picture", {
                        attrs: {
                          src: `${
                            _vm.product.hover
                              ? _vm.$photoUrl(_vm.product.hover, 520)
                              : _vm.$photoUrl(_vm.product.thumbnail, 520)
                          }`,
                          alt: _vm.product.name,
                          "class-img":
                            "product-item__img product-item__img--hidden",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _c("span", { staticClass: "img-placeholder" }),
          _vm._v(" "),
          _vm._t("body"),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "product-item__footer" },
        [
          _vm.product.producer
            ? _c("h4", {
                staticClass: "product-item__producer-name",
                domProps: { textContent: _vm._s(_vm.product.producer.name) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", {
            staticClass: "product-item__product-name",
            domProps: { textContent: _vm._s(_vm.product.name) },
          }),
          _vm._v(" "),
          _vm._t("attributes"),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "product-item__price" },
            [
              _vm._t("price", function () {
                return [_c("price", { attrs: { price: _vm.product.prices } })]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("footer"),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }