var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pswp",
      attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" },
    },
    [
      _c("div", { staticClass: "pswp__bg overlay-color" }),
      _vm._v(" "),
      _c("div", { staticClass: "pswp__scroll-wrap" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "pswp__ui pswp__ui--hidden" }, [
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("button", {
            staticClass: "pswp__button pswp__button--arrow--left",
            attrs: { title: "Previous (arrow left)" },
          }),
          _vm._v(" "),
          _c("button", {
            staticClass: "pswp__button pswp__button--arrow--right",
            attrs: { title: "Next (arrow right)" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "pswp__caption" }, [
            _c("span"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pswp__caption__center text-center" },
              _vm._l(_vm.items, function (img, index) {
                return _c("Picture", {
                  key: img.src,
                  attrs: {
                    src: img.src,
                    alt: img.name || img.description,
                    "class-img": `image-fluid mx-1 thumb-image ${
                      _vm.active == index ? "thumb-active" : ""
                    }`,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.goTo(index)
                    },
                  },
                })
              }),
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pswp__container" }, [
      _c("div", { staticClass: "pswp__item" }),
      _vm._v(" "),
      _c("div", { staticClass: "pswp__item" }),
      _vm._v(" "),
      _c("div", { staticClass: "pswp__item" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pswp__top-bar" }, [
      _c("div", { staticClass: "pswp__counter" }),
      _vm._v(" "),
      _c("button", {
        staticClass: "pswp__button pswp__button--close",
        attrs: { title: "Close (Esc)" },
      }),
      _vm._v(" "),
      _c("button", {
        staticClass: "pswp__button pswp__button--share",
        attrs: { title: "Share" },
      }),
      _vm._v(" "),
      _c("button", {
        staticClass: "pswp__button pswp__button--fs",
        attrs: { title: "Toggle fullscreen" },
      }),
      _vm._v(" "),
      _c("button", {
        staticClass: "pswp__button pswp__button--zoom",
        attrs: { title: "Zoom in/out" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "pswp__preloader" }, [
        _c("div", { staticClass: "pswp__preloader__icn" }, [
          _c("div", { staticClass: "pswp__preloader__cut" }, [
            _c("div", { staticClass: "pswp__preloader__donut" }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "pswp__share-modal pswp__share-modal--hidden pswp__single-tap",
      },
      [_c("div", { staticClass: "pswp__share-tooltip" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }