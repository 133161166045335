<template>
    <div class="d-flex align-items-center cupboard-list-container">
        <div
            v-if="button"
            class="col-8 px-0 ml-3 mt-1"
            >
            <a
                :disabled="loading && !show"
                @click="addCupboard"
                >
                <i class="i-cupboard cursor-pointer" />
            </a>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import BtnSubmit from '@components/btn-submit'

export default {
    components: {
        BtnSubmit
    },
    mixins: [ clickaway ],
    props: {
        button: { type: Boolean, default: true }
    },
    data() {
        return {
            show: false
        }
    },
    computed: {
        id: {
            get() { return this.$store.state.ProductCupboard.id },
            set(id) { this.$store.commit('ProductCupboard/setId', id) }
        },
        name: {
            get() { return this.$store.state.ProductCupboard.name },
            set(name) { this.$store.commit('ProductCupboard/setName', name) }
        },
        ...mapGetters({
            getSetName: 'ProductCupboard/getSetName',
            list: 'ProductCupboard/getList'
        }),
        ...mapState('Setup', ['login']),
        ...mapState('ProductCupboard', ['loading'])
    },
    watch: {
        id: 'close'
    },
    methods: {
        ...mapActions({
            addCupboard: 'ProductCard/addCupboard',
            newCupboard: 'ProductCupboard/newCupboard'
        }),
        close() {
            this.show = false
        }
    },
}
</script>
