var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h6", { staticClass: "text-black" }, [
      _c("strong", [_vm._v(_vm._s(_vm.details.name))]),
    ]),
    _vm._v(" "),
    _vm.details.address
      ? _c("p", { staticClass: "mb-0" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.details.address.street) +
              " " +
              _vm._s(_vm.details.address.building_number) +
              _vm._s(
                _vm.details.address.flat_number &&
                  `/${_vm.details.address.flat_number}`
              ) +
              "\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.details.address
      ? _c("p", [
          _vm._v(
            "\n        " +
              _vm._s(_vm.details.address.post_code) +
              " " +
              _vm._s(_vm.details.address.city) +
              "\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.details.operating_hours
      ? _c("h6", { staticClass: "mt-4" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$ll("basketDeliveryPointOpenHours")) +
              ":\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.details.operating_hours
      ? _c("p", {
          domProps: { innerHTML: _vm._s(_vm.details.operating_hours) },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.details.additional_info
      ? _c("h6", { staticClass: "mt-3" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$ll("basketDeliveryPointAdditionalInfo")) +
              ":\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("p", { domProps: { innerHTML: _vm._s(_vm.details.additional_info) } }),
    _vm._v(" "),
    _c("div", { staticClass: "text-center mt-4" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("setPoint", _vm.details)
            },
          },
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$ll("basketDeliveryPointCommit")) +
              "\n        "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }