var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100 basket-summary" }, [
    _c("ul", { staticClass: "list-unstyled w-100" }, [
      _c(
        "li",
        { class: _vm.free_delivery_missing_amount ? "mb-3" : "mb-2" },
        [
          _c("span", { staticClass: "mb-0" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$ll("basketSummaryProducts")) +
                ":\n            "
            ),
          ]),
          _vm._v(" "),
          _c("price-single", {
            staticClass: "float-right",
            attrs: { value: _vm.total.regular },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.free_delivery_missing_amount
        ? _c("li", { staticClass: "mb-2" }, [
            _c("span", { staticClass: "mb-0" }, [
              _vm._v("\n                 \n            "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "float-right" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$ll("basketSummaryFreeDeliveryLackStart")) +
                  " "
              ),
              _c(
                "strong",
                [
                  _c("price-single", {
                    attrs: { value: _vm.free_delivery_missing_amount },
                  }),
                ],
                1
              ),
              _vm._v(
                " " +
                  _vm._s(_vm.$ll("basketSummaryFreeDeliveryLackEnd")) +
                  "\n            "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.benefit
        ? _c(
            "li",
            {
              class: {
                "mb-3":
                  _vm.loyalty &&
                  !_vm.loyalty.points &&
                  _vm.voucher &&
                  !_vm.voucher.name,
              },
            },
            [
              _c("span", { staticClass: "mb-1" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$ll("basketSummaryPromotion")) +
                    ":\n            "
                ),
              ]),
              _vm._v(" "),
              _c("price-single", {
                staticClass: "float-right",
                attrs: { value: _vm.benefit },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loyalty && _vm.loyalty.points
        ? _c(
            "li",
            { class: { "mb-3": _vm.voucher && !_vm.voucher.name } },
            [
              _c("span", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$ll("basketLoyaltyDiscount")) +
                    ":\n            "
                ),
              ]),
              _vm._v(" "),
              _c("price-single", {
                staticClass: "float-right",
                attrs: { value: _vm.loyalty.current_discount },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.voucher && _vm.voucher.name
        ? _c(
            "li",
            { staticClass: "mb-3" },
            [
              _c("span", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$ll("basketVoucherSet")) +
                    ":\n            "
                ),
              ]),
              _vm._v(" "),
              _c("price-single", {
                staticClass: "float-right",
                attrs: { value: _vm.voucher.value },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.four_plus_one_promotion_value
        ? _c(
            "li",
            [
              _c("span", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$ll("basketFourPlusOnePromotionValue")) +
                    ":\n            "
                ),
              ]),
              _vm._v(" "),
              _c("price-single", {
                staticClass: "float-right",
                attrs: { value: _vm.four_plus_one_promotion_value },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.promotion && _vm.promotion.name
        ? _c(
            "li",
            { staticClass: "mb-3" },
            [
              _c("span", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$ll("basketPromotionSet")) +
                    " - " +
                    _vm._s(_vm.promotion.name) +
                    ":\n            "
                ),
              ]),
              _vm._v(" "),
              _c("price-single", {
                staticClass: "float-right",
                attrs: { value: _vm.promotion.value },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("li", [
        _c("span", { staticClass: "mb-0" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.$ll("basketSummaryDelivery")) +
              ":\n            "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "float-right" },
          [
            _vm.free_delivery
              ? _c("span", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$ll("basketSummaryFreeDelivery")) +
                      "\n                "
                  ),
                ])
              : !!_vm.payment
              ? _c("price-single", { attrs: { value: _vm.cost } })
              : _c("span", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$ll("basketSummaryDeliveryNotSet")) +
                      "\n                "
                  ),
                ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mt-4 mb-0 basket-summary__total" }, [
        _c("span", [
          _c("span", {}, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$ll("basketSummaryFull")) +
                ":\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "span",
          [
            _c("price-single", {
              staticClass: "float-right text-black",
              attrs: { value: _vm.shipment.final },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }