var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "btn-group",
      class: {
        "vouchers-img": _vm.target == "vouchers",
        "voucher-error": _vm.field.error,
      },
    },
    _vm._l(_vm.field.data, function (data, value) {
      return _c(
        "label",
        {
          key: value,
          staticClass: "btn-outline-primary p-1 m-2 cursor-pointer",
          class: { active: value == _vm.field.value },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.field.value,
                expression: "field.value",
              },
            ],
            staticClass: "d-none",
            attrs: { type: "radio", name: _vm.field.name },
            domProps: { value: value, checked: _vm._q(_vm.field.value, value) },
            on: {
              change: function ($event) {
                return _vm.$set(_vm.field, "value", value)
              },
            },
          }),
          _vm._v(" "),
          _c("Picture", { attrs: { src: _vm.$photoUrl(data.url) } }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }