<template>
    <div>
        <div class="mb-1">
            {{ data.invoice_company_name }}
        </div>
        <span class="d-block">
            {{ data.invoice_tax_identifier }}
        </span>
        <span class="d-block">
            {{ data.invoice_address }} {{ data.invoice_building_number }}
        </span>
        <span class="d-block">
            {{ data.invoice_postcode }} {{ data.invoice_city }}
        </span>
        <span class="d-block">
            {{ data.invoice_phone }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        data: { type: Object, required: true }
    }
}
</script>
