import axios from 'axios'
import json from '@db'

export default {
    namespaced: true,
    state() {
        return {
            id: null,
            loaded: false,
            cupboard: false,
            fiches: json.fiches,
            product: {}
        }
    },
    mutations: {
        /**
         * @description Ustaw ID projektu
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {*} payload
         */
        setID(state, payload) {
            state.id = payload
        },
        /**
         * @description Czy twa ładowanie
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {*} payload
         */
        setLoaded(state, payload) {
            state.loaded = payload
        },

        /**
         * @description
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {*} state
         * @param {*} [payload={}]
         */
        setProduct(state, payload = {}) {
            const { product = {} } = payload

            state.product = product
        },

        /**
         * @description Czy wybrano koszyk (false) czy schowek (true)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         * @param {Boolean} payload
         */
        setCupboard(state, payload) {
            state.cupboard = payload
        }
    },
    actions: {

        /**
         * @description Ładowanie produktu (informacji o wariantach itp)
         * @author Marcin Skibiński <mskibinski@media4u.pl>
         */
        load({ commit, state }) {
            if (!state.id) return
            commit('setProduct')
            commit('setLoaded', false)

            axios
                .get(`/product/ajaxProductPreview/${state.id}`)
                .then(res => {
                    commit('setProduct', res.data)
                    commit('ProductCard/setFromAjax', res.data, { root: true })
                    commit('setLoaded', true)
                })
        }
    }
}