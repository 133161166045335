<template>
    <div class="product-item__colors">
        <div
            v-for="(color, index) in colors"
            v-show="index < 10"
            :key="color.variant_id"
            class="color-wrapper"
            >
            <a :href="`${url}#${color.attribute_value_id}`">
                <div
                    v-if="color.image_url"
                    class="color color--image"
                    :style="{backgroundImage: `url(/${color.image_url})`}"
                    />
                <div
                    v-else
                    class="color"
                    :style="{backgroundColor: color.color}"
                    />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        colors: {
            type: Array,
            required: true
        },
        url: {
            type: String,
            required: true
        }
    }
}
</script>