var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("basket-breadcrumb"),
      _vm._v(" "),
      _c("div", { staticClass: "container mt-4 pb-5 px-2" }, [
        _c("div", { staticClass: "row no-gutters justify-content-between" }, [
          _c(
            "div",
            { staticClass: "col-12 col-lg-5 col-md-6 mt-5 pr-0 pr-md-3" },
            [_c("login-form", { attrs: { alone: false } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-lg-5 col-md-6 mt-5 pl-0 pl-md-3" },
            [
              _c("h3", { staticClass: "mb-4 text-center" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$ll("basketLoginTitle")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("p", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$ll("basketLoginPluses")) + ":"),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("i", {
                    staticClass: "mdi mdi-18px mdi-check text-success",
                  }),
                  _vm._v(" " + _vm._s(_vm.$ll("basketLoginSaveAddress")) + " "),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("i", {
                    staticClass: "mdi mdi-18px mdi-check text-success",
                  }),
                  _vm._v(" " + _vm._s(_vm.$ll("basketLoginOrderStatus"))),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("i", {
                    staticClass: "mdi mdi-18px mdi-check text-success",
                  }),
                  _vm._v(" " + _vm._s(_vm.$ll("basketLoginOrderHistory"))),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary w-100",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setAccountCreate.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$ll("basketLoginCreateAccount")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "mt-2 text-center" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$ll("basketLoginOr")) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary w-100",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setGuestWithoutAccout.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$ll("basketLoginContinueWithout")) +
                        "\n                    "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }