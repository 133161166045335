var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "list-item__prices" },
    [
      _c("price", {
        attrs: {
          value: _vm.price.promoted || _vm.price.regular,
          "view-currency": _vm.viewCurrency,
        },
      }),
      _vm._v(" "),
      _vm.price.promoted && _vm.price.promoted != _vm.price.regular
        ? _c(
            "s",
            { staticClass: "d-block light-gray" },
            [
              _c("price", {
                attrs: {
                  value: _vm.price.regular,
                  "view-currency": _vm.viewCurrency,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }