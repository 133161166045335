var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._v("\n    " + _vm._s(_vm.$ll("basketOrderCountrySummary")) + ": "),
    _c("strong", { domProps: { textContent: _vm._s(_vm.countries.name) } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }