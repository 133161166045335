var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "buttons-container" }, [
    _c(
      "div",
      { staticClass: "basket__buttons", class: { fixed: _vm.buttonFixed } },
      [
        _c("div", { class: { container: _vm.buttonFixed } }, [
          _c("div", { staticClass: "row no-gutters justify-content-end" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.buttonFixed,
                    expression: "buttonFixed",
                  },
                ],
                staticClass: "col-12 mb-3",
              },
              [
                _c("label", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$ll("basketSummaryFull")) + ":"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "strong",
                  [
                    _c("price-single", {
                      staticClass: "float-right",
                      attrs: { value: _vm.shipment.final },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12 order-sm-last",
                class: { "col-sm-6": !_vm.buttonFixed },
              },
              [
                _c("button", {
                  staticClass: "btn btn-black w-100",
                  class: { disabled: _vm.loading },
                  attrs: { disabled: _vm.loading },
                  domProps: {
                    textContent: _vm._s(_vm.$ll("basketConfirmOrderButton")),
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.order.apply(null, arguments)
                    },
                  },
                }),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }