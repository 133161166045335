<template>
    <div class="row mb-3">
        <div class="col-12 col-sm-6 mb-3">
            {{ $ll('basketDeliveryInfo') }}:<br><strong class="text-black">
                {{ getDeliveries[deliveryId].name }}
            </strong>
        </div>
        <div class="col-12 col-sm-6 mb-3">
            {{ $ll('basketPaymentInfo') }}:<br><strong class="text-black">
                {{ getDeliveries[deliveryId].items[payment].name }}
            </strong>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
    computed: {
        ...mapState('Basket', ['deliveryId', 'payment']),
        ...mapGetters({
            getDeliveries: 'Basket/getDeliveries'
        })
    }
}
</script>
