var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "ul",
      { staticClass: "filters-fishes-list list-unstyled row" },
      _vm._l(_vm.fiches.v, function (fiche) {
        return _c(
          "li",
          { key: fiche.id, staticClass: "col-6" },
          [
            _c("filter-checkbox", {
              attrs: { filter: { id: 0 }, option: fiche, check: _vm.check },
              on: { toggle: _vm.toggle },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }