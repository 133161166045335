<template>
    <div class="p-sm-2 py-2 ">
        <div
            v-if="language.current == '1'"
            class="container"
            >
            <ol
                v-if="products.length"
                class="breadcrumb basket__breadcrumb"
                >
                <li
                    v-for="step in stepsPl.filter(step => !step.hide)"
                    :key="step.path"
                    class="breadcrumb-item"
                    :class="{ active: $route.path == step.path }"
                    >
                    <span
                        v-if="$route.path.endsWith(step.path)"
                        class="text-black"
                        v-text="step.name"
                        />
                    <span
                        v-else
                        class="light-gray"
                        v-text="step.name"
                        />
                    <!-- <router-link v-else :to="$langUrl(step.path)" v-text="step.name" /> -->
                </li>
            </ol>
            <div class="basket__breadcrumb--line d-none d-md-block" />
        </div>
        <div
            v-else
            class="container"
            >
            <ol
                v-if="products.length"
                class="breadcrumb basket__breadcrumb"
                >
                <li
                    v-for="step in stepsEn.filter(step => !step.hide)"
                    :key="step.path"
                    class="breadcrumb-item"
                    :class="{ active: $route.path == step.path }"
                    >
                    <span
                        v-if="$route.path.endsWith(step.path)"
                        class="font-weight-bold"
                        v-text="step.name"
                        />
                    <span
                        v-else
                        v-text="step.name"
                        />
                    <!-- <router-link v-else :to="$langUrl(step.path)" v-text="step.name" /> -->
                </li>
            </ol>
            <div class="basket__breadcrumb--line d-none d-md-block" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {

    data() {
        return {
            stepsPl: [
                {
                    path: '/basket',
                    name: 'Koszyk'
                },
                {
                    path: '/basket/delivery',
                    name: 'Dostawa'
                },
                {
                    path: '/basket/summary',
                    name: 'Podsumowanie'
                }
            ],
            stepsEn: [
                {
                    path: '/basket',
                    name: 'Basket'
                },
                {
                    path: '/basket/delivery',
                    name: 'Delivery'
                },
                {
                    path: '/basket/summary',
                    name: 'Summary'
                }
            ]
        }
    },
    computed: {
        ...mapState('Basket', ['products']),
        ...mapState('Setup', ['language'])
    }
}
</script>
