<template>
    <div class="form-field">
        <div
            class="form-field__checkbox form-field__checkbox--color pl-2"
            :class="{'color-white': (ColorOption.color=='#ffffff')}"
            >
            <i 
                :style="{ background: ColorOption.color }"
                class="form_checkbox-color"
                />

            <input
                :id="'col-' + filter.id + '-' + option.id"
                type="checkbox"
                :checked="check.indexOf(option.id) >= 0"
                @change="$emit('toggle', option.id, $event.target.checked)"
                >

            <label
                :for="'col-' + filter.id + '-' + option.id"
                class="form__checkbox-label"
                v-text="option.v"
                />
        </div>
    </div>
</template>

<script> 
export default {

    props: ['filter', 'option', 'check'],
    data() {
        return {
            ColorOption: this.option.p || { "color": "#000000" }
        }
    }

}
</script>