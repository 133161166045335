var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("button", {
    ref: "btn",
    staticClass: "btn btn-primary",
    class: { "py-0": _vm.disabled && _vm.submit },
    attrs: { disabled: _vm.disabled },
    domProps: { innerHTML: _vm._s(_vm.btnValue) },
    on: {
      click: function ($event) {
        return _vm.$emit("click")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }