var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GmapMap",
    {
      ref: "gmap",
      staticStyle: { width: "100%", height: "50vh" },
      attrs: { center: _vm.center, zoom: 5 },
      on: {
        bounds_changed: function ($event) {
          _vm.start = true
        },
      },
    },
    [
      _vm.infoWindow.opened
        ? _c(
            "GmapInfoWindow",
            {
              attrs: {
                options: _vm.infoWindow.options,
                position: _vm.infoWindow.position,
                opened: !!_vm.infoWindow.opened,
              },
              on: {
                closeclick: function ($event) {
                  _vm.infoWindow.opened = false
                },
              },
            },
            [
              _c("h6", { staticClass: "text-primary" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.infoWindow.shopName) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.infoWindow.shopAddress
                ? _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.infoWindow.shopAddress) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.infoWindow.shopCity
                ? _c("p", { staticClass: "mb-2" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.infoWindow.shopCity) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.infoWindow.shopPhone
                ? _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$ll("stationaryShopsTelLabel")) +
                        ": " +
                        _vm._s(_vm.infoWindow.shopPhone) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.infoWindow.shopFax
                ? _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$ll("stationaryShopsFaxLabel")) +
                        ": " +
                        _vm._s(_vm.infoWindow.shopFax) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.infoWindow.shopEmail
                ? _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.infoWindow.shopEmail) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.infoWindow.shopWorkHours
                ? _c("h6", { staticClass: "mt-3" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$ll("stationaryShopsWorkingHours")) +
                        ":\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.infoWindow.shopWorkHours
                ? _c("p", {
                    staticClass: "mb-0",
                    domProps: {
                      innerHTML: _vm._s(_vm.infoWindow.shopWorkHours),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.infoWindow.shopAdditionalInfo
                ? _c("h6", { staticClass: "mt-3" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$ll("stationaryShopsAdditionalInfo")) +
                        ":\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", {
                staticClass: "mb-0",
                domProps: {
                  innerHTML: _vm._s(_vm.infoWindow.shopAdditionalInfo),
                },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }