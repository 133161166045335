<template>
    <div class="row no-gutters">
        <div class="col-12 mb-1">
            <div class="basket-discount-input-row">
                <div>
                    <div class="form-field form-field--alternate mb-2">
                        <input
                            v-model="value"
                            :disabled="quantityChangePending"
                            class="form-control form-field__input"
                            :class="{ 'form-field__input--invalid': discountError }"
                            :placeholder="$ll('basketCodePlaceholder')"
                            >
                        <label
                            class="form-field__placeholder"
                            v-text="$ll('basketCodePlaceholder')"
                            />
                        <div
                            v-if="discountError"
                            class="form-field__hint form-field__hint--invalid"
                            v-text="discountError"
                            />
                    </div>
                </div>
                <div>
                    <button
                        class="btn btn-secondary btn-secondary-slimed px-2 mr-1 w-100"
                        :class="{disabled: quantityChangePending }"
                        :disabled="!value"
                        @click="setDiscount($event,value)"
                        >
                        {{ $ll('basketCodeSubmit') }}
                    </button>
                </div>
            </div>
            <div
                v-if="discount.code"
                class="clearer"
                >
                <small>
                    {{ $ll('basketCodeUsed') }}: {{ discount.name }}
                    <a
                        class="btn-link-inline no-hover d-inline-block ml-2"
                        @click.prevent="clearDiscount"
                        >
                        <small><i class="i-close font-weight-bolder" /></small>
                    </a>
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {

    data() {
        return {
            value: ''
        }
    },

    computed: {
        ...mapState('Basket', [
            'discount', 'quantityChangePending', 'discountError'
        ])
    },

    methods: {
        ...mapActions({
            clearDiscount: 'Basket/clearDiscount'
        }),
        setDiscount(e) {
            if (this.quantityChangePending) return
            if (e.keyCode === 13 || e.type === 'click') {
                this.$store.dispatch('Basket/setDiscount', this.value)
                this.value = ''
            }
        }
    }
}
</script>
