var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("picture", { staticClass: "h-100" }, [
    !_vm.error && _vm.webp
      ? _c("source", {
          attrs: {
            srcset: _vm.webp,
            type: "image/webp",
            width: _vm.width,
            height: _vm.height,
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("source", {
      attrs: { srcset: _vm.srcEncode, width: _vm.width, height: _vm.height },
    }),
    _vm._v(" "),
    _vm.lazy
      ? _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.srcEncode,
              expression: "srcEncode",
            },
          ],
          staticClass: "img-fluid",
          class: _vm.classImg,
          style: _vm.styleImg,
          attrs: {
            loading: _vm.loading,
            alt: _vm.alt,
            width: _vm.width,
            height: _vm.height,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
            error: _vm.imgError,
          },
        })
      : _c("img", {
          staticClass: "img-fluid",
          class: _vm.classImg,
          style: _vm.styleImg,
          attrs: {
            src: _vm.srcEncode,
            loading: _vm.loading,
            alt: _vm.alt,
            width: _vm.width,
            height: _vm.height,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
            error: _vm.imgError,
          },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }